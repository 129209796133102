import {Component, Input} from '@angular/core';
import {IChangeLog} from 'app/shared/model/admin/audit/change-log.model';
import {MaskService} from 'app/shared/mask/mask.service';
import {IBaseEntity} from 'app/shared/model/root/base-entity.model';
import {FormModelChangeLogDetailModalService} from 'app/entities/form/form-model/form-model-change-log-detail-modal.service';
import {FormModelActionsService} from 'app/entities/form/form-model/form-model-actions.service';
import {FormModel, IFormModel} from 'app/shared/model/form/form-model.model';
import {FormModelQuestion, IFormModelQuestion} from 'app/shared/model/form/form-model-question.model';
import {allAlertColors} from 'app/shared/model/enumerations/alert-color.enum';
import {IFormModelCategory} from 'app/shared/model/form/form-model-category.model';
import {IFormModelQuestionOption} from 'app/shared/model/form/form-model-question-option.model';

@Component({
  selector: 'app-frm-form-model-change-log-detail-modal',
  templateUrl: './form-model-change-log-detail-modal.component.html'
})
export class FormModelChangeLogDetailModalComponent {
  @Input()
  changeLog!: IChangeLog;

  types = FormModel.allowedFormTypes();
  owners = FormModel.allowedOwners();
  questionTypes = FormModelQuestion.allowedFormQuestionTypes();
  alertColors = allAlertColors();

  close?: Function;

  constructor(
    private service: FormModelChangeLogDetailModalService,
    private formModelActionsService: FormModelActionsService,
    public mask: MaskService
  ) {
  }

  trackIdentity(index: number, item: IBaseEntity): any {
    return item.id ?? item.uuid;
  }

  totalActiveFormModelQuestions(formModel: IFormModel): number {
    return this.formModelActionsService.totalActiveFormModelQuestions(formModel);
  }

  showFormModelQuestionDetails(question: IFormModelQuestion): void {
    this.formModelActionsService.showFormModelQuestionDetails(question);
  }

  totalActiveOptions(formModelQuestion: IFormModelQuestion): number {
    return this.formModelActionsService.totalActiveFormModelQuestionOptions(formModelQuestion);
  }

  showFormModelCategoryDetails(category: IFormModelCategory): void {
    this.formModelActionsService.showFormModelCategoryDetails(category);
  }

  totalActiveFormModelCategories(formModel: IFormModel): number {
    return this.formModelActionsService.totalActiveFormModelCategories(formModel);
  }

  toggleDisplayOptions(question: IFormModelQuestion): void {
    question._displayOptions = !question._displayOptions;
  }

  showFormModelQuestionOptionDetails(option: IFormModelQuestionOption): void {
    this.formModelActionsService.showFormModelQuestionOptionDetails(option);
  }

  onCloseClicked(): void {
    this.close && this.close();
    this.service.close();
  }
}
