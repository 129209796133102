import moment, {Moment} from 'moment';
import {Company, ICompany} from 'app/shared/model/company/company.model';
import {BaseEntity, IBaseEntity} from 'app/shared/model/root/base-entity.model';
import {Criteria} from 'app/shared/filter/criteria.model';
import {City, ICity} from 'app/shared/model/location/city.model';
import {allEntityTypes, EntityType} from 'app/shared/model/enumerations/entity-type.enum';
import {IUser, User} from 'app/core/user/user.model';
import {allLocationOrigins, LocationOrigin} from 'app/shared/model/enumerations/location-origin.enum';

export interface ILocation extends IBaseEntity {
  address?: string;
  number?: string;
  neighborhood?: string;
  complement?: string;
  description?: string;
  phone?: string;
  currentlyActive?: boolean;
  zipCode?: string;
  company?: ICompany;
  city?: ICity;
  origin?: LocationOrigin;
  linkedEntityType?: EntityType;
  linkedEntityUuid?: string;
  owner?: IUser;
}

export class Location extends BaseEntity implements ILocation {
  constructor(
    public id?: number,
    public uuid?: string,
    public createdBy?: string,
    public createdDate?: Moment,
    public lastModifiedBy?: string,
    public lastModifiedDate?: Moment,
    public active?: boolean,
    public currentlyActive?: boolean,
    public address?: string,
    public number?: string,
    public phone?: string,
    public neighborhood?: string,
    public complement?: string,
    public description?: string,
    public zipCode?: string,
    public company?: ICompany,
    public city?: ICity,
    public origin?: LocationOrigin,
    public linkedEntityType?: EntityType,
    public linkedEntityUuid?: string,
    public owner?: IUser,
    public _label?: string,
    public _edited?: boolean
  ) {
    super(id, uuid, createdBy, createdDate, lastModifiedBy, lastModifiedDate, active, _label, _edited);
  }

  public static allowedOrigins(): LocationOrigin[] {
    return allLocationOrigins();
  }

  public static allowedEntityTypes(): EntityType[] {
    return allEntityTypes();
  }

  public static criterias(): Criteria<Location>[] {
    return Criteria.buildFrom(this.example()).flatMap(criteria =>
      criteria.property.name === 'city'
        ? [Criteria.fromTransient('state', 'object'), criteria]
        : criteria.property.name === 'origin'
        ? criteria.asEnum(() => this.allowedOrigins(), 'locationOrigin')
        : criteria.property.name === 'linkedEntityType'
        ? criteria.asEnum(() => this.allowedEntityTypes(), 'entityType')
        : criteria
    );
  }

  public static example(): Location {
    return new Location(
      0,
      '',
      '',
      moment(new Date()),
      '',
      moment(new Date()),
      false,
      false,
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      new Company(),
      new City(),
      LocationOrigin.USER_INPUT,
      EntityType.USR_USER,
      '',
      new User(),
      '',
      false
    );
  }
}
