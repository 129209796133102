import {Injectable} from '@angular/core';
import {ButtonColor} from 'app/shared/layout/window/button.model';
import {JhiLanguageService} from 'ng-jhipster';
import {SessionStorageService} from 'ngx-webstorage';

export type LanguageType = {
  code: string;
  name: string;
  rtl?: boolean;
  icon?: string;
  color?: ButtonColor;
};

export const APP_LANGUAGES: LanguageType[] = [
  {
    code: 'en',
    name: 'English',
    icon: 'fi-us',
    color: 'secondary',
  },
  {
    code: 'pt-br',
    name: 'Português',
    icon: 'fi-br',
    color: 'success',
  },
  {
    code: 'es',
    name: 'Español',
    icon: 'fi-es',
    color: 'danger',
  },
];

@Injectable({providedIn: 'root'})
export class LanguageUtil {
  constructor(private sessionStorage: SessionStorageService, private languageService: JhiLanguageService) {}

  changeLanguage(languageKey: string): void {
    this.sessionStorage.store('locale', languageKey);
    this.languageService.changeLanguage(languageKey);
  }

  getCurrentCode(): string {
    return this.languageService.getCurrentLanguage();
  }

  getCurrentSessionCode(): string {
    return this.sessionStorage.retrieve('locale');
  }

  getActiveCode(): string {
    return this.getCurrentCode() ?? this.getCurrentSessionCode();
  }

  public static get(lang: string): LanguageType {
    return APP_LANGUAGES.find(l => l.code === lang);
  }

  public static getName(lang: string): string {
    return this.get(lang)?.name ?? '';
  }

  public static getIcon(lang: string): string {
    return this.get(lang)?.icon ?? '';
  }

  public static isRTL(lang: string): boolean {
    return this.get(lang)?.rtl === true;
  }

  public static isValid(lang: string): boolean {
    return !!this.get(lang);
  }

  static getDefault(): LanguageType {
    return this.get('pt-br');
  }
}
