import {Component, Input} from '@angular/core';
import {ILocation} from 'app/shared/model/location/location.model';
import {StringUtilService} from 'app/shared/util/string-util.service';

@Component({
  selector: 'app-loc-location-output',
  template: `
    <ng-container *ngIf="location && !map">{{ print() }}</ng-container>
    <a *ngIf="location && map" [href]="buildMapLink()" target="_blank">
      <span>{{ print() }}</span>
      &nbsp;
      <fa-icon [fixedWidth]="true"icon="map-marked-alt"></fa-icon>
    </a>
  `
})
export class LocationOutputComponent {
  @Input()
  location: ILocation | null = null;

  @Input()
  map = true;

  constructor(private stringUtilService: StringUtilService) {}

  print(): string {
    return (this.location && this.stringUtilService.formatLocation(this.location)) || '';
  }

  buildMapLink(): string {
    return this.stringUtilService.googleMapsLink(this.location);
  }
}
