import moment, {Moment} from 'moment';
import {Company, ICompany} from 'app/shared/model/company/company.model';
import {Criteria} from 'app/shared/filter/criteria.model';
import {BaseEntity, IBaseEntity} from 'app/shared/model/root/base-entity.model';
import {Status} from 'app/shared/model/enumerations/status.enum';
import {IUser, User} from 'app/core/user/user.model';
import {FormModel, IFormModel} from 'app/shared/model/form/form-model.model';
import {IChangeLog} from 'app/shared/model/admin/audit/change-log.model';
import {IFormAnswer} from 'app/shared/model/form/form-answer.model';
import {Owner} from 'app/shared/model/enumerations/owner.enum';

export interface IForm extends IBaseEntity {
	owner?: Owner;
	company?: ICompany;
	user?: IUser;
	formModel?: IFormModel;
	name?: string;
	status?: Status;
	startedDate?: Moment;
	finishedDate?: Moment;
	score?: number;
	approvalUser?: IUser;
	approvalDate?: Moment;
	answers?: IFormAnswer[];
	changeLogs?: IChangeLog[];
}

export class Form extends BaseEntity implements IForm {
	constructor(
		public id?: number,
		public uuid?: string,
		public createdBy?: string,
		public createdDate?: Moment,
		public lastModifiedBy?: string,
		public lastModifiedDate?: Moment,
		public active?: boolean,
		public owner?: Owner,
		public company?: ICompany,
		public user?: IUser,
		public formModel?: IFormModel,
		public name?: string,
		public status?: Status,
		public startedDate?: Moment,
		public finishedDate?: Moment,
		public score?: number,
		public approvalUser?: IUser,
		public approvalDate?: Moment,
		public answers?: IFormAnswer[],
		public changeLogs?: IChangeLog[],
		public _label?: string,
		public _edited?: boolean
	) {
		super(id, uuid, createdBy, createdDate, lastModifiedBy, lastModifiedDate, active, _label, _edited);
	}

	public static convertDatesFromClient(form: IForm): IForm {
		const copy: IForm = Object.assign({}, form, {
			createdDate: form.createdDate && form.createdDate.isValid() ? form.createdDate.toJSON() : undefined,
			lastModifiedDate: form.lastModifiedDate && form.lastModifiedDate.isValid() ? form.lastModifiedDate.toJSON() : undefined,
			startedDate: form.startedDate && form.startedDate.isValid() ? form.startedDate.toJSON() : undefined,
			finishedDate: form.finishedDate && form.finishedDate.isValid() ? form.finishedDate.toJSON() : undefined,
			approvalDate: form.approvalDate && form.approvalDate.isValid() ? form.approvalDate.toJSON() : undefined,
			answers: (form.answers || []).map(a => ({
				...a,
				startedDate: a.startedDate && moment(a.startedDate).isValid() ? moment(a.startedDate).toJSON() : undefined,
				finishedDate: a.finishedDate && moment(a.finishedDate).isValid() ? moment(a.finishedDate).toJSON() : undefined,
				approvalDate: a.approvalDate && moment(a.approvalDate).isValid() ? moment(a.approvalDate).toJSON() : undefined
			}))
		});
		return copy;
	}

	public static convertDatesFromServer(form: IForm): IForm {
		if (form) {
			form.createdDate = form.createdDate ? moment(form.createdDate) : undefined;
			form.lastModifiedDate = form.lastModifiedDate ? moment(form.lastModifiedDate) : undefined;
			form.startedDate = form.startedDate ? moment(form.startedDate) : undefined;
			form.finishedDate = form.finishedDate ? moment(form.finishedDate) : undefined;
			form.approvalDate = form.approvalDate ? moment(form.approvalDate) : undefined;
			form.formModel = FormModel.convertDatesFromServer(form.formModel);
			form.answers &&
			form.answers.forEach((answer: IFormAnswer) => {
				answer.startedDate = answer.startedDate ? moment(answer.startedDate) : undefined;
				answer.finishedDate = answer.finishedDate ? moment(answer.finishedDate) : undefined;
				answer.approvalDate = answer.approvalDate ? moment(answer.approvalDate) : undefined;
			});
		}
		return form;
	}

	public static criterias(): Criteria<Form>[] {
		return Criteria.buildFrom(this.example())
			.filter(criteria => !['answers', 'changeLogs'].includes(criteria.property.name))
			.map(criteria => criteria.property.name === 'owner'
				? criteria.asEnum(() => this.allowedOwner())
				: criteria.property.name === 'status'
					? criteria.asEnum(() => this.allowedStatus()) : criteria);
	}

	public static allowedOwner(): Owner[] {
		return [Owner.SYSTEM, Owner.USER];
	}

	public static allowedStatus(): Status[] {
		return [Status.DRAFT, Status.AVAILABLE, Status.IN_PROGRESS, Status.FINISHED, Status.CANCELED];
	}

	public static example(): Form {
		return new Form(
			0,
			'',
			'',
			moment(new Date()),
			'',
			moment(new Date()),
			false,
			Owner.SYSTEM,
			new Company(),
			new User(),
			new FormModel(),
			'',
			Status.NEW,
			moment(new Date()),
			moment(new Date()),
			0.0,
			new User(),
			moment(new Date()),
			[],
			[],
			'',
			false
		);
	}
}
