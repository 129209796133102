import {Injectable} from '@angular/core';
import {NgbModal, NgbModalRef} from '@ng-bootstrap/ng-bootstrap';
import {IFormAnswer} from 'app/shared/model/form/form-answer.model';
import {FormAnswerRejectModalComponent} from 'app/entities/form/form/form-answer-reject-modal.component';

@Injectable({providedIn: 'root'})
export class FormAnswerRejectModalService {
  private isOpen = false;
  private instance?: NgbModalRef;

  constructor(private modalService: NgbModal) {}

  show(formAnswer: IFormAnswer, confirm?: Function, cancel?: Function): void {
    if (this.isOpen) return;

    this.isOpen = true;

    this.instance = this.modalService.open(FormAnswerRejectModalComponent, {size: 'lg', backdrop: 'static'});
    this.instance.componentInstance.formAnswer = formAnswer;
    this.instance.componentInstance.confirm = confirm;
    this.instance.componentInstance.cancel = cancel;

    this.instance.result.finally(() => (this.isOpen = false));
  }

  confirm(): void {
    if (!this.isOpen || !this.instance) return;
    this.instance.close();
  }

  dismiss(): void {
    if (!this.isOpen || !this.instance) return;
    this.instance.dismiss();
  }
}
