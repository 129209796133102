import {Component, Input} from '@angular/core';
import {MaskService} from 'app/shared/mask/mask.service';
import {IFormModelQuestionOption} from 'app/shared/model/form/form-model-question-option.model';
import {FormModelQuestionOptionDetailModalService} from 'app/entities/form/form-model/form-model-question-option-detail-modal.service';

@Component({
  selector: 'app-frm-form-model-question-option-detail-modal',
  templateUrl: './form-model-question-option-detail-modal.component.html'
})
export class FormModelQuestionOptionDetailModalComponent {
  @Input()
  formModelQuestionOption!: IFormModelQuestionOption;

  close?: Function;

  constructor(private service: FormModelQuestionOptionDetailModalService, public mask: MaskService) {}

  onCloseClicked(): void {
    this.close && this.close();
    this.service.close();
  }
}
