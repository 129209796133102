import {Routes} from '@angular/router';

export const adminRoutes: Routes = [
  {
    path: 'adm/plans',
    loadChildren: () => import('./plan/plan.module').then(m => m.AppPlanModule),
    data: {
      pageTitle: 'plan.home.title'
    }
  },
  {
    path: 'adm/files',
    loadChildren: () => import('./file/file.module').then(m => m.FileModule),
    data: {
      pageTitle: 'file.home.title'
    }
  },
  {
    path: 'adm/options',
    loadChildren: () => import('./option/option.module').then(m => m.AppOptionModule),
    data: {
      pageTitle: 'option.home.title'
    }
  },
  {
    path: 'adm/changes-logs',
    loadChildren: () => import('./audit/change-log/change-log.module').then(m => m.AppChangeLogModule),
    data: {
      pageTitle: 'changeLog.home.title'
    }
  },
  {
    path: 'adm/parameters',
    loadChildren: () => import('./parameter/parameter.module').then(m => m.AppParameterModule),
    data: {
      pageTitle: 'parameter.home.title'
    }
  },
  {
    path: 'adm/sequences',
    loadChildren: () => import('./sequence/sequence.module').then(m => m.AppSequenceModule),
    data: {
      pageTitle: 'sequence.home.title'
    }
  },
  {
    path: 'adm/units',
    loadChildren: () => import('./unit/unit.module').then(m => m.AppUnitModule),
    data: {
      pageTitle: 'unit.home.title'
    }
  },
  {
    path: 'adm/user-manual',
    loadChildren: () => import('./user-manual/user-manual.module').then(m => m.AppUserManual),
    data: {
      pageTitle: 'userManual.home.title'
    }
  },
  {
    path: 'adm/generals-alerts',
    loadChildren: () => import('./general-alert/general-alert.module').then(m => m.AppGeneralAlertModule),
    data: {
      pageTitle: 'generalAlerts.home.title'
    }
  },
  {
    path: 'adm/holidays',
    loadChildren: () => import('./holiday/holiday.module').then(m => m.AppHolidayModule),
    data: {
      pageTitle: 'holiday.home.title'
    }
  }
];
