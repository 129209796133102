import {Component, EventEmitter, Input, Output} from '@angular/core';
import {ILocation, Location} from 'app/shared/model/location/location.model';
import {LocationEditorModalService} from 'app/shared/location/location-editor-modal.service';
import {StringUtilService} from 'app/shared/util/string-util.service';
import {ButtonBuilder} from 'app/shared/layout/window/button-builder';
import {GenericModalService} from 'app/shared/modal/generic-modal.service';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'app-loc-location-input',
  styles: [
    `
      :host {
        display: flex;
        flex: auto;
      }

      .btn {
        border-bottom-left-radius: 0;
        border-top-left-radius: 0;
      }
    `
  ],
  template: `
    <input type="text" class="form-control" readonly [value]="print()" />
    <div *ngIf="editBtnVisible || removeBtnVisible" class="input-group-append">
      <button
        *ngIf="entity && removeBtnVisible"
        class="btn btn-outline-danger"
        (click)="remove()"
        type="button"
        [disabled]="removeBtnDisabled"
        [title]="'entity.action.remove' | translate"
      >
        <fa-icon [fixedWidth]="true"icon="times"></fa-icon>
      </button>
      <button
        *ngIf="editBtnVisible"
        class="btn btn-outline-secondary"
        (click)="edit()"
        type="button"
        [disabled]="editBtnDisabled"
        [title]="'entity.action.edit' | translate"
      >
        <fa-icon [fixedWidth]="true"icon="edit"></fa-icon>
      </button>
      <button
        *ngIf="mapsBtnVisible && entity?.address?.length"
        class="btn btn-outline-info"
        type="button"
        (click)="openMap()"
        [disabled]="mapsBtnDisabled"
        [title]="'entity.action.openMap' | translate"
      >
        <fa-icon [fixedWidth]="true"icon="map-marked-alt"></fa-icon>
      </button>
    </div>
  `
})
export class LocationInputComponent {
  @Input()
  entity: Location | null = null;

  @Input()
  editBtnVisible = true;

  @Input()
  editBtnDisabled = false;

  @Input()
  removeBtnVisible = true;

  @Input()
  removeBtnDisabled = false;

  @Input()
  mapsBtnVisible = true;

  @Input()
  mapsBtnDisabled = false;

  @Output()
  onEdited: EventEmitter<ILocation> = new EventEmitter<ILocation>();

  @Output()
  onRemoved: EventEmitter<ILocation | null> = new EventEmitter<ILocation | null>();

  constructor(
    private stringUtilService: StringUtilService,
    private locationEditorModalService: LocationEditorModalService,
    private genericModalService: GenericModalService,
    private translateService: TranslateService
  ) {}

  print(): string {
    return this.entity ? this.stringUtilService.formatLocation(this.entity) : '';
  }

  edit(): void {
    this.locationEditorModalService.show(this.entity || new Location(), (location: Location) => this.onEdited.emit(location));
  }

  remove(): void {
    this.genericModalService.show('entity.modals.confirm.title', this.translateService.instant('location.modals.editor.remove.question'), [
      ButtonBuilder.cancel(() => this.genericModalService.dismiss()),
      ButtonBuilder.remove(() => {
        this.onRemoved.emit(this.entity);
        this.genericModalService.confirm();
      })
    ]);
  }

  openMap(): void {
    window.open(this.buildMapLink(), '_blank');
  }

  buildMapLink(): string {
    return this.stringUtilService.googleMapsLink(this.entity);
  }
}
