<div class="btn-group" ngbDropdown [autoClose]="'outside'" role="group" container="body" placement="bottom-right"
     #myDrop="ngbDropdown">
  <button type="button" (click)="myDrop.toggle();" class="btn btn-default btn-sm no-after-content"
          title="{{ 'entity.action.chooseColumns' | translate }}"
          ngbDropdownAnchor>
    <fa-icon [fixedWidth]="true" icon="columns"></fa-icon>
    <ng-container *ngIf="showArrowIcon">
      &nbsp;
      <fa-icon [icon]="myDrop.isOpen() ? 'sort-up' : 'sort-down'"></fa-icon>
    </ng-container>
  </button>
  <div class="dropdown-menu entity-menu-dropdown-menu" ngbDropdownMenu>

    <ng-container *ngFor="let widget of widgets;">
      <div class="dropdown-divider"></div>
      <a class="dropdown-item entity-menu-dropdown-item-min"
         (click)="save(widget.id)">
        <div class="custom-control custom-switch">
          <input type="checkbox" class="custom-control-input"
                 [checked]="widgetsMap.get(widget.id)" (click)="onCheckboxClick($event)"
                 onkeydown="return false;" [id]="'checkbox-'+widget.id" [name]="'checkbox-'+widget.id">
          <label class="custom-control-label" [for]="'checkbox-'+widget.id">
            {{widget.name}}
          </label>
        </div>
      </a>
    </ng-container>

  </div>
</div>