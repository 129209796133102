import {Pipe, PipeTransform} from '@angular/core';
import {Logger} from 'app/shared/util/logger';

const Log = new Logger('JsonAsObjectPipe');

@Pipe({name: 'jsonAsObject'})
export class JsonAsObjectPipe implements PipeTransform {
  transform(value) {
    try {
      return JSON.parse(value);
    } catch (e) {
      Log.error(() => `Invalid JSON -> ${value}`, e);
      return null;
    }
  }
}
