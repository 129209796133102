import moment, {Moment} from 'moment';
import {Criteria} from 'app/shared/filter/criteria.model';
import {BaseEntity, IBaseEntity} from 'app/shared/model/root/base-entity.model';
import {File, IFile} from 'app/shared/model/admin/file.model';
import {Status} from 'app/shared/model/enumerations/status.enum';
import {User} from 'app/core/user/user.model';
import {PostType} from 'app/shared/model/enumerations/post-type.enum';
import {IPostCategory, PostCategory} from 'app/shared/model/content/post-category.model';
import {IMetadata, Metadata} from 'app/shared/model/content/metadata.model';
import {IChangeLog} from 'app/shared/model/admin/audit/change-log.model';
import {Company, ICompany} from 'app/shared/model/company/company.model';

export interface IPost extends IBaseEntity {
  company?: ICompany;
  title?: string;
  subtitle?: string;
  user?: User;
  postCategory?: IPostCategory;
  content?: string;
  image?: IFile;
  faIcon?: string;
  type?: PostType;
  date?: Moment;
  highlightMode?: boolean;
  showAtStoreMenu?: boolean;
  showAtStoreFooter?: boolean;
  status?: Status;
  readingTime?: number;
  metadata?: IMetadata;
  changeLogs?: IChangeLog[];
}

export class Post extends BaseEntity implements IPost {
  constructor(
    public id?: number,
    public uuid?: string,
    public createdBy?: string,
    public createdDate?: Moment,
    public lastModifiedBy?: string,
    public lastModifiedDate?: Moment,
    public active?: boolean,
    public company?: ICompany,
    public title?: string,
    public subtitle?: string,
    public user?: User,
    public postCategory?: IPostCategory,
    public content?: string,
    public image?: IFile,
    public faIcon?: string,
    public type?: PostType,
    public date?: Moment,
    public highlightMode?: boolean,
    public showAtStoreMenu?: boolean,
    public showAtStoreFooter?: boolean,
    public status?: Status,
    public readingTime?: number,
    public metadata?: IMetadata,
    public changeLogs?: IChangeLog[],
    public _label?: string,
    public _edited?: boolean
  ) {
    super(id, uuid, createdBy, createdDate, lastModifiedBy, lastModifiedDate, active, _label, _edited);
  }

  public static allowedTypes(): PostType[] {
    return [PostType.CONTENT, PostType.BLOG, PostType.NEWS];
  }

  public static allowedStatus(): Status[] {
    return [Status.ACTIVE, Status.INACTIVE];
  }

  public static criterias(): Criteria<Post>[] {
    return Criteria.buildFrom(this.example())
      .filter(criteria => !['metadata', 'changeLogs'].includes(criteria.property.name))
      .map(criteria =>
        criteria.property.name === 'image'
          ? criteria.asFilled()
          : criteria.property.name === 'faIcon'
            ? criteria.asFaIcon()
            : criteria.property.name === 'status'
              ? criteria.asEnum(() => this.allowedStatus())
              : criteria.property.name === 'type'
                ? criteria.asEnum(() => this.allowedTypes(), 'postType')
                : criteria
      );
  }

  public static example(): Post {
    return new Post(
      0,
      '',
      '',
      moment(new Date()),
      '',
      moment(new Date()),
      false,
      new Company(),
      '',
      '',
      new User(),
      new PostCategory(),
      '',
      new File(),
      '',
      PostType.BLOG,
      moment(new Date()),
      false,
      false,
      false,
      Status.ACTIVE,
      0,
      new Metadata(),
      [],
      '',
      false
    );
  }
}
