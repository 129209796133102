<div *ngIf="formAnswer">
  <div class="modal-body">
    <div class="row justify-content-center">
      <ngb-accordion class="col-12 entity-details" activeIds="panelEntity">
        <ngb-panel id="panelEntity">
          <ng-template ngbPanelTitle>
            <fa-icon [fixedWidth]="true" icon="list-alt"></fa-icon>
            &nbsp;
            <span>{{'entity.panels.details' | translate}}</span>
          </ng-template>
          <ng-template ngbPanelContent>
            <div class="row justify-content-center">
              <dl class="col-12">
                <dt><span jhiTranslate="global.field.id"></span></dt>
                <dd>{{ formAnswer.id }}</dd>

                <dt><span jhiTranslate="form.formAnswer.question"></span></dt>
                <dd>
                  <a *ngIf="formAnswer.question" href="javascript:void(0);"
                     (click)="showFormModelQuestionDetails(formAnswer.question)">{{ formAnswer.question._label }}</a>
                </dd>


                <ng-container *dmHasAnyAuthority="'SYSTEM_ADMIN'">
                  <ng-container *ngIf="formAnswer.answerFile">
                    <dt><span jhiTranslate="form.formAnswer.answerFile"></span></dt>
                    <dd>
                      <app-view-route-link [entity]="formAnswer.answerFile"
                                           route="adm/files" target="_blank"></app-view-route-link>
                    </dd>
                  </ng-container>
                </ng-container>

                <dt><span jhiTranslate="form.score"></span></dt>
                <dd>{{ formAnswer.score | number:'1.2-2' }}</dd>

                <dt><span jhiTranslate="form.startedDate"></span></dt>
                <dd>{{ formAnswer.startedDate | dateMoment:'dd/MM/yyyy HH:mm' }}</dd>

                <dt><span jhiTranslate="form.finishedDate"></span></dt>
                <dd>{{ formAnswer.finishedDate | dateMoment:'dd/MM/yyyy HH:mm' }}</dd>

                <dt><span jhiTranslate="form.approvalDate"></span></dt>
                <dd>{{ formAnswer.approvalDate | dateMoment:'dd/MM/yyyy HH:mm' }}</dd>

                <dt><span jhiTranslate="form.approvalUser"></span></dt>
                <dd>
                  <app-view-route-link [entity]="formAnswer.approvalUser" [param]="formAnswer.approvalUser?.login"
                                       route="usr/users" target="_blank"></app-view-route-link>
                </dd>

                <dt><span jhiTranslate="form.formAnswer.approvalObs"></span></dt>
                <dd>{{ formAnswer.approvalObs }}</dd>

                <dt><span jhiTranslate="global.field.status"></span></dt>
                <dd>
                  <app-status-label [status]="formAnswer.status"></app-status-label>
                </dd>
              </dl>
            </div>
          </ng-template>
        </ngb-panel>

        <ngb-panel
          *ngIf="['SINGLE_CHOICE', 'MULTIPLE_CHOICE'].includes(formAnswer.question?.type) && formAnswer.options"
          id="panelOptions">
          <ng-template ngbPanelTitle>
            <div class="d-flex justify-content-between">
              <div>
                <fa-icon [fixedWidth]="true" icon="check-double"></fa-icon>
                &nbsp;
                <span jhiTranslate="form.formAnswer.options"></span>
                &nbsp;
                <span *ngIf="totalActiveFormAnswerOptions()"
                      class="badge badge-secondary">{{totalActiveFormAnswerOptions()}}</span>
              </div>
            </div>
          </ng-template>

          <ng-template ngbPanelContent>
            <div *ngIf="!formAnswer.options.length"
                 class="list-empty-alert alert alert-light text-center" role="alert">
              <div class="font-weight-bold pb-2">
                <fa-icon [fixedWidth]="true" icon="exclamation-circle"></fa-icon>
                &nbsp;
                <span jhiTranslate="global.empty.list.alt"></span>
              </div>
            </div>
            <div *ngIf="formAnswer.options.length" class="table-responsive">
              <table class="table table-hover">
                <thead class="thead-light">
                <tr>
                  <th scope="col" class="icon"></th>
                  <th scope="col" class="text-center">
                    <span jhiTranslate="global.field.id"></span>
                  </th>
                  <th scope="col">
                    <span jhiTranslate="form.formAnswerOption.option"></span>
                  </th>
                </tr>
                </thead>
                <tbody>
                <tr
                  *ngFor="let formAnswerOption of formAnswer.options  | orderBy:'active':true | orderBy:'option?.order'; trackBy: trackIdentity"
                  [class]="{'notActive': !formAnswerOption.active}">
                  <td class="text-center dirty-warning">
                    <fa-icon *ngIf="formAnswerOption._edited" [fixedWidth]="true" icon="exclamation-triangle"
                             title="{{'global.messages.dirty.warning' | translate}}"></fa-icon>
                  </td>
                  <td class="text-center">
                    <a *ngIf="formAnswerOption.id" href="javascript:void(0);"
                       (click)="showFormAnswerOptionDetails(formAnswerOption)">{{ formAnswerOption.id }}</a>
                  </td>
                  <td>
                    <a *ngIf="formAnswerOption.option" href="javascript:void(0);"
                       (click)="showFormModelQuestionOptionDetails(formAnswerOption.option)">{{formAnswerOption.option?._label}}</a>
                  </td>
                </tr>
                </tbody>
              </table>
            </div>
          </ng-template>
        </ngb-panel>

        <ngb-panel id="panelAudit">
          <ng-template ngbPanelTitle>
            <fa-icon [fixedWidth]="true" icon="exclamation-circle"></fa-icon>
            &nbsp;
            <span>{{'entity.panels.audit' | translate}}</span>
          </ng-template>
          <ng-template ngbPanelContent>
            <div class="row justify-content-center">
              <dl class="col-12">
                <dt><span jhiTranslate="global.field.uuid"></span></dt>
                <dd>{{ formAnswer.uuid }}</dd>
                <dt><span jhiTranslate="global.field.active"></span></dt>
                <dd>
                  <app-bool-icon [value]="formAnswer.active"></app-bool-icon>
                </dd>
                <dt><span jhiTranslate="global.field.createdBy"></span></dt>
                <dd>{{ formAnswer.createdBy }}</dd>
                <dt><span jhiTranslate="global.field.createdDate"></span></dt>
                <dd>{{ formAnswer.createdDate | dateMoment:'dd/MM/yyyy HH:mm' }}</dd>
                <dt><span jhiTranslate="global.field.lastModifiedBy"></span></dt>
                <dd>{{ formAnswer.lastModifiedBy }}</dd>
                <dt><span jhiTranslate="global.field.lastModifiedDate"></span></dt>
                <dd>{{ formAnswer.lastModifiedDate | dateMoment:'dd/MM/yyyy HH:mm' }}</dd>
              </dl>
            </div>
          </ng-template>
        </ngb-panel>
      </ngb-accordion>
    </div>
  </div>

  <div class="modal-footer">
    <div class="form-row">
      <div class="col-12">
        <div class="float-right">
          <button type="button" (click)="onCloseClicked()" class="btn btn-default">
            <fa-icon [fixedWidth]="true" icon="times"></fa-icon>
            &nbsp;
            <span jhiTranslate="entity.action.close"></span>
          </button>
        </div>
      </div>
    </div>
  </div>
</div>