import moment, {Moment} from 'moment';
import {Status} from 'app/shared/model/enumerations/status.enum';
import {BaseEntity, IBaseEntity} from 'app/shared/model/root/base-entity.model';
import {Criteria} from 'app/shared/filter/criteria.model';
import {IPlanModule} from 'app/shared/model/admin/plan-module.model';

export interface IPlan extends IBaseEntity {
  name?: string;
  description?: string;
  position?: number;
  color?: string;
  status?: Status;
  modules?: IPlanModule[];
}

export class Plan extends BaseEntity implements IPlan {
  constructor(
    public id?: number,
    public uuid?: string,
    public createdBy?: string,
    public createdDate?: Moment,
    public lastModifiedBy?: string,
    public lastModifiedDate?: Moment,
    public active?: boolean,
    public name?: string,
    public description?: string,
    public position?: number,
    public color?: string,
    public status?: Status,
    public modules?: IPlanModule[],
    public _label?: string,
    public _edited?: boolean
  ) {
    super(id, uuid, createdBy, createdDate, lastModifiedBy, lastModifiedDate, active, _label);
  }

  public static allowedStatus(): Status[] {
    return [Status.DRAFT, Status.ACTIVE, Status.INACTIVE];
  }

  public static criterias(): Criteria<Plan>[] {
    return Criteria.buildFrom(this.example())
      .filter(criteria => criteria.property.name !== 'modules')
      .map(criteria => (criteria.property.name === 'status' ? criteria.asEnum(() => this.allowedStatus()) : criteria));
  }

  public static example(): Plan {
    return new Plan(0, '', '', moment(new Date()), '', moment(new Date()), false, '', '', 0, '', Status.DRAFT, [], '', false);
  }
}
