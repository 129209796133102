import {Injectable} from '@angular/core';
import {JhiEventManager, JhiEventWithContent} from 'ng-jhipster';
import {HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Observable} from 'rxjs';
import {tap} from 'rxjs/operators';

@Injectable()
export class ErrorHandlerInterceptor implements HttpInterceptor {
  constructor(private eventManager: JhiEventManager) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      tap(null, (err: HttpErrorResponse) => {
        if (!([401, 422].includes(err.status) && (err.message === '' || (err.url && err.url.includes('api/account'))))) {
          this.eventManager.broadcast(new JhiEventWithContent('app.httpError', err));
        }
      })
    );
  }
}
