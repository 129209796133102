import {Component} from '@angular/core';
import {FormResponseEditorModalService} from 'app/shared/form/form-response-editor-modal.service';
import {IForm} from 'app/shared/model/form/form.model';
import {IFormResponseOptions} from 'app/shared/form/form-response-editor.component';

@Component({
  selector: 'app-frm-response-editor-modal',
  template: `
    <app-frm-form-response-editor
      [form]="form"
      [options]="options"
      (onSubmitClicked)="onSubmitClicked($event)"
      (onSubmitSuccess)="onSubmitSuccess($event)"
      (onSubmitError)="onSubmitError($event)"
      (onCancelClicked)="onCancelClicked()"
    ></app-frm-form-response-editor>
  `
})
export class FormResponseEditorModalComponent {
  form?: IForm;
  submitClicked?: Function;
  submitSuccess?: Function;
  submitError?: Function;
  cancel?: Function;
  options: IFormResponseOptions;

  constructor(private service: FormResponseEditorModalService) {}

  onSubmitClicked(form: IForm): void {
    this.submitClicked && this.submitClicked(form);
  }

  onSubmitSuccess(form: IForm): void {
    this.submitSuccess && this.submitSuccess(form);
    this.options && this.options.autoClose && this.service.confirm();
  }

  onSubmitError(error: any): void {
    this.submitError && this.submitError(error);
  }

  onCancelClicked(): void {
    this.cancel && this.cancel();
    this.service.dismiss();
  }
}
