import {AfterViewInit, Component, ElementRef, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {UntypedFormBuilder} from '@angular/forms';
import {ActivatedRoute, NavigationEnd, Router} from '@angular/router';
import {LoginService} from 'app/core/login/login.service';

import {bounceInOnEnterAnimation} from 'angular-animations';
import {AccountService} from 'app/core/auth/account.service';
import {Particles} from 'app/shared/animations/particles/particles';
import {LoginType} from 'app/shared/model/enumerations/login-type.enum';
import {unsubscribe} from 'app/shared/util/react-util';
import {Subscription} from 'rxjs';
import {finalize, switchMap} from 'rxjs/operators';
import {PublicConfigurationService} from 'app/shared/services/login/public-configuration.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.scss'],
  animations: [bounceInOnEnterAnimation()]
})
export class LoginComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild('username', {static: false})
  username?: ElementRef;
  @ViewChild('account', {static: false})
  account?: ElementRef;

  authenticationError = false;
  showAccount = false;
  isLoading: boolean = false;

  loginForm = this.fb.group({
    account: [''],
    username: [''],
    password: [''],
    rememberMe: [false]
  });

  private subscriptions: Subscription[] = [];
  private particles?: Particles;

  constructor(
    private route: ActivatedRoute,
    private loginService: LoginService,
    public accountService: AccountService,
    private publicConfigurationService: PublicConfigurationService,
    private router: Router,
    private fb: UntypedFormBuilder
  ) {
  }

  ngOnInit(): void {
    this.loadResolvers();
    this.subscriptions.push(
      // try to log in automatically
      this.accountService.identity().subscribe(),
      this.router.events.subscribe(event => {
        if (event instanceof NavigationEnd) {
          this.isAuthenticated() && this.router.navigate(['']);
        }
      })
    );
    if (this.accountService.isAuthenticated()) {
      this.router.navigate(['my-activities']);
    }
  }

  loadResolvers() {
    this.subscriptions.push(
      this.route.data.subscribe(data => {
        this.showAccount = !!data?.showAccount;
      })
    );
  }

  isAuthenticated = (): boolean => this.accountService.isAuthenticated();

  ngAfterViewInit(): void {
    if (this.showAccount) {
      this.account.nativeElement.focus();
    } else {
      this.username.nativeElement.focus();
    }
  }

  cancel(): void {
    this.authenticationError = false;
    this.loginForm.patchValue({
      username: '',
      password: ''
    });
  }

  login(): void {
    this.authenticationError = false;
    this.isLoading = true;
    this.subscriptions.push(
      this.loginService
        .login({
          account: this.loginForm.get('account')?.value ?? '',
          username: this.loginForm.get('username')!.value,
          password: this.loginForm.get('password')!.value,
          type: LoginType.EMAIL,
          rememberMe: true
        })
        .pipe(
          switchMap(() => this.accountService.identity()),
          finalize(() => {
            this.isLoading = false;
          })
        )
        .subscribe(
          () => this.goHome(),
          () => (this.authenticationError = true)
        )
    );
  }

  goHome(): void {
    this.router.navigate(['/home']);
  }

  destroyParticles(): void {
    if (this.particles) {
      this.particles.destroy();
    }
  }

  ngOnDestroy(): void {
    this.destroyParticles();
    unsubscribe(this.subscriptions);
  }
}
