export enum AlertColor {
  light = 'light',
  dark = 'dark',
  info = 'info',
  primary = 'primary',
  secondary = 'secondary',
  success = 'success',
  danger = 'danger',
  warning = 'warning'
}

export const allAlertColors = () => Object.values(AlertColor);
