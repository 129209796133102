import moment from 'moment';
import {Moment} from 'moment';
import {BaseEntity, IBaseEntity} from 'app/shared/model/root/base-entity.model';
import {Criteria} from 'app/shared/filter/criteria.model';

export interface IPostCategory extends IBaseEntity {
  name?: string;
  color?: string;
}

export class PostCategory extends BaseEntity implements IPostCategory {
  constructor(
    public id?: number,
    public uuid?: string,
    public createdBy?: string,
    public createdDate?: Moment,
    public lastModifiedBy?: string,
    public lastModifiedDate?: Moment,
    public active?: boolean,
    public name?: string,
    public color?: string,
    public _label?: string,
    public _edited?: boolean
  ) {
    super(id, uuid, createdBy, createdDate, lastModifiedBy, lastModifiedDate, active, _label, _edited);
  }

  public static criterias(): Criteria<PostCategory>[] {
    return Criteria.buildFrom(this.example());
  }

  public static example(): PostCategory {
    return new PostCategory(0, '', '', moment(new Date()), '', moment(new Date()), false, '', '', '', false);
  }
}
