export enum EntityType {
  /* Administration */
  ADM_CHANGE_LOG = 'ADM_CHANGE_LOG',
  ADM_OPTION = 'ADM_OPTION',
  ADM_FILE = 'ADM_FILE',
  ADM_HOLIDAY = 'ADM_HOLIDAY',
  ADM_PARAMETER = 'ADM_PARAMETER',
  ADM_CORREIOS_SHIPPING_OPTION = 'ADM_CORREIOS_SHIPPING_OPTION',
  ADM_INSTALLMENT = 'ADM_INSTALLMENT',
  ADM_PLAN = 'ADM_PLAN',
  ADM_PLAN_MODULE = 'ADM_PLAN_MODULE',
  ADM_SEQUENCE = 'ADM_SEQUENCE',
  ADM_UNIT = 'ADM_UNIT',
  ADM_USER_MANUAL = 'ADM_SEQUENCE',
  ADM_GENERAL_ALERT = 'ADM_GENERAL_ALERT',

  /* Company */
  COM_CNPJ = 'COM_CNPJ',
  COM_COMPANY = 'COM_COMPANY',
  COM_COMPANY_CONTACT_INFO = 'COM_COMPANY_CONTACT_INFO',
  COM_COMPANY_PARAMETER = 'COM_COMPANY_PARAMETER',
  COM_COMPANY_PARAMETER_SCRIPT = 'COM_COMPANY_PARAMETER_SCRIPT',
  COM_COMPANY_USER = 'COM_COMPANY_USER',

  /* Conteúdos */
  CNT_BANNER = 'CNT_BANNER',
  CNT_METADATA = 'CNT_METADATA',
  CNT_POST = 'CNT_POST',
  CNT_POST_CATEGORY = 'CNT_POST_CATEGORY',

  /* DevOps */
  DEV_VAULT = 'DEV_VAULT',
  DEV_VAULT_TAG = 'DEV_VAULT_TAG',

  /* Email */
  EML_EMAIL_ADDRESS = 'EML_EMAIL_ADDRESS',
  EML_EMAIL_NOTIFICATION = 'EML_EMAIL_NOTIFICATION',
  EML_EMAIL_NOTIFICATION_RECIPIENT = 'EML_EMAIL_NOTIFICATION_RECIPIENT',
  EML_EMAIL_TEMPLATE = 'EML_EMAIL_TEMPLATE',

  /* Form Model */
  FRM_FORM = 'FRM_FORM',
  FRM_FORM_ANSWER = 'FRM_FORM_ANSWER',
  FRM_FORM_ANSWER_OPTION = 'FRM_FORM_ANSWER_OPTION',
  FRM_FORM_MODEL = 'FRM_FORM_MODEL',
  FRM_FORM_MODEL_CATEGORY = 'FRM_FORM_MODEL_CATEGORY',
  FRM_FORM_MODEL_QUESTION = 'FRM_FORM_MODEL_QUESTION',
  FRM_FORM_MODEL_QUESTION_OPTION = 'FRM_FORM_MODEL_QUESTION_OPTION',

  /* Location */
  LOC_CITY = 'LOC_CITY',
  LOC_COUNTRY = 'LOC_COUNTRY',
  LOC_LOCATION = 'LOC_LOCATION',
  LOC_STATE = 'LOC_STATE',

  /* Module */
  MOD_FEATURE = 'MOD_FEATURE',
  MOD_HELP = 'MOD_HELP',
  MOD_HELP_CATEGORY = 'MOD_HELP_CATEGORY',
  MOD_MODULE = 'MOD_MODULE',
  MOD_MODULE_FEATURE = 'MOD_MODULE_FEATURE',

  /* Notification */
  NTF_NOTIFICATION_EVENT = 'NTF_NOTIFICATION_EVENT',

  /* Payment */
  PAY_ARTIFACT = 'PAY_ARTIFACT',
  PAY_CARD = 'PAY_CARD',
  PAY_CHARGE = 'PAY_CHARGE',
  PAY_CUSTOMER = 'PAY_CUSTOMER',
  PAY_GATEWAY = 'PAY_GATEWAY',
  PAY_ORDER = 'PAY_ORDER',
  PAY_ORDER_ITEM = 'PAY_ORDER_ITEM',
  PAY_ORDER_STATUS = 'PAY_ORDER_STATUS',
  PAY_PIX = 'PAY_PIX',
  PAY_BILLET = 'PAY_BILLET',
  PAY_COUPON = 'PAY_COUPON',
  PAY_COUPON_GROUP_ITEM = 'PAY_COUPON_GROUP_ITEM',
  PAY_TRANSACTION = 'PAY_TRANSACTION',
  PAY_WEBHOOK = 'PAY_WEBHOOK',

  /* Prouct */
  PRO_BRAND = 'PRO_BRAND',
  PRO_BRAND_BANNER = 'PRO_BRAND_BANNER',
  PRO_CATEGORY = 'PRO_CATEGORY',
  PRO_CATEGORY_BANNER = 'PRO_CATEGORY_BANNER',
  PRO_CATEGORY_FORM_MODEL = 'PRO_CATEGORY_FORM_MODEL',
  PRO_CATEGORY_LINK = 'PRO_CATEGORY_LINK',
  PRO_PRICE_LIST = 'PRO_PRICE_LIST',
  PRO_PRICE_LIST_ITEM = 'PRO_PRICE_LIST_ITEM',
  PRO_PRODUCT = 'PRO_PRODUCT',
  PRO_PRODUCT_CATEGORY = 'PRO_PRODUCT_CATEGORY',
  PRO_PRODUCT_FORM = 'PRO_PRODUCT_FORM',
  PRO_PRODUCT_LINK = 'PRO_PRODUCT_LINK',
  PRO_PRODUCT_MEDIA = 'PRO_PRODUCT_MEDIA',
  PRO_PRODUCT_TAG = 'PRO_PRODUCT_TAG',
  PRO_TAG = 'PRO_TAG',

  /* Scrum */
  SCR_ACTIVITY = 'SCR_ACTIVITY',
  SCR_ACTIVITY_ROLE = 'SCR_ACTIVITY_ROLE',
  SCR_PROJECT = 'SCR_PROJECT',
  SCR_PROJECT_MEDIA = 'SCR_PROJECT_MEDIA',
  SCR_PROJECT_MEMBER = 'SCR_PROJECT_MEMBER',
  SCR_PROJECT_MEMBER_ROLE = 'SCR_PROJECT_MEMBER_ROLE',
  SCR_PROJECT_TAG = 'SCR_PROJECT_TAG',
  SCR_REGISTER = 'SCR_REGISTER',

  /* Security */
  SEC_ROLE = 'SEC_ROLE',
  SEC_ROLE_PERMISSION = 'SEC_ROLE_PERMISSION',
  SEC_ROLE_USER = 'SEC_ROLE_USER',

  /* Session */
  SSS_BOOKMARK = 'SSS_BOOKMARK',
  SSS_DEVICE = 'SSS_DEVICE',
  SSS_TRACKING = 'SSS_TRACKING',

  /* User */
  USR_USER = 'USR_USER',
  USR_USER_PAGE_STATUS = 'USR_USER_PAGE_STATUS',

  /* Warehouse */
  WHS_WAREHOUSE = 'WHS_WAREHOUSE',
  WHS_WAREHOUSE_ITEM = 'WHS_WAREHOUSE_ITEM',
  WHS_WAREHOUSE_ITEM_PLACEMENT = 'WHS_WAREHOUSE_ITEM_PLACEMENT',
  WHS_WAREHOUSE_SECTOR = 'WHS_WAREHOUSE_SECTOR',
  WHS_WAREHOUSE_LAUNCH = 'WHS_WAREHOUSE_LAUNCH',

  TPA_CONFIGURATION = 'TPA_CONFIGURATION',

  TPE_CONFIGURATION = 'TPE_CONFIGURATION',

  TPP_CLIENT = 'TPP_CLIENT',
  TPP_PROVIDER = 'TPP_PROVIDER'
}

export const allEntityTypes = () => Object.values(EntityType);
