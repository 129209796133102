import {AbstractControl, ValidationErrors, Validator} from '@angular/forms';
import moment, {unitOfTime} from 'moment';
import {Moment} from 'moment/moment';

enum RestrictionType {
  before,
  sameOrBefore,
  after,
  sameOrAfter
}

export class DateTimeValidator implements Validator {
  constructor(
    private dateTimePattern: string,
    private restrictionType: RestrictionType,
    private restrictionDate: Moment,
    private compareGranularity: unitOfTime.Base
  ) {
  }

  static isBefore(dateTime: Moment, pattern: string, granularity: unitOfTime.Base): Validator {
    return new DateTimeValidator(pattern, RestrictionType.before, dateTime, granularity);
  }

  static isSameOrBefore(dateTime: Moment, pattern: string, granularity: unitOfTime.Base): Validator {
    return new DateTimeValidator(pattern, RestrictionType.sameOrBefore, dateTime, granularity);
  }

  static isAfter(dateTime: Moment, pattern: string, granularity: unitOfTime.Base): Validator {
    return new DateTimeValidator(pattern, RestrictionType.after, dateTime, granularity);
  }

  static isSameOrAfter(dateTime: Moment, pattern: string, granularity: unitOfTime.Base): Validator {
    return new DateTimeValidator(pattern, RestrictionType.sameOrAfter, dateTime, granularity);
  }

  validate(control: AbstractControl): ValidationErrors | null {
    if (!control.value) {
      return null;
    }

    const value = moment(control.value, this.dateTimePattern);

    if (!value.isValid()) {
      return {invalid: true};
    }

    switch (this.restrictionType) {
      case RestrictionType.before:
        return value.isBefore(this.restrictionDate, this.compareGranularity) ? null : {dateTimeIsNotBefore: true};
      case RestrictionType.sameOrBefore:
        return value.isSameOrBefore(this.restrictionDate, this.compareGranularity) ? null : {dateTimeIsNotSameOrBefore: true};
      case RestrictionType.after:
        return value.isAfter(this.restrictionDate, this.compareGranularity) ? null : {dateTimeIsNotAfter: true};
      case RestrictionType.sameOrAfter:
        return value.isSameOrAfter(this.restrictionDate, this.compareGranularity) ? null : {dateTimeIsNotSameOrAfter: true};
    }
    return null;
  }
}
