import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {Condition, Criteria} from 'app/shared/filter/criteria.model';
import {TranslateService} from '@ngx-translate/core';
import {FormControl, FormGroup} from '@angular/forms';
import {Subscription} from 'rxjs';
import {unsubscribe} from 'app/shared/util/react-util';
import {Logger} from 'app/shared/util/logger';
import {CriteriaPropertyFilter} from 'app/shared/filter/criteria-filter.pipe';
import {IBaseEntity} from 'app/shared/model/root/base-entity.model';
import {MaskService} from 'app/shared/mask/mask.service';
import moment from 'moment';
import {getFontAwesomeIcons} from 'app/shared/util/icon-list';

export declare type CriteriaLayout = 'card' | 'modal';

export interface ICriteriaFilterOptions {
  mode: CriteriaLayout;
  showHeader: boolean;
  showApplyButton: boolean;
  showClearButton: boolean;
  showCancelButton: boolean;
}

@Component({
  selector: 'app-criteria-filter',
  templateUrl: './criteria-filter.component.html',
  styleUrls: ['./criteria-filter.scss']
})
export class CriteriaFilterComponent<T extends IBaseEntity> implements OnInit, OnDestroy {
  private log: Logger = new Logger(CriteriaFilterComponent.name);

  @Input()
  options: ICriteriaFilterOptions = {
    mode: 'card',
    showHeader: true,
    showApplyButton: true,
    showClearButton: true,
    showCancelButton: true
  };

  @Input()
  displayFields: CriteriaPropertyFilter = new CriteriaPropertyFilter(false, false, false);

  @Input()
  entityName: string | null = null;

  @Input()
  criterias: Criteria<T>[] = [];

  @Input()
  thirdPartyEntity: boolean = false;

  @Output()
  onCriteriaChanged: EventEmitter<Criteria<T>[]> = new EventEmitter<Criteria<T>[]>();

  @Output()
  onFilterClicked: EventEmitter<Object> = new EventEmitter<Object>();

  @Output()
  onCancelClicked: EventEmitter<never> = new EventEmitter<never>();

  form: FormGroup | null = null;

  faIcons = getFontAwesomeIcons();

  private subscriptions: Subscription[] = [];

  constructor(private translateService: TranslateService, public mask: MaskService) {
  }

  ngOnInit(): void {
    this.buildForm();
    this.registerInputChanges();
  }

  public availableConditions(criteria: Criteria<T>): Condition[] {
    let defaultConditions = criteria.conditions();
    if (this.thirdPartyEntity) {
      defaultConditions = defaultConditions.filter(defaultCondition => {
        return defaultCondition === 'ignore' ||
          defaultCondition === 'equals' ||
          defaultCondition === 'notEquals' ||
          defaultCondition === 'greaterThan' ||
          defaultCondition === 'lessThan' ||
          defaultCondition === 'greaterOrEqualThan' ||
          defaultCondition === 'lessOrEqualThan';
      });
      defaultConditions.push('notEquals');
    }
    return defaultConditions;
  }

  private buildForm(): void {
    this.log.debug(() => `Criteria => ${JSON.stringify(this.criterias, null, 2)}`);

    const group: any = {};

    this.criterias.forEach(criteria => {
      group[`condition-${criteria.property.name}`] = new FormControl('ignore');
      group[criteria.property.name] = new FormControl({value: null, disabled: true});
    });

    this.form = new FormGroup(group);
  }

  private registerInputChanges(): void {
    this.criterias.forEach(criteria => {
      const conditionInput = this.form!.get([`condition-${criteria.property.name}`])!;
      const valueInput = this.form!.get([criteria.property.name])!;

      conditionInput.setValue(criteria.condition);
      valueInput.setValue(criteria.value);

      if (['in', 'equals', 'contains', 'greaterThan', 'lessThan', 'greaterOrEqualThan', 'lessOrEqualThan'].includes(criteria.condition)) {
        valueInput.enable({emitEvent: false});
      }

      this.subscriptions.push(
        conditionInput.valueChanges.subscribe((condition: Condition) => {
          criteria.condition = condition;

          valueInput.disable({emitEvent: false});
          valueInput.setValue(null, {emitEvent: false});
          criteria.value = null;

          switch (condition) {
            case 'ignore':
              break;

            case 'isFalse':
            case 'isNull':
              valueInput.setValue(false, {emitEvent: false});
              criteria.value = false;
              break;

            case 'isTrue':
            case 'isNotNull':
              valueInput.setValue(true, {emitEvent: false});
              criteria.value = true;
              break;

            case 'in':
            case 'equals':
            case 'contains':
            case 'greaterThan':
            case 'lessThan':
            case 'greaterOrEqualThan':
            case 'lessOrEqualThan':
            default:
              valueInput.enable({emitEvent: false});
          }

          this.log.debug(() => `changed condition => ${criteria.property.name} | ${criteria.condition}`);

          criteria.conditionChangeCallback && criteria.conditionChangeCallback(criteria.condition);

          this.onCriteriaChanged.emit(this.criterias);
        }),
        ...(criteria.valueChangeCallback
          ? [valueInput.valueChanges.subscribe((value: any) => criteria.valueChangeCallback && criteria.valueChangeCallback(value))]
          : [])
      );

      this.subscriptions.push(
        valueInput.valueChanges.subscribe(value => {
          criteria.value = value;
          this.onCriteriaChanged.emit(this.criterias);
        })
      );
    });
  }

  cancel(): void {
    this.onCancelClicked.emit();
  }

  clear(): void {
    this.criterias.forEach(criteria => criteria.clear());
    unsubscribe(this.subscriptions);
    this.buildForm();
    this.registerInputChanges();
    this.onCriteriaChanged.emit(this.criterias);
  }

  submit(): void {
    const filter = {};

    this.criterias
      .filter(c => c.condition !== 'ignore')
      .forEach(criteria => {
        if (['isNull', 'isNotNull'].includes(criteria.condition)) {
          filter[`${criteria.property.name}.specified`] = this.sanitizeValue(criteria.value);
          return;
        }

        switch (criteria.property.type) {
          case 'object':
            filter[`${criteria.property.name}.${criteria.condition}`] =
              (criteria.value && this.sanitizeValue(criteria.value[criteria.objectIdPath])) || '';
            return;

          case 'date':
            if (criteria.condition === 'equals') {
              criteria.value = moment(criteria.value).startOf('day');
              filter[`${criteria.property.name}.greaterOrEqualThan`] = new Date(criteria.value).toISOString();
              criteria.value = moment(criteria.value).endOf('day');
              filter[`${criteria.property.name}.lessOrEqualThan`] = new Date(criteria.value).toISOString();
              return;
            }
            filter[`${criteria.property.name}.${criteria.condition}`] = new Date(criteria.value).toISOString();
            return;

          case 'boolean':
            filter[`${criteria.property.name}.equals`] = this.sanitizeValue(criteria.value);
            return;

          default:
            filter[`${criteria.property.name}.${criteria.condition}`] = this.sanitizeValue(criteria.value);
        }
      });

    this.onFilterClicked.emit(filter);
  }

  private sanitizeValue(value: any): string {
    return [null, undefined].includes(value) ? '' : `${value}`;
  }

  ngOnDestroy(): void {
    unsubscribe(this.subscriptions);
  }

  getFieldTranslation(field: string): string {
    const label = `${this.entityName!}.${field}`;
    const translated = this.translateService.instant(label);
    return translated === label ? this.translateService.instant(`global.field.${field}`) : translated;
  }

  isDateField(criteria: any): boolean {
    return criteria.inputType() === 'datetime-local';
  }

  trackIdentity(index: number, item: Criteria<T>): any {
    return item.property.name + item.condition;
  }
}
