import {Injectable} from '@angular/core';
import {NgbModal, NgbModalRef} from '@ng-bootstrap/ng-bootstrap';
import {IFormAnswerOption} from 'app/shared/model/form/form-answer-option.model';
import {FormAnswerOptionDetailModalComponent} from 'app/entities/form/form/form-answer-option-detail-modal.component';

@Injectable({providedIn: 'root'})
export class FormAnswerOptionDetailModalService {
  private isOpen = false;
  private instance?: NgbModalRef;

  constructor(private modalService: NgbModal) {}

  show(formAnswerOption: IFormAnswerOption, close?: Function): void {
    if (this.isOpen) return;

    this.isOpen = true;

    this.instance = this.modalService.open(FormAnswerOptionDetailModalComponent, {
      size: 'lg',
      backdrop: 'static'
    });
    this.instance.componentInstance.formAnswerOption = formAnswerOption;
    this.instance.componentInstance.close = close;

    this.instance.result.finally(() => (this.isOpen = false));
  }

  close(): void {
    if (!this.isOpen || !this.instance) return;
    this.instance.dismiss();
  }
}
