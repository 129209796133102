import {Component, Input} from '@angular/core';
import {MaskService} from 'app/shared/mask/mask.service';
import {IFormModelCategory} from 'app/shared/model/form/form-model-category.model';
import {FormModelCategoryDetailModalService} from 'app/entities/form/form-model/form-model-category-detail-modal.service';
import {allAlertColors} from 'app/shared/model/enumerations/alert-color.enum';

@Component({
  selector: 'app-frm-form-model-category-detail-modal',
  templateUrl: './form-model-category-detail-modal.component.html'
})
export class FormModelCategoryDetailModalComponent {
  @Input()
  formModelCategory!: IFormModelCategory;

  close?: Function;

  alertColors = allAlertColors();

  constructor(private service: FormModelCategoryDetailModalService, public mask: MaskService) {}

  onCloseClicked(): void {
    this.close && this.close();
    this.service.close();
  }
}
