import {Pipe, PipeTransform} from '@angular/core';
import {Logger} from 'app/shared/util/logger';

const Log = new Logger('FormatJsonPipe');

@Pipe({name: 'formatJSON'})
export class FormatJsonPipe implements PipeTransform {
  transform(value) {
    try {
      return JSON.stringify(JSON.parse(value), null, 2);
    } catch (e) {
      Log.error(() => `Invalid JSON -> ${value}`, e);
      return value;
    }
  }
}
