import {Component} from '@angular/core';
import {Button} from 'app/shared/layout/window/button.model';
import {GenericModalService} from 'app/shared/modal/generic-modal.service';

@Component({
  selector: 'app-generic-modal',
  templateUrl: './generic-modal.component.html'
})
export class GenericModalComponent {
  title?: string;
  question?: string;
  buttons?: Button[];

  constructor(private genericModalService: GenericModalService) {}

  dismiss(): void {
    if (this.buttons) {
      const dismissBtn = this.buttons.find(b => b.type === 'dismiss');
      dismissBtn && dismissBtn.action && dismissBtn.action();
    }
    this.genericModalService.dismiss();
  }
}
