import {Component, Input} from '@angular/core';
import {MaskService} from 'app/shared/mask/mask.service';
import {FormModelQuestion, IFormModelQuestion} from 'app/shared/model/form/form-model-question.model';
import {FormModelQuestionDetailModalService} from 'app/entities/form/form-model/form-model-question-detail-modal.service';
import {IFormModelCategory} from 'app/shared/model/form/form-model-category.model';
import {FormModelActionsService} from 'app/entities/form/form-model/form-model-actions.service';
import {IBaseEntity} from 'app/shared/model/root/base-entity.model';
import {IFormModelQuestionOption} from 'app/shared/model/form/form-model-question-option.model';
import {allAlertColors} from 'app/shared/model/enumerations/alert-color.enum';
import {FormQuestionType} from 'app/shared/model/enumerations/form-question-type.enum';
import {allCurrencies} from 'app/shared/model/enumerations/currency.enum';

@Component({
  selector: 'app-frm-form-model-question-detail-modal',
  templateUrl: './form-model-question-detail-modal.component.html'
})
export class FormModelQuestionDetailModalComponent {
  @Input()
  formModelQuestion!: IFormModelQuestion;

  close?: Function;

  types = FormModelQuestion.allowedFormQuestionTypes();
  alertColors = allAlertColors();
  currencies = allCurrencies();

  constructor(
    private service: FormModelQuestionDetailModalService,
    private formModelActionsService: FormModelActionsService,
    public mask: MaskService
  ) {}

  showFormModelCategoryDetails(category: IFormModelCategory): void {
    this.formModelActionsService.showFormModelCategoryDetails(category);
  }

  totalActiveOptions(): number {
    return this.formModelActionsService.totalActiveFormModelQuestionOptions(this.formModelQuestion);
  }

  showTextMinAndMaxLength(): boolean {
    return [FormQuestionType.SINGLE_LINE_TEXT, FormQuestionType.MULTILINE_TEXT, FormQuestionType.URL, FormQuestionType.EMAIL].includes(
      this.formModelQuestion.type
    );
  }

  showMinAndMax(): boolean {
    return [FormQuestionType.CURRENCY, FormQuestionType.INTEGER_NUMBER, FormQuestionType.DECIMAL_NUMBER, FormQuestionType.TIME].includes(
      this.formModelQuestion.type
    );
  }

  showFormModelQuestionOptionDetails(formModelQuestionOption: IFormModelQuestionOption): void {
    this.formModelActionsService.showFormModelQuestionOptionDetails(formModelQuestionOption);
  }

  trackIdentity(index: number, item: IBaseEntity): any {
    return item.uuid;
  }

  onCloseClicked(): void {
    this.close && this.close();
    this.service.close();
  }
}
