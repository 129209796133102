export enum ScrumRole {
  PRODUCT_OWNER = 'PRODUCT_OWNER',
  STAKEHOLDER = 'STAKEHOLDER',
  SCRUM_MASTER = 'SCRUM_MASTER',
  REQUIREMENT = 'REQUIREMENT',
  DESIGN = 'DESIGN',
  DEVELOPER = 'DEVELOPER',
  QA = 'QA',
  DEVOPS = 'DEVOPS',
}

export const allScrumRoles = () => Object.values(ScrumRole);
