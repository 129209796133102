import {Component, Input} from '@angular/core';
import {IconProp} from '@fortawesome/fontawesome-svg-core';
import {ButtonColor} from 'app/shared/layout/window/button.model';
import {Status} from 'app/shared/model/enumerations/status.enum';

@Component({
  selector: 'app-status-label',
  template: `
    <div *ngIf="status" class="badge" [ngClass]="'badge-' + color()">
      <fa-icon class="mr-1" *ngIf="showIcon && icon()" [icon]="icon()" [fixedWidth]="true"></fa-icon>
      <span>{{ translationPrefix + '.' + status | translate }}</span>
    </div>
  `
})
export class StatusLabelComponent {
  @Input()
  status: Status | null = null;

  @Input()
  overpassColor: ButtonColor | null = null;

  @Input()
  translationPrefix: string | null = 'status';

  @Input()
  showIcon: boolean = false;

  color(): ButtonColor {
    if (!this.status) return 'default';

    switch (this.status) {
      case Status.NEW:
      case Status.DRAFT:
      case Status.CART:
      case Status.TRIAL:
        return 'info';

      case Status.PRODUCTION:
      case Status.ACTIVE:
      case Status.OPENED:
      case Status.CAPTURED:
      case Status.SUBMITTED:
      case Status.GENERATED:
      case Status.FUNDED:
      case Status.SHIPPED:
      case Status.CLICKED:
      case Status.ALL:
      case Status.INDEXED:
        return 'primary';

      case Status.RUNNING:
      case Status.IN_PROGRESS:
      case Status.PROCESSING:
      case Status.ANSWERED:
      case Status.AUTHORIZED_PENDING_CAPTURE:
      case Status.PARTIAL_CAPTURE:
      case Status.WAITING_CAPTURE:
      case Status.UNKNOWN:
      case Status.WITH_ERROR:
      case Status.PARTIAL_REFUNDED:
      case Status.PARTIAL_VOID:
      case Status.WAITING_CANCELLATION:
      case Status.UNDERPAID:
      case Status.OVERPAID:
      case Status.WAITING_PAYMENT:
      case Status.WAITING_CONFIRM:
      case Status.PENDING_REFUDED:
      case Status.UNPLANNED:
      case Status.OUT_OF_DATE:
      case Status.NOT_APPLIED:
      case Status.APPROVED_WITH_RESTRICTION:
      case Status.WAITING_RELEASE:
      case Status.WILLBEOVERDUE:
      case Status.PENDING_REGISTRATION:
      case Status.PENDING_CHECKOUT:
      case Status.RENEWING:
      case Status.NOT_PAID:
      case Status.BOUNCED:
      case Status.DELIVERY_DELAYED:
      case Status.PENDING_INDEXING:
      case Status.RUNNING_OUT:
        return 'warning';

      case Status.WAITING:
      case Status.PENDING:
      case Status.SCHEDULED:
      case Status.IN_EVALUATION:
      case Status.REFUNDED:
      case Status.VIEWED:
      case Status.PENDING_VALIDATION:
      case Status.PENDING_ACTIVATION:
      case Status.SENDING:
        return 'secondary';

      case Status.PROCESSED:
      case Status.APPROVED:
      case Status.FINISHED:
      case Status.FINISHED_BY_CONSULTANT:
      case Status.CONCLUDED:
      case Status.AVAILABLE:
      case Status.ACCEPTED:
      case Status.PAID:
      case Status.SOLD:
      case Status.ACCOMPLISHED:
      case Status.UP_TO_DATE:
      case Status.DELIVERED:
      case Status.SENT:
      case Status.IN_STOCK:
        return 'success';

      case Status.REFUSED:
      case Status.ERROR:
      case Status.CANCELED:
      case Status.REMOVED:
      case Status.FAILED:
      case Status.DELETED:
      case Status.NOT_AUTHORIZED:
      case Status.VOIDED:
      case Status.ERROR_ON_VOIDING:
      case Status.ERROR_ON_REFUNDING:
      case Status.NOT_APPROVED:
      case Status.WAITING_FOR_SERVICE:
      case Status.DISAPPROVED:
      case Status.OVERDUE:
      case Status.COMPLAINED:
      case Status.REJECTED:
      case Status.SOLD_OUT:
        return 'danger';

      default:
      case Status.EXPIRED:
      case Status.INACTIVE:
      case Status.ARCHIVED:
      case Status.NOT_PROCESSED:
      case Status.RETAINED:
        return 'default';
    }
  }


  icon(): IconProp {
    if (!this.status) return null;

    switch (this.status) {
      case Status.SHIPPED:
        return 'truck';

      case Status.PAID:
        return 'credit-card';

      case Status.PENDING:
        return 'clock';

      case Status.FINISHED:
        return 'check';

      case Status.CANCELED:
        return 'ban';

      case Status.FAILED:
        return 'times';

      default:
        return null;
    }
  }
}
