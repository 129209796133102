<div class="footer" [ngClass]="{'footer-authenticated': isUser()}">
  <div class="langs">
    <ul *ngIf="languages && languages.length > 1" class="list-inline">
      <li *ngFor="let language of languages" class="list-inline-item"
          [class.active]="language.code === getActiveLanguage()">
        <a href="javascript:void(0);" (click)="changeLanguage(language.code);">
          <i class="fi {{ language.icon }}"></i>
          {{ language.name }}
        </a>
      </li>
    </ul>
  </div>

  <div class="credits">
    <span>{{'footer' | translate}} - {{getCurrentYear()}} - </span>
    <a href="https://qasapp.onclickon.onclick.com.br" target="_blank">https://qasapp.onclickon.onclick.com.br</a>
    <span> - v{{version}}</span>
  </div>
  
</div>
