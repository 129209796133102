import {Component, OnInit} from '@angular/core';
import {Observable} from 'rxjs';
import {AccountService} from 'app/core/auth/account.service';
import {Account} from 'app/core/user/account.model';
import {PageService} from 'app/shared/layout/window/page.service';
import {Page} from 'app/shared/layout/window/page.model';
import {GeneralAlertService} from 'app/shared/services/admin/general-alert.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['home.scss']
})
export class HomeComponent implements OnInit {
  account$?: Observable<Account | null>;
  generalAlertsContents: string[];

  constructor(private accountService: AccountService, private pageService: PageService, private generalAlertService: GeneralAlertService) {
  }

  ngOnInit(): void {
    this.account$ = this.accountService.identity();
    this.pageService.setCurrentPage(
      new Page(
        () => 'th-large',
        () => 'sidebar.myActivities.main'
      )
    );
    this.loadGeneralAlert();
  }

  loadGeneralAlert() {
    this.generalAlertService.getContentGeneralAlert().subscribe(res => {
      this.generalAlertsContents = res.body || [];
    });
  }

  isAuthenticated(): boolean {
    return this.accountService.isAuthenticated();
  }
}
