import {IBaseEntity} from 'app/shared/model/root/base-entity.model';
import {getProperties, Property, PropertyType} from 'app/shared/util/object-util';

export declare type FilterFunction = (filter: any) => void;

export declare type CriteriaItemsFunction = () => IBaseEntity[] | string[] | any[];

export declare type Condition =
  | 'ignore'
  | 'equals'
  | 'notEquals'
  | 'in'
  | 'isNull'
  | 'isNotNull'
  | 'isTrue'
  | 'isFalse'
  | 'contains'
  | 'greaterThan'
  | 'lessThan'
  | 'greaterOrEqualThan'
  | 'lessOrEqualThan';

type EqualsSuffix<Key extends string> = `${Key}.equals`;
type InSuffix<Key extends string> = `${Key}.in`;
type SpecifiedSuffix<Key extends string> = `${Key}.specified`;
type ContainsSuffix<Key extends string> = `${Key}.contains`;
type LessThanSuffix<Key extends string> = `${Key}.lessThan`;
type LessOrEqualThanSuffix<Key extends string> = `${Key}.lessOrEqualThan`;
type GreaterThanSuffix<Key extends string> = `${Key}.greaterThan`;
type GreaterOrEqualThanSuffix<Key extends string> = `${Key}.greaterOrEqualThan`;

export declare type ConditionSuffixes<Key extends string> = EqualsSuffix<Key> |
  InSuffix<Key> |
  SpecifiedSuffix<Key> |
  ContainsSuffix<Key> |
  LessThanSuffix<Key> |
  LessOrEqualThanSuffix<Key> |
  GreaterThanSuffix<Key> |
  GreaterOrEqualThanSuffix<Key>;

export declare type CriteriaSuffixes<T extends string> = {
  [Key in ConditionSuffixes<T>]?: any;
}

export declare type SortAsc<Key extends string> = `${Key},asc`;
export declare type SortDesc<Key extends string> = `${Key},desc`;

export declare type CriteriaRequestModel<Key extends string> = CriteriaSuffixes<Key> & {
  sort?: Array<SortAsc<Key> | SortDesc<Key>>;
  page?: number;
  size?: number;
}

function forBoolean(): Condition[] {
  return ['ignore', 'isTrue', 'isFalse'];
}

function forString(): Condition[] {
  return ['ignore', 'equals', 'contains', 'isNull', 'isNotNull'];
}

function forNumber(): Condition[] {
  return ['ignore', 'equals', 'isNull', 'isNotNull', 'greaterThan', 'lessThan', 'greaterOrEqualThan', 'lessOrEqualThan'];
}

function forObject(): Condition[] {
  return ['ignore', 'equals', 'isNull', 'isNotNull'];
}

function forEnum(): Condition[] {
  return forObject();
}

function forFilled(): Condition[] {
  return ['ignore', 'isNull', 'isNotNull'];
}

function forFaIcon(): Condition[] {
  return forObject();
}

function forDate(): Condition[] {
  return forNumber();
}

export class Criteria<T extends IBaseEntity> {
  public readonly property: Property<T>;
  public translationPrefix?: string;

  constructor(
    property: Property<T>,
    public condition: Condition,
    public value: any = null,
    public items: CriteriaItemsFunction = () => [],
    public objectIdPath: string = 'id',
    translationPrefix?: string,
    public conditionChangeCallback?: Function,
    public valueChangeCallback?: Function
  ) {
    this.property = property;
    this.translationPrefix = translationPrefix || property.name;
  }

  public static fromTransient(name: string, type: PropertyType): Criteria<any> {
    return new Criteria(new Property(name, type), 'ignore', true);
  }

  public static buildFrom<T extends IBaseEntity>(obj: T): Criteria<T>[] {
    return getProperties(obj)
      .filter(p => !p.name.startsWith('_') && ['string', 'number', 'date', 'boolean', 'object', 'array'].includes(p.type))
      .map(property => new Criteria<T>(property, 'ignore'));
  }

  conditions(): Condition[] {
    switch (this.property.type) {
      case 'string':
        return forString();
      case 'number':
        return forNumber();
      case 'date':
        return forDate();
      case 'boolean':
        return forBoolean();
      case 'object':
        return forObject();
      case 'enum':
        return forEnum();
      case 'filled':
        return forFilled();
      case 'faIcon':
        return forFaIcon();
      case 'array':
      case 'function':
      case 'undefined':
        return [];
    }
  }

  requiresValue(): boolean {
    return ['equals', 'notEquals', 'in', 'contains', 'greaterThan', 'lessThan', 'greaterOrEqualThan', 'lessOrEqualThan'].includes(this.condition);
  }

  inputType(): string {
    switch (this.property.type) {
      case 'date':
        return 'datetime-local';
      case 'number':
        return 'number';
      case 'string':
        return 'text';
      default:
        return '';
    }
  }

  isInput(): boolean {
    return this.inputType().length > 0;
  }

  isObject(): boolean {
    return this.property.type === 'object';
  }

  isEnum(): boolean {
    return this.property.type === 'enum';
  }

  isFaIcon(): boolean {
    return this.property.type === 'faIcon';
  }

  clear(): void {
    this.condition = 'ignore';
    this.value = null;
  }

  asEnum(items: CriteriaItemsFunction, translationPrefix?: string): Criteria<T> {
    return new Criteria<T>(
      this.property.asEnum(),
      this.condition,
      this.value,
      items,
      this.objectIdPath,
      translationPrefix || this.translationPrefix
    );
  }

  asNumber(): Criteria<T> {
    return new Criteria<T>(this.property.asNumber(), this.condition, this.value);
  }

  asFilled(): Criteria<T> {
    return new Criteria<T>(this.property.asFilled(), this.condition, this.value);
  }

  asFaIcon(): Criteria<T> {
    return new Criteria<T>(this.property.asFaIcon(), this.condition, this.value);
  }

  asObject(): Criteria<T> {
    return new Criteria<T>(this.property.asObject(), this.condition, this.value, () => [], this.objectIdPath, this.translationPrefix);
  }
}
