import {Pipe, PipeTransform} from '@angular/core';

@Pipe({name: 'removeHtmlTags'})
export class RemoveHtmlTagsPipe implements PipeTransform {
  constructor() {}

  transform(content: string): string {
    return content.replace(/<[^>]*>?/gm, '');
  }
}
