import moment from 'moment';
import {Moment} from 'moment';
import {BaseEntity, IBaseEntity} from 'app/shared/model/root/base-entity.model';
import {Criteria} from 'app/shared/filter/criteria.model';
import {FormModel, IFormModel} from 'app/shared/model/form/form-model.model';
import {IFormModelQuestionOption} from 'app/shared/model/form/form-model-question-option.model';
import {allFormQuestionTypes, FormQuestionType} from 'app/shared/model/enumerations/form-question-type.enum';
import {FormModelCategory, IFormModelCategory} from 'app/shared/model/form/form-model-category.model';
import {allCurrencies, Currency} from 'app/shared/model/enumerations/currency.enum';

export interface IFormModelQuestion extends IBaseEntity {
  formModel?: IFormModel;
  question?: string;
  description?: string;
  faIcon?: string;
  alertColor?: string;
  type?: FormQuestionType;
  order?: number;
  category?: IFormModelCategory;
  weight?: number;
  mandatory?: boolean;
  currency?: Currency;
  min?: string;
  max?: string;
  minDate?: Moment;
  maxDate?: Moment;
  options?: IFormModelQuestionOption[];
  _displayOptions?: boolean;
}

export class FormModelQuestion extends BaseEntity implements IFormModelQuestion {
  constructor(
    public id?: number,
    public uuid?: string,
    public createdBy?: string,
    public createdDate?: Moment,
    public lastModifiedBy?: string,
    public lastModifiedDate?: Moment,
    public active?: boolean,
    public formModel?: IFormModel,
    public question?: string,
    public description?: string,
    public faIcon?: string,
    public alertColor?: string,
    public type?: FormQuestionType,
    public order?: number,
    public category?: IFormModelCategory,
    public weight?: number,
    public mandatory?: boolean,
    public currency?: Currency,
    public min?: string,
    public max?: string,
    public minDate?: Moment,
    public maxDate?: Moment,
    public options?: IFormModelQuestionOption[],
    public _displayOptions?: boolean,
    public _label?: string,
    public _edited?: boolean
  ) {
    super(id, uuid, createdBy, createdDate, lastModifiedBy, lastModifiedDate, active, _label, _edited);
  }

  public static allowedFormQuestionTypes(): FormQuestionType[] {
    return allFormQuestionTypes();
  }

  public static allowedCurrencies(): Currency[] {
    return allCurrencies();
  }

  public static criterias(): Criteria<FormModelQuestion>[] {
    return Criteria.buildFrom(this.example())
      .filter(criteria => !['options'].includes(criteria.property.name))
      .map(criteria =>
        criteria.property.name === 'type'
          ? criteria.asEnum(() => this.allowedFormQuestionTypes(), 'formQuestionType')
          : criteria.property.name === 'currency'
          ? criteria.asEnum(() => this.allowedCurrencies())
          : criteria
      );
  }

  public static example(): FormModelQuestion {
    return new FormModelQuestion(
      0,
      '',
      '',
      moment(new Date()),
      '',
      moment(new Date()),
      false,
      new FormModel(),
      '',
      '',
      '',
      '',
      FormQuestionType.YES_NO,
      0,
      new FormModelCategory(),
      0,
      false,
      Currency.UNKNOWN,
      '',
      '',
      moment(new Date()),
      moment(new Date()),
      [],
      false,
      '',
      false
    );
  }
}
