import {NgModule} from '@angular/core';
import {RouterModule} from '@angular/router';
import {errorRoute} from './layouts/error/error.route';
import {navbarRoute} from './layouts/navbar/navbar.route';
import {DEBUG_INFO_ENABLED} from 'app/app.constants';
import {Authority} from 'app/shared/model/enumerations/authority.enum';

import {UserRouteAccessService} from 'app/core/auth/user-route-access-service';
import {sidebarRoute} from 'app/layouts/sidebar/sidebar.route';

import {loginRoute} from 'app/account/login/login.route';

const LOGIN_ROUTE = [loginRoute];
const LAYOUT_ROUTES = [navbarRoute, sidebarRoute, ...errorRoute];

@NgModule({
  imports: [
    RouterModule.forRoot(
      [
        {
          path: 'system',
          data: {
            authorities: [Authority.SYSTEM_ADMIN]
          },
          canActivate: [UserRouteAccessService],
          loadChildren: () => import('./system/system-routing.module').then(m => m.SystemRoutingModule)
        },
        {
          path: 'account',
          loadChildren: () => import('./account/account.module').then(m => m.AccountModule)
        },
        ...LOGIN_ROUTE,
        ...LAYOUT_ROUTES
      ],
      {enableTracing: DEBUG_INFO_ENABLED}
    )
  ],
  exports: [RouterModule]
})
export class AppAppRoutingModule {}
