import {Component, Input} from '@angular/core';
import {ButtonColor} from 'app/shared/layout/window/button.model';

@Component({
  selector: 'app-percent-label',
  template: `
    <span *ngIf="![null, undefined].includes(this.value)" class="badge" [ngClass]="'badge-' + color()">{{ value?.toFixed(0) }}%</span>
  `
})
export class PercentLabelComponent {
  @Input()
  value: number;

  color(): ButtonColor {
    if ([null, undefined].includes(this.value)) return 'default';
    return this.value <= 0 ? 'danger' : this.value >= 100 ? 'success' : 'warning';
  }
}
