export enum Status {
  NEW = 'NEW',
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
  DRAFT = 'DRAFT',
  TRIAL = 'TRIAL',
  PRODUCTION = 'PRODUCTION',
  RUNNING = 'RUNNING',
  IN_PROGRESS = 'IN_PROGRESS',
  PROCESSING = 'PROCESSING',
  WAITING = 'WAITING',
  PROCESSED = 'PROCESSED',
  SHIPPED = 'SHIPPED',
  NOT_PROCESSED = 'NOT_PROCESSED',
  APPROVED = 'APPROVED',
  APPROVED_WITH_RESTRICTION = 'APPROVED_WITH_RESTRICTION',
  NOT_APPROVED = 'NOT_APPROVED',
  DISAPPROVED = 'DISAPPROVED',
  REFUSED = 'REFUSED',
  SCHEDULED = 'SCHEDULED',
  PENDING = 'PENDING',
  FINISHED = 'FINISHED',
  ACCOMPLISHED = 'ACCOMPLISHED',
  ERROR = 'ERROR',
  CANCELED = 'CANCELED',
  REMOVED = 'REMOVED',
  EXPIRED = 'EXPIRED',
  UNPLANNED = 'UNPLANNED',
  OUT_OF_DATE = 'OUT_OF_DATE',
  NOT_APPLIED = 'NOT_APPLIED',
  OPENED = 'OPENED',
  AVAILABLE = 'AVAILABLE',
  SOLD = 'SOLD',
  UP_TO_DATE = 'UP_TO_DATE',
  LATE = 'LATE',
  CART = 'CART',
  ARCHIVED = 'ARCHIVED',
  WAITING_FOR_SERVICE = 'WAITING_FOR_SERVICE',
  FINISHED_BY_CONSULTANT = 'FINISHED_BY_CONSULTANT',
  PENDING_VALIDATION = 'PENDING_VALIDATION',
  PENDING_REGISTRATION = 'PENDING_REGISTRATION',
  PENDING_CHECKOUT = 'PENDING_CHECKOUT',
  PENDING_ACTIVATION = 'PENDING_ACTIVATION',
  ANSWERED = 'ANSWERED',
  CONCLUDED = 'CONCLUDED',
  ACCEPTED = 'ACCEPTED',
  SUBMITTED = 'SUBMITTED',
  IN_EVALUATION = 'IN_EVALUATION',
  DELETED = 'DELETED',
  FUNDED = 'FUNDED',
  PAID = 'PAID',
  CAPTURED = 'CAPTURED',
  FAILED = 'FAILED',
  UNKNOWN = 'UNKNOWN',
  RENEWING = 'RENEWING',
  AUTHORIZED_PENDING_CAPTURE = 'AUTHORIZED_PENDING_CAPTURE',
  NOT_AUTHORIZED = 'NOT_AUTHORIZED',
  PARTIAL_CAPTURE = 'PARTIAL_CAPTURE',
  WAITING_CAPTURE = 'WAITING_CAPTURE',
  REFUNDED = 'REFUNDED',
  VOIDED = 'VOIDED',
  PARTIAL_REFUNDED = 'PARTIAL_REFUNDED',
  PARTIAL_VOID = 'PARTIAL_VOID',
  ERROR_ON_VOIDING = 'ERROR_ON_VOIDING',
  ERROR_ON_REFUNDING = 'ERROR_ON_REFUNDING',
  WAITING_CANCELLATION = 'WAITING_CANCELLATION',
  WITH_ERROR = 'WITH_ERROR',
  GENERATED = 'GENERATED',
  VIEWED = 'VIEWED',
  UNDERPAID = 'UNDERPAID',
  OVERPAID = 'OVERPAID',
  NOT_PAID = 'NOT_PAID',
  WAITING_PAYMENT = 'WAITING_PAYMENT',
  WAITING_RELEASE = 'WAITING_RELEASE',
  WAITING_CONFIRM = 'WAITING_CONFIRM',
  OVERDUE = 'OVERDUE',
  WILLBEOVERDUE = 'WILLBEOVERDUE',
  SENDING = 'SENDING',
  SENT = 'SENT',
  REJECTED = 'REJECTED',
  DELIVERY_DELAYED = 'DELIVERY_DELAYED',
  COMPLAINED = 'COMPLAINED',
  BOUNCED = 'BOUNCED',
  DELIVERED = 'DELIVERED',
  CLICKED = 'CLICKED',
  RETAINED = 'RETAINED',
  TODAY = 'TODAY',
  ALL = 'ALL',
  PENDING_INDEXING = 'PENDING_INDEXING',
  INDEXED = 'INDEXED',
  IN_STOCK = 'IN_STOCK',
  RUNNING_OUT = 'RUNNING_OUT',
  SOLD_OUT = 'SOLD_OUT',
  PENDING_REFUDED = 'PENDING_REFUDED',
}

export const allStatus = () => Object.values(Status);
