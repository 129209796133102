import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {AbstractControl, FormControl, FormGroup, UntypedFormBuilder, Validators} from '@angular/forms';
import {MaskService} from 'app/shared/mask/mask.service';
import {Subscription, throwError} from 'rxjs';
import {unsubscribe} from 'app/shared/util/react-util';
import {IBaseEntity} from 'app/shared/model/root/base-entity.model';
import {Form, IForm} from 'app/shared/model/form/form.model';
import {FormQuestionType} from 'app/shared/model/enumerations/form-question-type.enum';
import {IFormModelQuestion} from 'app/shared/model/form/form-model-question.model';
import {IFormSection} from 'app/shared/form/form-section.model';
import {StringUtilService} from 'app/shared/util/string-util.service';
import {DateTimeValidator} from 'app/shared/util/date-time-validator';
import moment from 'moment';
import {BrValidator} from 'app/shared/util/br-validator';
import {IFile} from 'app/shared/model/admin/file.model';
import {FILE_EXTENSIONS} from 'app/shared/constants/system.constants';
import {DropzoneConfigInterface} from 'app/shared/dropzone/dropzone.interfaces';
import {TranslateService} from '@ngx-translate/core';
import {Logger} from 'app/shared/util/logger';
import {S3PreSignedURL} from '../../../../serverless/model/s3-pre-signed-url.model';
import {UPLOAD_BUCKET_URL} from 'app/app.constants';
import {UploadService} from 'app/shared/upload/upload.service';
import {UploadRequest} from '../../../../serverless/model/upload-request.model';
import {catchError, finalize} from 'rxjs/operators';
import {ToastService} from 'app/shared/toast/toast.service';
import {Toast} from 'app/shared/toast/toast.model';
import {IFormAnswer} from 'app/shared/model/form/form-answer.model';
import {Status} from 'app/shared/model/enumerations/status.enum';
import {FormService} from 'app/shared/services/form/form.service';
import {HttpResponse} from '@angular/common/http';
import {IFormModel} from 'app/shared/model/form/form-model.model';
import {IFormAnswerOption} from 'app/shared/model/form/form-answer-option.model';
import {clone} from 'app/shared/util/object-util';
import {FormActionsService} from 'app/entities/form/form/form-actions.service';
import {ValidatorUtil} from 'app/shared/util/validator-util';

export declare type Layout = 'card' | 'modal';

export interface IFormResponseOptions {
	mode: Layout;
	showHeader?: boolean;
	showFormDescription?: boolean;
	showCancelButton?: boolean;
	showSaveButton?: boolean;
	showApprovalInfo?: boolean;
	autoSubmit?: boolean;
	autoClose?: boolean;
}

const Log = new Logger('FormResponseEditorComponent');

@Component({
	selector: 'app-frm-form-response-editor',
	templateUrl: './form-response-editor.component.html',
	styleUrls: ['./form-response-editor.component.scss']
})
export class FormResponseEditorComponent implements OnInit, OnDestroy {
	ready = false;
	editForm!: FormGroup;
	sections: IFormSection[] = [];
	uuidPattern: any;
	docExtensions = FILE_EXTENSIONS;
	isSaving = false;

	@Input()
	options: IFormResponseOptions = {
		mode: 'card',
		showHeader: true,
		showFormDescription: true,
		showCancelButton: true,
		showApprovalInfo: true,
		autoSubmit: true,
		autoClose: true
	};

	@Output()
	onFormIsValid: EventEmitter<IForm> = new EventEmitter<IForm>();

	@Output()
	onSubmitClicked: EventEmitter<IForm> = new EventEmitter<IForm>();

	@Output()
	onSubmitSuccess: EventEmitter<IForm> = new EventEmitter<IForm>();

	@Output()
	onSubmitError: EventEmitter<Object> = new EventEmitter<Object>();

	@Output()
	onCancelClicked: EventEmitter<never> = new EventEmitter<never>();

	imageFileDropzoneConfig: DropzoneConfigInterface = {
		clickable: true,
		maxFiles: 1,
		acceptedFiles: '.png, .jpg, .webp, .jpeg, .avif',
		autoReset: null,
		errorReset: null,
		maxFilesize: 25,
		cancelReset: null,
		method: 'PUT',
		parallelUploads: 1,
		dictDefaultMessage: this.translateService.instant('upload.dropzone.drop.image.here'),
		params: (files: any, xhr: any) => {
			Log.debug(() => `params() => files: ${files}`);
			files.length && (xhr as XMLHttpRequest).setRequestHeader('Content-Type', (files[0]['_preSignedURL'] as S3PreSignedURL).contentType);
		},
		url: (files: File[]) => {
			Log.debug(() => `url() => files: ${files}`);
			return files.length ? (files[0]['_preSignedURL'] as S3PreSignedURL).url : '';
		},
		accept: (file: File, done: Function) => {
			Log.debug(() => `accept() => file: ${file}`);

			const uuid = StringUtilService.uuid();
			const newFileName = `${uuid}.${StringUtilService.extractFileExtension(file.name)}`;
			const newFile = {
				uuid: uuid,
				active: true,
				name: newFileName,
				sourceName: file.name,
				contentType: file.type,
				url: `${UPLOAD_BUCKET_URL}${newFileName}`,
				_edited: true
			} as IFile;

			this.subscriptions.push(
				this.uploadService
					.getPreSignedURL(
						UploadRequest.from({
							fileName: newFile.name,
							contentType: newFile.contentType
						})
					)
					.pipe(
						catchError(err => {
							Log.error(() => `accept() => Couldn't get pre-signed url => ${JSON.stringify(err)}`);
							return throwError('Something bad happened; please try again later.');
						})
					)
					.subscribe((preSignedURL: S3PreSignedURL) => {
						Log.debug(() => `accept() => preSignedURL: ${JSON.stringify(preSignedURL)}`);
						file['_file'] = newFile;
						file['_preSignedURL'] = preSignedURL;
						done && done();
					})
			);
		}
	};

	private _form!: IForm;

	private subscriptions: Subscription[] = [];

	constructor(
		private fb: UntypedFormBuilder,
		private translateService: TranslateService,
		private uploadService: UploadService,
		private toastService: ToastService,
		private formService: FormService,
		private formActionsService: FormActionsService,
		public mask: MaskService
	) {
		this.uuidPattern = this.mask.uuidPattern();
	}

	ngOnInit(): void {
	}

	get form(): IForm {
		return this._form;
	}

	@Input()
	set form(value: IForm) {
		this.ready = false;
		this._form = value ? Form.convertDatesFromServer(clone<IForm>(value)) : null;
		this.buildFormAnswers();
	}

	// https://stackoverflow.com/questions/59935560/angular-reactive-form-with-dynamic-fields
	private buildFormAnswers(): void {
		if (!this._form?.formModel?.questions) {
			return;
		}

		this.editForm = this.fb.group({});

		this.subscriptions.push(
			this.editForm.valueChanges.subscribe(() => {
				if (this.ready && this.canSubmit()) {
					this.onFormIsValid.emit(this.buildFormToSubmit());
				}
			})
		);

		this.sections = [];

		this._form.formModel?.questions
			.filter((question: IFormModelQuestion) => question.active)
			.forEach((question: IFormModelQuestion) => this.addQuestion(question));

		this.ready = true;
	}

	private addQuestion(question: IFormModelQuestion): void {
		Log.debug(() => `addQuestion() => question: ${question}`);

		const a = this.getAnswerAndAddToSection(question);
		const validators = question.mandatory ? [Validators.required] : [];
		let initialValue;

		switch (question.type) {
			case FormQuestionType.YES_NO:
				initialValue = a.answer ? ['true', 'yes', '1'].includes(a.answer.toLowerCase()) : undefined;
				break;

			case FormQuestionType.SINGLE_LINE_TEXT:
			case FormQuestionType.MULTILINE_TEXT:
			case FormQuestionType.EMAIL:
			case FormQuestionType.URL:
				![undefined, null].includes(question.min) && validators.push(Validators.minLength((question.min as unknown) as number));
				![undefined, null].includes(question.max) && validators.push(Validators.maxLength((question.max as unknown) as number));
				question.type === FormQuestionType.EMAIL && validators.push(ValidatorUtil.email);
				break;

			case FormQuestionType.CPF:
			case FormQuestionType.CPF_CNPJ:
			case FormQuestionType.CNPJ:
				validators.push(BrValidator.validate);
				break;

			case FormQuestionType.CURRENCY:
			case FormQuestionType.INTEGER_NUMBER:
			case FormQuestionType.DECIMAL_NUMBER:
				![undefined, null].includes(question.min) && validators.push(Validators.min((question.min as unknown) as number));
				![undefined, null].includes(question.max) && validators.push(Validators.max((question.max as unknown) as number));
				break;

			case FormQuestionType.SINGLE_CHOICE:
				initialValue = (a.options ?? []).find(foa => foa.active);
				break;

			case FormQuestionType.MULTIPLE_CHOICE:
				initialValue = (a.options ?? []).filter(foa => foa.active);
				break;

			case FormQuestionType.DATE:
				initialValue = a.answer ? moment(a.answer) : undefined;

				![undefined, null].includes(question.minDate) &&
				validators.push((control: AbstractControl) =>
					DateTimeValidator.isSameOrAfter(moment(question.minDate, 'DD/MM/YYYY'), 'DD/MM/YYYY', 'day').validate(control)
				);
				![undefined, null].includes(question.maxDate) &&
				validators.push((control: AbstractControl) =>
					DateTimeValidator.isSameOrBefore(moment(question.maxDate, 'DD/MM/YYYY'), 'DD/MM/YYYY', 'day').validate(control)
				);
				break;

			case FormQuestionType.TIME:
				![undefined, null].includes(question.min) &&
				validators.push((control: AbstractControl) =>
					DateTimeValidator.isSameOrAfter(moment(question.min, 'HH:mm'), 'HH:mm', 'minute').validate(control)
				);
				![undefined, null].includes(question.max) &&
				validators.push((control: AbstractControl) =>
					DateTimeValidator.isSameOrBefore(moment(question.max, 'HH:mm'), 'HH:mm', 'minute').validate(control)
				);
				break;

			default:
				break;
		}

		this.editForm.addControl(a.uuid, new FormControl(initialValue ?? a.answerFile ?? a.answer, validators.length ? validators : null));

		// only for console debugging proposes.
		this.editForm.get(a.uuid)['_appType'] = a.question.type;
		this.editForm.get(a.uuid)['_appQuestion'] = a.question.question;

		if (this._form.status === Status.FINISHED) {
			this.editForm.get(a.uuid).disable();
		}

		this.subscriptions.push(this.editForm.get(a.uuid).valueChanges.subscribe(value => this.onFieldChangeValue(a, value)));
	}

	private onFieldChangeValue(answer: IFormAnswer, newValue: any): void {
		Log.debug(() => `onFieldChangeValue() => answer: ${answer.uuid}, newValue: ${newValue}`);

		answer._edited = true;
		answer.status = Status.IN_PROGRESS;
		answer.score = 0.0;
		!answer.startedDate && (answer.startedDate = moment());

		switch (answer.question.type) {
			case FormQuestionType.YES_NO:
				answer.answer = newValue;
				break;

			case FormQuestionType.DATE:
				answer.answer = newValue && moment(newValue).isValid() ? moment(newValue).toJSON() : null;
				break;

			case FormQuestionType.IMAGE_UPLOAD:
			case FormQuestionType.DOC_UPLOAD:
				answer.answerFile = newValue;
				break;

			case FormQuestionType.SINGLE_CHOICE:
			case FormQuestionType.MULTIPLE_CHOICE: {
				(answer.options ?? [])
					.filter(foa => foa.id !== undefined && foa.active)
					.forEach(o => {
						o.active = false;
						o._edited = true;
					});

				(newValue ? (newValue instanceof Array ? newValue : [newValue]) : []).forEach(foa => {
					foa.active = true;
					foa._edited = true;
					!answer.options.find(o => o.uuid === foa.uuid) && answer.options.push(foa);
				});
				break;
			}

			default:
				answer.answer = newValue;
		}

		answer.score = this.formActionsService.calcAnswerScore(answer);
	}

	private getAnswerAndAddToSection(question: IFormModelQuestion): IFormAnswer {
		let section = this.sections.find(
			s => (question.category && s.category?.uuid === question.category.uuid) || (!question.category && s.type === 'leftover')
		);
		if (!section) {
			section = (question.category
				? {
					uuid: question.category.uuid,
					type: 'category',
					category: question.category,
					collapsed: false,
					order: question.category.order,
					answers: []
				}
				: {
					uuid: StringUtilService.uuid(),
					type: 'leftover',
					collapsed: false,
					order: -1000,
					answers: []
				}) as IFormSection;
			this.sections.push(section);
		}

		const answer =
			this._form?.answers?.find(a => a.question?.uuid === question.uuid) ??
			({
				uuid: StringUtilService.uuid(),
				active: true,
				question,
				score: 0.0,
				answer: null,
				answerFile: null,
				status: Status.NEW,
				options: [],
				_availableOptions: [],
				_edited: true
			} as IFormAnswer);

		if (!answer.active) {
			answer.active = true;
			answer._edited = true;
		}

		[FormQuestionType.SINGLE_CHOICE, FormQuestionType.MULTIPLE_CHOICE].includes(question.type) &&
		(answer._availableOptions = (question.options ?? [])
			.filter(o => o.active)
			.map(
				option =>
					answer.options?.find(fao => fao.option?.uuid === option.uuid) ??
					({
						uuid: StringUtilService.uuid(),
						option,
						active: true,
						_edited: true
					} as IFormAnswerOption)
			));

		section.answers.push(answer);
		return answer;
	}

	onUploadedFile(file: IFile, answer: IFormAnswer): void {
		file._edited = true;
		this.editForm.get(answer.uuid).setValue(file);
		this.editForm.markAsDirty();
	}

	removeFile(answer: IFormAnswer): void {
		this.editForm.get(answer.uuid).setValue(null);
		this.editForm.markAsDirty();
	}

	onImageUploadSuccess(answer: IFormAnswer, event: any[]): void {
		Log.debug(() => `onImageUploadSuccess() => answer: ${answer}, event: ${event}`);

		if (!event.length) {
			return;
		}

		const file = event[0]['_file'] as IFile;
		file._edited = true;

		this.editForm.get(answer.uuid).setValue(file);
		this.editForm.markAsDirty();
	}

	public onImageUploadError(event: ErrorEvent): void {
		Log.error(() => `onImageUploadError() => event: ${event}`);
		this.toastService.show(Toast.danger('upload.toast.error'));
	}

	onImageUploading(event: any[]): void {
		// https://stackoverflow.com/questions/57418410/dropzone-client-side-resize-with-file-upload-to-aws-pre-signed-url
		Log.error(() => `onImageUploading() => event: ${event}`);
		const file = event[0];
		const xhr = event[1] as XMLHttpRequest;
		const _send = xhr.send;
		xhr.send = () => _send.call(xhr, file);
	}

	isDate(question: IFormModelQuestion): boolean {
		return [FormQuestionType.DATE].includes(question?.type);
	}

	isText(question: IFormModelQuestion): boolean {
		return [FormQuestionType.SINGLE_LINE_TEXT, FormQuestionType.MULTILINE_TEXT, FormQuestionType.URL, FormQuestionType.EMAIL].includes(
			question?.type
		);
	}

	isCEP(question: IFormModelQuestion): boolean {
		return [FormQuestionType.CEP].includes(question?.type);
	}

	isPhone(question: IFormModelQuestion): boolean {
		return [FormQuestionType.PHONE].includes(question?.type);
	}

	isCPF(question: IFormModelQuestion): boolean {
		return [FormQuestionType.CPF].includes(question?.type);
	}

	isCNPJ(question: IFormModelQuestion): boolean {
		return [FormQuestionType.CNPJ].includes(question?.type);
	}

	isCPForCNPJ(question: IFormModelQuestion): boolean {
		return [FormQuestionType.CPF_CNPJ].includes(question?.type);
	}

	getMinLength(question: IFormModelQuestion): number {
		return ((this.isDate(question) ? 10 : question?.min) as unknown) as number;
	}

	getMaxLength(question: IFormModelQuestion): number {
		return ((this.isDate(question) ? 10 : question?.max) as unknown) as number;
	}

	getFormattedMinDateTime(question: IFormModelQuestion): string {
		return this.isDate(question) ? question.minDate?.format('DD/MM/YYYY') : question.min;
	}

	getFormattedMaxDateTime(question: IFormModelQuestion): string {
		return this.isDate(question) ? question.maxDate?.format('DD/MM/YYYY') : question.max;
	}

	isInvalidAndTouched(field: string): boolean {
		const input = this.editForm.get(field);
		return input !== null && input.invalid && (input.dirty || input.touched);
	}

	canShowForm(): boolean {
		return this.form && this.ready === true;
	}

	canSubmit(): boolean {
		return this.editForm.valid && this.editForm.dirty && !this.isSaving;
	}

	trackIdentity(index: number, item: IBaseEntity): any {
		return item.id ?? item.uuid;
	}

	trackIdentityUuid(index: number, item: IBaseEntity): any {
		return item.uuid;
	}

	private buildFormToSubmit(): IForm {
		return {
			...this._form,
			formModel: {
				id: this._form.formModel.id,
				uuid: this._form.formModel.uuid
			} as IFormModel,
			startedDate:
				this._form.startedDate ??
				this.sections
					.flatMap(s => s.answers)
					.filter(a => a.active && a.startedDate)
					.sort(a => a.startedDate.unix())
					.map(a => a.startedDate)
					.find(() => true) ??
				moment(),
			status: Status.IN_PROGRESS,
			score: this.formActionsService.calcFormScore({answers: this.sections.flatMap(s => s.answers)} as IForm),
			answers: this.sections
				.flatMap(s => s.answers)
				.filter(a => a.active && a._edited)
				.map(
					a =>
						({
							...a,
							finishedDate: moment(),
							status: Status.IN_EVALUATION,
							question: {
								...(a.question)
							} as IFormModelQuestion
						} as IFormAnswer)
				),
			_edited: true
		} as IForm;
	}

	submit(): void {
		this.isSaving = true;

		const formToSubmit = this.buildFormToSubmit();

		this.onSubmitClicked.emit(this._form);

		this.options.autoSubmit &&
		this.subscriptions.push(
			this.formService
				.updateForCurrentUser(formToSubmit)
				.pipe(finalize(() => (this.isSaving = false)))
				.subscribe(
					(updatedForm: HttpResponse<IForm>) => {
						this._form = updatedForm.body;
						this.buildFormAnswers();
						this.onSubmitSuccess.emit(updatedForm.body);
					},
					err => this.onSubmitError.emit(err)
				)
		);
	}

	isDisabled(answer: IFormAnswer): boolean {
		return this.editForm.get(answer.uuid)?.disabled === true;
	}

	cancel(): void {
		this.onCancelClicked.emit();
	}

	ngOnDestroy(): void {
		unsubscribe(this.subscriptions);
	}
}
