import {Component, Input} from '@angular/core';
import {ButtonColor} from 'app/shared/layout/window/button.model';

const colors: ButtonColor[] = ['success', 'danger', 'primary', 'secondary', 'warning', 'info', 'default'];

@Component({
  selector: 'app-enum-label',
  template: `
    <span *ngIf="value" class="badge" [ngClass]="'badge-' + color()">{{ name + '.' + value | translate }}</span>
  `
})
export class EnumLabelComponent {
  @Input()
  name: string | null = null;

  @Input()
  value: any | null = null;

  @Input()
  options: any[] = [];

  color(): ButtonColor {
    if (!this.value) return 'default';
    const idx = this.options.indexOf(this.value) ?? 0;
    // https://stackoverflow.com/a/54427125/770000
    return colors[((idx % colors.length) + colors.length) % colors.length];
  }
}
