import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {UntypedFormBuilder, Validators} from '@angular/forms';
import {MaskService} from 'app/shared/mask/mask.service';
import {map, switchMap} from 'rxjs/operators';
import {HttpResponse} from '@angular/common/http';
import {ILocation} from 'app/shared/model/location/location.model';
import {Subscription} from 'rxjs';
import {unsubscribe} from 'app/shared/util/react-util';
import {ICity} from 'app/shared/model/location/city.model';
import {CityService} from 'app/shared/services/location/city.service';

@Component({
  selector: 'app-loc-location-editor',
  templateUrl: './location-editor.component.html'
})
export class LocationEditorComponent implements OnInit, OnDestroy {
  @Input()
  entity!: ILocation;

  cities: ICity[] = [];

  editForm = this.fb.group({
    id: [{value: null, disabled: true}],
    active: [false, [Validators.required]],
    address: ['', [Validators.required, Validators.minLength(1), Validators.maxLength(255)]],
    number: [null, [Validators.maxLength(10)]],
    neighborhood: [null, [Validators.maxLength(255)]],
    complement: [null, [Validators.maxLength(255)]],
    zipCode: [null, [Validators.maxLength(9)]],
    city: [null, Validators.required]
  });

  private subscriptions: Subscription[] = [];

  constructor(private cityService: CityService, private fb: UntypedFormBuilder, public mask: MaskService) {
  }

  ngOnInit(): void {
    this.subscriptions.push(
      this.cityService
        .query({page: 0, size: 100})
        .pipe(
          map((res: HttpResponse<ICity[]>) => (this.cities = res.body || [])),
          map(() => this.entity && this.updateForm(this.entity)),
          switchMap(() => this.editForm.statusChanges)
        )
        .subscribe(() => this.updateLocation(this.entity))
    );
  }

  private updateForm(location: ILocation): void {
    this.editForm.patchValue({
      id: location.id,
      uuid: location.uuid,
      active: location.active,
      address: location.address,
      number: location.number,
      neighborhood: location.neighborhood,
      complement: location.complement,
      zipCode: location.zipCode,
      city: location.city
    });
  }

  private updateLocation(location: ILocation): void {
    location.active = this.editForm.get(['active'])!.value;
    location.address = this.editForm.get(['address'])!.value;
    location.number = this.editForm.get(['number'])!.value;
    location.neighborhood = this.editForm.get(['neighborhood'])!.value;
    location.complement = this.editForm.get(['complement'])!.value;
    location.zipCode = this.editForm.get(['zipCode'])!.value;
    location.city = this.editForm.get(['city'])!.value;
    location._edited = true;
  }

  isInvalidAndTouched(field: string): boolean {
    const input = this.editForm.get(field);
    return input !== null && input.invalid && (input.dirty || input.touched);
  }

  ngOnDestroy(): void {
    unsubscribe(this.subscriptions);
  }
}
