import {isMoment} from 'moment';
import {BaseEntity} from 'app/shared/model/root/base-entity.model';

export function getTypeOfProperty<T, K extends keyof T>(obj: T, key: K): string {
	return typeof obj[key];
}

export declare type PropertyType =
	'string'
	| 'number'
	| 'boolean'
	| 'object'
	| 'function'
	| 'undefined'
	| 'array'
	| 'date'
	| 'enum'
	| 'filled'
	| 'faIcon';

export class Property<T> {
	public readonly key: keyof T | string;
	public readonly name: string;
	public readonly type: PropertyType;
	public readonly identifier: boolean;
	public readonly admin: boolean;
	public readonly audit: boolean;
	public readonly transient: boolean;

	constructor(key: keyof T | string, type: PropertyType, transient: boolean = false) {
		this.key = key;
		this.type = type;
		this.name = key.toString();
		this.admin = this.name === 'active';
		this.identifier = ['id', 'uuid'].includes(this.name);
		this.audit = ['createdBy', 'createdDate', 'lastModifiedBy', 'lastModifiedDate'].includes(this.name);
		this.transient = transient;
	}

	asEnum(): Property<T> {
		return new Property<T>(this.key, 'enum');
	}

	asNumber(): Property<T> {
		return new Property<T>(this.key, 'number');
	}

	asObject(): Property<T> {
		return new Property<T>(this.key, 'object');
	}

	asFilled(): Property<T> {
		return new Property<T>(this.key, 'filled');
	}

	asFaIcon(): Property<T> {
		return new Property<T>(this.key, 'faIcon');
	}
}

export function getProperties<T>(obj: T): Property<T>[] {
	return Object.keys(obj)
		.map(name => name as keyof T)
		.map(
			key =>
				new Property(
					key,
					((prop: string): PropertyType => {
						if (['string', 'number', 'boolean', 'function'].includes(prop)) {
							return prop as PropertyType;
						}

						const value = obj[key];
						if (!value) {
							return 'undefined';
						}

						if (value instanceof Array) {
							return 'array';
						}
						if (value instanceof Date || isMoment(value)) {
							return 'date';
						}
						if (value instanceof BaseEntity) {
							return 'object';
						}

						return 'undefined';
					})(getTypeOfProperty(obj, key))
				)
		);
}

export function clone<T extends Object>(obj: T): T {
	return JSON.parse(JSON.stringify(obj));
}
