import {Component, OnDestroy} from '@angular/core';
import {Toast} from 'app/shared/toast/toast.model';
import {ToastService} from 'app/shared/toast/toast.service';
import {Subscription} from 'rxjs';
import {unsubscribe} from 'app/shared/util/react-util';
import {tadaOnEnterAnimation} from 'angular-animations';

@Component({
  selector: 'app-toast',
  animations: [tadaOnEnterAnimation()],
  styleUrls: ['./toast.scss'],
  template: `
    <div aria-live="polite" aria-atomic="true">
      <ng-container *ngFor="let toast of toasts">
        <ng-container *ngIf="toast.showHeader">
          <ngb-toast
            class="dm-toast"
            [@tadaOnEnter]
            [autohide]="toast.autoHide"
            [delay]="toast.delay"
            (hide)="dismiss(toast)"
            [ngClass]="['bg-' + toast.color, toast.getTextColor()]"
          >
            <ng-template ngbToastHeader>
              <div class="{{ toast.getTextColor() }}">
                <fa-icon [fixedWidth]="true" [icon]="toast.headerIcon"></fa-icon>
                <span>&nbsp;</span>
                <strong class="mx-1" [innerHTML]="toast.header || '' | translate: toast.headerParams"></strong>
              </div>
            </ng-template>
            <fa-icon *ngIf="toast.bodyIcon" [fixedWidth]="true" [icon]="toast.bodyIcon"></fa-icon>
            <span *ngIf="toast.bodyIcon">&nbsp;</span>
            <span [innerHTML]="toast.body | translate: toast.bodyParams"></span>
          </ngb-toast>
        </ng-container>
        <ng-container *ngIf="!toast.showHeader">
          <ngb-toast
            class="dm-toast"
            [@tadaOnEnter]
            [autohide]="toast.autoHide"
            [delay]="toast.delay"
            (hide)="dismiss(toast)"
            [ngClass]="['bg-' + toast.color, toast.getTextColor()]"
          >
            <fa-icon *ngIf="toast.bodyIcon" [fixedWidth]="true" [icon]="toast.bodyIcon"></fa-icon>
            <span *ngIf="toast.bodyIcon">&nbsp;</span>
            <span [innerHTML]="toast.body | translate: toast.bodyParams"></span>
          </ngb-toast>
        </ng-container>
      </ng-container>
    </div>
  `
})
export class ToastComponent implements OnDestroy {
  toasts: Toast[] = [];
  private toasts$: Subscription;
  private toastClear$: Subscription;

  constructor(private toastService: ToastService) {
    this.toasts$ = this.toastService.addSubscribe(event => this.add(event.content));
    this.toastClear$ = this.toastService.clearSubscribe(() => this.clear());
  }

  private add(toast: Toast): void {
    this.toasts.push(toast);
  }

  private clear(): void {
    this.toasts = [];
  }

  dismiss(toast: Toast): void {
    this.toasts = this.toasts.filter(t => t !== toast);
    toast.dismiss && toast.dismiss();
  }

  ngOnDestroy(): void {
    unsubscribe([this.toasts$]);
  }
}
