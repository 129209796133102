import {Component} from '@angular/core';
import {Criteria, FilterFunction} from 'app/shared/filter/criteria.model';
import {CriteriaFilterModalService} from 'app/shared/filter/criteria-filter-modal.service';
import {IBaseEntity} from 'app/shared/model/root/base-entity.model';
import {ICriteriaFilterOptions} from 'app/shared/filter/criteria-filter.component';

@Component({
  selector: 'app-criteria-filter-modal',
  template: `
    <app-criteria-filter
      [entityName]="entityName"
      [criterias]="criterias"
      [options]="options"
      [thirdPartyEntity]="thirdPartyEntity"
      (onFilterClicked)="onFilterClicked($event)"
      (onCancelClicked)="onCancelClicked()"
      (onCriteriaChanged)="onCriteriaChanged($event)"
    ></app-criteria-filter>
  `
})
export class CriteriaFilterModalComponent<T extends IBaseEntity> {
  entityName: string | null = null;
  criterias: Criteria<T>[] = [];
  applyFilter?: FilterFunction;
  criteriaUpdated?: Function;
  clear?: Function;
  cancel?: Function;
  thirdPartyEntity?: boolean;
  options: ICriteriaFilterOptions = {
    mode: 'modal',
    showHeader: true,
    showApplyButton: true,
    showClearButton: true,
    showCancelButton: true
  };

  constructor(private service: CriteriaFilterModalService<T>) {
  }

  onFilterClicked(filter: Object): void {
    this.applyFilter && this.applyFilter(filter);
    this.service.confirm();
  }

  onClearClicked(): void {
    this.clear && this.clear();
  }

  onCriteriaChanged($event: Criteria<T>[]): void {
    this.criteriaUpdated && this.criteriaUpdated($event);
  }

  onCancelClicked(): void {
    this.cancel && this.cancel();
    this.service.dismiss();
  }
}
