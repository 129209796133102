export enum FeatureAuthority {
  /* Administration */
  ADM_CHANGE_LOG_VIEW = 'ADM_CHANGE_LOG_VIEW',
  ADM_CHANGE_LOG_CREATE = 'ADM_CHANGE_LOG_CREATE',
  ADM_CHANGE_LOG_EDIT = 'ADM_CHANGE_LOG_EDIT',
  ADM_CHANGE_LOG_DELETE = 'ADM_CHANGE_LOG_DELETE',

  ADM_OPTION_VIEW = 'ADM_OPTION_VIEW',
  ADM_OPTION_CREATE = 'ADM_OPTION_CREATE',
  ADM_OPTION_EDIT = 'ADM_OPTION_EDIT',
  ADM_OPTION_DELETE = 'ADM_OPTION_DELETE',

  ADM_FILE_VIEW = 'ADM_FILE_VIEW',
  ADM_FILE_CREATE = 'ADM_FILE_CREATE',
  ADM_FILE_EDIT = 'ADM_FILE_EDIT',
  ADM_FILE_DELETE = 'ADM_FILE_DELETE',

  ADM_HOLIDAY_VIEW = 'ADM_HOLIDAY_VIEW',
  ADM_HOLIDAY_CREATE = 'ADM_HOLIDAY_CREATE',
  ADM_HOLIDAY_EDIT = 'ADM_HOLIDAY_EDIT',
  ADM_HOLIDAY_DELETE = 'ADM_HOLIDAY_DELETE',

  ADM_PARAMETER_VIEW = 'ADM_PARAMETER_VIEW',
  ADM_PARAMETER_CREATE = 'ADM_PARAMETER_CREATE',
  ADM_PARAMETER_EDIT = 'ADM_PARAMETER_EDIT',
  ADM_PARAMETER_DELETE = 'ADM_PARAMETER_DELETE',

  ADM_PLAN_VIEW = 'ADM_PLAN_VIEW',
  ADM_PLAN_CREATE = 'ADM_PLAN_CREATE',
  ADM_PLAN_EDIT = 'ADM_PLAN_EDIT',
  ADM_PLAN_DELETE = 'ADM_PLAN_DELETE',

  ADM_SEQUENCE_VIEW = 'ADM_SEQUENCE_VIEW',
  ADM_SEQUENCE_CREATE = 'ADM_SEQUENCE_CREATE',
  ADM_SEQUENCE_EDIT = 'ADM_SEQUENCE_EDIT',
  ADM_SEQUENCE_DELETE = 'ADM_SEQUENCE_DELETE',

  ADM_UNIT_VIEW = 'ADM_UNIT_VIEW',
  ADM_UNIT_CREATE = 'ADM_UNIT_CREATE',
  ADM_UNIT_EDIT = 'ADM_UNIT_EDIT',
  ADM_UNIT_DELETE = 'ADM_UNIT_DELETE',

  ADM_USER_MANUAL_VIEW = 'ADM_USER_MANUAL_VIEW',
  ADM_USER_MANUAL_CREATE = 'ADM_USER_MANUAL_CREATE',
  ADM_USER_MANUAL_EDIT = 'ADM_USER_MANUAL_EDIT',
  ADM_USER_MANUAL_DELETE = 'ADM_USER_MANUAL_DELETE',

  /* Company */
  COM_CNPJ_VIEW = 'COM_CNPJ_VIEW',
  COM_CNPJ_CREATE = 'COM_CNPJ_CREATE',
  COM_CNPJ_EDIT = 'COM_CNPJ_EDIT',
  COM_CNPJ_DELETE = 'COM_CNPJ_DELETE',

  COM_COMPANY_VIEW = 'COM_COMPANY_VIEW',
  COM_COMPANY_CREATE = 'COM_COMPANY_CREATE',
  COM_COMPANY_EDIT = 'COM_COMPANY_EDIT',
  COM_COMPANY_DELETE = 'COM_COMPANY_DELETE',

  COM_COMPANY_PARAMETER_VIEW = 'COM_COMPANY_PARAMETER_VIEW',
  COM_COMPANY_PARAMETER_EDIT = 'COM_COMPANY_PARAMETER_EDIT',

  /* Content */
  CNT_BANNER_VIEW = 'CNT_BANNER_VIEW',
  CNT_BANNER_CREATE = 'CNT_BANNER_CREATE',
  CNT_BANNER_EDIT = 'CNT_BANNER_EDIT',
  CNT_BANNER_DELETE = 'CNT_BANNER_DELETE',

  CNT_METADATA_VIEW = 'CNT_METADATA_VIEW',
  CNT_METADATA_CREATE = 'CNT_METADATA_CREATE',
  CNT_METADATA_EDIT = 'CNT_METADATA_EDIT',
  CNT_METADATA_DELETE = 'CNT_METADATA_DELETE',

  CNT_POST_CATEGORY_VIEW = 'CNT_POST_CATEGORY_VIEW',
  CNT_POST_CATEGORY_CREATE = 'CNT_POST_CATEGORY_CREATE',
  CNT_POST_CATEGORY_EDIT = 'CNT_POST_CATEGORY_EDIT',
  CNT_POST_CATEGORY_DELETE = 'CNT_POST_CATEGORY_DELETE',

  CNT_POST_VIEW = 'CNT_POST_VIEW',
  CNT_POST_CREATE = 'CNT_POST_CREATE',
  CNT_POST_EDIT = 'CNT_POST_EDIT',
  CNT_POST_DELETE = 'CNT_POST_DELETE',

  /* DevOps */
  DEV_VAULT_VIEW = 'DEV_VAULT_VIEW',
  DEV_VAULT_CREATE = 'DEV_VAULT_CREATE',
  DEV_VAULT_EDIT = 'DEV_VAULT_EDIT',
  DEV_VAULT_DELETE = 'DEV_VAULT_DELETE',

  DEV_VAULT_REVEAL_SECRET_VIEW = 'DEV_VAULT_REVEAL_SECRET_VIEW',
  DEV_VAULT_UPDATE_SECRET_EDIT = 'DEV_VAULT_UPDATE_SECRET_EDIT',

  /* General Alert */
  ADM_GENERAL_ALERT_VIEW = 'ADM_GENERAL_ALERT_VIEW',
  ADM_GENERAL_ALERT_CREATE = 'ADM_GENERAL_ALERT_CREATE',
  ADM_GENERAL_ALERT_EDIT = 'ADM_GENERAL_ALERT_EDIT',
  ADM_GENERAL_ALERT_DELETE = 'ADM_GENERAL_ALERT_DELETE',

  /* Email */
  EML_EMAIL_ADDRESS_VIEW = 'EML_EMAIL_ADDRESS_VIEW',
  EML_EMAIL_ADDRESS_CREATE = 'EML_EMAIL_ADDRESS_CREATE',
  EML_EMAIL_ADDRESS_EDIT = 'EML_EMAIL_ADDRESS_EDIT',
  EML_EMAIL_ADDRESS_DELETE = 'EML_EMAIL_ADDRESS_DELETE',

  EML_EMAIL_NOTIFICATION_VIEW = 'EML_EMAIL_NOTIFICATION_VIEW',
  EML_EMAIL_NOTIFICATION_CREATE = 'EML_EMAIL_NOTIFICATION_CREATE',
  EML_EMAIL_NOTIFICATION_EDIT = 'EML_EMAIL_NOTIFICATION_EDIT',
  EML_EMAIL_NOTIFICATION_DELETE = 'EML_EMAIL_NOTIFICATION_DELETE',

  EML_EMAIL_TEMPLATE_VIEW = 'EML_EMAIL_TEMPLATE_VIEW',
  EML_EMAIL_TEMPLATE_CREATE = 'EML_EMAIL_TEMPLATE_CREATE',
  EML_EMAIL_TEMPLATE_EDIT = 'EML_EMAIL_TEMPLATE_EDIT',
  EML_EMAIL_TEMPLATE_DELETE = 'EML_EMAIL_TEMPLATE_DELETE',

  /* Form */
  FRM_FORM_VIEW = 'FRM_FORM_VIEW',
  FRM_FORM_CREATE = 'FRM_FORM_CREATE',
  FRM_FORM_EDIT = 'FRM_FORM_EDIT',
  FRM_FORM_DELETE = 'FRM_FORM_DELETE',

  FRM_FORM_MODEL_VIEW = 'FRM_FORM_MODEL_VIEW',
  FRM_FORM_MODEL_CREATE = 'FRM_FORM_MODEL_CREATE',
  FRM_FORM_MODEL_EDIT = 'FRM_FORM_MODEL_EDIT',
  FRM_FORM_MODEL_DELETE = 'FRM_FORM_MODEL_DELETE',

  /* Locations */
  LOC_COUNTRY_VIEW = 'LOC_COUNTRY_VIEW',
  LOC_COUNTRY_CREATE = 'LOC_COUNTRY_CREATE',
  LOC_COUNTRY_EDIT = 'LOC_COUNTRY_EDIT',
  LOC_COUNTRY_DELETE = 'LOC_COUNTRY_DELETE',

  LOC_STATE_VIEW = 'LOC_STATE_VIEW',
  LOC_STATE_CREATE = 'LOC_STATE_CREATE',
  LOC_STATE_EDIT = 'LOC_STATE_EDIT',
  LOC_STATE_DELETE = 'LOC_STATE_DELETE',

  LOC_CITY_VIEW = 'LOC_CITY_VIEW',
  LOC_CITY_CREATE = 'LOC_CITY_CREATE',
  LOC_CITY_EDIT = 'LOC_CITY_EDIT',
  LOC_CITY_DELETE = 'LOC_CITY_DELETE',

  LOC_LOCATION_VIEW = 'LOC_LOCATION_VIEW',
  LOC_LOCATION_CREATE = 'LOC_LOCATION_CREATE',
  LOC_LOCATION_EDIT = 'LOC_LOCATION_EDIT',
  LOC_LOCATION_DELETE = 'LOC_LOCATION_DELETE',

  /* Module */
  MOD_FEATURE_VIEW = 'MOD_FEATURE_VIEW',
  MOD_FEATURE_CREATE = 'MOD_FEATURE_CREATE',
  MOD_FEATURE_EDIT = 'MOD_FEATURE_EDIT',
  MOD_FEATURE_DELETE = 'MOD_FEATURE_DELETE',

  MOD_MODULE_VIEW = 'MOD_MODULE_VIEW',
  MOD_MODULE_CREATE = 'MOD_MODULE_CREATE',
  MOD_MODULE_EDIT = 'MOD_MODULE_EDIT',
  MOD_MODULE_DELETE = 'MOD_MODULE_DELETE',

  MOD_HELP_VIEW = 'MOD_HELP_VIEW',
  MOD_HELP_CREATE = 'MOD_HELP_CREATE',
  MOD_HELP_EDIT = 'MOD_HELP_EDIT',
  MOD_HELP_DELETE = 'MOD_HELP_DELETE',

  MOD_HELP_CATEGORY_VIEW = 'MOD_HELP_CATEGORY_VIEW',
  MOD_HELP_CATEGORY_CREATE = 'MOD_HELP_CATEGORY_CREATE',
  MOD_HELP_CATEGORY_EDIT = 'MOD_HELP_CATEGORY_EDIT',
  MOD_HELP_CATEGORY_DELETE = 'MOD_HELP_CATEGORY_DELETE',

  /* Notification */
  NTF_NOTIFICATION_EVENT_VIEW = 'NTF_NOTIFICATION_EVENT_VIEW',
  NTF_NOTIFICATION_EVENT_CREATE = 'NTF_NOTIFICATION_EVENT_CREATE',
  NTF_NOTIFICATION_EVENT_EDIT = 'NTF_NOTIFICATION_EVENT_EDIT',
  NTF_NOTIFICATION_EVENT_DELETE = 'NTF_NOTIFICATION_EVENT_DELETE',

  /* Payment */
  PAY_ARTIFACT_VIEW = 'PAY_ARTIFACT_VIEW',
  PAY_ARTIFACT_CREATE = 'PAY_ARTIFACT_CREATE',
  PAY_ARTIFACT_EDIT = 'PAY_ARTIFACT_EDIT',
  PAY_ARTIFACT_DELETE = 'PAY_ARTIFACT_DELETE',

  PAY_COUPON_VIEW = 'PAY_COUPON_VIEW',
  PAY_COUPON_CREATE = 'PAY_COUPON_CREATE',
  PAY_COUPON_EDIT = 'PAY_COUPON_EDIT',
  PAY_COUPON_DELETE = 'PAY_COUPON_DELETE',

  PAY_CARD_VIEW = 'PAY_CARD_VIEW',
  PAY_CARD_CREATE = 'PAY_CARD_CREATE',
  PAY_CARD_EDIT = 'PAY_CARD_EDIT',
  PAY_CARD_DELETE = 'PAY_CARD_DELETE',

  PAY_PIX_VIEW = 'PAY_PIX_VIEW',
  PAY_PIX_CREATE = 'PAY_PIX_CREATE',
  PAY_PIX_EDIT = 'PAY_PIX_EDIT',
  PAY_PIX_DELETE = 'PAY_PIX_DELETE',

  PAY_BILLET_VIEW = 'PAY_BILLET_VIEW',
  PAY_BILLET_CREATE = 'PAY_BILLET_CREATE',
  PAY_BILLET_EDIT = 'PAY_BILLET_EDIT',
  PAY_BILLET_DELETE = 'PAY_BILLET_DELETE',

  PAY_CHARGE_VIEW = 'PAY_CHARGE_VIEW',
  PAY_CHARGE_CREATE = 'PAY_CHARGE_CREATE',
  PAY_CHARGE_EDIT = 'PAY_CHARGE_EDIT',
  PAY_CHARGE_DELETE = 'PAY_CHARGE_DELETE',

  PAY_CUSTOMER_VIEW = 'PAY_CUSTOMER_VIEW',
  PAY_CUSTOMER_CREATE = 'PAY_CUSTOMER_CREATE',
  PAY_CUSTOMER_EDIT = 'PAY_CUSTOMER_EDIT',
  PAY_CUSTOMER_DELETE = 'PAY_CUSTOMER_DELETE',

  PAY_GATEWAY_VIEW = 'PAY_GATEWAY_VIEW',
  PAY_GATEWAY_CREATE = 'PAY_GATEWAY_CREATE',
  PAY_GATEWAY_EDIT = 'PAY_GATEWAY_EDIT',
  PAY_GATEWAY_DELETE = 'PAY_GATEWAY_DELETE',

  PAY_ORDER_VIEW = 'PAY_ORDER_VIEW',
  PAY_ORDER_CREATE = 'PAY_ORDER_CREATE',
  PAY_ORDER_EDIT = 'PAY_ORDER_EDIT',
  PAY_ORDER_DELETE = 'PAY_ORDER_DELETE',

  PAY_TRANSACTION_VIEW = 'PAY_TRANSACTION_VIEW',
  PAY_TRANSACTION_CREATE = 'PAY_TRANSACTION_CREATE',
  PAY_TRANSACTION_EDIT = 'PAY_TRANSACTION_EDIT',
  PAY_TRANSACTION_DELETE = 'PAY_TRANSACTION_DELETE',

  PAY_WEBHOOK_VIEW = 'PAY_WEBHOOK_VIEW',
  PAY_WEBHOOK_CREATE = 'PAY_WEBHOOK_CREATE',
  PAY_WEBHOOK_EDIT = 'PAY_WEBHOOK_EDIT',
  PAY_WEBHOOK_DELETE = 'PAY_WEBHOOK_DELETE',

  /* Product */
  PRO_BRAND_VIEW = 'PRO_BRAND_VIEW',
  PRO_BRAND_CREATE = 'PRO_BRAND_CREATE',
  PRO_BRAND_EDIT = 'PRO_BRAND_EDIT',
  PRO_BRAND_DELETE = 'PRO_BRAND_DELETE',

  PRO_CATEGORY_VIEW = 'PRO_CATEGORY_VIEW',
  PRO_CATEGORY_CREATE = 'PRO_CATEGORY_CREATE',
  PRO_CATEGORY_EDIT = 'PRO_CATEGORY_EDIT',
  PRO_CATEGORY_DELETE = 'PRO_CATEGORY_DELETE',

  PRO_PRICE_LIST_VIEW = 'PRO_PRICE_LIST_VIEW',
  PRO_PRICE_LIST_CREATE = 'PRO_PRICE_LIST_CREATE',
  PRO_PRICE_LIST_EDIT = 'PRO_PRICE_LIST_EDIT',
  PRO_PRICE_LIST_DELETE = 'PRO_PRICE_LIST_DELETE',

  PRO_PRODUCT_VIEW = 'PRO_PRODUCT_VIEW',
  PRO_PRODUCT_CREATE = 'PRO_PRODUCT_CREATE',
  PRO_PRODUCT_EDIT = 'PRO_PRODUCT_EDIT',
  PRO_PRODUCT_DELETE = 'PRO_PRODUCT_DELETE',

  PRO_TAG_VIEW = 'PRO_TAG_VIEW',
  PRO_TAG_CREATE = 'PRO_TAG_CREATE',
  PRO_TAG_EDIT = 'PRO_TAG_EDIT',
  PRO_TAG_DELETE = 'PRO_TAG_DELETE',

  /* Scrum */
  SCR_ACTIVITY_VIEW = 'SCR_ACTIVITY_VIEW',
  SCR_ACTIVITY_CREATE = 'SCR_ACTIVITY_CREATE',
  SCR_ACTIVITY_EDIT = 'SCR_ACTIVITY_EDIT',
  SCR_ACTIVITY_DELETE = 'SCR_ACTIVITY_DELETE',

  SCR_PROJECT_VIEW = 'SCR_PROJECT_VIEW',
  SCR_PROJECT_CREATE = 'SCR_PROJECT_CREATE',
  SCR_PROJECT_EDIT = 'SCR_PROJECT_EDIT',
  SCR_PROJECT_DELETE = 'SCR_PROJECT_DELETE',

  SCR_REGISTER_VIEW = 'SCR_REGISTER_VIEW',
  SCR_REGISTER_CREATE = 'SCR_REGISTER_CREATE',
  SCR_REGISTER_EDIT = 'SCR_REGISTER_EDIT',
  SCR_REGISTER_DELETE = 'SCR_REGISTER_DELETE',

  /* Security */
  SEC_ROLE_VIEW = 'SEC_ROLE_VIEW',
  SEC_ROLE_CREATE = 'SEC_ROLE_CREATE',
  SEC_ROLE_EDIT = 'SEC_ROLE_EDIT',
  SEC_ROLE_DELETE = 'SEC_ROLE_DELETE',

  /* Session */
  SSS_BOOKMARK_VIEW = 'SSS_BOOKMARK_VIEW',
  SSS_BOOKMARK_CREATE = 'SSS_BOOKMARK_CREATE',
  SSS_BOOKMARK_EDIT = 'SSS_BOOKMARK_EDIT',
  SSS_BOOKMARK_DELETE = 'SSS_BOOKMARK_DELETE',

  SSS_DEVICE_VIEW = 'SSS_DEVICE_VIEW',
  SSS_DEVICE_CREATE = 'SSS_DEVICE_CREATE',
  SSS_DEVICE_EDIT = 'SSS_DEVICE_EDIT',
  SSS_DEVICE_DELETE = 'SSS_DEVICE_DELETE',

  SSS_TRACKING_VIEW = 'SSS_TRACKING_VIEW',
  SSS_TRACKING_CREATE = 'SSS_TRACKING_CREATE',
  SSS_TRACKING_EDIT = 'SSS_TRACKING_EDIT',
  SSS_TRACKING_DELETE = 'SSS_TRACKING_DELETE',

  /* Users */
  USR_USER_VIEW = 'USR_USER_VIEW',
  USR_USER_CREATE = 'USR_USER_CREATE',
  USR_USER_EDIT = 'USR_USER_EDIT',
  USR_USER_DELETE = 'USR_USER_DELETE',

  /* Warehouses */
  WHS_WAREHOUSE_VIEW = 'WHS_WAREHOUSE_VIEW',
  WHS_WAREHOUSE_CREATE = 'WHS_WAREHOUSE_CREATE',
  WHS_WAREHOUSE_EDIT = 'WHS_WAREHOUSE_EDIT',
  WHS_WAREHOUSE_DELETE = 'WHS_WAREHOUSE_DELETE',

  WHS_WAREHOUSE_LAUNCH_VIEW = 'WHS_WAREHOUSE_LAUNCH_VIEW',
  WHS_WAREHOUSE_LAUNCH_CREATE = 'WHS_WAREHOUSE_LAUNCH_CREATE',
  WHS_WAREHOUSE_LAUNCH_EDIT = 'WHS_WAREHOUSE_LAUNCH_EDIT',
  WHS_WAREHOUSE_LAUNCH_DELETE = 'WHS_WAREHOUSE_LAUNCH_DELETE',

  TPA_CONFIGURATION_VIEW = 'TPA_CONFIGURATION_VIEW',
  TPA_CONFIGURATION_CREATE = 'TPA_CONFIGURATION_CREATE',
  TPA_CONFIGURATION_EDIT = 'TPA_CONFIGURATION_EDIT',
  TPA_CONFIGURATION_DELETE = 'TPA_CONFIGURATION_DELETE',

  TPE_CONFIGURATION_VIEW = 'TPE_CONFIGURATION_VIEW',
  TPE_CONFIGURATION_CREATE = 'TPE_CONFIGURATION_VIEW',
  TPE_CONFIGURATION_EDIT = 'TPE_CONFIGURATION_EDIT',
  TPE_CONFIGURATION_DELETE = 'TPE_CONFIGURATION_DELETE',

  TPP_CLIENT_VIEW = 'TPP_CLIENT_VIEW',
  TPP_CLIENT_CREATE = 'TPP_CLIENT_CREATE',
  TPP_CLIENT_EDIT = 'TPP_CLIENT_EDIT',
  TPP_CLIENT_DELETE = 'TPP_CLIENT_DELETE',

  TPP_PROVIDER_VIEW = 'TPP_PROVIDER_VIEW',
  TPP_PROVIDER_CREATE = 'TPP_PROVIDER_CREATE',
  TPP_PROVIDER_EDIT = 'TPP_PROVIDER_EDIT',
  TPP_PROVIDER_DELETE = 'TPP_PROVIDER_DELETE',

  /* Customer */
  ERP_CUSTOMER_VIEW = 'ERP_CUSTOMER_VIEW',
  ERP_CUSTOMER_CREATE = 'ERP_CUSTOMER_CREATE',
  ERP_CUSTOMER_EDIT = 'ERP_CUSTOMER_EDIT',
  ERP_CUSTOMER_DELETE = 'ERP_CUSTOMER_DELETE',

  /* Provider */
  ERP_PROVIDER_VIEW = 'ERP_PROVIDER_VIEW',
  ERP_PROVIDER_CREATE = 'ERP_PROVIDER_CREATE',
  ERP_PROVIDER_EDIT = 'ERP_PROVIDER_EDIT',
  ERP_PROVIDER_DELETE = 'ERP_PROVIDER_DELETE',

}
