import {Component, OnDestroy, OnInit} from '@angular/core';
import {Subscription} from 'rxjs';
import {unsubscribe} from 'app/shared/util/react-util';
import {VideoModalService} from 'app/shared/video-modal/video-modal.service';
import {DomSanitizer} from '@angular/platform-browser';
import {IVideoEntity} from 'app/shared/video-modal/video-entity.model';

@Component({
  selector: 'app-video-modal',
  templateUrl: './video-modal.component.html',
  styleUrls: ['./video-modal.component.scss']
})
export class VideoModalComponent implements OnInit, OnDestroy {
  entity?: IVideoEntity;
  close?: Function;

  url = null;

  private subscriptions: Subscription[] = [];

  constructor(private service: VideoModalService, private _sanitizer: DomSanitizer) {}

  ngOnInit(): void {
    this.url = this._sanitizer.bypassSecurityTrustResourceUrl(this.entity.videoUrl);
  }

  dismiss(): void {
    this.close && this.close();
    this.service.dismiss();
  }

  ngOnDestroy(): void {
    unsubscribe(this.subscriptions);
  }
}
