import {Injectable} from '@angular/core';
import {NgbModal, NgbModalRef} from '@ng-bootstrap/ng-bootstrap';
import {CriteriaFilterModalComponent} from 'app/shared/filter/criteria-filter-modal.component';
import {Criteria, FilterFunction} from 'app/shared/filter/criteria.model';
import {IBaseEntity} from 'app/shared/model/root/base-entity.model';

@Injectable({providedIn: 'root'})
export class CriteriaFilterModalService<T extends IBaseEntity> {
  private isOpen = false;
  private instance?: NgbModalRef;

  constructor(private modalService: NgbModal) {
  }

  show(
    entityName: string,
    criterias: Criteria<T>[],
    applyFilter: FilterFunction,
    criteriaUpdated?: Function,
    thirdPartyEntity?: boolean,
    clear?: Function,
    cancel?: Function
  ): void {
    if (this.isOpen) return;

    this.isOpen = true;

    this.instance = this.modalService.open(CriteriaFilterModalComponent, {size: 'lg', backdrop: 'static'});
    this.instance.componentInstance.entityName = entityName;
    this.instance.componentInstance.criterias = criterias;
    this.instance.componentInstance.applyFilter = applyFilter;
    this.instance.componentInstance.criteriaUpdated = criteriaUpdated;
    this.instance.componentInstance.thirdPartyEntity = thirdPartyEntity;
    this.instance.componentInstance.clear = clear;
    this.instance.componentInstance.cancel = cancel;

    this.instance.result.finally(() => (this.isOpen = false));
  }

  confirm(): void {
    if (!this.isOpen || !this.instance) return;
    this.instance.close();
  }

  dismiss(): void {
    if (!this.isOpen || !this.instance) return;
    this.instance.dismiss();
  }
}
