import {Injectable} from '@angular/core';
import {HttpClient, HttpResponse} from '@angular/common/http';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import moment from 'moment';

import {SERVER_API_URL} from 'app/app.constants';
import {createRequestOption} from 'app/shared/util/request-util';
import {ICompanyParameter} from 'app/shared/model/company/company-parameter.model';
import {ICompany} from 'app/shared/model/company/company.model';

type EntityResponseType = HttpResponse<ICompanyParameter>;
type EntityArrayResponseType = HttpResponse<ICompanyParameter[]>;

@Injectable({providedIn: 'root'})
export class CompanyParameterService {
  public resourceUrl = `${SERVER_API_URL}api/com/companies-parameters`;

  constructor(protected http: HttpClient) {}

  create(companyParameter: ICompanyParameter): Observable<EntityResponseType> {
    const copy = this.convertDateFromClient(companyParameter);
    return this.http
      .post<ICompanyParameter>(this.resourceUrl, copy, {observe: 'response'})
      .pipe(map((res: EntityResponseType) => this.convertDateFromServer(res)));
  }

  update(companyParameter: ICompanyParameter): Observable<EntityResponseType> {
    const copy = this.convertDateFromClient(companyParameter);
    return this.http
      .put<ICompanyParameter>(this.resourceUrl, copy, {observe: 'response'})
      .pipe(map((res: EntityResponseType) => this.convertDateFromServer(res)));
  }

  find(id: number): Observable<EntityResponseType> {
    return this.http
      .get<ICompanyParameter>(`${this.resourceUrl}/${id}`, {observe: 'response'})
      .pipe(map((res: EntityResponseType) => this.convertDateFromServer(res)));
  }

  query(req?: any): Observable<EntityArrayResponseType> {
    const options = createRequestOption(req);
    return this.http
      .get<ICompanyParameter[]>(this.resourceUrl, {params: options, observe: 'response'})
      .pipe(map((res: EntityArrayResponseType) => this.convertDateArrayFromServer(res)));
  }

  findByCompany(company: ICompany): Observable<EntityResponseType> {
    const options = createRequestOption({
      companyId: company.id
    });
    return this.http
      .get<ICompanyParameter>(`${this.resourceUrl}/by-company`, {params: options, observe: 'response'})
      .pipe(map((res: EntityResponseType) => this.convertDateFromServer(res)));
  }

  delete(id: number): Observable<HttpResponse<{}>> {
    return this.http.delete(`${this.resourceUrl}/${id}`, {observe: 'response'});
  }

  getAllActiveByCurrentUser(pagination?: any): Observable<EntityArrayResponseType> {
    const options = createRequestOption(pagination);
    return this.http
      .get<ICompanyParameter[]>(`${this.resourceUrl}/all-active-by-current-user`, {
        params: options,
        observe: 'response'
      })
      .pipe(map((res: EntityArrayResponseType) => this.convertDateArrayFromServer(res)));
  }

  getTimeToLogoutForInactivity(): Observable<number> {
    return this.http
      .get<number>(`${this.resourceUrl}/time-to-logout-for-inactivity`, {observe: 'response'})
      .pipe(map(res => res.body));
  }

  protected convertDateFromClient(companyParameter: ICompanyParameter): ICompanyParameter {
    const copy: ICompanyParameter = Object.assign({}, companyParameter, {
      createdDate:
        companyParameter.createdDate && companyParameter.createdDate.isValid() ? companyParameter.createdDate.toJSON() : undefined,
      lastModifiedDate:
        companyParameter.lastModifiedDate && companyParameter.lastModifiedDate.isValid()
          ? companyParameter.lastModifiedDate.toJSON()
          : undefined
    });
    return copy;
  }

  protected convertDateFromServer(res: EntityResponseType): EntityResponseType {
    if (res.body) {
      res.body.createdDate = res.body.createdDate ? moment(res.body.createdDate) : undefined;
      res.body.lastModifiedDate = res.body.lastModifiedDate ? moment(res.body.lastModifiedDate) : undefined;
    }
    return res;
  }

  protected convertDateArrayFromServer(res: EntityArrayResponseType): EntityArrayResponseType {
    if (res.body) {
      res.body.forEach((companyParameter: ICompanyParameter) => {
        companyParameter.createdDate = companyParameter.createdDate ? moment(companyParameter.createdDate) : undefined;
        companyParameter.lastModifiedDate = companyParameter.lastModifiedDate ? moment(companyParameter.lastModifiedDate) : undefined;
      });
    }
    return res;
  }
}
