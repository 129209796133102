import {Routes} from '@angular/router';

export const productRoutes: Routes = [
  {
    path: 'pro/brands',
    loadChildren: () => import('./brand/brand.module').then(m => m.AppBrandModule),
    data: {
      pageTitle: 'brand.home.title'
    }
  },
  {
    path: 'pro/categories',
    loadChildren: () => import('./category/category.module').then(m => m.AppCategoryModule),
    data: {
      pageTitle: 'category.home.title'
    }
  },
  {
    path: 'pro/prices-lists',
    loadChildren: () => import('./price-list/price-list.module').then(m => m.AppPriceListModule),
    data: {
      pageTitle: 'priceList.home.title'
    }
  },
  {
    path: 'pro/products',
    loadChildren: () => import('./product/product.module').then(m => m.AppProductModule),
    data: {
      pageTitle: 'product.home.title'
    }
  },
  {
    path: 'pro/tags',
    loadChildren: () => import('./tag/tag.module').then(m => m.AppTagModule),
    data: {
      pageTitle: 'tag.home.title'
    }
  }
];
