import {Injectable} from '@angular/core';
import {Page} from 'app/shared/layout/window/page.model';
import {Store} from 'app/core/store/store';
import {Observable} from 'rxjs';

@Injectable({providedIn: 'root'})
export class PageService extends Store<Page | null> {
  constructor() {
    super(null);
  }

  setCurrentPage(page: Page): void {
    this.setState(page);
  }

  clear(): void {
    this.setState(null);
  }

  get(): Observable<Page | null> {
    return this.state$;
  }
}
