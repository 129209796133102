<div (click)="toggleSidebarMobile()" class="mobile-navbar-background"></div>

<aside class="menu-sidebar d-lg-block">
  <div class="menu-sidebar-items">
    <nav class="navbar-sidebar" [ngSwitch]="isAuthenticated()">
      <ul class="navbar-list ml-auto">

        <ng-container *ngIf="!backoffice">
          <!-- Framework -->
          <li *ngIf="isSystemAdmin()" class="pointer" routerLinkActive="active">

            <a title="{{ 'sidebar.framework.main' | translate }}" (click)="toggle('framework')">
              <fa-icon [fixedWidth]="true" icon="tools"></fa-icon>
              <span jhiTranslate="sidebar.framework.main"></span>
              <fa-icon [fixedWidth]="true"
                       [icon]="menuCollapse.framework ? 'chevron-down' : 'chevron-up'"></fa-icon>
            </a>

            <ul [ngbCollapse]="menuCollapse.framework" class="navbar-sub-list">

              <!-- Administração -->
              <li class="sidebar-section">
                <span jhiTranslate="sidebar.framework.admin.main"></span>
              </li>

              <li routerLinkActive="active" *ngIf="showFeatureItem('USR_USER_VIEW')">
                <a (click)="toggleSidebarMobile()" routerLink="usr/users"
                   title="{{ 'sidebar.framework.admin.user' | translate }}">
                  <fa-icon [fixedWidth]="true" icon="users"></fa-icon>
                  <span jhiTranslate="sidebar.framework.admin.user"></span>
                </a>
              </li>

              <li routerLinkActive="active" *ngIf="showFeatureItem('SEC_ROLE_VIEW')">
                <a (click)="toggleSidebarMobile()" routerLink="sec/roles"
                   title="{{ 'sidebar.framework.admin.role' | translate }}">
                  <fa-icon [fixedWidth]="true" icon="shield-alt"></fa-icon>
                  <span jhiTranslate="sidebar.framework.admin.roles"></span>
                </a>
              </li>

              <!-- Empresa -->
              <li class="sidebar-section">
                <span jhiTranslate="sidebar.framework.company.main"></span>
              </li>

              <li routerLinkActive="active" *ngIf="showFeatureItem('COM_COMPANY_VIEW')">
                <a (click)="toggleSidebarMobile()" routerLink="com/companies"
                   title="{{ 'sidebar.framework.company.company' | translate }}">
                  <fa-icon [fixedWidth]="true" icon="building"></fa-icon>
                  <span jhiTranslate="sidebar.framework.company.company"></span>
                </a>
              </li>

              <li routerLinkActive="active" *ngIf="showFeatureItem('COM_COMPANY_PARAMETER_VIEW')">
                <a
                  (click)="toggleSidebarMobile()"
                  routerLink="com/companies-parameters"
                  title="{{ 'sidebar.framework.company.parameter' | translate }}"
                >
                  <fa-icon [fixedWidth]="true" icon="cogs"></fa-icon>
                  <span jhiTranslate="sidebar.framework.company.parameter"></span>
                </a>
              </li>

              <!-- Autenticação de terceiros -->
              <li class="sidebar-section">
                <span jhiTranslate="sidebar.framework.thirdPartyAuthentication.main"></span>
              </li>

              <li routerLinkActive="active" *ngIf="showFeatureItem('TPA_CONFIGURATION_VIEW')">
                <a (click)="toggleSidebarMobile()" routerLink="tpa/configurations"
                   title="{{ 'sidebar.framework.thirdPartyAuthentication.configurations' | translate }}">
                  <fa-icon [fixedWidth]="true" icon="cog"></fa-icon>
                  <span jhiTranslate="sidebar.framework.thirdPartyAuthentication.configurations"></span>
                </a>
              </li>

              <!-- Entidades de terceiros -->
              <li class="sidebar-section">
                <span jhiTranslate="sidebar.framework.thirdPartyEntity.main"></span>
              </li>

              <li routerLinkActive="active" *ngIf="showFeatureItem('TPE_CONFIGURATION_VIEW')">
                <a (click)="toggleSidebarMobile()" routerLink="tpe/configurations"
                   title="{{ 'sidebar.framework.thirdPartyEntity.configurations' | translate }}">
                  <fa-icon [fixedWidth]="true" icon="cog"></fa-icon>
                  <span jhiTranslate="sidebar.framework.thirdPartyEntity.configurations"></span>
                </a>
              </li>

              <!-- Sessões -->
              <li class="sidebar-section">
                <span jhiTranslate="sidebar.framework.session.main"></span>
              </li>

              <li routerLinkActive="active" *ngIf="showFeatureItem('SSS_BOOKMARK_VIEW')">
                <a (click)="toggleSidebarMobile()" routerLink="sss/bookmarks"
                   title="{{ 'sidebar.framework.session.bookmark' | translate }}">
                  <fa-icon [fixedWidth]="true" icon="bookmark"></fa-icon>
                  <span jhiTranslate="sidebar.framework.session.bookmark"></span>
                </a>
              </li>

              <li routerLinkActive="active" *ngIf="showFeatureItem('SSS_TRACKING_VIEW')">
                <a (click)="toggleSidebarMobile()" routerLink="sss/trackings"
                   title="{{ 'sidebar.framework.session.tracking' | translate }}">
                  <fa-icon [fixedWidth]="true" icon="shoe-prints"></fa-icon>
                  <span jhiTranslate="sidebar.framework.session.tracking"></span>
                </a>
              </li>

              <!-- Sistema -->

              <li class="sidebar-section">
                <span jhiTranslate="sidebar.framework.system.main"></span>
              </li>

              <li routerLinkActive="active">
                <a (click)="toggleSidebarMobile()" routerLink="adm/units"
                   title="{{ 'sidebar.framework.admin.units' | translate }}">
                  <fa-icon [fixedWidth]="true" icon="weight"></fa-icon>
                  <span jhiTranslate="sidebar.framework.admin.units"></span>
                </a>
              </li>

              <li routerLinkActive="active">
                <a (click)="toggleSidebarMobile()" routerLink="loc/countries"
                   title="{{ 'sidebar.framework.admin.country' | translate }}">
                  <fa-icon [fixedWidth]="true" icon="flag"></fa-icon>
                  <span jhiTranslate="sidebar.framework.admin.country"></span>
                </a>
              </li>

              <li routerLinkActive="active">
                <a (click)="toggleSidebarMobile()" routerLink="loc/states"
                   title="{{ 'sidebar.framework.admin.state' | translate }}">
                  <fa-icon [fixedWidth]="true" icon="flag-checkered"></fa-icon>
                  <span jhiTranslate="sidebar.framework.admin.state"></span>
                </a>
              </li>

              <li routerLinkActive="active">
                <a (click)="toggleSidebarMobile()" routerLink="loc/cities"
                   title="{{ 'sidebar.framework.admin.city' | translate }}">
                  <fa-icon [fixedWidth]="true" icon="map-signs"></fa-icon>
                  <span jhiTranslate="sidebar.framework.admin.city"></span>
                </a>
              </li>

              <li routerLinkActive="active">
                <a (click)="toggleSidebarMobile()" routerLink="loc/locations"
                   title="{{ 'sidebar.framework.admin.location' | translate }}">
                  <fa-icon [fixedWidth]="true" icon="map-marker-alt"></fa-icon>
                  <span jhiTranslate="sidebar.framework.admin.location"></span>
                </a>
              </li>

              <li routerLinkActive="active">
                <a (click)="toggleSidebarMobile()" routerLink="adm/options"
                   title="{{ 'sidebar.framework.admin.option' | translate }}">
                  <fa-icon [fixedWidth]="true" icon="list-ul"></fa-icon>
                  <span jhiTranslate="sidebar.framework.admin.option"></span>
                </a>
              </li>

              <li routerLinkActive="active">
                <a (click)="toggleSidebarMobile()" routerLink="mod/modules"
                   title="{{ 'sidebar.framework.admin.modules' | translate }}">
                  <fa-icon [fixedWidth]="true" icon="cubes"></fa-icon>
                  <span jhiTranslate="sidebar.framework.admin.modules"></span>
                </a>
              </li>

              <li routerLinkActive="active">
                <a (click)="toggleSidebarMobile()" routerLink="mod/features"
                   title="{{ 'sidebar.framework.admin.features' | translate }}">
                  <fa-icon [fixedWidth]="true" icon="cog"></fa-icon>
                  <span jhiTranslate="sidebar.framework.admin.features"></span>
                </a>
              </li>

              <li routerLinkActive="active">
                <a
                  (click)="toggleSidebarMobile()"
                  routerLink="mod/helps-categories"
                  title="{{ 'sidebar.framework.admin.helpsCategories' | translate }}"
                >
                  <fa-icon [fixedWidth]="true" icon="question-circle"></fa-icon>
                  <span jhiTranslate="sidebar.framework.admin.helpsCategories"></span>
                </a>
              </li>

              <li routerLinkActive="active">
                <a (click)="toggleSidebarMobile()" routerLink="mod/helps"
                   title="{{ 'sidebar.framework.admin.helps' | translate }}">
                  <fa-icon [fixedWidth]="true" icon="question"></fa-icon>
                  <span jhiTranslate="sidebar.framework.admin.helps"></span>
                </a>
              </li>

              <li routerLinkActive="active">
                <a (click)="toggleSidebarMobile()" routerLink="adm/user-manual/view"
                   title="{{ 'sidebar.framework.admin.userManual' | translate }}">
                  <fa-icon [fixedWidth]="true" icon="book-reader"></fa-icon>
                  <span jhiTranslate="sidebar.framework.admin.userManual"></span>
                </a>
              </li>

              <li routerLinkActive="active">
                <a (click)="toggleSidebarMobile()" routerLink="adm/plans"
                   title="{{ 'sidebar.framework.admin.plans' | translate }}">
                  <fa-icon [fixedWidth]="true" icon="layer-group"></fa-icon>
                  <span jhiTranslate="sidebar.framework.admin.plans"></span>
                </a>
              </li>

              <li routerLinkActive="active">
                <a (click)="toggleSidebarMobile()" routerLink="com/cnpjs"
                   title="{{ 'sidebar.framework.company.cnpj' | translate }}">
                  <fa-icon [fixedWidth]="true" icon="print-search"></fa-icon>
                  <span jhiTranslate="sidebar.framework.company.cnpj"></span>
                </a>
              </li>

              <li routerLinkActive="active">
                <a (click)="toggleSidebarMobile()" routerLink="adm/holidays"
                   title="{{ 'sidebar.framework.admin.holiday' | translate }}">
                  <fa-icon [fixedWidth]="true" icon="tree-christmas"></fa-icon>
                  <span jhiTranslate="sidebar.framework.admin.holiday"></span>
                </a>
              </li>

              <li routerLinkActive="active">
                <a (click)="toggleSidebarMobile()" routerLink="adm/parameters"
                   title="{{ 'sidebar.framework.admin.parameters' | translate }}">
                  <fa-icon [fixedWidth]="true" icon="sliders-h-square"></fa-icon>
                  <span jhiTranslate="sidebar.framework.admin.parameters"></span>
                </a>
              </li>

              <li routerLinkActive="active">
                <a (click)="toggleSidebarMobile()" routerLink="adm/files"
                   title="{{ 'sidebar.framework.admin.file' | translate }}">
                  <fa-icon [fixedWidth]="true" icon="file-alt"></fa-icon>
                  <span jhiTranslate="sidebar.framework.admin.file"></span>
                </a>
              </li>

              <li routerLinkActive="active">
                <a (click)="toggleSidebarMobile()" routerLink="adm/sequences"
                   title="{{ 'sidebar.framework.admin.sequences' | translate }}">
                  <fa-icon [fixedWidth]="true" icon="sort-numeric-down"></fa-icon>
                  <span jhiTranslate="sidebar.framework.admin.sequences"></span>
                </a>
              </li>

              <li routerLinkActive="active">
                <a (click)="toggleSidebarMobile()" routerLink="adm/changes-logs"
                   title="{{ 'sidebar.framework.admin.changeLog' | translate }}">
                  <fa-icon [fixedWidth]="true" icon="history"></fa-icon>
                  <span jhiTranslate="sidebar.framework.admin.changeLog"></span>
                </a>
              </li>

              <li routerLinkActive="active">
                <a (click)="toggleSidebarMobile()" routerLink="adm/generals-alerts"
                   title="{{ 'sidebar.general.alert' | translate }}">
                  <fa-icon [fixedWidth]="true" icon="comment-alt-exclamation"></fa-icon>
                  <span jhiTranslate="sidebar.framework.system.generalAlert"></span>
                </a>
              </li>

              <li routerLinkActive="active">
                <a (click)="toggleSidebarMobile()" routerLink="eml/addresses"
                   title="{{ 'sidebar.framework.email.emailAddress' | translate }}">
                  <fa-icon [fixedWidth]="true" icon="at"></fa-icon>
                  <span jhiTranslate="sidebar.framework.email.emailAddress"></span>
                </a>
              </li>

              <li routerLinkActive="active">
                <a (click)="toggleSidebarMobile()" routerLink="eml/notifications"
                   title="{{ 'sidebar.framework.email.emailNotification' | translate }}">
                  <fa-icon [fixedWidth]="true" icon="mail-bulk"></fa-icon>
                  <span jhiTranslate="sidebar.framework.email.emailNotification"></span>
                </a>
              </li>

              <li routerLinkActive="active">
                <a (click)="toggleSidebarMobile()" routerLink="eml/templates"
                   title="{{ 'sidebar.framework.email.emailTemplate' | translate }}">
                  <fa-icon [fixedWidth]="true" icon="envelope-square"></fa-icon>
                  <span jhiTranslate="sidebar.framework.email.emailTemplate"></span>
                </a>
              </li>

              <li routerLinkActive="active">
                <a (click)="toggleSidebarMobile()" routerLink="ntf/events"
                   title="{{ 'sidebar.framework.system.notificationEvent' | translate }}">
                  <fa-icon [fixedWidth]="true" icon="rss"></fa-icon>
                  <span jhiTranslate="sidebar.framework.system.notificationEvent"></span>
                </a>
              </li>

              <li routerLinkActive="active">
                <a (click)="toggleSidebarMobile()" routerLink="sss/devices"
                   title="{{ 'sidebar.framework.system.device' | translate }}">
                  <fa-icon [fixedWidth]="true" icon="mobile-alt"></fa-icon>
                  <span jhiTranslate="sidebar.framework.system.device"></span>
                </a>
              </li>

              <li routerLinkActive="active">
                <a (click)="toggleSidebarMobile()" routerLink="system/metrics"
                   title="{{ 'sidebar.framework.system.metrics' | translate }}">
                  <fa-icon [fixedWidth]="true" icon="tachometer-alt"></fa-icon>
                  <span jhiTranslate="sidebar.framework.system.metrics"></span>
                </a>
              </li>

              <li routerLinkActive="active">
                <a (click)="toggleSidebarMobile()" routerLink="system/health"
                   title="{{ 'sidebar.framework.system.health' | translate }}">
                  <fa-icon [fixedWidth]="true" icon="heartbeat"></fa-icon>
                  <span jhiTranslate="sidebar.framework.system.health"></span>
                </a>
              </li>

              <li routerLinkActive="active">
                <a (click)="toggleSidebarMobile()" routerLink="system/configuration"
                   title="{{ 'sidebar.framework.system.configuration' | translate }}">
                  <fa-icon [fixedWidth]="true" icon="sliders-h"></fa-icon>
                  <span jhiTranslate="sidebar.framework.system.configuration"></span>
                </a>
              </li>

              <li routerLinkActive="active">
                <a (click)="toggleSidebarMobile()" routerLink="system/logs"
                   title="{{ 'sidebar.framework.system.logs' | translate }}">
                  <fa-icon [fixedWidth]="true" icon="tasks"></fa-icon>
                  <span jhiTranslate="sidebar.framework.system.logs"></span>
                </a>
              </li>

              <li routerLinkActive="active" *ngIf="swaggerEnabled">
                <a (click)="toggleSidebarMobile()" routerLink="system/docs"
                   title="{{ 'sidebar.framework.system.apidocs' | translate }}">
                  <fa-icon [fixedWidth]="true" icon="code"></fa-icon>
                  <span jhiTranslate="sidebar.framework.system.apidocs"></span>
                </a>
              </li>

            </ul>


          </li>

          <!-- Minhas atividades -->
          <li class="pointer" routerLinkActive="active">

            <a (click)="toggle('third')" routerLink="my-activities"
               title="{{ 'sidebar.myActivities.main' | translate }}">
              <fa-icon [fixedWidth]="true" icon="th-large"></fa-icon>
              <span jhiTranslate="sidebar.myActivities.main"></span>
              <fa-icon [fixedWidth]="true"></fa-icon>
            </a>

          </li>

          <!-- Gestão de vendas -->
          <li class="pointer" routerLinkActive="active">

            <a (click)="toggle('salesManagement')"
               title="{{ 'sidebar.salesManagement.main' | translate }}">
              <fa-icon [fixedWidth]="true" icon="comments-dollar"></fa-icon>
              <span jhiTranslate="sidebar.salesManagement.main"></span>
              <fa-icon [fixedWidth]="true"
                       [icon]="menuCollapse.salesManagement ? 'chevron-down' : 'chevron-up'"></fa-icon>
            </a>

            <ul [ngbCollapse]="menuCollapse.salesManagement" class="navbar-sub-list">

              <li routerLinkActive="active">
                <a (click)="toggleSidebarMobile()" routerLink="nothing"
                   title="{{ 'sidebar.salesManagement.dashboard' | translate }}">
                  <fa-icon [fixedWidth]="true" icon="chart-pie"></fa-icon>
                  <span jhiTranslate="sidebar.salesManagement.dashboard"></span>
                </a>
              </li>

              <li routerLinkActive="active">
                <a (click)="toggleSidebarMobile()" routerLink="erp/customers"
                   title="{{ 'sidebar.salesManagement.clients' | translate }}">
                  <fa-icon [fixedWidth]="true" icon="users"></fa-icon>
                  <span jhiTranslate="sidebar.salesManagement.clients"></span>
                </a>
              </li>

              <li routerLinkActive="active">
                <a (click)="toggleSidebarMobile()" routerLink="erp/products"
                   title="{{ 'sidebar.salesManagement.products' | translate }}">
                  <fa-icon [fixedWidth]="true" icon="box"></fa-icon>
                  <span jhiTranslate="sidebar.salesManagement.products"></span>
                </a>
              </li>

              <li routerLinkActive="active">
                <a (click)="toggleSidebarMobile()" routerLink="erp/services"
                   title="{{ 'sidebar.salesManagement.services' | translate }}">
                  <fa-icon [fixedWidth]="true" icon="hammer"></fa-icon>
                  <span jhiTranslate="sidebar.salesManagement.services"></span>
                </a>
              </li>

              <li routerLinkActive="active">
                <a (click)="toggleSidebarMobile()" routerLink="erp/sales-orders"
                   title="{{ 'sidebar.salesManagement.salesOrders' | translate }}">
                  <fa-icon [fixedWidth]="true" icon="file-signature"></fa-icon>
                  <span jhiTranslate="sidebar.salesManagement.salesOrders"></span>
                </a>
              </li>

              <li routerLinkActive="active">
                <a (click)="toggleSidebarMobile()" routerLink="nothing"
                   title="{{ 'sidebar.salesManagement.billing' | translate }}">
                  <fa-icon [fixedWidth]="true" icon="file-invoice-dollar"></fa-icon>
                  <span jhiTranslate="sidebar.salesManagement.billing"></span>
                </a>
              </li>

              <li routerLinkActive="active">
                <a (click)="toggleSidebarMobile()" routerLink="nothing"
                   title="{{ 'sidebar.salesManagement.goodsDispatch' | translate }}">
                  <fa-icon [fixedWidth]="true" icon="dolly"></fa-icon>
                  <span jhiTranslate="sidebar.salesManagement.goodsDispatch"></span>
                </a>
              </li>

              <li routerLinkActive="active">
                <a (click)="toggleSidebarMobile()" routerLink="nothing"
                   title="{{ 'sidebar.salesManagement.salesMachine' | translate }}">
                  <fa-icon [fixedWidth]="true" icon="funnel-dollar"></fa-icon>
                  <span jhiTranslate="sidebar.salesManagement.salesMachine"></span>
                </a>
              </li>

              <li routerLinkActive="active">
                <a (click)="toggleSidebarMobile()" routerLink="nothing"
                   title="{{ 'sidebar.salesManagement.salesReports' | translate }}">
                  <fa-icon [fixedWidth]="true" icon="file-pdf"></fa-icon>
                  <span jhiTranslate="sidebar.salesManagement.salesReports"></span>
                </a>
              </li>

              <!-- Configurações de vendas -->
              <li class="sidebar-section">
                <span jhiTranslate="sidebar.salesManagement.salesSettings.main"></span>
              </li>

              <li routerLinkActive="active">
                <a (click)="toggleSidebarMobile()" routerLink="erp/products-groups"
                   title="{{ 'sidebar.salesManagement.salesSettings.group' | translate }}">
                  <fa-icon [fixedWidth]="true" icon="layer-group"></fa-icon>
                  <span jhiTranslate="sidebar.salesManagement.salesSettings.group"></span>
                </a>
              </li>

              <li routerLinkActive="active">
                <a (click)="toggleSidebarMobile()" routerLink="erp/products-subgroups"
                   title="{{ 'sidebar.salesManagement.salesSettings.subGroup' | translate }}">
                  <fa-icon [fixedWidth]="true" icon="object-ungroup"></fa-icon>
                  <span jhiTranslate="sidebar.salesManagement.salesSettings.subGroup"></span>
                </a>
              </li>

              <li routerLinkActive="active">
                <a (click)="toggleSidebarMobile()" routerLink="erp/brands"
                   title="{{ 'sidebar.salesManagement.salesSettings.brand' | translate }}">
                  <fa-icon [fixedWidth]="true" icon="copyright"></fa-icon>
                  <span jhiTranslate="sidebar.salesManagement.salesSettings.brand"></span>
                </a>
              </li>

              <li routerLinkActive="active">
                <a (click)="toggleSidebarMobile()" routerLink="erp/units-of-measures"
                   title="{{ 'sidebar.salesManagement.salesSettings.unitOfMeasure' | translate }}">
                  <fa-icon [fixedWidth]="true" icon="ruler-vertical"></fa-icon>
                  <span jhiTranslate="sidebar.salesManagement.salesSettings.unitOfMeasure"></span>
                </a>
              </li>

              <li routerLinkActive="active">
                <a (click)="toggleSidebarMobile()" routerLink="erp/sales-representatives"
                   title="{{ 'sidebar.salesManagement.salesSettings.salesRepresentative' | translate }}">
                  <fa-icon [fixedWidth]="true" icon="briefcase"></fa-icon>
                  <span jhiTranslate="sidebar.salesManagement.salesSettings.salesRepresentative"></span>
                </a>
              </li>

              <li routerLinkActive="active">
                <a (click)="toggleSidebarMobile()" routerLink="nothing"
                   title="{{ 'sidebar.salesManagement.salesSettings.pricing' | translate }}">
                  <fa-icon [fixedWidth]="true" icon="dollar-sign"></fa-icon>
                  <span jhiTranslate="sidebar.salesManagement.salesSettings.pricing"></span>
                </a>
              </li>

              <li routerLinkActive="active">
                <a (click)="toggleSidebarMobile()" routerLink="erp/payment-terms"
                   title="{{ 'sidebar.salesManagement.salesSettings.paymentTerm' | translate }}">
                  <fa-icon [fixedWidth]="true" icon="money-check"></fa-icon>
                  <span jhiTranslate="sidebar.salesManagement.salesSettings.paymentTerm"></span>
                </a>
              </li>

              <li routerLinkActive="active">
                <a (click)="toggleSidebarMobile()" routerLink="erp/payment-methods"
                   title="{{ 'sidebar.salesManagement.salesSettings.paymentMethod' | translate }}">
                  <fa-icon [fixedWidth]="true" icon="credit-card"></fa-icon>
                  <span jhiTranslate="sidebar.salesManagement.salesSettings.paymentMethod"></span>
                </a>
              </li>

            </ul>

          </li>

          <!-- Gestão de compras -->
          <li class="pointer" routerLinkActive="active">

            <a title="{{ 'sidebar.purchasingManagement.main' | translate }}"
               (click)="toggle('purchasingManagement')">
              <fa-icon [fixedWidth]="true" icon="shopping-basket"></fa-icon>
              <span jhiTranslate="sidebar.purchasingManagement.main"></span>
              <fa-icon [fixedWidth]="true"
                       [icon]="menuCollapse.purchasingManagement ? 'chevron-down' : 'chevron-up'"></fa-icon>
            </a>

            <ul [ngbCollapse]="menuCollapse.purchasingManagement" class="navbar-sub-list">

              <li routerLinkActive="active">
                <a (click)="toggleSidebarMobile()" routerLink="nothing"
                   title="{{ 'sidebar.purchasingManagement.dashboard' | translate }}">
                  <fa-icon [fixedWidth]="true" icon="chart-pie"></fa-icon>
                  <span jhiTranslate="sidebar.purchasingManagement.dashboard"></span>
                </a>
              </li>

              <li routerLinkActive="active">
                <a (click)="toggleSidebarMobile()" routerLink="erp/providers"
                   title="{{ 'sidebar.purchasingManagement.suppliers' | translate }}">
                  <fa-icon [fixedWidth]="true" icon="truck"></fa-icon>
                  <span jhiTranslate="sidebar.purchasingManagement.suppliers"></span>
                </a>
              </li>

              <li routerLinkActive="active">
                <a (click)="toggleSidebarMobile()" routerLink="erp/products"
                   title="{{ 'sidebar.purchasingManagement.products' | translate }}">
                  <fa-icon [fixedWidth]="true" icon="box"></fa-icon>
                  <span jhiTranslate="sidebar.purchasingManagement.products"></span>
                </a>
              </li>

              <li routerLinkActive="active">
                <a (click)="toggleSidebarMobile()" routerLink="erp/services"
                   title="{{ 'sidebar.purchasingManagement.services' | translate }}">
                  <fa-icon [fixedWidth]="true" icon="hammer"></fa-icon>
                  <span jhiTranslate="sidebar.purchasingManagement.services"></span>
                </a>
              </li>

              <li routerLinkActive="active">
                <a (click)="toggleSidebarMobile()" routerLink="erp/purchases-orders"
                   title="{{ 'sidebar.purchasingManagement.purchaseOrders' | translate }}">
                  <fa-icon [fixedWidth]="true" icon="file-signature"></fa-icon>
                  <span jhiTranslate="sidebar.purchasingManagement.purchaseOrders"></span>
                </a>
              </li>

              <li routerLinkActive="active">
                <a (click)="toggleSidebarMobile()" routerLink="nothing"
                   title="{{ 'sidebar.purchasingManagement.goodsReceipt' | translate }}">
                  <fa-icon [fixedWidth]="true" icon="box-open"></fa-icon>
                  <span jhiTranslate="sidebar.purchasingManagement.goodsReceipt"></span>
                </a>
              </li>

              <li routerLinkActive="active">
                <a (click)="toggleSidebarMobile()" routerLink="nothing"
                   title="{{ 'sidebar.purchasingManagement.purchaseReports' | translate }}">
                  <fa-icon [fixedWidth]="true" icon="file-pdf"></fa-icon>
                  <span jhiTranslate="sidebar.purchasingManagement.purchaseReports"></span>
                </a>
              </li>

              <li routerLinkActive="active">
                <a (click)="toggleSidebarMobile()" routerLink="nothing"
                   title="{{ 'sidebar.purchasingManagement.purchaseSettings' | translate }}">
                  <fa-icon [fixedWidth]="true" icon="cog"></fa-icon>
                  <span jhiTranslate="sidebar.purchasingManagement.purchaseSettings"></span>
                </a>
              </li>

            </ul>

          </li>

          <!-- Gestão de estoques & logística -->
          <li class="pointer" routerLinkActive="active">

            <a title="{{ 'sidebar.inventoryManagement.main' | translate }}"
               (click)="toggle('inventoryManagement')">
              <fa-icon [fixedWidth]="true" icon="warehouse"></fa-icon>
              <span jhiTranslate="sidebar.inventoryManagement.main"></span>
              <fa-icon [fixedWidth]="true"
                       [icon]="menuCollapse.inventoryManagement ? 'chevron-down' : 'chevron-up'"></fa-icon>
            </a>

            <ul [ngbCollapse]="menuCollapse.inventoryManagement" class="navbar-sub-list">

              <li routerLinkActive="active">
                <a (click)="toggleSidebarMobile()" routerLink="nothing"
                   title="{{ 'sidebar.inventoryManagement.dashboard' | translate }}">
                  <fa-icon [fixedWidth]="true" icon="chart-pie"></fa-icon>
                  <span jhiTranslate="sidebar.purchasingManagement.dashboard"></span>
                </a>
              </li>

              <li routerLinkActive="active">
                <a (click)="toggleSidebarMobile()" routerLink="erp/products"
                   title="{{ 'sidebar.inventoryManagement.products' | translate }}">
                  <fa-icon [fixedWidth]="true" icon="box"></fa-icon>
                  <span jhiTranslate="sidebar.inventoryManagement.products"></span>
                </a>
              </li>

              <li routerLinkActive="active">
                <a (click)="toggleSidebarMobile()" routerLink="erp/services"
                   title="{{ 'sidebar.inventoryManagement.services' | translate }}">
                  <fa-icon [fixedWidth]="true" icon="hammer"></fa-icon>
                  <span jhiTranslate="sidebar.inventoryManagement.services"></span>
                </a>
              </li>

              <li routerLinkActive="active">
                <a (click)="toggleSidebarMobile()" routerLink="erp/documents"
                   title="{{ 'sidebar.inventoryManagement.stockMovement' | translate }}">
                  <fa-icon [fixedWidth]="true" icon="exchange-alt"></fa-icon>
                  <span jhiTranslate="sidebar.inventoryManagement.stockMovement"></span>
                </a>
              </li>

              <li routerLinkActive="active">
                <a (click)="toggleSidebarMobile()" routerLink="nothing"
                   title="{{ 'sidebar.inventoryManagement.goodsDispatch' | translate }}">
                  <fa-icon [fixedWidth]="true" icon="dolly"></fa-icon>
                  <span jhiTranslate="sidebar.inventoryManagement.goodsDispatch"></span>
                </a>
              </li>

              <li routerLinkActive="active">
                <a (click)="toggleSidebarMobile()" routerLink="nothing"
                   title="{{ 'sidebar.inventoryManagement.goodsReceipt' | translate }}">
                  <fa-icon [fixedWidth]="true" icon="box-open"></fa-icon>
                  <span jhiTranslate="sidebar.inventoryManagement.goodsReceipt"></span>
                </a>
              </li>

              <li routerLinkActive="active">
                <a (click)="toggleSidebarMobile()" routerLink="nothing"
                   title="{{ 'sidebar.inventoryManagement.inventory' | translate }}">
                  <fa-icon [fixedWidth]="true" icon="boxes"></fa-icon>
                  <span jhiTranslate="sidebar.inventoryManagement.inventory"></span>
                </a>
              </li>

              <li routerLinkActive="active">
                <a (click)="toggleSidebarMobile()" routerLink="nothing"
                   title="{{ 'sidebar.inventoryManagement.stockReports' | translate }}">
                  <fa-icon [fixedWidth]="true" icon="file-pdf"></fa-icon>
                  <span jhiTranslate="sidebar.inventoryManagement.stockReports"></span>
                </a>
              </li>

              <!-- Configurações de estoques -->
              <li class="sidebar-section">
                <span jhiTranslate="sidebar.inventoryManagement.stockSettings.main"></span>
              </li>

              <li routerLinkActive="active">
                <a (click)="toggleSidebarMobile()" routerLink="nothing"
                   title="{{ 'sidebar.inventoryManagement.stockSettings.carriersAndDeliveryServices' | translate }}">
                  <fa-icon [fixedWidth]="true" icon="truck"></fa-icon>
                  <span jhiTranslate="sidebar.inventoryManagement.stockSettings.carriersAndDeliveryServices"></span>
                </a>
              </li>

            </ul>

          </li>

          <!-- Gestão financeira -->
          <li class="pointer" routerLinkActive="active">

            <a title="{{ 'sidebar.financialManagement.main' | translate }}"
               (click)="toggle('financialManagement')">
              <fa-icon [fixedWidth]="true" icon="hand-holding-usd"></fa-icon>
              <span jhiTranslate="sidebar.financialManagement.main"></span>
              <fa-icon [fixedWidth]="true"
                       [icon]="menuCollapse.financialManagement ? 'chevron-down' : 'chevron-up'"></fa-icon>
            </a>

            <ul [ngbCollapse]="menuCollapse.financialManagement" class="navbar-sub-list">

              <!-- Contas a pagar -->
              <li class="sidebar-section">
                <span jhiTranslate="sidebar.financialManagement.accountsPayable.main"></span>
              </li>

              <li routerLinkActive="active">
                <a (click)="toggleSidebarMobile()" routerLink="nothing"
                   title="{{ 'sidebar.financialManagement.accountsPayable.dashboard' | translate }}">
                  <fa-icon [fixedWidth]="true" icon="hand-holding-usd"></fa-icon>
                  <span jhiTranslate="sidebar.financialManagement.accountsPayable.dashboard"></span>
                </a>
              </li>

              <li routerLinkActive="active">
                <a (click)="toggleSidebarMobile()" routerLink="erp/providers"
                   title="{{ 'sidebar.financialManagement.accountsPayable.suppliers' | translate }}">
                  <fa-icon [fixedWidth]="true" icon="truck"></fa-icon>
                  <span jhiTranslate="sidebar.financialManagement.accountsPayable.suppliers"></span>
                </a>
              </li>

              <li routerLinkActive="active">
                <a (click)="toggleSidebarMobile()" routerLink="nothing"
                   title="{{ 'sidebar.financialManagement.accountsPayable.payableAccounts' | translate }}">
                  <fa-icon [fixedWidth]="true" icon="chart-pie"></fa-icon>
                  <span jhiTranslate="sidebar.financialManagement.accountsPayable.payableAccounts"></span>
                </a>
              </li>

              <li routerLinkActive="active">
                <a (click)="toggleSidebarMobile()" routerLink="nothing"
                   title="{{ 'sidebar.financialManagement.accountsPayable.payableReports' | translate }}">
                  <fa-icon [fixedWidth]="true" icon="file-pdf"></fa-icon>
                  <span jhiTranslate="sidebar.financialManagement.accountsPayable.payableReports"></span>
                </a>
              </li>

              <li routerLinkActive="active">
                <a (click)="toggleSidebarMobile()" routerLink="nothing"
                   title="{{ 'sidebar.financialManagement.accountsPayable.payableSettings' | translate }}">
                  <fa-icon [fixedWidth]="true" icon="cog"></fa-icon>
                  <span jhiTranslate="sidebar.financialManagement.accountsPayable.payableSettings"></span>
                </a>
              </li>

              <!-- Contas a Receber -->
              <li class="sidebar-section">
                <span jhiTranslate="sidebar.financialManagement.accountsReceivable.main"></span>
              </li>

              <li routerLinkActive="active">
                <a (click)="toggleSidebarMobile()" routerLink="nothing"
                   title="{{ 'sidebar.financialManagement.accountsReceivable.dashboard' | translate }}">
                  <fa-icon [fixedWidth]="true" icon="cash-register"></fa-icon>
                  <span jhiTranslate="sidebar.financialManagement.accountsReceivable.dashboard"></span>
                </a>
              </li>

              <li routerLinkActive="active">
                <a (click)="toggleSidebarMobile()" routerLink="erp/customers"
                   title="{{ 'sidebar.financialManagement.accountsReceivable.clients' | translate }}">
                  <fa-icon [fixedWidth]="true" icon="users"></fa-icon>
                  <span jhiTranslate="sidebar.financialManagement.accountsReceivable.clients"></span>
                </a>
              </li>

              <li routerLinkActive="active">
                <a (click)="toggleSidebarMobile()" routerLink="nothing"
                   title="{{ 'sidebar.financialManagement.accountsReceivable.receivableAccounts' | translate }}">
                  <fa-icon [fixedWidth]="true" icon="chart-pie"></fa-icon>
                  <span jhiTranslate="sidebar.financialManagement.accountsReceivable.receivableAccounts"></span>
                </a>
              </li>

              <li routerLinkActive="active">
                <a (click)="toggleSidebarMobile()" routerLink="nothing"
                   title="{{ 'sidebar.financialManagement.accountsReceivable.receivableReports' | translate }}">
                  <fa-icon [fixedWidth]="true" icon="file-pdf"></fa-icon>
                  <span jhiTranslate="sidebar.financialManagement.accountsReceivable.receivableReports"></span>
                </a>
              </li>

              <li routerLinkActive="active">
                <a (click)="toggleSidebarMobile()" routerLink="nothing"
                   title="{{ 'sidebar.financialManagement.accountsReceivable.receivableSettings' | translate }}">
                  <fa-icon [fixedWidth]="true" icon="cog"></fa-icon>
                  <span jhiTranslate="sidebar.financialManagement.accountsReceivable.receivableSettings"></span>
                </a>
              </li>

              <!-- Bancos -->
              <li class="sidebar-section">
                <span jhiTranslate="sidebar.financialManagement.banks.main"></span>
              </li>

              <li routerLinkActive="active">
                <a (click)="toggleSidebarMobile()" routerLink="nothing"
                   title="{{ 'sidebar.financialManagement.banks.dashboard' | translate }}">
                  <fa-icon [fixedWidth]="true" icon="chart-pie"></fa-icon>
                  <span jhiTranslate="sidebar.financialManagement.banks.dashboard"></span>
                </a>
              </li>

              <li routerLinkActive="active">
                <a (click)="toggleSidebarMobile()" routerLink="nothing"
                   title="{{ 'sidebar.financialManagement.banks.bankAccounts' | translate }}">
                  <fa-icon [fixedWidth]="true" icon="university"></fa-icon>
                  <span jhiTranslate="sidebar.financialManagement.banks.bankAccounts"></span>
                </a>
              </li>

              <li routerLinkActive="active">
                <a (click)="toggleSidebarMobile()" routerLink="nothing"
                   title="{{ 'sidebar.financialManagement.banks.bankReports' | translate }}">
                  <fa-icon [fixedWidth]="true" icon="file-pdf"></fa-icon>
                  <span jhiTranslate="sidebar.financialManagement.banks.bankReports"></span>
                </a>
              </li>

              <li routerLinkActive="active">
                <a (click)="toggleSidebarMobile()" routerLink="nothing"
                   title="{{ 'sidebar.financialManagement.banks.bankSettings' | translate }}">
                  <fa-icon [fixedWidth]="true" icon="cog"></fa-icon>
                  <span jhiTranslate="sidebar.financialManagement.banks.bankSettings"></span>
                </a>
              </li>

            </ul>

          </li>

          <!-- Gestão fiscal -->
          <li class="pointer" routerLinkActive="active">

            <a title="{{ 'sidebar.taxManagement.main' | translate }}"
               (click)="toggle('taxManagement')">
              <fa-icon [fixedWidth]="true" icon="file-invoice-dollar"></fa-icon>
              <span jhiTranslate="sidebar.taxManagement.main"></span>
              <fa-icon [fixedWidth]="true"
                       [icon]="menuCollapse.taxManagement ? 'chevron-down' : 'chevron-up'"></fa-icon>
            </a>

            <ul [ngbCollapse]="menuCollapse.taxManagement" class="navbar-sub-list">

              <li routerLinkActive="active">
                <a (click)="toggleSidebarMobile()" routerLink="nothing"
                   title="{{ 'sidebar.taxManagement.invoices' | translate }}">
                  <fa-icon [fixedWidth]="true" icon="receipt"></fa-icon>
                  <span jhiTranslate="sidebar.taxManagement.invoices"></span>
                </a>
              </li>

              <li routerLinkActive="active">
                <a (click)="toggleSidebarMobile()" routerLink="nothing"
                   title="{{ 'sidebar.taxManagement.billingMonitor' | translate }}">
                  <fa-icon [fixedWidth]="true" icon="search-dollar"></fa-icon>
                  <span jhiTranslate="sidebar.taxManagement.billingMonitor"></span>
                </a>
              </li>

              <!-- Configurações fiscais -->
              <li class="sidebar-section">
                <span jhiTranslate="sidebar.taxManagement.taxSettings.main"></span>
              </li>

              <li routerLinkActive="active">
                <a (click)="toggleSidebarMobile()" routerLink="nothing"
                   title="{{ 'sidebar.taxManagement.taxSettings.taxCategoryOfClients' | translate }}">
                  <fa-icon [fixedWidth]="true" icon="users"></fa-icon>
                  <span jhiTranslate="sidebar.taxManagement.taxSettings.taxCategoryOfClients"></span>
                </a>
              </li>

              <li routerLinkActive="active">
                <a (click)="toggleSidebarMobile()" routerLink="nothing"
                   title="{{ 'sidebar.taxManagement.taxSettings.taxCategoryOfProducts' | translate }}">
                  <fa-icon [fixedWidth]="true" icon="boxes"></fa-icon>
                  <span jhiTranslate="sidebar.taxManagement.taxSettings.taxCategoryOfProducts"></span>
                </a>
              </li>

              <li routerLinkActive="active">
                <a (click)="toggleSidebarMobile()" routerLink="nothing"
                   title="{{ 'sidebar.taxManagement.taxSettings.commercialization' | translate }}">
                  <fa-icon [fixedWidth]="true" icon="comments-dollar"></fa-icon>
                  <span jhiTranslate="sidebar.taxManagement.taxSettings.commercialization"></span>
                </a>
              </li>

              <li routerLinkActive="active">
                <a (click)="toggleSidebarMobile()" routerLink="nothing"
                   title="{{ 'sidebar.taxManagement.taxSettings.companyAndCertificate' | translate }}">
                  <fa-icon [fixedWidth]="true" icon="landmark"></fa-icon>
                  <span jhiTranslate="sidebar.taxManagement.taxSettings.companyAndCertificate"></span>
                </a>
              </li>

            </ul>
          </li>


          <!-- Configurações do sistema -->
          <li class="pointer" routerLinkActive="active">

            <a title="{{ 'sidebar.systemSettings.main' | translate }}"
               (click)="toggle('systemSettings')">
              <fa-icon [fixedWidth]="true" icon="cogs"></fa-icon>
              <span jhiTranslate="sidebar.systemSettings.main"></span>
              <fa-icon [fixedWidth]="true"
                       [icon]="menuCollapse.systemSettings ? 'chevron-down' : 'chevron-up'"></fa-icon>
            </a>

            <ul [ngbCollapse]="menuCollapse.systemSettings" class="navbar-sub-list">

              <li routerLinkActive="active">
                <a (click)="toggleSidebarMobile()" routerLink="nothing"
                   title="{{ 'sidebar.systemSettings.company' | translate }}">
                  <fa-icon [fixedWidth]="true" icon="building"></fa-icon>
                  <span jhiTranslate="sidebar.systemSettings.company"></span>
                </a>
              </li>

              <li routerLinkActive="active">
                <a (click)="toggleSidebarMobile()" routerLink="usr/users"
                   title="{{ 'sidebar.systemSettings.userPermissions' | translate }}">
                  <fa-icon [fixedWidth]="true" icon="user-plus"></fa-icon>
                  <span jhiTranslate="sidebar.systemSettings.userPermissions"></span>
                </a>
              </li>

              <li routerLinkActive="active">
                <a (click)="toggleSidebarMobile()" routerLink="nothing"
                   title="{{ 'sidebar.systemSettings.systemParameters' | translate }}">
                  <fa-icon [fixedWidth]="true" icon="cog"></fa-icon>
                  <span jhiTranslate="sidebar.systemSettings.systemParameters"></span>
                </a>
              </li>

              <li routerLinkActive="active">
                <a (click)="toggleSidebarMobile()" routerLink="nothing"
                   title="{{ 'sidebar.systemSettings.integrations' | translate }}">
                  <fa-icon [fixedWidth]="true" icon="folder-plus"></fa-icon>
                  <span jhiTranslate="sidebar.systemSettings.integrations"></span>
                </a>
              </li>

              <li routerLinkActive="active">
                <a (click)="toggleSidebarMobile()" routerLink="nothing"
                   title="{{ 'sidebar.systemSettings.backupRestore' | translate }}">
                  <fa-icon [fixedWidth]="true" icon="file-import"></fa-icon>
                  <span jhiTranslate="sidebar.systemSettings.backupRestore"></span>
                </a>
              </li>
            </ul>

          </li>

          <!-- Ajuda -->
          <li class="pointer" routerLinkActive="active">

            <a title="{{ 'sidebar.help.main' | translate }}"
               (click)="toggle('help')">
              <fa-icon [fixedWidth]="true" icon="question-circle"></fa-icon>
              <span jhiTranslate="sidebar.help.main"></span>
              <fa-icon [fixedWidth]="true"
                       [icon]="menuCollapse.help ? 'chevron-down' : 'chevron-up'"></fa-icon>
            </a>

            <ul [ngbCollapse]="menuCollapse.help" class="navbar-sub-list">

              <li routerLinkActive="active">
                <a (click)="toggleSidebarMobile()" routerLink="nothing"
                   title="{{ 'sidebar.help.documentationTutorials' | translate }}">
                  <fa-icon [fixedWidth]="true" icon="book"></fa-icon>
                  <span jhiTranslate="sidebar.help.documentationTutorials"></span>
                </a>
              </li>

              <li routerLinkActive="active">
                <a (click)="toggleSidebarMobile()" routerLink="nothing"
                   title="{{ 'sidebar.help.technicalSupport' | translate }}">
                  <fa-icon [fixedWidth]="true" icon="phone-alt"></fa-icon>
                  <span jhiTranslate="sidebar.help.technicalSupport"></span>
                </a>
              </li>

              <li routerLinkActive="active">
                <a (click)="toggleSidebarMobile()" routerLink="nothing"
                   title="{{ 'sidebar.help.academy' | translate }}">
                  <fa-icon [fixedWidth]="true" icon="graduation-cap"></fa-icon>
                  <span jhiTranslate="sidebar.help.academy"></span>
                </a>
              </li>

            </ul>

          </li>
        </ng-container>

        <ng-container *ngIf="backoffice">
          <!-- Minhas atividades -->
          <li class="pointer" routerLinkActive="active">

            <a (click)="toggle('third')" routerLink="my-activities"
               title="{{ 'sidebar.myActivities.main' | translate }}">
              <fa-icon [fixedWidth]="true" icon="th-large"></fa-icon>
              <span jhiTranslate="sidebar.myActivities.main"></span>
              <fa-icon [fixedWidth]="true"></fa-icon>
            </a>

          </li>

          <!-- Gestão de vendas -->
          <li class="pointer" routerLinkActive="active">

            <a (click)="toggle('salesManagement')"
               title="{{ 'sidebar.salesManagement.main' | translate }}">
              <fa-icon [fixedWidth]="true" icon="comments-dollar"></fa-icon>
              <span jhiTranslate="sidebar.salesManagement.main"></span>
              <fa-icon [fixedWidth]="true"
                       [icon]="menuCollapse.salesManagement ? 'chevron-down' : 'chevron-up'"></fa-icon>
            </a>

            <ul [ngbCollapse]="menuCollapse.salesManagement" class="navbar-sub-list">

              <li routerLinkActive="active">
                <a (click)="toggleSidebarMobile()" routerLink="nothing"
                   title="{{ 'sidebar.salesManagement.dashboard' | translate }}">
                  <fa-icon [fixedWidth]="true" icon="chart-pie"></fa-icon>
                  <span jhiTranslate="sidebar.salesManagement.dashboard"></span>
                </a>
              </li>

              <li routerLinkActive="active">
                <a (click)="toggleSidebarMobile()" routerLink="erp/customers"
                   title="{{ 'sidebar.salesManagement.clients' | translate }}">
                  <fa-icon [fixedWidth]="true" icon="users"></fa-icon>
                  <span jhiTranslate="sidebar.salesManagement.clients"></span>
                </a>
              </li>

              <li routerLinkActive="active">
                <a (click)="toggleSidebarMobile()" routerLink="erp/products"
                   title="{{ 'sidebar.salesManagement.products' | translate }}">
                  <fa-icon [fixedWidth]="true" icon="box"></fa-icon>
                  <span jhiTranslate="sidebar.salesManagement.products"></span>
                </a>
              </li>

              <li routerLinkActive="active">
                <a (click)="toggleSidebarMobile()" routerLink="erp/services"
                   title="{{ 'sidebar.salesManagement.services' | translate }}">
                  <fa-icon [fixedWidth]="true" icon="hammer"></fa-icon>
                  <span jhiTranslate="sidebar.salesManagement.services"></span>
                </a>
              </li>

              <li routerLinkActive="active">
                <a (click)="toggleSidebarMobile()" routerLink="nothing"
                   title="{{ 'sidebar.salesManagement.billing' | translate }}">
                  <fa-icon [fixedWidth]="true" icon="file-invoice-dollar"></fa-icon>
                  <span jhiTranslate="sidebar.salesManagement.billing"></span>
                </a>
              </li>

              <li routerLinkActive="active">
                <a (click)="toggleSidebarMobile()" routerLink="nothing"
                   title="{{ 'sidebar.salesManagement.salesReports' | translate }}">
                  <fa-icon [fixedWidth]="true" icon="file-pdf"></fa-icon>
                  <span jhiTranslate="sidebar.salesManagement.salesReports"></span>
                </a>
              </li>

              <!-- Configurações de vendas -->
              <li class="sidebar-section">
                <span jhiTranslate="sidebar.salesManagement.salesSettings.main"></span>
              </li>

              <li routerLinkActive="active">
                <a (click)="toggleSidebarMobile()" routerLink="erp/plans"
                   title="{{ 'sidebar.salesManagement.salesSettings.plans' | translate }}">
                  <fa-icon [fixedWidth]="true" icon="layer-group"></fa-icon>
                  <span jhiTranslate="sidebar.salesManagement.salesSettings.plans"></span>
                </a>
              </li>

            </ul>

          </li>

          <!-- Configurações do sistema -->
          <li class="pointer" routerLinkActive="active">

            <a title="{{ 'sidebar.systemSettings.main' | translate }}"
               (click)="toggle('systemSettings')">
              <fa-icon [fixedWidth]="true" icon="cogs"></fa-icon>
              <span jhiTranslate="sidebar.systemSettings.main"></span>
              <fa-icon [fixedWidth]="true"
                       [icon]="menuCollapse.systemSettings ? 'chevron-down' : 'chevron-up'"></fa-icon>
            </a>

            <ul [ngbCollapse]="menuCollapse.systemSettings" class="navbar-sub-list">

              <li routerLinkActive="active">
                <a (click)="toggleSidebarMobile()" routerLink="nothing"
                   title="{{ 'sidebar.systemSettings.userPermissions' | translate }}">
                  <fa-icon [fixedWidth]="true" icon="user-plus"></fa-icon>
                  <span jhiTranslate="sidebar.systemSettings.userPermissions"></span>
                </a>
              </li>

            </ul>

          </li>
        </ng-container>

        <!-- Usuário -->
        <li *ngIf="account$ | async as account" class="pointer" routerLinkActive="active">
          <a class="user-item" title="{{ account.login }}" (click)="toggle('profile')">
            <fa-icon class="no-avatar" *ngIf="!account.avatar" [fixedWidth]="true"
                     icon="user-circle"></fa-icon>

            <img *ngIf="account.avatar" [src]="account.avatar | resizeImage: 250" alt=""
                 class="img-thumbnail avatar"/>

            <div class="user-item-name">
              <span>{{ account.firstName }} {{ account.lastName }}</span>
              <small>{{ account.login }}</small>
            </div>

            <fa-icon [fixedWidth]="true"
                     [icon]="menuCollapse.profile ? 'chevron-down' : 'chevron-up'"></fa-icon>
          </a>
          <ul [ngbCollapse]="menuCollapse.profile" class="navbar-sub-list">
            <li routerLinkActive="active">
              <a (click)="toggleSidebarMobile()" routerLink="account/profile"
                 title="{{ 'sidebar.profile.profile' | translate }}">
                <fa-icon icon="user-edit" [fixedWidth]="true"></fa-icon>
                <span jhiTranslate="sidebar.profile.profile"></span>
              </a>
            </li>
            <li routerLinkActive="active">
              <a (click)="toggleSidebarMobile()" routerLink="account/password"
                 title="{{ 'sidebar.profile.password' | translate }}">
                <fa-icon icon="key" [fixedWidth]="true"></fa-icon>
                <span jhiTranslate="sidebar.profile.password"></span>
              </a>
            </li>
            <li routerLinkActive="active">
              <a (click)="logout()" id="logout" title="{{ 'sidebar.profile.logout' | translate }}">
                <fa-icon icon="sign-out-alt" [fixedWidth]="true"></fa-icon>
                <span jhiTranslate="sidebar.profile.logout"></span>
              </a>
            </li>
          </ul>
        </li>

      </ul>
    </nav>
  </div>
</aside>
