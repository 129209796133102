import {Component, Input} from '@angular/core';
import {IFile} from 'app/shared/model/admin/file.model';
import {FileViewModalService} from 'app/shared/file/file-view-modal.service';
import {IMAGE_EXTENSIONS} from 'app/shared/constants/system.constants';
import {DomSanitizer} from '@angular/platform-browser';

@Component({
  selector: 'app-file-view-modal',
  templateUrl: './file-view-modal.component.html',
  styleUrls: ['file-view-modal.scss']
})
export class FileViewModalComponent {
  @Input()
  file!: IFile;

  @Input()
  title: string | undefined = undefined;

  close?: Function;

  fileExtension: string;
  googleViewerUrl: string = 'https://docs.google.com/viewer?url=';

  imageExtensions = IMAGE_EXTENSIONS.split(',');
  extensionsSupportedByGoogleViewer = ['.doc', '.docx', '.gdoc', '.xls', '.xlsx', '.gsheet', '.ppt', '.pptx', '.gslides', '.pdf'];

  constructor(private service: FileViewModalService, private sanitized: DomSanitizer) {}

  ngOnInit(): void {
    this.fileExtension =
      '.' +
      this.file?.name
        ?.split('.')
        .pop()
        .toLowerCase();
    this.googleViewerUrl += this.file?.url + '&embedded=true';
  }

  isAImage(): boolean {
    return this.imageExtensions.includes(this.fileExtension);
  }

  isAGoogleViewerSupportedExtension(): boolean {
    return this.extensionsSupportedByGoogleViewer.includes(this.fileExtension);
  }

  cleanURL(url: string): any {
    return this.sanitized.bypassSecurityTrustResourceUrl(url);
  }

  onCloseClicked(): void {
    this.close && this.close();
    this.service.close();
  }
}
