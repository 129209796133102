import {Pipe, PipeTransform} from '@angular/core';
import {Card, ICard} from 'app/shared/model/payment/card.model';

@Pipe({
  name: 'maskCardNumber',
  pure: false
})
export class MaskedCardNumberPipe implements PipeTransform {
  constructor() {}

  transform(card: ICard): any {
    return card ? Card.getMaskedCardNumber(card) : '';
  }
}
