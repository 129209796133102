import {Component, Input} from '@angular/core';

@Component({
  selector: 'app-external-link',
  template: `
    <ng-container *ngIf="href && href.length">
      <a [href]="href" [target]="target">
        <span>
          {{ label || href }}
        </span>
        &nbsp;
        <fa-icon [fixedWidth]="true"icon="external-link-alt"></fa-icon>
      </a>
    </ng-container>
  `
})
export class ExternalLinkComponent {
  @Input()
  href: string | undefined = undefined;

  @Input()
  label: string | undefined = undefined;

  @Input()
  target: string = '_blank';
}
