import {BaseEntity, IBaseEntity} from 'app/shared/model/root/base-entity.model';
import {allEntityTypes, EntityType} from 'app/shared/model/enumerations/entity-type.enum';
import {IUser, User} from 'app/core/user/user.model';
import moment from 'moment';
import {Moment} from 'moment';
import {Criteria} from 'app/shared/filter/criteria.model';
import {FileType} from 'app/shared/model/enumerations/file-type.enum';

export interface IFile extends IBaseEntity {
  name?: string;
  sourceName?: string;
  contentType?: string;
  url?: string;
  linkedEntityType?: EntityType;
  linkedEntityUuid?: string;
  owner?: IUser;
  fileType?: FileType;
  _base64?: string;
}

export class File extends BaseEntity implements IFile {
  constructor(
    public id?: number,
    public uuid?: string,
    public createdBy?: string,
    public createdDate?: Moment,
    public lastModifiedBy?: string,
    public lastModifiedDate?: Moment,
    public active?: boolean,
    public name?: string,
    public sourceName?: string,
    public contentType?: string,
    public url?: string,
    public linkedEntityType?: EntityType,
    public linkedEntityUuid?: string,
    public owner?: IUser,
    public fileType?: FileType,
    public _base64?: string,
    public _label?: string,
    public _edited?: boolean
  ) {
    super(id, uuid, createdBy, createdDate, lastModifiedBy, lastModifiedDate, active, _label, _edited);
  }

  public static allowedEntityTypes(): EntityType[] {
    return allEntityTypes();
  }

  public static criterias(): Criteria<File>[] {
    return Criteria.buildFrom(this.example()).map(criteria =>
      criteria.property.name === 'linkedEntityType' ? criteria.asEnum(() => this.allowedEntityTypes(), 'entityType') : criteria
    );
  }

  public static example(): File {
    return new File(
      0,
      '',
      '',
      moment(new Date()),
      '',
      moment(new Date()),
      false,
      '',
      '',
      '',
      '',
      EntityType.USR_USER,
      '',
      new User(),
      FileType.FILE,
      '',
      '',
      false
    );
  }
}
