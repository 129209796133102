import {Injectable} from '@angular/core';
import {MaskApplierService} from 'ngx-mask';

export declare type MaskType =
  'cnpj'
  | 'cpf'
  | 'cpfOrCnpj'
  | 'cep'
  | 'phone'
  | 'currency'
  | 'date'
  | 'time'
  | 'uuid'
  | 'score'
  | 'stateRegistration';

/**
 * follow -> https://github.com/JsDaddy/ngx-mask
 */
@Injectable({providedIn: 'root'})
export class MaskService {
  constructor(private maskApplierService: MaskApplierService) {}

  cnpj(): string {
    return '00.000.000/0000-00';
  }

  cpf(): string {
    return '000.000.000-00';
  }

  cpfOrCnpj(): string {
    return 'CPF_CNPJ';
  }

  creditCard(): string {
    return '0000 0000 0000 0000';
  }

  creditCardMasked(): string {
    return '0000 SSSS SSSS 0000';
  }

  cvv(): string {
    return '0009';
  }

  phone(): string {
    return '(00) 0000-00009';
  }

  cep(): string {
    return '00000-000';
  }

  currency(): string {
    return '9,99';
  }

  ip(): string {
    return 'IP';
  }

  dateDDMMYYYY(): string {
    // é 9 ao invés de 0 pois deve ser usado com o 'rInputMask'
    return '99/99/9999';
  }

  timeHHMMSS(): string {
    return 'HH:mm:ss';
  }

  timeHHMM(): string {
    return 'HH:mm';
  }

  long(): string {
    return '0*';
  }

  datePlaceholder(): string {
    return 'dd/mm/yyyy';
  }

  dateFormat(): string {
    return 'dd/MM/yyyy';
  }

  uuid(): string {
    return 'AAAAAAAA-AAAA-AAAA-AAAA-AAAAAAAAAAAA';
  }

  score(): string {
    return '999.99';
  }

  stateRegistration(): string {
    return '000 000 000 000';
  }

  uuidPattern() {
    return {
      A: {
        pattern: new RegExp('[0-9a-f]')
      }
    };
  }

  apply(value: string, mask: MaskType): string {
    switch (mask) {
      case 'cnpj':
        return this.maskApplierService.applyMask(value, this.cnpj());
      case 'cpf':
        return this.maskApplierService.applyMask(value, this.cpf());
      case 'cpfOrCnpj':
        return this.maskApplierService.applyMask(value, this.cpfOrCnpj());
      case 'cep':
        return this.maskApplierService.applyMask(value, this.cep());
      case 'phone':
        return this.maskApplierService.applyMask(value, this.phone());
      case 'currency':
        return this.maskApplierService.applyMask(value, this.currency());
      case 'date':
        return this.maskApplierService.applyMask(value, this.dateDDMMYYYY());
      case 'time':
        return this.maskApplierService.applyMask(value, this.timeHHMMSS());
      case 'uuid':
        return this.maskApplierService.applyMask(value, this.uuid());
      case 'score':
        return this.maskApplierService.applyMask(value, this.score());
      case 'stateRegistration':
        return this.maskApplierService.applyMask(value, this.stateRegistration());
    }
  }
}
