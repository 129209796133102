import {Injectable} from '@angular/core';
import {Toast} from 'app/shared/toast/toast.model';
import {JhiEventManager, JhiEventWithContent} from 'ng-jhipster';
import {Subscription} from 'rxjs';

@Injectable({providedIn: 'root'})
export class ToastService {
  constructor(private eventManager: JhiEventManager) {}

  show(toast: Toast): void {
    this.eventManager.broadcast(new JhiEventWithContent('app.toast', toast));
  }

  addSubscribe(callback: (response: JhiEventWithContent<Toast>) => any): Subscription {
    return this.eventManager.subscribe('app.toast', (toast: JhiEventWithContent<Toast>) => callback(toast));
  }

  clearSubscribe(callback: () => any): Subscription {
    return this.eventManager.subscribe('app.toast.clearAll', () => callback());
  }

  actionPerformed(): void {
    this.show(Toast.primary('toaster.action.performed').withBodyIcon('check-circle'));
  }

  savedSuccessfully(): void {
    this.show(Toast.success('toaster.saved.success').withBodyIcon('check-circle'));
  }

  clear(): void {
    this.eventManager.broadcast(new JhiEventWithContent('app.toast.clearAll', undefined));
  }
}
