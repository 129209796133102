<nav class="navbar navbar-expand-md">
  <div class="logo">
    <div class="toggle-sidebar" *ngIf="isAuthenticated()">
      <a class="btn btn-sm btn-outline-light" href="javascript:void(0);" (click)="toggleSidebar()">
        <fa-icon [fixedWidth]="true" [icon]="isSidebarVisible() ? 'times' : 'bars'"></fa-icon>
      </a>
    </div>

    <a routerLink="/home">
      <img src="/content/images/brand/logo.png" alt="Logo ONCLICK"/>
    </a>
  </div>

  <div *ngIf="page" class="navbar-page">
    <div class="navbar-page-title" *ngIf="page.titleOnClick">
      <a (click)="page.titleOnClick()">
        <fa-icon *ngIf="page.icon && page.icon() as icon" [fixedWidth]="true" [icon]="icon"></fa-icon>
        <span *ngIf="page.title && page.title() as title">{{ title | translate | ellipsis: 80:true }}</span>
        <small
          *ngIf="page.subtitle && page.subtitle() as subtitle">{{ subtitle | translate | ellipsis: 60:true }}</small>
        <fa-icon
          *ngIf="page.isDirty && page.isDirty()"
          class="dirty-warning"
          [fixedWidth]="true"
          icon="exclamation-triangle"
          title="{{ 'global.messages.dirty.warning' | translate }}"
        ></fa-icon>
      </a>
    </div>

    <div class="navbar-page-title" *ngIf="!page.titleOnClick">
      <fa-icon *ngIf="page.icon && page.icon() as icon" [fixedWidth]="true" [icon]="icon"></fa-icon>
      <span *ngIf="page.title && page.title() as title">{{ title | translate }}</span>
      <small *ngIf="page.subtitle && page.subtitle() as subtitle">{{ subtitle | translate }}</small>
      <fa-icon
        *ngIf="page.isDirty && page.isDirty()"
        class="dirty-warning"
        [fixedWidth]="true"
        icon="exclamation-triangle"
        title="{{ 'global.messages.dirty.warning' | translate }}"
      ></fa-icon>
    </div>

    <div class="btn-group" *ngIf="page.buttons?.length || page.onFilterSubmit">
      <ng-container *ngFor="let btn of page.buttons">
        <button
          *ngIf="btn.visible()"
          type="button"
          [disabled]="!btn.enabled()"
          class="btn btn-sm btn-{{ btn.color() }} ml-1"
          (click)="btn.action()"
          title="{{ btn.tooltip() || '' | translate }}"
        >
          <fa-icon *ngIf="btn.icon() as icon" [fixedWidth]="true" [icon]="icon"></fa-icon>
          &nbsp;
          <span *ngIf="btn.label() as label" jhiTranslate="{{ label }}"></span>
        </button>
      </ng-container>

      <form
        class="filter-form"
        #filterForm="ngForm"
        name="searchForm"
        *ngIf="page.onFilterSubmit"
        role="form"
        novalidate
        (ngSubmit)="search()"
        [formGroup]="searchForm"
      >
        <div class="input-group">
          <input
            placeholder="{{ 'global.navbar.globalFilter' | translate }}"
            type="text"
            class="form-control mb-0"
            name="filter"
            maxlength="255"
            formControlName="filter"
          />
          <div class="input-group-append">
            <button class="btn btn-sm btn-outline-default">
              <fa-icon [fixedWidth]="true" icon="search"></fa-icon>
            </button>
          </div>
        </div>
      </form>
    </div>
  </div>
</nav>
