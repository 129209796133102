import {Routes} from '@angular/router';

export const erpRoutes: Routes = [
  {
    path: 'erp/customers',
    loadChildren: () => import('./customer/customer.module').then(m => m.AppCustomerModule),
    data: {
      pageTitle: 'partner.customer.home.title'
    }
  },
  {
    path: 'erp/providers',
    loadChildren: () => import('./provider/provider.module').then(m => m.AppProviderModule),
    data: {
      pageTitle: 'partner.provider.home.title'
    }
  },
  {
    path: 'erp/products',
    loadChildren: () => import('./product/product.module').then(m => m.AppProductModule),
    data: {
      pageTitle: 'product.product.home.title'
    }
  },
  {
    path: 'erp/services',
    loadChildren: () => import('./service/service.module').then(m => m.AppServiceModule),
    data: {
      pageTitle: 'product.service.home.title'
    }
  },
  {
    path: 'erp/services-codes',
    loadChildren: () => import('./service-code/service-code.module').then(m => m.AppServiceCodeModule),
    data: {
      pageTitle: 'product.service.home.title'
    }
  },
  {
    path: 'erp/products-groups',
    loadChildren: () => import('./product-group/product-group.module').then(m => m.AppProductGroupModule),
    data: {
      pageTitle: 'productGroup.home.title'
    }
  },
  {
    path: 'erp/products-subgroups',
    loadChildren: () => import('./product-subgroup/product-subgroup.module').then(m => m.AppProductSubgroupModule),
    data: {
      pageTitle: 'productSubgroup.home.title'
    }
  },
  {
    path: 'erp/units-of-measures',
    loadChildren: () => import('./unit-of-measure/unit-of-measure.module').then(m => m.AppUnitOfMeasureModule),
    data: {
      pageTitle: 'unitOfMeasure.home.title'
    }
  },
  {
    path: 'erp/brands',
    loadChildren: () => import('./brand/brand.module').then(m => m.AppBrandModule),
    data: {
      pageTitle: 'brand.home.title'
    }
  },
  {
    path: 'erp/purchases-orders',
    loadChildren: () => import('./purchase-order/purchase-order.module').then(m => m.AppPurchaseOrderModule),
    data: {
      pageTitle: 'purchaseOrder.home.title'
    }
  },
  {
    path: 'erp/sales-representatives',
    loadChildren: () => import('./sales-representative/sales-representative.module').then(m => m.AppSalesRepresentativeModule),
    data: {
      pageTitle: 'salesRepresentative.home.title'
    }
  },
  {
    path: 'erp/documents',
    loadChildren: () => import('./document/document.module').then(m => m.AppDocumentModule),
    data: {
      pageTitle: 'document.home.title'
    }
  },
  {
    path: 'erp/sales-orders',
    loadChildren: () => import('./sales-order/sales-order.module').then(m => m.AppSalesOrderModule),
    data: {
      pageTitle: 'salesOrder.home.title'
    }
  },
  {
    path: 'erp/plans',
    loadChildren: () => import('./plan/plan-module').then(m => m.AppPlanModule),
    data: {
      pageTitle: 'plan.home.title'
    }
  },
  {
    path: 'erp/payment-terms',
    loadChildren: () => import('./payment-term/payment-term.module').then(m => m.AppPaymentTermModule),
    data: {
      pageTitle: 'paymentTerm.home.title'
    }
  },
  {
    path: 'erp/payment-methods',
    loadChildren: () => import('./payment-method/payment-method.module').then(m => m.AppPaymentMethodModule),
    data: {
      pageTitle: 'paymentMethod.home.title'
    }
  }
];
