import {Resolve, Route} from '@angular/router';

import {LoginComponent} from './login.component';
import {PublicConfigurationService} from 'app/shared/services/login/public-configuration.service';
import {Observable, of} from 'rxjs';
import {HttpResponse} from '@angular/common/http';
import {flatMap} from 'rxjs/operators';
import {Injectable} from '@angular/core';

@Injectable({providedIn: 'root'})
export class ShowAccountResolve implements Resolve<boolean> {
  constructor(private publicConfigurationService: PublicConfigurationService) {
  }

  resolve(): Observable<boolean> | Observable<never> {
    return this.publicConfigurationService.showAccount().pipe(
      flatMap((res: HttpResponse<boolean>) => {
        return of(!!res.body);
      })
    );
  }
}

export const loginRoute: Route = {
  path: 'login',
  component: LoginComponent,
  resolve: {
    showAccount: ShowAccountResolve
  },
  data: {
    authorities: [],
    pageTitle: 'login.title'
  }
};
