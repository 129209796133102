import {GenericModalService} from 'app/shared/modal/generic-modal.service';
import {ButtonBuilder} from 'app/shared/layout/window/button-builder';
import {TranslateService} from '@ngx-translate/core';
import {Injectable, OnDestroy} from '@angular/core';
import {Subscription} from 'rxjs';
import {Router} from '@angular/router';
import {unsubscribe} from 'app/shared/util/react-util';
import {Authority} from 'app/shared/model/enumerations/authority.enum';
import {FeatureAuthority} from 'app/shared/model/enumerations/feature-authority.enum';
import {AccountService} from 'app/core/auth/account.service';
import {FormModelService} from 'app/shared/services/form/form-model.service';
import {FormModelQuestionDetailModalService} from 'app/entities/form/form-model/form-model-question-detail-modal.service';
import {IFormModel} from 'app/shared/model/form/form-model.model';
import {IFormModelQuestion} from 'app/shared/model/form/form-model-question.model';
import {FormModelQuestionOptionDetailModalService} from 'app/entities/form/form-model/form-model-question-option-detail-modal.service';
import {IFormModelQuestionOption} from 'app/shared/model/form/form-model-question-option.model';
import {IFormModelCategory} from 'app/shared/model/form/form-model-category.model';
import {FormModelCategoryDetailModalService} from 'app/entities/form/form-model/form-model-category-detail-modal.service';
import {FormModelChangeLogDetailModalService} from 'app/entities/form/form-model/form-model-change-log-detail-modal.service';
import {IChangeLog} from 'app/shared/model/admin/audit/change-log.model';

@Injectable({providedIn: 'root'})
export class FormModelActionsService implements OnDestroy {
  private subscriptions: Subscription[] = [];

  constructor(
    private formModelService: FormModelService,
    private router: Router,
    private genericModalService: GenericModalService,
    private translateService: TranslateService,
    private accountService: AccountService,
    private formModelCategoryDetailModalService: FormModelCategoryDetailModalService,
    private formModelQuestionDetailModalService: FormModelQuestionDetailModalService,
    private formModelQuestionOptionDetailModalService: FormModelQuestionOptionDetailModalService,
    private formModelChangeLogDetailModalService: FormModelChangeLogDetailModalService
  ) {
  }

  delete(formModel: IFormModel, successCallback?: Function): void {
    this.genericModalService.show(
      'entity.delete.title',
      this.translateService.instant('formModel.modals.delete.question', {id: formModel._label}),
      [
        ButtonBuilder.cancel(() => this.genericModalService.dismiss()),
        ButtonBuilder.delete(() => {
          this.subscriptions.push(
            this.formModelService.delete(formModel.id!).subscribe(result => successCallback && successCallback(result))
          );
          this.genericModalService.confirm();
        })
      ]
    );
  }

  goToList(): void {
    this.router.navigate(['/frm/forms-models']);
  }

  goToView(formModel: IFormModel): void {
    this.router.navigate(['/frm/forms-models', formModel.id!, 'view']);
  }

  goToViewOrList(formModel?: IFormModel): void {
    formModel && formModel.id ? this.goToView(formModel) : this.goToList();
  }

  goToNew(): void {
    this.router.navigate(['/frm/forms-models/new']);
  }

  goToEdit(formModel: IFormModel): void {
    this.router.navigate(['/frm/forms-models', formModel.id!, 'edit']);
  }

  showFormModelQuestionDetails(formModelQuestion: IFormModelQuestion): void {
    this.formModelQuestionDetailModalService.show(formModelQuestion);
  }

  totalActiveFormModelQuestions(formModel: IFormModel): number {
    return formModel.questions?.filter(q => q.active).length || 0;
  }

  showFormModelQuestionOptionDetails(formModelQuestionOption: IFormModelQuestionOption): void {
    this.formModelQuestionOptionDetailModalService.show(formModelQuestionOption);
  }

  totalActiveFormModelQuestionOptions(formModelQuestion: IFormModelQuestion): number {
    return formModelQuestion.options?.filter(q => q.active).length || 0;
  }

  showFormModelCategoryDetails(category: IFormModelCategory): void {
    this.formModelCategoryDetailModalService.show(category);
  }

  totalActiveFormModelCategories(formModel: IFormModel): number {
    return formModel.categories?.filter(q => q.active).length || 0;
  }

  totalActiveFormModelChangeLogs(formModel: IFormModel): number {
    return formModel.changeLogs?.filter(l => l.active).length || 0;
  }

  showFormModelChangeLogDetails(changeLog: IChangeLog): void {
    this.formModelChangeLogDetailModalService.show(changeLog);
  }

  hasPrivilegeToView(): boolean {
    return this.accountService.hasAnyAuthority([Authority.SYSTEM_ADMIN, FeatureAuthority.FRM_FORM_MODEL_VIEW]);
  }

  hasPrivilegeToCreate(): boolean {
    return this.accountService.hasAnyAuthority([Authority.SYSTEM_ADMIN, FeatureAuthority.FRM_FORM_MODEL_CREATE]);
  }

  hasPrivilegeToEdit(): boolean {
    return this.accountService.hasAnyAuthority([Authority.SYSTEM_ADMIN, FeatureAuthority.FRM_FORM_MODEL_EDIT]);
  }

  hasPrivilegeToDelete(): boolean {
    return this.accountService.hasAnyAuthority([Authority.SYSTEM_ADMIN, FeatureAuthority.FRM_FORM_MODEL_DELETE]);
  }

  ngOnDestroy(): void {
    unsubscribe(this.subscriptions);
  }
}
