import {Component, Input} from '@angular/core';
import {IFormAnswer} from 'app/shared/model/form/form-answer.model';
import {FormActionsService} from 'app/entities/form/form/form-actions.service';
import {IFormAnswerOption} from 'app/shared/model/form/form-answer-option.model';
import {IBaseEntity} from 'app/shared/model/root/base-entity.model';

@Component({
  selector: 'app-frm-form-answer-value',
  template: `
    <ng-container *ngIf="answer" [ngSwitch]="answer.question?.type">
      <span *ngSwitchCase="'DECIMAL_NUMBER'">{{ answer.answer | number: '1.2-2' }}</span>

      <span *ngSwitchCase="'CURRENCY'">
        {{ answer.question?.currency === 'UNKNOWN' ? '' : ('currency.' + answer.question?.currency | translate) + ' ' }}
        {{ answer.answer | number: '1.2-2' }}
      </span>

      <span *ngSwitchCase="'DATE'">{{ answer.answer | dateMoment: 'dd/MM/yyyy' }}</span>

      <app-mail-link *ngSwitchCase="'EMAIL'" [value]="answer.answer"></app-mail-link>

      <app-external-link *ngSwitchCase="'URL'" [href]="answer.answer" target="_blank"></app-external-link>

      <app-bool-icon
        *ngSwitchCase="'YES_NO'"
        [value]="answer.answer && ['true', 'yes', '1'].includes((answer.answer + '').toLowerCase())"
      ></app-bool-icon>

      <app-file-download-link
        *ngSwitchCase="'DOC_UPLOAD'"
        class="value"
        [file]="answer.answerFile"
        [label]="'entity.action.download' | translate"
      ></app-file-download-link>

      <div *ngSwitchCase="'IMAGE_UPLOAD'" class="image-card">
        <img
          *ngIf="answer.answerFile"
          class="img-thumbnail img-fluid"
          [src]="answer.answerFile | resizeImage: 500"
          [alt]="answer.answerFile?.name"
        />
      </div>

      <div *ngSwitchCase="'PHONE'">
        <app-masked-value [value]="answer.answer" pattern="phone"></app-masked-value>
      </div>

      <div *ngSwitchCase="'CEP'">
        <app-masked-value [value]="answer.answer" pattern="cep"></app-masked-value>
      </div>

      <div *ngSwitchCase="'IP'">
        <app-masked-value [value]="answer.answer" pattern="ip"></app-masked-value>
      </div>

      <div *ngSwitchCase="'CPF'">
        <app-masked-value [value]="answer.answer" pattern="cpf"></app-masked-value>
      </div>

      <div *ngSwitchCase="'CNPJ'">
        <app-masked-value [value]="answer.answer" pattern="cnpj"></app-masked-value>
      </div>

      <div *ngSwitchCase="'CPF_CNPJ'">
        <app-masked-value [value]="answer.answer" pattern="cpfOrCnpj"></app-masked-value>
      </div>

      <div *ngSwitchCase="'UUID'">
        <app-masked-value [value]="answer.answer" pattern="uuid"></app-masked-value>
      </div>

      <div *ngSwitchCase="'SINGLE_CHOICE'">
        <ul *ngFor="let opt of answer.options | orderBy: 'option?.order' | filter: {active: true}; trackBy: trackIdentity">
          <li>
            <a *ngIf="opt.id" href="javascript:void(0);" (click)="showFormAnswerOptionDetails(opt)">{{ opt.option?._label }}</a>
          </li>
        </ul>
      </div>

      <div *ngSwitchCase="'MULTIPLE_CHOICE'">
        <ul *ngFor="let opt of answer.options | orderBy: 'option?.order' | filter: {active: true}; trackBy: trackIdentity">
          <li>
            <a *ngIf="opt.id" href="javascript:void(0);" (click)="showFormAnswerOptionDetails(opt)">{{ opt.option?._label }}</a>
          </li>
        </ul>
      </div>

      <span *ngSwitchDefault>{{ answer.answer }}</span>
    </ng-container>
  `
})
export class FormAnswerValueComponent {
  @Input()
  answer: IFormAnswer | undefined = undefined;

  constructor(private formActionsService: FormActionsService) {}

  showFormAnswerOptionDetails(option: IFormAnswerOption): void {
    this.formActionsService.showFormAnswerOptionDetails(option);
  }

  trackIdentity(index: number, item: IBaseEntity): any {
    return item.id ?? item.uuid;
  }
}
