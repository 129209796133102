import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {IFile} from 'app/shared/model/admin/file.model';
import {FileService} from 'app/shared/services/admin/file.service';
import {IconProp} from '@fortawesome/fontawesome-svg-core';
import {FileType} from 'app/shared/model/enumerations/file-type.enum';

@Component({
  selector: 'app-file-download-link',
  template: `
    <ng-container *ngIf="file && file.url">
      <ng-container *ngIf="hasDownloadButton">
        <a
          *ngIf="save"
          (click)="download()"
          href="javascript:void(0);"
          [classList]="asButton ? 'btn btn-sm ' + (buttonClasses || 'btn-outline-primary') : ''"
        >
          <ng-container *ngIf="asButton">
            <fa-icon *ngIf="buttonIcon" [fixedWidth]="true"[icon]="buttonIcon"></fa-icon>
            <fa-icon *ngIf="!buttonIcon" [fixedWidth]="true"icon="download"></fa-icon>
            <ng-container *ngIf="!noLabel">
              &nbsp;
            </ng-container>
          </ng-container>
          <span *ngIf="!noLabel">
            {{ label || file.name }}
          </span>
          <ng-container *ngIf="!asButton">
            &nbsp;
            <fa-icon [fixedWidth]="true"icon="download"></fa-icon>
          </ng-container>
        </a>
        <a
          *ngIf="!save"
          (click)="download()"
          href="javascript:void(0);"
          [classList]="asButton ? 'btn btn-sm ' + (buttonClasses || 'btn-outline-primary') : ''"
        >
          <ng-container *ngIf="asButton">
            <fa-icon *ngIf="buttonIcon" [fixedWidth]="true"[icon]="buttonIcon"></fa-icon>
            <fa-icon *ngIf="!buttonIcon" [fixedWidth]="true"icon="download"></fa-icon>
            <ng-container *ngIf="!noLabel">
              &nbsp;
            </ng-container>
          </ng-container>
          <span *ngIf="!noLabel">
            {{ label || file.name }}
          </span>
          <ng-container *ngIf="!asButton">
            &nbsp;
            <fa-icon [fixedWidth]="true"icon="download"></fa-icon>
          </ng-container>
        </a>
        &nbsp;
      </ng-container>

      <ng-container *ngIf="hasViewButton">
        <a
          (click)="view()"
          href="javascript:void(0);"
          [classList]="asButton ? 'btn btn-sm ' + (buttonClasses || 'btn-outline-primary') : ''"
        >
          <ng-container *ngIf="asButton">
            <fa-icon *ngIf="buttonViewIcon" [fixedWidth]="true"[icon]="buttonViewIcon"></fa-icon>
            <fa-icon *ngIf="!buttonViewIcon" [fixedWidth]="true"icon="eye"></fa-icon>
          </ng-container>
          <ng-container *ngIf="!asButton">
            <fa-icon [fixedWidth]="true"icon="eye"></fa-icon>
          </ng-container>
        </a>
      </ng-container>
    </ng-container>
  `
})
export class FileDownloadLinkComponent implements OnInit {
  @Input()
  file: IFile | undefined = undefined;

  @Input()
  label: string | undefined = undefined;

  @Input()
  save: boolean;

  @Input()
  asButton = false;

  @Input()
  buttonClasses: string | undefined = undefined;

  @Input()
  buttonIcon: IconProp | undefined = undefined;

  @Input()
  buttonViewIcon: IconProp | undefined = undefined;

  @Input()
  noLabel = false;

  @Input()
  hasViewButton = true;

  @Input()
  hasDownloadButton = true;

  @Output()
  onClick: EventEmitter<never> = new EventEmitter<never>();

  constructor(private fileService: FileService) {}

  ngOnInit(): void {
    this.file?.fileType === FileType.LINK && (this.hasDownloadButton = false);
  }

  doClick(): void {
    this.onClick?.emit();
  }

  download(): void {
    this.file?.fileType === FileType.FILE && this.fileService.download(this.file);
    this.doClick();
  }

  view(): void {
    this.openPdf(this.file);
  }

  openPdf(file: IFile) {
    window.open(file.url, '_blank');
  }
}
