import {Injectable} from '@angular/core';
import {NgbModal, NgbModalRef} from '@ng-bootstrap/ng-bootstrap';
import {FormModelQuestionOptionDetailModalComponent} from 'app/entities/form/form-model/form-model-question-option-detail-modal.component';
import {IFormModelQuestionOption} from 'app/shared/model/form/form-model-question-option.model';

@Injectable({providedIn: 'root'})
export class FormModelQuestionOptionDetailModalService {
  private isOpen = false;
  private instance?: NgbModalRef;

  constructor(private modalService: NgbModal) {}

  show(formModelQuestionOption: IFormModelQuestionOption, close?: Function): void {
    if (this.isOpen) return;

    this.isOpen = true;

    this.instance = this.modalService.open(FormModelQuestionOptionDetailModalComponent, {
      size: 'lg',
      backdrop: 'static'
    });
    this.instance.componentInstance.formModelQuestionOption = formModelQuestionOption;
    this.instance.componentInstance.close = close;

    this.instance.result.finally(() => (this.isOpen = false));
  }

  close(): void {
    if (!this.isOpen || !this.instance) return;
    this.instance.dismiss();
  }
}
