import {MissingTranslationHandler, MissingTranslationHandlerParams} from '@ngx-translate/core';
import {Logger} from 'app/shared/util/logger';

export class TranslationNotFoundHandler implements MissingTranslationHandler {
	private log: Logger = new Logger(TranslationNotFoundHandler.name);

	handle(params: MissingTranslationHandlerParams): any {
		// this.log.warn(() => `Translation not found for => ${params.key}`);
		return params.key;
	}
}
