import {Component, OnDestroy, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {SessionStorageService} from 'ngx-webstorage';
import {AccountService} from 'app/core/auth/account.service';
import {LoginService} from 'app/core/login/login.service';
import {ProfileService} from 'app/layouts/profiles/profile.service';
import {Observable, Subscription} from 'rxjs';
import {Account} from 'app/core/user/account.model';
import {unsubscribe} from 'app/shared/util/react-util';
import {Authority} from 'app/shared/model/enumerations/authority.enum';
import {FeatureAuthority} from 'app/shared/model/enumerations/feature-authority.enum';
import {FileService} from 'app/shared/services/admin/file.service';
import {UserManualService} from 'app/shared/services/admin/user-manual.service';
import {UserManual} from 'app/shared/model/admin/user-manual.model';
import {HttpResponse} from '@angular/common/http';
import {IFile} from 'app/shared/model/admin/file.model';
import {SidebarService} from 'app/layouts/sidebar/sidebar.service';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['sidebar.scss']
})
export class SidebarComponent implements OnInit, OnDestroy {
  private sidebarVisibility = true;
  inProduction?: boolean;

  swaggerEnabled?: boolean;

  userManual?: IFile;

  backoffice: boolean = false;

  menuCollapse: any = {
    profile: true,
    partner: true,
    framework: true,
    salesManagement: true,
    purchasingManagement: true,
    inventoryManagement: true,
    financialManagement: true,
    systemSettings: true
  };

  account$?: Observable<Account | null>;

  private subscriptions: Subscription[] = [];

  constructor(
    private loginService: LoginService,
    private sessionStorage: SessionStorageService,
    private accountService: AccountService,
    private profileService: ProfileService,
    private fileService: FileService,
    private userManualService: UserManualService,
    private sidebarService: SidebarService,
    private router: Router
  ) {
  }

  ngOnInit(): void {
    this.account$ = this.accountService.identity();
    this.setBackoffice();
    this.subscriptions.push(
      this.profileService.getProfileInfo().subscribe(profileInfo => {
        this.inProduction = profileInfo.inProduction;
        this.swaggerEnabled = profileInfo.swaggerEnabled;
      }),
      this.userManualService.find().subscribe((userManual: HttpResponse<UserManual>) => {
        this.userManual = userManual.body?.manual;
      })
    );
  }

  setBackoffice() {
    const currentURL = window.location.href;
    if (currentURL.indexOf('qasadmin.onclickon.onclick.com.br') > 0) {
      this.backoffice = true;
    }
  }

  isAuthenticated(): boolean {
    return this.accountService.isAuthenticated();
  }

  toggle(menu?: string): void {
    if (!menu) {
      return;
    }
    this.menuCollapse[menu] = !this.menuCollapse[menu];
    !this.menuCollapse[menu] && Object.keys(this.menuCollapse).forEach(key => key !== menu && (this.menuCollapse[key] = true));
  }

  logout(): void {
    this.loginService.logout();
    this.router.navigate(['/']);
  }

  getImageUrl(): string {
    return this.isAuthenticated() ? this.accountService.getImageUrl() : '';
  }

  toggleSidebarMobile(): void {
    const pref = this.sessionStorage.retrieve('sidebarVisibility');
    if (screen.width < 768) {
      this.sidebarVisibility = pref === null ? true : !!pref;
      this.sessionStorage.store('sidebarVisibility', !this.sidebarVisibility);
    }
  }

  showAdminMenu(): boolean {
    return this.accountService.hasAnyAuthority([
      Authority.SYSTEM_ADMIN,
      FeatureAuthority.ADM_UNIT_VIEW,
      FeatureAuthority.USR_USER_VIEW,
      FeatureAuthority.SEC_ROLE_VIEW
    ]);
  }

  showCompanyMenu() {
    return this.accountService.hasAnyAuthority([
      Authority.SYSTEM_ADMIN,
      FeatureAuthority.COM_COMPANY_VIEW,
      FeatureAuthority.COM_COMPANY_PARAMETER_VIEW
    ]);
  }

  showScrumMenu() {
    return this.accountService.hasAnyAuthority([
      Authority.SYSTEM_ADMIN,
      FeatureAuthority.SCR_ACTIVITY_VIEW,
      FeatureAuthority.SCR_PROJECT_VIEW,
      FeatureAuthority.SCR_REGISTER_VIEW
    ]);
  }

  showThirdPartyAuthenticationMenu() {
    return this.accountService.hasAnyAuthority([
      Authority.SYSTEM_ADMIN,
      FeatureAuthority.TPA_CONFIGURATION_VIEW
    ]);
  }

  showThirdPartyEntityMenu() {
    return this.accountService.hasAnyAuthority([
      Authority.SYSTEM_ADMIN,
      FeatureAuthority.TPE_CONFIGURATION_VIEW
    ]);
  }

  showThirdMenu() {
    return this.accountService.hasAnyAuthority([
      Authority.SYSTEM_ADMIN,
      FeatureAuthority.ERP_CUSTOMER_VIEW,
      FeatureAuthority.ERP_PROVIDER_VIEW
    ]);
  }

  showDevOpsMenu() {
    return this.accountService.hasAnyAuthority([
      Authority.SYSTEM_ADMIN,
      FeatureAuthority.DEV_VAULT_VIEW
    ]);
  }

  showProductMenu() {
    return this.accountService.hasAnyAuthority([
      Authority.SYSTEM_ADMIN,
      FeatureAuthority.PRO_BRAND_VIEW,
      FeatureAuthority.PRO_CATEGORY_VIEW,
      FeatureAuthority.PRO_PRICE_LIST_VIEW,
      FeatureAuthority.PRO_PRODUCT_VIEW,
      FeatureAuthority.PRO_TAG_VIEW
    ]);
  }

  showContentMenu(): boolean {
    return this.accountService.hasAnyAuthority([
      Authority.SYSTEM_ADMIN,
      FeatureAuthority.CNT_BANNER_VIEW,
      FeatureAuthority.CNT_POST_CATEGORY_VIEW,
      FeatureAuthority.CNT_POST_VIEW,
      FeatureAuthority.CNT_METADATA_VIEW
    ]);
  }

  showFormMenu(): boolean {
    return this.accountService.hasAnyAuthority([
      Authority.SYSTEM_ADMIN,
      FeatureAuthority.FRM_FORM_VIEW,
      FeatureAuthority.FRM_FORM_MODEL_VIEW
    ]);
  }

  showWarehouseMenu(): boolean {
    return this.accountService.hasAnyAuthority([
      Authority.SYSTEM_ADMIN,
      FeatureAuthority.WHS_WAREHOUSE_VIEW,
      FeatureAuthority.WHS_WAREHOUSE_LAUNCH_VIEW
    ]);
  }

  showPaymentMenu(): boolean {
    return this.accountService.hasAnyAuthority([
      Authority.SYSTEM_ADMIN,
      FeatureAuthority.PAY_ORDER_VIEW,
      FeatureAuthority.PAY_CARD_VIEW,
      FeatureAuthority.PAY_CUSTOMER_VIEW,
      FeatureAuthority.PAY_CHARGE_VIEW,
      FeatureAuthority.PAY_TRANSACTION_VIEW,
      FeatureAuthority.PAY_WEBHOOK_VIEW,
      FeatureAuthority.PAY_GATEWAY_VIEW
    ]);
  }

  showSessionMenu(): boolean {
    return this.accountService.hasAnyAuthority([
      Authority.SYSTEM_ADMIN,
      FeatureAuthority.SSS_BOOKMARK_VIEW,
      FeatureAuthority.SSS_TRACKING_VIEW
    ]);
  }

  showPartnerMenu(): boolean {
    return this.accountService.hasAnyAuthority([
      Authority.SYSTEM_ADMIN,
      FeatureAuthority.ERP_CUSTOMER_VIEW,
      FeatureAuthority.ERP_PROVIDER_VIEW
    ]);
  }

  showSystemMenu(): boolean {
    return this.accountService.isSystemAdmin();
  }

  showFeatureItem(authority: string): boolean {
    return this.accountService.hasAnyAuthority([Authority.SYSTEM_ADMIN, authority]);
  }

  isSystemAdmin(): boolean {
    return this.accountService.hasAnyAuthority(Authority.SYSTEM_ADMIN);
  }

  viewUserManual(): void {
    this.userManual && this.fileService.view(this.userManual);
    this.toggleSidebarMobile();
  }

  ngOnDestroy(): void {
    unsubscribe(this.subscriptions);
  }
}
