<div class="modal-body">
  <div class="container">

    <div class="row justify-content-md-center">
      <div class="col-12 text-center">
        <div class="close-button">
          <fa-icon icon="times" class="close" (click)="dismiss()"></fa-icon>
        </div>

        <video *ngIf="entity.video" class="img-fluid" [src]="entity.video.url" controls></video>
        <iframe *ngIf="entity.videoUrl" [src]="url"
                width="600" height="400" frameborder="0" allowfullscreen></iframe>
      </div>
    </div>
    <div *ngIf="entity.name" class="row justify-content-md-center">
      <div class="col-md-8 pt-4">
        <h3>{{ entity.name }}</h3>
        <p class="description-video-modal">{{ entity.description }}</p>
      </div>
    </div>
  </div>
</div>
