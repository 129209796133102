import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {IBaseEntity} from 'app/shared/model/root/base-entity.model';
import {EntityMenuColumnService} from 'app/shared/entity-menu-column/entity-menu-column.service';
import {Subscription} from 'rxjs';
import {unsubscribe} from 'app/shared/util/react-util';

@Component({
	selector: 'entity-menu-column',
	templateUrl: './entity-menu-column.component.html',
	styleUrls: ['./entity-menu-column.component.scss']
})
export class EntityMenuColumnComponent<T extends IBaseEntity> implements OnInit, OnDestroy {

	@Input()
	entityName: string = null;

	@Input()
	page: string = null;

	@Input()
	columns: string  [] = null;

	@Input()
	columnsMap: Map<string, boolean> = null;

	@Input()
	showArrowIcon: boolean = false;

	@Output()
	columnsMapChange = new EventEmitter<Map<string, boolean>>();

	isSaving: boolean = false;
	isEdited: boolean = false;

	private subscriptions: Subscription[] = [];

	constructor(private translateService: TranslateService, private entityMenuColumnService: EntityMenuColumnService) {
	}

	ngOnInit(): void {
		this.initColumnsMap();
	}

	fillColumnMapWithTrue(): void {
		this.columns.forEach(key => {
			this.columnsMap.set(key, true);
		});
	}

	setEditedToTrue(): void {
		this.isEdited = true;
	}

	translateColumn(column: string): string {
		const label = `${this.entityName!}.${column}`;
		const translated = this.translateService.instant(label);
		return translated === label ? this.translateService.instant(`global.field.${column}`) : translated;
	}

	saveCurrentStatus(): void {

		this.isSaving = true;
		const columnMap: string = JSON.stringify(Object.fromEntries(this.columnsMap.entries()));

		this.subscriptions.push(
			this.entityMenuColumnService.saveCurrentStatus(this.page, columnMap).subscribe(() => {
				this.isSaving = false;
				this.isEdited = false;
			})
		);
	}

	private initColumnsMap(): void {
		this.subscriptions.push(
			this.entityMenuColumnService.findByCurrentUserAndPage(this.page).subscribe(res => {
				if (res.body?.columnsMap) {
					// this.fillColumnMapWithTrue();
					this.columnsMap = new Map(Object.entries(JSON.parse(res.body.columnsMap))) as Map<string, boolean>;
				} else {
					this.fillColumnMapWithTrue();
				}
				this.columnsMapChange.emit(this.columnsMap);
			})
		);
	}

	ngOnDestroy(): void {
		unsubscribe(this.subscriptions);
	}
}
