<ng-container *ngIf="canShowForm()">
	<form autocomplete="off" name="answerForm" role="form" novalidate (ngSubmit)="submit()" [formGroup]="editForm">

		<div class="form-response-editor" [ngClass]="{'card': options.mode === 'card'}">

			<ng-container *ngIf="options.showHeader">
				<div *ngIf="options.mode === 'card'" class="card-header">
					<span>{{ form.name }}</span>
				</div>
				<div *ngIf="options.mode === 'modal'" class="modal-header">
					<h4 class="modal-title">
						<span>{{ form.name }}</span>
					</h4>
					<button type="button" class="close" data-dismiss="modal" aria-hidden="true" (click)="cancel()">&times;
					</button>
				</div>
			</ng-container>

			<div class="{{options.mode}}-body">
				<div *ngIf="options.showFormDescription && form.formModel?.description" class="form-row">
					<div class="form-description mb-3 p-2">
						<h3 class="pb-1">{{form.formModel?.name}}</h3>
						<p class="lead">{{form.formModel?.description}}</p>
					</div>
				</div>

				<div class="form-row" *ngFor="let section of sections | orderBy:'order'; trackBy: trackIdentityUuid">
					<div class="col-12">
						<ngb-accordion class="entity-child-section" [activeIds]="section.uuid">
							<ngb-panel [id]="section.uuid">
								<ng-template ngbPanelTitle>
									<div class="d-flex justify-content-between">
										<div>
											<fa-icon [fixedWidth]="true"
											         [icon]="section.category?.faIcon ? section.category?.faIcon : 'bars'"></fa-icon>
											&nbsp;
											<span>{{section.category?.name}}</span>
										</div>
									</div>
								</ng-template>
								<ng-template ngbPanelContent>
									<div *ngIf="section.category?.description" class="form-row">
										<div *ngIf="!section.category?.alertColor" class="section-description mb-4">
											<p class="lead">{{section.category?.description}}</p>
										</div>

										<div *ngIf="section.category?.alertColor"
										     class="section-description mb-4 alert alert-{{section.category?.alertColor}}" role="alert">
											<p class="lead">{{section.category?.description}}</p>
										</div>
									</div>

									<div *ngFor="let answer of section.answers | orderBy: 'question.order'; trackBy: trackIdentity;"
									     class="form-row pb-1">
										<div class="form-group col-12 question {{
                      options.showApprovalInfo && answer.status === 'FINISHED' ? 'p-2 border rounded border-success' :
                      options.showApprovalInfo && answer.status === 'REFUSED' ? 'p-2 border rounded border-danger' : ''
                    }}">
											<div *ngIf="options.showApprovalInfo && ['REFUSED', 'FINISHED'].includes(answer.status)"
											     class="col-12 answer-approval text-center p-2 pb-3">
												<div class="text text-success font-weight-normal" *ngIf="answer.status === 'FINISHED'">
													<fa-icon [fixedWidth]="true" icon="check-circle"></fa-icon>
													&nbsp;
													<span jhiTranslate="form.answer.approved"
													      [translateValues]="{ date: (answer.approvalDate | dateMoment:'dd/MM/yyyy') }"></span>
												</div>
												<div class="text text-danger font-weight-normal" *ngIf="answer.status === 'REFUSED'">
													<fa-icon [fixedWidth]="true" icon="times-circle"></fa-icon>
													&nbsp;
													<span jhiTranslate="form.answer.rejected"
													      [translateValues]="{ date: (answer.approvalDate | dateMoment:'dd/MM/yyyy') }"></span>
												</div>
												<div class="text-justify" *ngIf="answer.approvalObs?.length">
													<div class="font-weight-bold">
														<span jhiTranslate="form.formAnswer.approvalObs"></span>:
													</div>
													<p>{{ answer.approvalObs }}</p>
												</div>
											</div>

											<div *ngIf="answer.question.description"
											     class="alert alert-{{answer.question.alertColor ? answer.question.alertColor : 'light'}} question-description mb-1"
											     role="alert">
												<h5 class="alert-heading pb-1">
													<fa-icon [fixedWidth]="true"
													         [icon]="answer.question.faIcon ? answer.question.faIcon : 'question-circle'"></fa-icon>
													&nbsp;
													<span [ngClass]="{'required': answer.question.mandatory}">{{answer.question.question}}</span>
												</h5>
												<p><small>{{answer.question.description}}</small></p>
											</div>

											<div>
												<div class="input-group">
													<div class="input-group-prepend">
														<label
															class="input-group-text {{!answer.question.description && answer.question.alertColor ? 'border border-'+answer.question.alertColor : ''}}"
															[ngClass]="{'required': answer.question.mandatory}">
															<fa-icon *ngIf="!answer.question.description && answer.question.faIcon"
															         [fixedWidth]="true"
															         [icon]="answer.question.faIcon"
															         class="mr-2 {{answer.question.alertColor ? 'text-'+answer.question.alertColor : ''}}"></fa-icon>
															<span
																[jhiTranslate]="answer.question.description ? 'global.field.answer' : answer.question.question"></span>
														</label>
													</div>

													<!-- INTEGER_NUMBER -->
													<input *ngIf="answer.question.type === 'INTEGER_NUMBER'"
													       type="text" class="form-control" [mask]="mask.long()"
													       [formControlName]="answer.uuid" [name]="answer.uuid"
													       [ngClass]="{'is-invalid': isInvalidAndTouched(answer.uuid)}">

													<!-- DECIMAL_NUMBER -->
													<input *ngIf="answer.question.type === 'DECIMAL_NUMBER'"
													       type="text" class="form-control" currencyMask
													       [formControlName]="answer.uuid" [name]="answer.uuid"
													       [options]="{ prefix: '', suffix: '', thousands: '.', decimal: ',', precision: 2 }"
													       [ngClass]="{'is-invalid': isInvalidAndTouched(answer.uuid)}">

													<!-- CURRENCY -->
													<input *ngIf="answer.question.type === 'CURRENCY'"
													       type="text" class="form-control" currencyMask
													       [formControlName]="answer.uuid" [name]="answer.uuid"
													       [options]="{ prefix: (answer.question.currency === 'UNKNOWN' ? '' : ('currency.' + answer.question.currency | translate) + ' '), thousands: '.', decimal: ',' }"
													       [ngClass]="{'is-invalid': isInvalidAndTouched(answer.uuid)}">

													<!-- SINGLE_LINE_TEXT -->
													<input *ngIf="answer.question.type === 'SINGLE_LINE_TEXT'"
													       type="text" class="form-control"
													       [formControlName]="answer.uuid" [name]="answer.uuid"
													       [ngClass]="{'is-invalid': isInvalidAndTouched(answer.uuid)}">

													<!-- EMAIL -->
													<input *ngIf="answer.question.type === 'EMAIL'"
													       type="email" class="form-control"
													       [formControlName]="answer.uuid" [name]="answer.uuid"
													       [ngClass]="{'is-invalid': isInvalidAndTouched(answer.uuid)}">

													<!-- URL -->
													<input *ngIf="answer.question.type === 'URL'"
													       type="url" class="form-control"
													       [formControlName]="answer.uuid" [name]="answer.uuid"
													       [ngClass]="{'is-invalid': isInvalidAndTouched(answer.uuid)}">

													<!-- PHONE -->
													<input *ngIf="answer.question.type === 'PHONE'"
													       type="text" class="form-control"
													       [formControlName]="answer.uuid" [name]="answer.uuid"
													       [showMaskTyped]="true" [mask]="mask.phone()"
													       [ngClass]="{'is-invalid': isInvalidAndTouched(answer.uuid)}">

													<!-- CEP -->
													<input *ngIf="answer.question.type === 'CEP'"
													       type="text" class="form-control"
													       [formControlName]="answer.uuid" [name]="answer.uuid"
													       [showMaskTyped]="true" [mask]="mask.cep()"
													       [ngClass]="{'is-invalid': isInvalidAndTouched(answer.uuid)}">

													<!-- IP -->
													<input *ngIf="answer.question.type === 'IP'"
													       type="text" class="form-control"
													       [formControlName]="answer.uuid" [name]="answer.uuid"
													       [mask]="mask.ip()" [dropSpecialCharacters]="false"
													       [ngClass]="{'is-invalid': isInvalidAndTouched(answer.uuid)}">

													<!-- UUID -->
													<input *ngIf="answer.question.type === 'UUID'"
													       type="text" class="form-control"
													       [formControlName]="answer.uuid" [name]="answer.uuid"
													       [showMaskTyped]="true" [mask]="mask.uuid()" [patterns]="uuidPattern"
													       [ngClass]="{'is-invalid': isInvalidAndTouched(answer.uuid)}">

													<!-- CPF -->
													<input *ngIf="answer.question.type === 'CPF'"
													       type="text" class="form-control"
													       [formControlName]="answer.uuid" [name]="answer.uuid"
													       [showMaskTyped]="true" [mask]="mask.cpf()"
													       [ngClass]="{'is-invalid': isInvalidAndTouched(answer.uuid)}">

													<!-- CPF_CNPJ -->
													<input *ngIf="answer.question.type === 'CPF_CNPJ'"
													       type="text" class="form-control"
													       [formControlName]="answer.uuid" [name]="answer.uuid"
													       [showMaskTyped]="true" [mask]="mask.cpfOrCnpj()"
													       [ngClass]="{'is-invalid': isInvalidAndTouched(answer.uuid)}">

													<!-- CNPJ -->
													<input *ngIf="answer.question.type === 'CNPJ'"
													       type="text" class="form-control"
													       [formControlName]="answer.uuid" [name]="answer.uuid"
													       [showMaskTyped]="true" [mask]="mask.cnpj()"
													       [ngClass]="{'is-invalid': isInvalidAndTouched(answer.uuid)}">

													<!-- MULTILINE_TEXT -->
													<textarea *ngIf="answer.question.type === 'MULTILINE_TEXT'"
													          class="form-control" [formControlName]="answer.uuid"
													          [name]="answer.uuid"
													          [ngClass]="{'is-invalid': isInvalidAndTouched(answer.uuid)}"></textarea>

													<!-- SINGLE_CHOICE -->
													<div *ngIf="answer.question.type === 'SINGLE_CHOICE'" class="form-control selector"
													     [ngClass]="{'is-invalid': isInvalidAndTouched(answer.uuid)}">
														<ng-select [items]="answer._availableOptions" appendTo="body"
														           [virtualScroll]="true" [formControlName]="answer.uuid"
														           bindLabel="option.description">
															<ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
																<div class="option-item" [title]="item.option.description">
                                  <span>
                                    <input id="item1-{{index}}" type="checkbox" [ngModel]="item$.selected"
                                           [ngModelOptions]="{ standalone : true }" />
                                  </span>
																	<label for="item1-{{index}}">{{item.option.description}}</label>
																</div>
															</ng-template>
														</ng-select>
													</div>

													<!-- MULTIPLE_CHOICE -->
													<div *ngIf="answer.question.type === 'MULTIPLE_CHOICE'" class="form-control selector"
													     [ngClass]="{'is-invalid': isInvalidAndTouched(answer.uuid)}">
														<ng-select [items]="answer._availableOptions" [multiple]="true" appendTo="body"
														           [virtualScroll]="true" [formControlName]="answer.uuid"
														           bindLabel="option.description">
															<ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
																<div class="option-item" [title]="item.option.description">
                                  <span>
                                    <input id="item-{{index}}" type="checkbox" [ngModel]="item$.selected"
                                           [ngModelOptions]="{ standalone : true }" />
                                  </span>
																	<label for="item-{{index}}">{{item.option.description}}</label>
																</div>
															</ng-template>
														</ng-select>
													</div>

													<!-- YES_NO -->
													<select *ngIf="answer.question.type === 'YES_NO'" class="form-control"
													        [formControlName]="answer.uuid" [name]="answer.uuid"
													        [ngClass]="{'is-invalid': isInvalidAndTouched(answer.uuid)}">
														<option [ngValue]="true">
															{{ 'global.conditional.yes' | translate }}
														</option>
														<option [ngValue]="false">
															{{ 'global.conditional.not' | translate }}
														</option>
													</select>

													<!-- DATE -->
													<ng-container *ngIf="answer.question.type === 'DATE'">
														<input type="text" class="form-control"
														       [placeholder]="mask.datePlaceholder()" minlength="10"
														       maxlength="10" [formControlName]="answer.uuid" [name]="answer.uuid"
														       [rInputMask]="mask.dateDDMMYYYY()"
														       [ngClass]="{'is-invalid': isInvalidAndTouched(answer.uuid)}"
														       ngbDatepicker #d="ngbDatepicker" placement="bottom-right" [positionTarget]="btnCal">
														<div class="input-group-append">
															<button #btnCal class="btn btn-outline-secondary" (click)="d.toggle()" type="button">
																<fa-icon [fixedWidth]="true" icon="calendar-alt"></fa-icon>
															</button>
														</div>
													</ng-container>

													<!-- TIME -->
													<input *ngIf="answer.question.type === 'TIME'" type="time" class="form-control"
													       [formControlName]="answer.uuid" [name]="answer.uuid"
													       [ngClass]="{'is-invalid': isInvalidAndTouched(answer.uuid)}">

													<!-- DOC_UPLOAD -->
													<ng-container *ngIf="answer.question.type === 'DOC_UPLOAD'">
														<input type="hidden" [formControlName]="answer.uuid" [name]="answer.uuid"
														       [ngClass]="{'is-invalid': isInvalidAndTouched(answer.uuid)}">
														<app-file-input [file]="answer.answerFile" [fileExtensions]="docExtensions"
														                [removeBtnVisible]="!isDisabled(answer)"
														                [removeBtnDisabled]="isDisabled(answer)"
														                [uploadBtnVisible]="!isDisabled(answer)"
														                [editBtnDisabled]="isDisabled(answer)"
														                [downloadBtnVisible]="true" (onUploaded)="onUploadedFile($event, answer)"
														                (onRemoved)="removeFile(answer)"></app-file-input>
													</ng-container>

													<!-- IMAGE_UPLOAD -->
													<ng-container *ngIf="answer.question.type === 'IMAGE_UPLOAD'">
														<div *ngIf="!answer.answerFile" class="dropzone form-control"
														     [dropzone]="imageFileDropzoneConfig" [disabled]="isDisabled(answer)"
														     (sending)="onImageUploading($event)"
														     (error)="onImageUploadError($event)"
														     (success)="onImageUploadSuccess(answer, $event)">
														</div>
														<ng-container *ngIf="answer.answerFile">
															<div class="imageFile form-control">
																<img [src]="answer.answerFile | resizeImage:500" [alt]="answer.answerFile.sourceName" />
																<app-file-download-link [file]="answer.answerFile"
																                        [label]="answer.answerFile.sourceName">
																</app-file-download-link>
															</div>
															<div class="input-group-append" *ngIf="!isDisabled(answer)">
																<button type="button" title="{{'entity.action.remove' | translate}}"
																        (click)="removeFile(answer)" class="btn btn-outline-danger">
																	<fa-icon icon="trash-alt" [fixedWidth]="true"></fa-icon>
																</button>
															</div>
														</ng-container>
													</ng-container>
												</div>

												<div *ngIf="isInvalidAndTouched(answer.uuid)">
													<small *ngIf="editForm.get(answer.uuid)?.errors?.required"
													       class="form-text text-danger"
													       jhiTranslate="entity.validation.required">
													</small>

													<small *ngIf="editForm.get(answer.uuid)?.errors?.min"
													       [translateValues]="{ min: answer.question.min }"
													       class="form-text text-danger"
													       jhiTranslate="entity.validation.min">
													</small>

													<small *ngIf="editForm.get(answer.uuid)?.errors?.max"
													       [translateValues]="{ max: answer.question.max }"
													       class="form-text text-danger"
													       jhiTranslate="entity.validation.max">
													</small>

													<small *ngIf="editForm.get(answer.uuid)?.errors?.minlength"
													       [translateValues]="{ min: getMinLength(answer.question) }"
													       class="form-text text-danger"
													       jhiTranslate="entity.validation.minlength">
													</small>

													<small *ngIf="editForm.get(answer.uuid)?.errors?.maxlength"
													       [translateValues]="{ max: getMaxLength(answer.question) }"
													       class="form-text text-danger"
													       jhiTranslate="entity.validation.maxlength">
													</small>

													<small class="form-text text-danger"
													       *ngIf="editForm.get(answer.uuid)?.errors?.ngbDate"
													       jhiTranslate="entity.validation.date">
													</small>

													<small *ngIf="editForm.get(answer.uuid)?.errors?.dateTimeIsNotSameOrAfter"
													       [translateValues]="{ min: getFormattedMinDateTime(answer.question) }"
													       class="form-text text-danger"
													       jhiTranslate="entity.validation.sameOrMinDate">
													</small>

													<small *ngIf="editForm.get(answer.uuid)?.errors?.dateTimeIsNotSameOrBefore"
													       [translateValues]="{ max: getFormattedMaxDateTime(answer.question) }"
													       class="form-text text-danger"
													       jhiTranslate="entity.validation.sameOrMaxDate">
													</small>

													<small *ngIf="editForm.get(answer.uuid)?.errors?.email"
													       class="form-text text-danger"
													       jhiTranslate="global.messages.validate.email.invalid">
													</small>

													<small
														*ngIf="editForm.get(answer.uuid)?.errors?.mask || editForm.get(answer.uuid)?.errors?.invalid"
														class="form-text text-danger"
														[jhiTranslate]="isCPF(answer.question) ? 'entity.validation.cpf' :
                                  isCNPJ(answer.question) ? 'entity.validation.cnpj' :
                                  isCPForCNPJ(answer.question) ? 'entity.validation.cpfOrCnpj' :
                                  isCEP(answer.question) ? 'entity.validation.cep' :
                                  isPhone(answer.question) ? 'entity.validation.phone' :
                                  'entity.validation.invalid'">
													</small>

												</div>
											</div>

										</div>
									</div>
								</ng-template>
							</ngb-panel>
						</ngb-accordion>
					</div>
				</div>
			</div>

			<div class="{{options.mode}}-footer"
			     *ngIf="options.showSaveButton || (options.mode === 'modal' && options.showCancelButton)">
				<div class="form-row">
					<div class="col-12">
						<div class="float-right">
							<button *ngIf="options.mode === 'modal' && options.showCancelButton" type="button" (click)="cancel()"
							        class="btn btn-secondary">
								<fa-icon [fixedWidth]="true" icon="ban"></fa-icon>
								&nbsp;
								<span jhiTranslate="entity.action.cancel"></span>
							</button>
							&nbsp;
							<button *ngIf="options.showSaveButton" class="btn btn-primary" type="submit" [disabled]="!canSubmit()">
								<fa-icon [fixedWidth]="true" icon="save"></fa-icon>
								&nbsp;
								<span jhiTranslate="entity.action.save"></span>
							</button>
						</div>
					</div>
				</div>
			</div>

		</div>
	</form>
</ng-container>
