import {Routes} from '@angular/router';

import {HomeComponent} from './home.component';
import {Authority} from 'app/shared/model/enumerations/authority.enum';

export const HOME_ROUTES: Routes = [
    {
      path: 'home',
      component: HomeComponent,
      data: {
        authorities: [Authority.USER],
        pageTitle: 'sidebar.myActivities.main',
        animation: 'HomeComponent'
      }
    },
    {
      path: 'my-activities',
      component: HomeComponent,
      data: {
        authorities: [Authority.USER],
        pageTitle: 'sidebar.myActivities.main',
        animation: 'HomeComponent'
      }
    }
  ]

;
