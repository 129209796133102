<div class="btn-group" ngbDropdown [autoClose]="'outside'" role="group" container="body" placement="bottom-right"
     #myDrop="ngbDropdown">
	<button type="button" (click)="myDrop.toggle();" class="btn btn-default btn-sm no-after-content"
	        title="{{ 'entity.action.chooseColumns' | translate }}"
	        ngbDropdownAnchor>
		<fa-icon [fixedWidth]="true" icon="columns"></fa-icon>
		<ng-container *ngIf="showArrowIcon">
			&nbsp;
			<fa-icon [icon]="myDrop.isOpen() ? 'sort-up' : 'sort-down'"></fa-icon>
		</ng-container>
	</button>
	<div class="dropdown-menu entity-menu-dropdown-menu" ngbDropdownMenu>

		<div class="d-flex justify-content-center p-2">
			<button type="button" class="btn btn-default btn-sm mt-auto" (click)="saveCurrentStatus(); myDrop.close();"
			        [disabled]="isSaving || !isEdited">
				<fa-icon [fixedWidth]="true" icon="save"></fa-icon>
				&nbsp;
				<span jhiTranslate="entity.action.savePreferences"></span>
			</button>
		</div>

		<ng-container *ngFor="let column of columns; index as i">
			<div class="dropdown-divider"></div>
			<a class="dropdown-item entity-menu-dropdown-item-min"
			   (click)="columnsMap.set(column, !columnsMap.get(column)); setEditedToTrue();">
				<div class="custom-control custom-switch">
					<input type="checkbox" class="custom-control-input"
					       [checked]="columnsMap.get(column)" onclick="return false;"
					       onkeydown="return false;">
					<label class="custom-control-label">
						{{translateColumn(column)}}
					</label>
				</div>
			</a>
		</ng-container>

	</div>
</div>