import moment from 'moment';
import {Moment} from 'moment';
import {BaseEntity, IBaseEntity} from 'app/shared/model/root/base-entity.model';
import {Criteria} from 'app/shared/filter/criteria.model';
import {FormModel, IFormModel} from 'app/shared/model/form/form-model.model';

export interface IFormModelCategory extends IBaseEntity {
  formModel?: IFormModel;
  name?: string;
  description?: string;
  faIcon?: string;
  alertColor?: string;
  order?: number;
}

export class FormModelCategory extends BaseEntity implements IFormModelCategory {
  constructor(
    public id?: number,
    public uuid?: string,
    public createdBy?: string,
    public createdDate?: Moment,
    public lastModifiedBy?: string,
    public lastModifiedDate?: Moment,
    public active?: boolean,
    public formModel?: IFormModel,
    public name?: string,
    public description?: string,
    public faIcon?: string,
    public alertColor?: string,
    public order?: number,
    public _label?: string,
    public _edited?: boolean
  ) {
    super(id, uuid, createdBy, createdDate, lastModifiedBy, lastModifiedDate, active, _label, _edited);
  }

  public static criterias(): Criteria<FormModelCategory>[] {
    return Criteria.buildFrom(this.example());
  }

  public static example(): FormModelCategory {
    return new FormModelCategory(
      0,
      '',
      '',
      moment(new Date()),
      '',
      moment(new Date()),
      false,
      new FormModel(),
      '',
      '',
      '',
      '',
      0,
      '',
      false
    );
  }
}
