import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'ellipsis',
  pure: false
})
export class EllipsisPipe implements PipeTransform {
  transform(txt: string, size: number, singleLine = false): any {
    const sanitized = singleLine ? `${txt}`.replace(/\r?\n|\r/g, ' ').trim() : `${txt}`.trim();
    return sanitized.length > size ? `${sanitized.substring(0, size)}…` : sanitized;
  }
}
