import {Component, Input} from '@angular/core';
import {ButtonColor} from 'app/shared/layout/window/button.model';
import {Level} from 'app/shared/model/enumerations/level.enum';

@Component({
  selector: 'app-level-label',
  template: `
    <span *ngIf="level" class="badge" [ngClass]="'badge-' + color()">{{ 'level.' + level | translate }}</span>
  `
})
export class LevelLabelComponent {
  @Input()
  level: Level | null = null;

  color(): ButtonColor {
    if (!this.level) return 'default';

    switch (this.level) {
      case Level.NONE:
        return 'secondary';

      case Level.LOW:
        return 'success';

      case Level.MEDIUM:
        return 'primary';

      case Level.HIGH:
        return 'warning';

      case Level.SEVERE:
        return 'danger';

      default:
        return 'default';
    }
  }
}
