import {AfterViewInit, Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {UntypedFormBuilder, NgForm, Validators} from '@angular/forms';
import {MaskService} from 'app/shared/mask/mask.service';
import {Subscription, throwError} from 'rxjs';
import {unsubscribe} from 'app/shared/util/react-util';
import {BrValidator} from 'app/shared/util/br-validator';
import {CnpjService} from 'app/shared/services/company/cnpj.service';
import {SearchCnpjModalService} from 'app/shared/company/search-cnpj-modal.service';
import {CnpjOrigin} from 'app/shared/model/enumerations/cnpj-origin.enum';
import {catchError} from 'rxjs/operators';
import {Logger} from 'app/shared/util/logger';
import {StringUtilService} from 'app/shared/util/string-util.service';

const Log = new Logger('SearchCnpjModalComponent');

@Component({
  selector: 'app-com-search-cnpj-modal',
  templateUrl: './search-cnpj-modal.component.html'
})
export class SearchCnpjModalComponent implements OnInit, AfterViewInit, OnDestroy {
  origins = [CnpjOrigin.CNPJ_WS, CnpjOrigin.RECEITA_WS, CnpjOrigin.BRASIL_API];

  cnpjNotFound: boolean = false;

  inputCnpj?: string;

  editForm = this.fb.group({
    cnpj: [null, [BrValidator.validate, Validators.maxLength(18)]],
    origin: [null]
  });

  @ViewChild('form')
  form?: NgForm;

  confirm?: Function;

  cancel?: Function;

  private subscriptions: Subscription[] = [];

  constructor(
    private service: SearchCnpjModalService,
    private cnpjService: CnpjService,
    private fb: UntypedFormBuilder,
    public mask: MaskService
  ) {}

  ngOnInit(): void {}

  ngAfterViewInit(): void {
    setTimeout(() => {
      if (StringUtilService.hasText(this.inputCnpj)) {
        this.editForm.get(['cnpj']).setValue(this.inputCnpj);
        this.editForm.markAsDirty();
      }
    });
  }

  isInvalidAndTouched(field: string): boolean {
    const input = this.editForm.get(field);
    return input !== null && input.invalid && (input.dirty || input.touched);
  }

  canSubmit(): boolean {
    return this.editForm.valid && this.editForm.dirty;
  }

  onConfirmClicked(): void {
    this.cnpjNotFound = false;

    this.subscriptions.push(
      this.cnpjService
        .searchCnpj(this.editForm.get(['cnpj']).value, this.editForm.get(['origin']).value)
        .pipe(
          catchError(err => {
            this.cnpjNotFound = true;
            Log.error(() => `accept() => Couldn't get pre-signed url => ${JSON.stringify(err)}`);
            return throwError('Something bad happened; please try again later.');
          })
        )
        .subscribe(({body}) => {
          this.confirm && this.confirm(body);
          this.service.confirm();
        })
    );
  }

  onCancelClicked(): void {
    this.cancel && this.cancel();
    this.service.dismiss();
  }

  ngOnDestroy(): void {
    unsubscribe(this.subscriptions);
  }
}
