import {Injectable} from '@angular/core';
import {NgbModal, NgbModalRef} from '@ng-bootstrap/ng-bootstrap';
import {QuillViewModalComponent} from 'app/shared/quill/quill-view-modal.component';

@Injectable({providedIn: 'root'})
export class QuillViewModalService {
  private isOpen = false;
  private instance?: NgbModalRef;

  constructor(private modalService: NgbModal) {}

  show(content: string, title?: string, hasExportButton?: boolean, close?: Function): void {
    if (this.isOpen) return;

    this.isOpen = true;

    this.instance = this.modalService.open(QuillViewModalComponent, {size: 'lg', backdrop: 'static'});
    this.instance.componentInstance.content = content;
    this.instance.componentInstance.hasExportButton = hasExportButton;
    this.instance.componentInstance.title = title;
    this.instance.componentInstance.close = close;

    this.instance.result.finally(() => (this.isOpen = false));
  }

  close(): void {
    if (!this.isOpen || !this.instance) return;
    this.instance.dismiss();
  }
}
