// These constants are injected via webpack environment variables.
// You can add more variables in webpack.common.js or in profile specific webpack.<dev|prod>.js files.
// If you change the values in the webpack config files, you need to re run webpack to update the application

export const VERSION = process.env.__VERSION__;
export const DEBUG_INFO_ENABLED = Boolean(process.env.__DEBUG_INFO_ENABLED__);
export const I18N_HASH = process.env.I18N_HASH;
export const SERVER_API_URL = process.env.SERVER_API_URL;
export const NODE_ENV = process.env.NODE_ENV;
export const BUILD_TIMESTAMP = process.env.BUILD_TIMESTAMP;
export const CAPTCHA_KEY = process.env.CAPTCHA_KEY;
export const SERVERLESS_API_URL = process.env.SERVERLESS_API_URL;
export const SERVERLESS_API_KEY = process.env.SERVERLESS_API_KEY;
export const UPLOAD_BUCKET_URL = process.env.UPLOAD_BUCKET_URL;
