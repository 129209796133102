import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {IWidget} from 'app/shared/model/erp/widget.model';
import {unsubscribe} from 'app/shared/util/react-util';
import {Subscription} from 'rxjs';
import {WidgetDetailService} from 'app/shared/services/erp/widget-detail.service';
import {IWidgetDetail} from 'app/shared/model/erp/widget-detail.model';
import {finalize} from 'rxjs/operators';
import {Semaphore} from 'app/shared/model/enumerations/semaphore.enum';

@Component({
  selector: 'app-widget',
  templateUrl: './widget.component.html',
  styleUrls: ['./widget.component.scss']
})
export class WidgetComponent implements OnInit, OnDestroy {

  private subscriptions: Subscription[] = [];

  isLoading: boolean = false;

  @Input()
  widget: IWidget;

  widgetDetail: IWidgetDetail;

  constructor(private widgetDetailService: WidgetDetailService) {
  }

  ngOnInit(): void {
    this.loadWidgetDetail();
  }

  loadWidgetDetail() {
    this.isLoading = true;
    this.widget?.id && this.subscriptions.push(
      this.widgetDetailService.find(this.widget.id)
        .pipe(finalize(() => {
          this.isLoading = false;
        }))
        .subscribe(res => {
          this.widgetDetail = res?.body;
        })
    );
  }

  getSemaphoreClass(): string {
    switch (this.widgetDetail?.semaphore) {
      case Semaphore.GREEN:
        return 'semaphore-green';
      case Semaphore.YELLOW:
        return 'semaphore-yellow';
      case Semaphore.RED:
        return 'semaphore-red';
      default:
        return '';
    }
  }

  ngOnDestroy(): void {
    unsubscribe(this.subscriptions);
  }
}
