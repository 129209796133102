import {Component, Input, OnDestroy} from '@angular/core';
import {HttpErrorResponse} from '@angular/common/http';
import {JhiAlert, JhiAlertService, JhiEventManager, JhiEventWithContent} from 'ng-jhipster';
import {Subscription} from 'rxjs';
import {TranslateService} from '@ngx-translate/core';

import {AlertError} from './alert-error.model';
import {bounceInOnEnterAnimation} from 'angular-animations';
import {ErrorDetail} from 'app/shared/alert/error-detail.model';

@Component({
  selector: 'app-alert-error',
  animations: [bounceInOnEnterAnimation()],
  template: `
    <div class="alerts" role="alert">
      <div [@bounceInOnEnter] *ngFor="let alert of alerts" [ngClass]="setClasses(alert)">
        <ngb-alert *ngIf="alert && alert.type && alert.msg" [type]="alert.type" (close)="alert.close(alerts)">
          <span *ngIf="!alert.msg" jhiTranslate="error.alert"></span>
          <div *ngIf="alert.msg" [innerHTML]="alert.msg"></div>
        </ngb-alert>
      </div>
    </div>
  `
})
export class AlertErrorComponent implements OnDestroy {
  @Input()
  ignoreHttpCodes: number[] = [];

  alerts: JhiAlert[] = [];

  errorListener: Subscription;
  httpErrorListener: Subscription;

  constructor(
    private alertService: JhiAlertService,
    private eventManager: JhiEventManager,
    private translateService: TranslateService
  ) {
    this.errorListener = eventManager.subscribe('app.error', (response: JhiEventWithContent<AlertError>) => {
      const errorResponse = response.content;
      this.addErrorAlert(errorResponse.message, errorResponse.key, errorResponse.params);
    });

    this.httpErrorListener = eventManager.subscribe('app.httpError', (response: JhiEventWithContent<HttpErrorResponse>) => {
      const httpErrorResponse = response.content;

      if (this.ignoreHttpCodes.includes(httpErrorResponse.status)) {
        return;
      }

      const detail = {...httpErrorResponse.error} as ErrorDetail;
      if (detail.showAlert !== undefined && !detail.showAlert) return;

      switch (true) {
        case httpErrorResponse.status === 0:
          this.addErrorAlert('Server not reachable', 'error.server.not.reachable');
          break;
        case httpErrorResponse.status === 403:
          this.addErrorAlert('Access denied', 'error.access.denied');
          break;
        case httpErrorResponse.status === 404:
          this.addErrorAlert('Not found', 'error.url.not.found');
          break;
        case httpErrorResponse.status === 422:
          this.addErrorAlert(detail.message || httpErrorResponse.message);
          break;
        case httpErrorResponse.status === 503:
          this.addErrorAlert('Service unavailable', 'error.service.unavailable');
          break;
        default:
          this.parseErrorResponse(httpErrorResponse);
      }
    });
  }

  setClasses(alert: JhiAlert): {[key: string]: boolean} {
    const classes = {'jhi-toast': Boolean(alert.toast)};
    return alert.position ? {...classes, [alert.position]: true} : classes;
  }

  ngOnDestroy(): void {
    this.errorListener && this.eventManager.destroy(this.errorListener);
    this.httpErrorListener && this.eventManager.destroy(this.httpErrorListener);
  }

  parseErrorResponse(httpError: HttpErrorResponse): void {
    const error = {
      cause: (() => {
        let errors = '';
        if (httpError.error && httpError.error.errors && httpError.error.errors.length) {
          httpError.error.errors.forEach((e: any) => (errors += `[${e.code}] ${e.field} ${e.defaultMessage}<br>`));
        }
        return errors.length ? errors : null;
      })(),
      message: (() => {
        const msg =
          (httpError.error && httpError.error.message
            ? `${httpError.error.message}`
            : (httpError.error && httpError.error.message)) + '';
        return msg.length ? msg : null;
      })(),
      status: (httpError.error && httpError.error.status) || httpError.status + ' ' + httpError.statusText,
      path: (httpError.error && httpError.error.path) || httpError.url,
      method: httpError.error && httpError.error.method,
      timestamp: httpError.error && httpError.error.timestamp,
      trace: (httpError.error && httpError.error.trace) || httpError.error.stackTrace,
      title: (httpError.error && httpError.error.title) || httpError.error.title,
      errorUuid: (httpError.error && httpError.error.errorUuid) || httpError.error.errorUuid
    };

    this.addErrorAlert(`
        ${error.title ? `<span>${this.translateService.instant('errorCode.' + error.title, {code: error.errorUuid})}</span>` : ''}
        <div class="alert-error-details">
          ${error.message ? `<span>${error.message}</span>` : ''}
        </div>`);
  }

  addErrorAlert(message: string, key?: string, data?: any): void {
    const newAlert: JhiAlert = {
      type: 'danger',
      msg: key || message,
      params: data,
      timeout: 500000,
      toast: this.alertService.isToast(),
      scoped: true,
      position: 'top'
    };

    this.alerts.push(this.alertService.addAlert(newAlert, this.alerts));
  }
}
