import {Routes} from '@angular/router';

export const securityRoutes: Routes = [
  {
    path: 'sec/roles',
    loadChildren: () => import('./role/role.module').then(m => m.AppRoleModule),
    data: {
      pageTitle: 'role.home.title'
    }
  }
];
