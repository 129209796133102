import {Component, Input} from '@angular/core';
import {MaskService} from 'app/shared/mask/mask.service';
import {IFormAnswerOption} from 'app/shared/model/form/form-answer-option.model';
import {FormAnswerOptionDetailModalService} from 'app/entities/form/form/form-answer-option-detail-modal.service';
import {IFormModelQuestionOption} from 'app/shared/model/form/form-model-question-option.model';
import {FormModelActionsService} from 'app/entities/form/form-model/form-model-actions.service';

@Component({
  selector: 'app-frm-form-answer-option-detail-modal',
  templateUrl: './form-answer-option-detail-modal.component.html'
})
export class FormAnswerOptionDetailModalComponent {
  @Input()
  formAnswerOption!: IFormAnswerOption;

  close?: Function;

  constructor(
    private service: FormAnswerOptionDetailModalService,
    private formModelActionsService: FormModelActionsService,
    public mask: MaskService
  ) {}

  showFormModelQuestionOptionDetails(option: IFormModelQuestionOption): void {
    this.formModelActionsService.showFormModelQuestionOptionDetails(option);
  }

  onCloseClicked(): void {
    this.close && this.close();
    this.service.close();
  }
}
