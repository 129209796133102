import {Component, Input} from '@angular/core';
import {ButtonColor} from 'app/shared/layout/window/button.model';
import {Approval} from 'app/shared/model/enumerations/approval.enum';

@Component({
  selector: 'app-approval-label',
  template: `
    <span *ngIf="value" class="badge" [ngClass]="'badge-' + color()">{{ 'approval.' + value | translate }}</span>
  `
})
export class ApprovalLabelComponent {
  @Input()
  value: Approval | null = null;

  color(): ButtonColor {
    if (!this.value) return 'default';

    switch (this.value) {
      case Approval.PENDING:
        return 'warning';
      case Approval.APPROVED:
        return 'success';
      case Approval.REFUSED:
        return 'danger';
      default:
        return 'default';
    }
  }
}
