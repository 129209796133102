import {Component, ElementRef, forwardRef, Input, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {IBaseEntityTreeNode} from 'app/shared/prime-ng/base-entity-tree-node';
import {IBaseEntity} from 'app/shared/model/root/base-entity.model';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';
import {BehaviorSubject, Subject, Subscription} from 'rxjs';
import {unsubscribe} from 'app/shared/util/react-util';

export const CUSTOM_INPUT_CONTROL_VALUE_ACCESSOR: any = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => TreeSelectComponent),
  multi: true
};

@Component({
  selector: 'app-tree-select',
  providers: [CUSTOM_INPUT_CONTROL_VALUE_ACCESSOR],
  styleUrls: ['tree-select.component.scss'],
  template: `
    <p-treeSelect #selector class="form-control"
                  [options]="tree"
                  [showClear]="showClear"
                  [ngModel]="selectedNode"
                  (ngModelChange)="selectedItemChanged($event)"
                  [filter]="true"
                  [emptyMessage]="'global.empty.list.alt' | translate"
                  (onNodeSelect)="onNodeSelect($event)"
                  [ngModelOptions]="{standalone: true}"></p-treeSelect>
  `
})
export class TreeSelectComponent<T extends IBaseEntity> implements OnInit, ControlValueAccessor, OnDestroy {

  private _values: BehaviorSubject<T[]> = new BehaviorSubject<T[]>([]);

  private _tree: BehaviorSubject<IBaseEntityTreeNode<T>[]> = new BehaviorSubject<IBaseEntityTreeNode<T>[]>([]);

  private _itemChanged: Subject<any> = new Subject<any>();

  private _hashTable: Map<string, IBaseEntityTreeNode<T>> = new Map<string, IBaseEntityTreeNode<T>>();

  @Input()
  selectedNode: IBaseEntityTreeNode<T> | null;

  @Input()
  showClear: boolean = false;

  @Input()
  expanded: boolean = false;

  @Input()
  parentFieldName: keyof T;

  @ViewChild('selector')
  selector: ElementRef;

  @Input()
  selfNodeUuid: string | null;

  private subscriptions: Subscription[] = [];

  ngOnInit(): void {
    this.subscriptions.push(
      this._itemChanged.pipe().subscribe(item => {
        this.selectedNode = item;
        this.propagateChange(this.selectedNode?.data);
      }),
      this._values.pipe().subscribe(values => {
        this.buildTree(values);
      })
    );
  }

  @Input()
  get values(): T[] {
    return this._values.getValue();
  }

  set values(items: T[]) {
    this._values.next(items);
  }

  get tree(): IBaseEntityTreeNode<T>[] {
    return this._tree.getValue();
  }

  set tree(items: IBaseEntityTreeNode<T>[]) {
    this._tree.next(items);
  }

  private buildTree(items: T[]): void {

    this._hashTable.clear();

    items.forEach(item => this._hashTable.set(item.uuid, ({
      key: item.uuid,
      label: item._label,
      data: item,
      selectable: true,
      expanded: this.expanded,
      collapsedIcon: 'pi pi-folder',
      expandedIcon: 'pi pi-folder-open',
      children: []
    } as IBaseEntityTreeNode<T>)));

    const treeNodes = [];

    items.forEach(item => {
      const parent = item[this.parentFieldName] as IBaseEntity;

      if (parent?.uuid) {
        this._hashTable.get(parent?.uuid)?.children.push(this._hashTable.get(item.uuid));
      } else {
        treeNodes.push(this._hashTable.get(item.uuid));
      }
    });

    const disableRecursively = (node: IBaseEntityTreeNode<T>) => {
      node.selectable = false;
      node.icon = 'pi pi-ban';
      node.styleClass = 'not-selectable';
      node.children?.forEach(child => disableRecursively(child));
    };

    if (this.selfNodeUuid?.length) {
      const selfNode = this._hashTable.get(this.selfNodeUuid);
      selfNode && disableRecursively(selfNode);
    }

    this.tree = treeNodes;
  };

  // propagate changes into the custom form control
  propagateChange = (_: any) => {
  };

  // propagate touches into the custom form control
  propagateTouch = () => {
  };

  selectedItemChanged(item: IBaseEntityTreeNode<T> | null) {
    this._itemChanged.next(item);
  }

  // From ControlValueAccessor interface
  registerOnChange(fn: any) {
    this.propagateChange = fn;
  }

  // From ControlValueAccessor interface
  registerOnTouched(fn: () => void) {
    this.propagateTouch = fn;
  }

  // From ControlValueAccessor interface
  setDisabledState(isDisabled: boolean) {
    (<any>this.selector)?.setDisabledState(isDisabled);
  }

  // From ControlValueAccessor interface
  writeValue(value: T | null): void {
    this.subscriptions.push(
      this._tree.subscribe(() => {
        this.selectedNode = value?.uuid ? this._hashTable.get(value.uuid) : null;
      })
    );
  }

  onNodeSelect($event: any): void {
    // Log.debug(() => `${$event}`);

    // const newSelected = $event['node'] as IBaseEntityTreeNode<T>;

    // if (this.selectedNode?.data?.uuid === newSelected?.data?.uuid) {
    //   (<any>this.selector)?.onSelect(null);
    // }
  }

  ngOnDestroy(): void {
    unsubscribe(this.subscriptions);
  }
}