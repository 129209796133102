import {Routes} from '@angular/router';

export const paymentRoutes: Routes = [
  {
    path: 'pay/gateways',
    loadChildren: () => import('./gateway/gateway.module').then(m => m.AppGatewayModule),
    data: {
      pageTitle: 'gateway.home.title'
    }
  },
  {
    path: 'pay/artifacts',
    loadChildren: () => import('./artifact/artifact.module').then(m => m.AppArtifactModule),
    data: {
      pageTitle: 'artifact.home.title'
    }
  },
  {
    path: 'pay/coupons',
    loadChildren: () => import('./coupon/coupon.module').then(m => m.AppCouponModule),
    data: {
      pageTitle: 'artifact.home.title'
    }
  },
  {
    path: 'pay/cards',
    loadChildren: () => import('./card/card.module').then(m => m.AppCardModule),
    data: {
      pageTitle: 'card.home.title'
    }
  },
  {
    path: 'pay/billets',
    loadChildren: () => import('./billet/billet.module').then(m => m.AppBilletModule),
    data: {
      pageTitle: 'billet.home.title'
    }
  },
  {
    path: 'pay/pix',
    loadChildren: () => import('./pix/pix.module').then(m => m.AppPixModule),
    data: {
      pageTitle: 'pix.home.title'
    }
  },
  {
    path: 'pay/charges',
    loadChildren: () => import('./charge/charge.module').then(m => m.AppChargeModule),
    data: {
      pageTitle: 'charge.home.title'
    }
  },
  {
    path: 'pay/customers',
    loadChildren: () => import('./customer/customer.module').then(m => m.AppCustomerModule),
    data: {
      pageTitle: 'customer.home.title'
    }
  },
  {
    path: 'pay/orders',
    loadChildren: () => import('./order/order.module').then(m => m.AppOrderModule),
    data: {
      pageTitle: 'order.home.title'
    }
  },
  {
    path: 'pay/transactions',
    loadChildren: () => import('./transaction/transaction.module').then(m => m.AppTransactionModule),
    data: {
      pageTitle: 'transaction.home.title'
    }
  },
  {
    path: 'pay/webhooks',
    loadChildren: () => import('./webhook/webhook.module').then(m => m.AppWebhookModule),
    data: {
      pageTitle: 'webhook.home.title'
    }
  }
];
