import {Injectable, OnDestroy} from '@angular/core';
import {Subscription} from 'rxjs';
import {unsubscribe} from 'app/shared/util/react-util';

@Injectable()
export class SidebarService implements OnDestroy {
  private subscriptions: Subscription[] = [];

  constructor() {}

  ngOnDestroy(): void {
    unsubscribe(this.subscriptions);
  }
}
