import {Component, Input} from '@angular/core';
import {ButtonColor} from 'app/shared/layout/window/button.model';
import {LanguageType, LanguageUtil} from 'app/shared/util/language-util';

@Component({
  selector: 'app-language-label',
  template: `
    <span *ngIf="langKey && isValid()" class="badge" [ngClass]="'badge-' + color()">
      <i class="fi {{ getLang().icon }}"></i>
      {{ getLang().name }}
    </span>
  `
})
export class LanguageLabelComponent {
  @Input()
  langKey: string | null = null;

  isValid(): boolean {
    return LanguageUtil.isValid(this.langKey);
  }

  getLang(): LanguageType {
    return LanguageUtil.get(this.langKey);
  }

  color(): ButtonColor {
    return this.getLang()?.color ?? 'default';
  }
}
