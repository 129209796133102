export enum PaymentMethodType {
  NOT_INFORMED = 'NOT_INFORMED',
  BANK_SLIP = 'BANK_SLIP',
  CREDIT_CARD = 'CREDIT_CARD',
  DEBIT_CARD = 'DEBIT_CARD',
  VOUCHER = 'VOUCHER',
  PIX = 'PIX'
}

export const allPaymentMethodTypes = () => Object.values(PaymentMethodType);

export function getPaymentIcon(type?: string): string {
  switch (type || '') {
    case 'PIX':
      return '/assets/images/payment-types/pix.svg';
    case 'CREDIT_CARD':
      return '/assets/images/credit-card/flat/generic.svg';
    case 'BANK_SLIP':
      return '/assets/images/payment-types/boleto.svg';
    case 'DEBIT_CARD':
      return '/assets/images/credit-card/flat/generic.svg';
    default:
      return '/assets/images/credit-card/flat/generic.svg';
  }
}
