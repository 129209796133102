import moment from 'moment';
import {Moment} from 'moment';
import {BaseEntity, IBaseEntity} from 'app/shared/model/root/base-entity.model';
import {Criteria} from 'app/shared/filter/criteria.model';
import {IUser, User} from 'app/core/user/user.model';
import {Status} from 'app/shared/model/enumerations/status.enum';
import {Gateway, IGateway} from 'app/shared/model/payment/gateway.model';
import {ILocation, Location} from 'app/shared/model/location/location.model';
import {PaymentMethodType} from 'app/shared/model/enumerations/payment-method-type.enum';
import {CardBrand} from 'app/shared/model/enumerations/card-brand.enum';
import {Artifact, IArtifact} from 'app/shared/model/payment/artifact.model';
import {Customer, ICustomer} from 'app/shared/model/payment/customer.model';
import {Company, ICompany} from 'app/shared/model/company/company.model';

export interface ICard extends IBaseEntity {
  company?: ICompany;
  gateway?: IGateway;
  user?: IUser;
  status?: Status;
  main?: boolean;
  type?: PaymentMethodType;
  brand?: CardBrand;
  currentlyActive?: boolean;
  firstSixDigits?: string;
  lastFourDigits?: string;
  holderName?: string;
  holderDocument?: string;
  expirationMonth?: number;
  expirationYear?: number;
  billingAddress?: ILocation;
  gatewayType?: string;
  label?: string;
  customer?: ICustomer;
  artifact?: IArtifact;
  sha256Hash?: string;
  json?: string;
  _number?: string;
  _cvv?: string;
}

export class Card extends BaseEntity implements ICard {
  constructor(
    public id?: number,
    public uuid?: string,
    public createdBy?: string,
    public createdDate?: Moment,
    public lastModifiedBy?: string,
    public lastModifiedDate?: Moment,
    public active?: boolean,
    public company?: ICompany,
    public gateway?: IGateway,
    public user?: IUser,
    public status?: Status,
    public main?: boolean,
    public type?: PaymentMethodType,
    public brand?: CardBrand,
    public firstSixDigits?: string,
    public lastFourDigits?: string,
    public holderName?: string,
    public holderDocument?: string,
    public expirationMonth?: number,
    public expirationYear?: number,
    public billingAddress?: ILocation,
    public gatewayType?: string,
    public label?: string,
    public customer?: ICustomer,
    public artifact?: IArtifact,
    public json?: string,
    public sha256Hash?: string,
    public _number?: string,
    public _cvv?: string,
    public _label?: string,
    public _edited?: boolean
  ) {
    super(id, uuid, createdBy, createdDate, lastModifiedBy, lastModifiedDate, active, _label, _edited);
  }

  public static allowedStatus(): Status[] {
    return [Status.ACTIVE, Status.DELETED, Status.EXPIRED];
  }

  public static allowedPaymentMethodTypes(): PaymentMethodType[] {
    return [PaymentMethodType.CREDIT_CARD];
  }

  public static allowedCardBrands(): CardBrand[] {
    return [CardBrand.MASTERCARD, CardBrand.VISA, CardBrand.UNKNOWN];
  }

  public static criterias(): Criteria<Card>[] {
    return Criteria.buildFrom(this.example())
      .filter(criteria => !['billingAddress', '_number', '_cvv'].includes(criteria.property.name))
      .map(criteria =>
        criteria.property.name === 'type'
          ? criteria.asEnum(() => this.allowedPaymentMethodTypes(), 'paymentMethodType')
          : criteria.property.name === 'brand'
          ? criteria.asEnum(() => this.allowedCardBrands(), 'cardBrand')
          : criteria.property.name === 'status'
          ? criteria.asEnum(() => this.allowedStatus())
          : criteria
      );
  }

  public static example(): Card {
    return new Card(
      0,
      '',
      '',
      moment(new Date()),
      '',
      moment(new Date()),
      false,
      new Company(),
      new Gateway(),
      new User(),
      Status.ACTIVE,
      false,
      PaymentMethodType.NOT_INFORMED,
      CardBrand.NOT_APPLICABLE,
      '',
      '',
      '',
      '',
      1,
      1,
      new Location(),
      '',
      '',
      new Customer(),
      new Artifact(),
      '',
      '',
      '',
      '',
      '',
      false
    );
  }

  static getMaskedCardNumber(card: ICard): string {
    return `${card.firstSixDigits.slice(0, 4)} xxxx xxxx ${card.lastFourDigits}`;
  }
}
