import {Routes} from '@angular/router';

export const userRoutes: Routes = [
  {
    path: 'usr/users',
    loadChildren: () => import('./user/user.module').then(m => m.UserModule),
    data: {
      pageTitle: 'user.home.title'
    }
  }
];
