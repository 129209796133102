import {Component, Input, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {ILocation, Location} from 'app/shared/model/location/location.model';
import {LocationEditorModalService} from 'app/shared/location/location-editor-modal.service';
import {UntypedFormBuilder, NgForm, Validators} from '@angular/forms';
import {MaskService} from 'app/shared/mask/mask.service';
import {HttpResponse} from '@angular/common/http';
import {Subscription, throwError} from 'rxjs';
import {unsubscribe} from 'app/shared/util/react-util';
import {ICity} from 'app/shared/model/location/city.model';
import {CityService} from 'app/shared/services/location/city.service';
import {LocationService} from 'app/shared/services/location/location.service';
import {catchError, map, switchMap} from 'rxjs/operators';
import {Logger} from 'app/shared/util/logger';
import {IState} from 'app/shared/model/location/state.model';
import {StateService} from 'app/shared/services/location/state.service';

const Log = new Logger('LocationEditorModalComponent');

@Component({
  selector: 'app-loc-location-editor-modal',
  templateUrl: './location-editor-modal.component.html'
})
export class LocationEditorModalComponent implements OnInit, OnDestroy {
  @Input()
  entity!: ILocation;

  outerEntity!: ILocation;

  cities: ICity[] = [];
  states: IState[] = [];
  zipCodeNotFound: boolean = false;

  editForm = this.fb.group({
    address: ['', [Validators.required, Validators.minLength(1), Validators.maxLength(255)]],
    number: [null, [Validators.maxLength(10)]],
    neighborhood: [null, [Validators.maxLength(255)]],
    complement: [null, [Validators.maxLength(255)]],
    zipCode: [null, [Validators.maxLength(9)]],
    city: [null, Validators.required],
    state: [null, Validators.required]
  });

  @ViewChild('form')
  form?: NgForm;

  confirm?: Function;

  cancel?: Function;

  private subscriptions: Subscription[] = [];

  constructor(
    private service: LocationEditorModalService,
    private cityService: CityService,
    private stateService: StateService,
    private fb: UntypedFormBuilder,
    public mask: MaskService,
    private locationService: LocationService
  ) {}

  ngOnInit(): void {
    this.initOuterEntity(this.entity);
    this.subscriptions.push(
      this.cityService
        .query({page: 0, size: 100})
        .pipe(
          map((res: HttpResponse<ICity[]>) => (this.cities = res.body || [])),
          switchMap(() => this.stateService.query({'active.equals': true, size: 1000}))
        )
        .subscribe((res: HttpResponse<IState[]>) => {
          this.states = res.body || [];
          this.updateForm(this.outerEntity);
          this.registerInputChanges();
        })
    );
  }

  private registerInputChanges(): void {
    const zipCodeField = this.editForm.get(['zipCode'])!;
    const stateField = this.editForm.get(['state'])!;

    this.subscriptions.push(
      zipCodeField.valueChanges.subscribe(zipCode => {
        this.handleZipCode(zipCode);
      }),
      stateField.valueChanges.subscribe((state: IState) => {
        state && state.id ? this.cityService.getByState(state).subscribe(i => (this.cities = i.body || [])) : (this.cities = []);
      })
    );
  }

  private initOuterEntity(location: Location): void {
    this.outerEntity = new Location();
    this.outerEntity.address = location.address;
    this.outerEntity.number = location.number;
    this.outerEntity.neighborhood = location.neighborhood;
    this.outerEntity.complement = location.complement;
    this.outerEntity.zipCode = location.zipCode;
    this.outerEntity.city = location.city;
    // this.outerEntity.state = location.state;
  }

  private updateForm(location: ILocation): void {
    this.editForm.patchValue({
      address: location.address,
      number: location.number,
      neighborhood: location.neighborhood,
      complement: location.complement,
      zipCode: location.zipCode,
      city: location.city,
      state: location.city && location.city.state
    });
  }

  private updateLocation(location: ILocation): void {
    location.address = this.editForm.get(['address'])!.value;
    location.number = this.editForm.get(['number'])!.value;
    location.neighborhood = this.editForm.get(['neighborhood'])!.value;
    location.complement = this.editForm.get(['complement'])!.value;
    location.zipCode = this.editForm.get(['zipCode'])!.value;
    location.city = this.editForm.get(['city'])!.value;
    // location.state = this.editForm.get(['state'])!.value;
    location._edited = true;
  }

  handleZipCode(zipCode: number): any {
    this.zipCodeNotFound = false;
    `${zipCode}`.length === 8
      ? this.locationService
          .searchZipCode(zipCode)
          .pipe(
            catchError(err => {
              this.zipCodeNotFound = true;
              Log.error(() => `accept() => Couldn't get pre-signed url => ${JSON.stringify(err)}`);
              return throwError('Something bad happened; please try again later.');
            })
          )
          .subscribe(res => {
            const location = res.body;
            location.address ? this.editForm.get(['address']).setValue(location.address) : this.editForm.get(['address']).setValue(null);
            location.neighborhood
              ? this.editForm.get(['neighborhood']).setValue(location.neighborhood)
              : this.editForm.get(['neighborhood']).setValue(null);
            this.editForm.get(['city']).setValue(location.city);
            this.editForm.get(['state']).setValue(location.city?.state);

            location.address
              ? this.editForm.get(['address'])!.disable({emitEvent: false})
              : this.editForm.get(['address'])!.enable({emitEvent: false});
            location.neighborhood
              ? this.editForm.get(['neighborhood'])!.disable({emitEvent: false})
              : this.editForm.get(['neighborhood'])!.enable({emitEvent: false});
            this.editForm.get(['city'])!.disable({emitEvent: false});
            this.editForm.get(['state'])!.disable({emitEvent: false});
          })
      : this.clearAddressField();
  }

  clearAddressField(): void {
    this.editForm.get(['address']).setValue(null);
    this.editForm.get(['neighborhood']).setValue(null);
    this.editForm.get(['city']).setValue(null);
    this.editForm.get(['address'])!.enable({emitEvent: false});
    this.editForm.get(['neighborhood'])!.enable({emitEvent: false});
    this.editForm.get(['city'])!.enable({emitEvent: false});
    this.editForm.get(['state'])!.enable({emitEvent: false});
  }

  isInvalidAndTouched(field: string): boolean {
    const input = this.editForm.get(field);
    return input !== null && input.invalid && (input.dirty || input.touched);
  }

  canSubmit(): boolean {
    return this.editForm.valid && this.editForm.dirty;
  }

  onConfirmClicked(): void {
    this.updateLocation(this.outerEntity);
    this.confirm && this.confirm(this.outerEntity);
    this.service.confirm();
  }

  onCancelClicked(): void {
    this.cancel && this.cancel();
    this.service.dismiss();
  }

  ngOnDestroy(): void {
    unsubscribe(this.subscriptions);
  }
}
