export enum FormQuestionType {
  YES_NO = 'YES_NO',
  SINGLE_CHOICE = 'SINGLE_CHOICE',
  MULTIPLE_CHOICE = 'MULTIPLE_CHOICE',
  SINGLE_LINE_TEXT = 'SINGLE_LINE_TEXT',
  MULTILINE_TEXT = 'MULTILINE_TEXT',
  EMAIL = 'EMAIL',
  URL = 'URL',
  CPF = 'CPF',
  CNPJ = 'CNPJ',
  CPF_CNPJ = 'CPF_CNPJ',
  CEP = 'CEP',
  PHONE = 'PHONE',
  CURRENCY = 'CURRENCY',
  UUID = 'UUID',
  IP = 'IP',
  INTEGER_NUMBER = 'INTEGER_NUMBER',
  DECIMAL_NUMBER = 'DECIMAL_NUMBER',
  DATE = 'DATE',
  TIME = 'TIME',
  DOC_UPLOAD = 'DOC_UPLOAD',
  IMAGE_UPLOAD = 'IMAGE_UPLOAD'
}

export const allFormQuestionTypes = () => Object.values(FormQuestionType);
