import moment, {Moment} from 'moment';
import {Status} from 'app/shared/model/enumerations/status.enum';
import {BaseEntity, IBaseEntity} from 'app/shared/model/root/base-entity.model';
import {Criteria} from 'app/shared/filter/criteria.model';
import {IPlan, Plan} from 'app/shared/model/admin/plan.model';
import {File} from 'app/shared/model/admin/file.model';
import {ILocation, Location} from 'app/shared/model/location/location.model';
import {IChangeLog} from 'app/shared/model/admin/audit/change-log.model';
import {ICompanyContactInfo} from 'app/shared/model/company/company-contact-info.model';
import {IPost, Post} from 'app/shared/model/content/post.model';

export interface ICompany extends IBaseEntity {
  plan?: IPlan;
  name?: string;
  corporateName?: string;
  cnpj?: string;
  phone?: string;
  email?: string;
  website?: string;
  status?: Status;
  logo?: File;
  location?: ILocation;
  contactInfos?: ICompanyContactInfo[];
  changeLogs?: IChangeLog[];
  termsOfUse?: IPost;
  privacyPolicy?: IPost;
}

export class Company extends BaseEntity implements ICompany {
  constructor(
    public id?: number,
    public uuid?: string,
    public createdBy?: string,
    public createdDate?: Moment,
    public lastModifiedBy?: string,
    public lastModifiedDate?: Moment,
    public active?: boolean,
    public plan?: IPlan,
    public name?: string,
    public corporateName?: string,
    public cnpj?: string,
    public phone?: string,
    public email?: string,
    public website?: string,
    public status?: Status,
    public logo?: File,
    public location?: ILocation,
    public contactInfos?: ICompanyContactInfo[],
    public changeLogs?: IChangeLog[],
    public termsOfUse?: IPost,
    public privacyPolicy?: IPost,
    public _label?: string,
    public _edited?: boolean
  ) {
    super(id, uuid, createdBy, createdDate, lastModifiedBy, lastModifiedDate, active, _label, _edited);
  }

  public static allowedStatus(): Status[] {
    return [Status.DRAFT, Status.TRIAL, Status.PRODUCTION, Status.FINISHED, Status.CANCELED];
  }

  public static criterias(): Criteria<Company>[] {
    return Criteria.buildFrom(this.example())
      .filter(criteria => !['location', 'contactInfos', 'changeLogs'].includes(criteria.property.name))
      .map(criteria => criteria.property.name === 'status'
        ? criteria.asEnum(() => this.allowedStatus())
        : criteria.property.name === 'logo'
          ? criteria.asFilled()
          : criteria);
  }

  public static example(): Company {
    return new Company(
      0,
      '',
      '',
      moment(new Date()),
      '',
      moment(new Date()),
      false,
      new Plan(),
      '',
      '',
      '',
      '',
      '',
      '',
      Status.DRAFT,
      new File(),
      new Location(),
      [],
      [],
      new Post(),
      new Post(),
      '',
      false
    );
  }
}
