export enum Semaphore {
  GREEN = 'GREEN',
  YELLOW = 'YELLOW',
  RED = 'RED'
}

export const allSemaphores = () => Object.values(Semaphore);

export function thirdPartyEntityValue(value: Semaphore): string {
  switch (value) {
    case Semaphore.GREEN:
      return 'G';
    case Semaphore.YELLOW:
      return 'Y';
    case Semaphore.RED:
      return 'R';
    default:
      return null;
  }
}
