import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {SessionStorageService} from 'ngx-webstorage';

import {VERSION} from 'app/app.constants';
import {AccountService} from 'app/core/auth/account.service';
import {LoginService} from 'app/core/login/login.service';
import {ProfileService} from 'app/layouts/profiles/profile.service';
import {PageService} from 'app/shared/layout/window/page.service';
import {Page} from 'app/shared/layout/window/page.model';
import {Subscription} from 'rxjs';
import {delay} from 'rxjs/operators';
import {unsubscribe} from 'app/shared/util/react-util';
import {UntypedFormBuilder, NgForm} from '@angular/forms';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['navbar.scss']
})
export class NavbarComponent implements OnInit, OnDestroy {
  inProduction?: boolean;

  swaggerEnabled?: boolean;

  version: string;

  page?: Page | null;

  @ViewChild('filterForm')
  form?: NgForm;

  searchForm = this.fb.group({
    filter: []
  });

  private sidebarVisibility = true;

  private subscriptions: Subscription[] = [];

  constructor(
    private loginService: LoginService,
    private sessionStorage: SessionStorageService,
    private accountService: AccountService,
    private profileService: ProfileService,
    private fb: UntypedFormBuilder,
    private pageService: PageService
  ) {
    this.version = VERSION ? (VERSION.toLowerCase().startsWith('v') ? VERSION : 'v' + VERSION) : '';
  }

  ngOnInit(): void {
    this.subscriptions.push(
      this.profileService.getProfileInfo().subscribe(profileInfo => {
        this.inProduction = profileInfo.inProduction;
        this.swaggerEnabled = profileInfo.swaggerEnabled;
      }),
      this.sessionStorage.observe('sidebarVisibility').subscribe((value: any) => (this.sidebarVisibility = !!value)),
      this.pageService
        .get()
        .pipe(delay(0))
        .subscribe(page => (this.page = page))
    );
  }

  isAuthenticated(): boolean {
    return this.accountService.isAuthenticated();
  }

  toggleSidebar(): void {
    const pref = this.sessionStorage.retrieve('sidebarVisibility');
    this.sidebarVisibility = pref === null ? true : !!pref;
    this.sessionStorage.store('sidebarVisibility', !this.sidebarVisibility);
  }

  isSidebarVisible = (): boolean => this.sidebarVisibility;

  search(): void {
    const filterValue = this.searchForm.get(['filter'])!.value;
    this.page.onFilterSubmit && this.page.onFilterSubmit(filterValue);
  }

  ngOnDestroy(): void {
    unsubscribe(this.subscriptions);
  }
}
