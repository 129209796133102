import {Component, OnDestroy, OnInit} from '@angular/core';
import {JhiAlert, JhiAlertService} from 'ng-jhipster';
import {slideInDownOnEnterAnimation} from 'angular-animations';

@Component({
  selector: 'app-alert',
  animations: [slideInDownOnEnterAnimation()],
  template: `
    <div class="alerts" role="alert">
      <div [@slideInDownOnEnter] *ngFor="let alert of alerts" [ngClass]="setClasses(alert)">
        <ngb-alert *ngIf="alert && alert.type && alert.msg" [type]="alert.type" (close)="alert.close(alerts)">
          <pre [innerHTML]="alert.msg"></pre>
        </ngb-alert>
      </div>
    </div>
  `
})
export class AlertComponent implements OnInit, OnDestroy {
  alerts: JhiAlert[] = [];

  constructor(private alertService: JhiAlertService) {}

  ngOnInit(): void {
    this.alerts = this.alertService.get();
  }

  setClasses(alert: JhiAlert): {[key: string]: boolean} {
    const classes = {'jhi-toast': Boolean(alert.toast)};
    return alert.position ? {...classes, [alert.position]: true} : classes;
  }

  ngOnDestroy(): void {
    this.alertService.clear();
  }
}
