import {Injectable, OnDestroy} from '@angular/core';
import {Router} from '@angular/router';
import {HttpClient, HttpResponse} from '@angular/common/http';
import {SessionStorageService} from 'ngx-webstorage';
import {Observable, of, ReplaySubject, Subscription} from 'rxjs';
import {catchError, map, shareReplay, tap} from 'rxjs/operators';
import {StateStorageService} from 'app/core/auth/state-storage.service';

import {SERVER_API_URL} from 'app/app.constants';
import {Account} from 'app/core/user/account.model';
import {IUserAuthority} from 'app/core/user/user-authority.model';
import {Authority} from 'app/shared/model/enumerations/authority.enum';
import {FeatureAuthority} from 'app/shared/model/enumerations/feature-authority.enum';
import {ICompany} from 'app/shared/model/company/company.model';
import {unsubscribe} from 'app/shared/util/react-util';
import {UserService} from 'app/core/user/user.service';
import {LanguageUtil} from 'app/shared/util/language-util';
import {InactiveLogoutService} from 'app/inactive-logout.service';
import {createRequestOption} from 'app/shared/util/request-util';

@Injectable({providedIn: 'root'})
export class AccountService implements OnDestroy {
  private userIdentity: Account | null = null;
  private authenticationState = new ReplaySubject<Account | null>(1);
  private accountCache$?: Observable<Account | null>;
  private subscriptions: Subscription[] = [];

  constructor(
    private languageUtil: LanguageUtil,
    private sessionStorage: SessionStorageService,
    private http: HttpClient,
    private stateStorageService: StateStorageService,
    private router: Router,
    private userService: UserService,
    private inactiveLogoutService: InactiveLogoutService
  ) {
  }

  save(account: Account, isFromPortal: boolean): Observable<{}> {
    const options = createRequestOption({
      portal: isFromPortal
    });
    return this.http.post(`${SERVER_API_URL}api/account`, account, {
      params: options,
      observe: 'response'
    });
  }

  confirmEmail(code: string): Observable<HttpResponse<{}>> {
    return this.http.post(`${SERVER_API_URL}api/account/${code}/confirm-email`, null, {observe: 'response'});
  }

  sendEmailActivationCode(): Observable<HttpResponse<{}>> {
    return this.http.post(`${SERVER_API_URL}api/account/send-activation-code-email`, null, {observe: 'response'});
  }

  authenticate(identity: Account | null): void {
    this.userIdentity = identity;
    this.authenticationState.next(this.userIdentity);
  }

  hasAnyAuthority(authoritiesToTest: Authority[] | Authority | FeatureAuthority[] | FeatureAuthority | string[] | string): boolean {
    if (!this.userIdentity || !this.userIdentity.authorities) {
      return false;
    }

    const authorities = Array.isArray(authoritiesToTest) ? authoritiesToTest : [authoritiesToTest];
    const userAuthorities = this.userIdentity.authorities.map((authority: IUserAuthority) => authority.code);

    const parseUserAuthority = (userAuthority: string): string => {

      const parts = userAuthority.split('#');

      let userAuthorityWithoutPrefixes = parts[parts.length - 1];

      const authorityPrefix = 'AUTHORITY_';

      if (userAuthorityWithoutPrefixes.startsWith(authorityPrefix)) {
        userAuthorityWithoutPrefixes = userAuthorityWithoutPrefixes.slice(authorityPrefix.length);
      }

      return userAuthorityWithoutPrefixes;
    };

    for (const userAuthority of userAuthorities) {
      if (authorities.some(authority => authority === parseUserAuthority(userAuthority))) return true;
    }

    return false;
  }

  hasAuthorityByCompany(company: ICompany, authority: FeatureAuthority): boolean {
    return (
      this.userIdentity &&
      this.userIdentity.authorities &&
      !!this.userIdentity.authorities.find(a => a.code === `${company.id}_${authority}`)
    );
  }

  identity(force?: boolean): Observable<Account | null> {
    if (!this.accountCache$ || force || !this.isAuthenticated()) {
      this.accountCache$ = this.fetch().pipe(
        catchError(() => {
          return of(null);
        }),
        tap((account: Account | null) => {
          this.authenticate(account);

          if (account) {
            const langKey = account.langKey ?? this.languageUtil.getCurrentSessionCode() ?? LanguageUtil.getDefault().code;
            this.languageUtil.changeLanguage(langKey);
            this.navigateToStoredUrl();
            this.inactiveLogoutService.startCheckLoginExpiredTimer();
          }
        }),
        shareReplay()
      );
    }
    return this.accountCache$;
  }

  isAuthenticated(): boolean {
    return this.userIdentity !== null;
  }

  getAuthenticationState(): Observable<Account | null> {
    return this.authenticationState.asObservable();
  }

  getImageUrl(): string {
    return '';
  }

  private fetch(): Observable<Account> {
    return this.http.get<Account>(`${SERVER_API_URL}api/account`).pipe(map(account => this.userService.convertFromServer(account)));
  }

  private navigateToStoredUrl(): void {
    // previousState can be set in the authExpiredInterceptor and in the userRouteAccessService
    // if login is successful, go to stored previousState and clear previousState
    const previousUrl = this.stateStorageService.getUrl();
    if (previousUrl) {
      this.stateStorageService.clearUrl();
      this.router.navigateByUrl(previousUrl);
    }
  }

  isRegistering(): boolean {
    return this.hasAnyAuthority(Authority.REGISTERING);
  }

  isUser(): boolean {
    return this.hasAnyAuthority(Authority.USER);
  }

  isCompanyAdmin(): boolean {
    return this.hasAnyAuthority(Authority.COMPANY_ADMIN);
  }

  isSystemAdmin(): boolean {
    return this.hasAnyAuthority(Authority.SYSTEM_ADMIN);
  }

  ngOnDestroy(): void {
    unsubscribe(this.subscriptions);
  }
}
