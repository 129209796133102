import {Component, Input} from '@angular/core';
import {ButtonColor} from 'app/shared/layout/window/button.model';
import {Authority} from 'app/shared/model/enumerations/authority.enum';

@Component({
  selector: 'app-role-label',
  template: `
    <span *ngIf="role" class="badge" [ngClass]="'badge-' + color()">{{ 'authority.' + role | translate }}</span>
  `
})
export class RoleLabelComponent {
  @Input()
  role: string | null = null;

  color(): ButtonColor {
    if (!this.role) return 'default';
    switch (this.role) {
      case Authority.SYSTEM_ADMIN:
        return 'danger';
      case Authority.COMPANY_ADMIN:
        return 'warning';
      case Authority.USER:
        return 'success';
      case Authority.ANONYMOUS:
        return 'secondary';
      case Authority.REGISTERING:
        return 'primary';
      case Authority.CLIENT_USER:
        return 'info';
      default:
        return 'default';
    }
  }
}
