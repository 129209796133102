import {Component, Input} from '@angular/core';
import {MaskService} from 'app/shared/mask/mask.service';
import {IBaseEntity} from 'app/shared/model/root/base-entity.model';
import {IFormAnswer} from 'app/shared/model/form/form-answer.model';
import {FormActionsService} from 'app/entities/form/form/form-actions.service';
import {IFormModelQuestion} from 'app/shared/model/form/form-model-question.model';
import {FormModelActionsService} from 'app/entities/form/form-model/form-model-actions.service';
import {IFormAnswerOption} from 'app/shared/model/form/form-answer-option.model';
import {FormAnswerDetailModalService} from 'app/entities/form/form/form-answer-detail-modal.service';
import {IFormModelQuestionOption} from 'app/shared/model/form/form-model-question-option.model';

@Component({
  selector: 'app-frm-form-answer-detail-modal',
  templateUrl: './form-answer-detail-modal.component.html'
})
export class FormAnswerDetailModalComponent {
  @Input()
  formAnswer!: IFormAnswer;

  close?: Function;

  constructor(
    private service: FormAnswerDetailModalService,
    private formActionsService: FormActionsService,
    private formModelActionsService: FormModelActionsService,
    public mask: MaskService
  ) {}

  showFormModelQuestionDetails(question: IFormModelQuestion): void {
    this.formModelActionsService.showFormModelQuestionDetails(question);
  }

  totalActiveFormAnswerOptions(): number {
    return this.formActionsService.totalActiveFormAnswerOptions(this.formAnswer);
  }

  showFormAnswerOptionDetails(formAnswerOption: IFormAnswerOption): void {
    this.formActionsService.showFormAnswerOptionDetails(formAnswerOption);
  }

  showFormModelQuestionOptionDetails(option: IFormModelQuestionOption): void {
    this.formModelActionsService.showFormModelQuestionOptionDetails(option);
  }

  trackIdentity(index: number, item: IBaseEntity): any {
    return item.uuid;
  }

  onCloseClicked(): void {
    this.close && this.close();
    this.service.close();
  }
}
