import {Component, Input} from '@angular/core';
import {ButtonColor} from 'app/shared/layout/window/button.model';
import {Effective} from 'app/shared/model/enumerations/effective.enum';

@Component({
  selector: 'app-effective-label',
  template: `
    <span *ngIf="effective" class="badge" [ngClass]="'badge-' + color()">{{ 'effective.' + effective | translate }}</span>
  `
})
export class EffectiveLabelComponent {
  @Input()
  effective: Effective | null = null;

  color(): ButtonColor {
    if (!this.effective) return 'default';

    switch (this.effective) {
      case Effective.AWAITING:
        return 'warning';
      case Effective.YES:
        return 'success';
      case Effective.NOT:
        return 'danger';
      default:
        return 'default';
    }
  }
}
