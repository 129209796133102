import {Component} from '@angular/core';
import {Button} from 'app/shared/layout/window/button.model';
import {HelpModalService} from 'app/entities/module/help/help-modal/help-modal.service';
import {TranslateService} from '@ngx-translate/core';
import {IFile} from 'app/shared/model/admin/file.model';

@Component({
  selector: 'app-help-modal',
  templateUrl: './help-modal.component.html',
  styleUrls: ['./help-modal.component.scss']
})
export class HelpModalComponent {
  title?: string;
  file?: IFile;
  buttons?: Button[];

  constructor(private genericModalService: HelpModalService, private translate: TranslateService) {}

  dismiss(): void {
    if (this.buttons) {
      const dismissBtn = this.buttons.find(b => b.type === 'dismiss');
      dismissBtn && dismissBtn.action && dismissBtn.action();
    }
    this.genericModalService.dismiss();
  }
}
