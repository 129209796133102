import {Component, HostListener, OnDestroy, OnInit, Renderer2, RendererFactory2} from '@angular/core';
import {Title} from '@angular/platform-browser';
import {ActivatedRouteSnapshot, NavigationEnd, NavigationError, NavigationStart, Router} from '@angular/router';
import {LangChangeEvent, TranslateService} from '@ngx-translate/core';
import {LocalStorageService, SessionStorageService} from 'ngx-webstorage';

import {AccountService} from 'app/core/auth/account.service';
import {PageService} from 'app/shared/layout/window/page.service';
import {catchError} from 'rxjs/operators';
import {of, Subscription} from 'rxjs';
import {unsubscribe} from 'app/shared/util/react-util';
import {routeAnimations} from 'app/shared/animations/animations';
import {fadeInOnEnterAnimation, fadeOutOnLeaveAnimation} from 'angular-animations';
import {Location} from '@angular/common';
import {NgSelectConfig} from '@ng-select/ng-select';
import moment from 'moment';
import {InactiveLogoutService} from 'app/inactive-logout.service';

const anonymousURLs = [
  // '', //
  // '/', //
  '/404', //
  '/login', //
  '/account/activate', //
  '/account/reset/request', //
  '/account/reset/finish', //
  '/sign-up/register'
];

@Component({
  selector: 'app-main',
  templateUrl: './main.component.html',
  styleUrls: ['main.scss'],
  animations: [routeAnimations, fadeInOnEnterAnimation(), fadeOutOnLeaveAnimation()]
})
export class MainComponent implements OnInit, OnDestroy {
  private renderer: Renderer2;

  private sidebarVisibility = true;

  isLoading = '';

  private subscriptions: Subscription[] = [];

  @HostListener('click')
  @HostListener('mousemove')
  @HostListener('scroll')
  @HostListener('keydown')
  onClick() {
    this.localStorageService.store('lastEventMoment', moment());
  }

  constructor(
    private accountService: AccountService,
    private sessionStorage: SessionStorageService,
    private localStorageService: LocalStorageService,
    private titleService: Title,
    private router: Router,
    private location: Location,
    private translateService: TranslateService,
    private pageService: PageService,
    private inactiveLogoutService: InactiveLogoutService,
    private ngSelectConfig: NgSelectConfig,
    rootRenderer: RendererFactory2
  ) {
    this.renderer = rootRenderer.createRenderer(document.querySelector('html'), null);

    // try to log in automatically
    this.subscriptions.push(
      this.accountService
        .identity(true)
        .pipe(
          catchError(() => {
            return of(null);
          })
        )
        .subscribe(account => {
          const currentURL = this.location.path();

          if (
            account &&
            (['', '/'].includes(currentURL) ||
              anonymousURLs.filter(url => !['', '/'].includes(url)).filter(url => currentURL.endsWith(url)).length)
          ) {
            this.router.navigate(['/home']);
          }
        }),

      this.router.events.subscribe(event => {
        switch (true) {
          case event instanceof NavigationStart: {
            const navStartEvent = event as NavigationStart;
            const destinationPath = (navStartEvent.url || '').split('?')[0];

            if (this.router.url !== destinationPath) {
              this.isLoading = destinationPath;
            }
            break;
          }

          case event instanceof NavigationEnd: {
            const navEndEvent = event as NavigationEnd;

            if (!this.isAuthenticated() && !anonymousURLs.some(url => navEndEvent.url.startsWith(url))) {
              this.router.navigate(['/login']);
              return;
            }

            this.updateTitle();
            this.isLoading = '';
            break;
          }

          case event instanceof NavigationError && event.error.status === 404: {
            this.router.navigate(['/404']);
            break;
          }
        }
      }),

      this.translateService.onLangChange.subscribe((langChangeEvent: LangChangeEvent) => {
        this.updateTitle();

        this.renderer.setAttribute(document.querySelector('html'), 'lang', langChangeEvent.lang);

        ngSelectConfig.addTagText = this.translateService.instant('global.ngSelect.addTagText');
        ngSelectConfig.clearAllText = this.translateService.instant('global.ngSelect.clearAllText');
        ngSelectConfig.loadingText = this.translateService.instant('global.ngSelect.loadingText');
        ngSelectConfig.notFoundText = this.translateService.instant('global.ngSelect.notFoundText');
        ngSelectConfig.typeToSearchText = this.translateService.instant('global.ngSelect.typeToSearchText');
      }),

      this.sessionStorage.observe('sidebarVisibility').subscribe((value: any) => (this.sidebarVisibility = !!value))
    );
  }

  ngOnInit(): void {}

  isAuthenticated = (): boolean => this.accountService.isAuthenticated();

  isUser = (): boolean => this.isAuthenticated() && this.accountService.isUser();

  isSidebarVisible = (): boolean => this.sidebarVisibility && this.isUser();

  isNavbarVisible = (): boolean => this.isUser();

  private getPageTitle(routeSnapshot: ActivatedRouteSnapshot): string {
    let title: string = routeSnapshot.data && routeSnapshot.data['pageTitle'] ? routeSnapshot.data['pageTitle'] : '';
    if (routeSnapshot.firstChild) {
      title = this.getPageTitle(routeSnapshot.firstChild) || title;
    }
    return title;
  }

  private updateTitle(): void {
    this.subscriptions.push(
      this.translateService.get('global.appTitle').subscribe(appTitle => {
        const pageTitle = this.getPageTitle(this.router.routerState.snapshot.root);
        if (pageTitle) {
          this.subscriptions.push(
            this.translateService.get(pageTitle).subscribe(title => this.titleService.setTitle(`${appTitle} - ${title}`))
          );
        } else {
          this.titleService.setTitle(appTitle);
        }
      })
    );
  }

  ngOnDestroy(): void {
    unsubscribe(this.subscriptions);
  }
}
