import {Component, Input, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {UntypedFormBuilder, NgForm, Validators} from '@angular/forms';
import {Subscription} from 'rxjs';
import {unsubscribe} from 'app/shared/util/react-util';
import {IFormAnswer} from 'app/shared/model/form/form-answer.model';
import {FormAnswerRejectModalService} from 'app/entities/form/form/form-answer-reject-modal.service';

@Component({
  selector: 'app-frm-form-answer-reject-modal',
  templateUrl: './form-answer-reject-modal.component.html'
})
export class FormAnswerRejectModalComponent implements OnInit, OnDestroy {
  @Input()
  formAnswer!: IFormAnswer;

  editForm = this.fb.group({
    description: [null, [Validators.required, Validators.maxLength(2000)]]
  });

  @ViewChild('form')
  form?: NgForm;

  confirm?: Function;

  cancel?: Function;

  private subscriptions: Subscription[] = [];

  constructor(private service: FormAnswerRejectModalService, private fb: UntypedFormBuilder) {}

  ngOnInit(): void {}

  isInvalidAndTouched(field: string): boolean {
    const input = this.editForm.get(field);
    return input !== null && input.invalid && (input.dirty || input.touched);
  }

  canSubmit(): boolean {
    return this.editForm.valid && this.editForm.dirty;
  }

  onConfirmClicked(): void {
    this.confirm &&
      this.confirm({
        description: this.editForm.get(['description'])!.value
      });
    this.service.confirm();
  }

  onCancelClicked(): void {
    this.cancel && this.cancel();
    this.service.dismiss();
  }

  ngOnDestroy(): void {
    unsubscribe(this.subscriptions);
  }
}
