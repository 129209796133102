import {Component, OnDestroy, OnInit} from '@angular/core';
import {UploadService} from 'app/shared/upload/upload.service';
import {StringUtilService} from 'app/shared/util/string-util.service';
import {UploadRequest} from '../../../../serverless/model/upload-request.model';
import {catchError, flatMap} from 'rxjs/operators';
import {Logger} from 'app/shared/util/logger';
import {S3PreSignedURL} from '../../../../serverless/model/s3-pre-signed-url.model';
import {Subject, Subscription, throwError} from 'rxjs';
import {unsubscribe} from 'app/shared/util/react-util';
import {HttpEventType} from '@angular/common/http';
import {IFile} from 'app/shared/model/admin/file.model';
import {UPLOAD_BUCKET_URL} from 'app/app.constants';
import {UploadOptions} from 'app/shared/upload/upload-options.model';
import {UploadFileModalService} from 'app/shared/upload/upload-file-modal.service';
import {ToastService} from 'app/shared/toast/toast.service';
import {Toast} from 'app/shared/toast/toast.model';
import {FileType} from 'app/shared/model/enumerations/file-type.enum';

const Log = new Logger('UploadImageModalComponent');

@Component({
  selector: 'app-upload-file-modal',
  templateUrl: './upload-file-modal.component.html',
  styleUrls: ['./upload-modal.component.scss']
})
export class UploadFileModalComponent implements OnDestroy, OnInit {
  fileChangedEvent: Event = null;
  uploading = false;
  uploadProgress = 0;
  options?: UploadOptions;
  fileUploaded?: Function;
  cancel?: Function;
  originalFile?: File;

  MAX_SIZE_OF_URL = 255;

  fileTypes?: FileType[] = [];
  fileType: FileType = FileType.FILE;
  url: string;

  private subscriptions: Subscription[] = [];

  constructor(private service: UploadFileModalService, private uploadService: UploadService, private toastService: ToastService) {
  }

  ngOnInit(): void {
    this.fileTypes = !this.options?.fileTypes?.length ? [FileType.FILE, FileType.LINK] : this.options?.fileTypes;
    if (this.fileTypes.length === 1 && this.fileTypes[0] === FileType.LINK) {
      this.fileType = FileType.LINK;
    }
  }

  private checkFileExtension(filename: String): boolean {
    return (
      filename &&
      (this.options.fileExtension.includes('*.*') ||
        this.options.fileExtension
          .toLowerCase()
          .split(',')
          .some(ext => filename.toLowerCase().endsWith(ext)))
    );
  }

  fileChangeEvent(event: Event): void {
    this.fileChangedEvent = event;

    const file =
      this.fileChangedEvent.target instanceof HTMLInputElement ? (this.fileChangedEvent.target as HTMLInputElement).files[0] : null;

    if (file && this.checkFileExtension(file.name)) {
      this.originalFile = file;
    } else {
      this.toastService.show(Toast.danger('upload.toast.load.error'));
      this.originalFile = null;
    }
  }

  dismiss(): void {
    this.cancel && this.cancel();
    this.service.dismiss();
  }

  canConfirm(): boolean {
    return this.fileType === FileType.FILE ? !!this.originalFile && !this.uploading : !this.urlIsInvalid(this.url);
  }

  startUpload(): void {
    Log.debug(() => `startUpload()`);

    this.uploading = true;
    this.uploadProgress = 0;

    const progress = new Subject<number>();

    const uuid = StringUtilService.uuid();
    const newFileName = `${uuid}.${StringUtilService.extractFileExtension(this.originalFile.name)}`;
    const file: IFile = {
      uuid: uuid,
      active: true,
      name: newFileName,
      sourceName: this.originalFile.name,
      contentType: this.originalFile.type,
      url: `${UPLOAD_BUCKET_URL}${newFileName}`,
      fileType: FileType.FILE,
      _edited: true
    } as IFile;

    this.subscriptions.push(
      progress.subscribe(value => (this.uploadProgress = value)),
      this.uploadService
        .getPreSignedURL(
          UploadRequest.from({
            fileName: file.name,
            contentType: this.originalFile.type
          })
        )
        .pipe(
          catchError(err => {
            Log.error(() => `startUpload() => Couldn't get pre-signed url => ${JSON.stringify(err)}`);
            return throwError('Something bad happened; please try again later.');
          }),
          flatMap((preSignedURL: S3PreSignedURL) => {
            Log.debug(() => `startUpload() => preSignedURL: ${JSON.stringify(preSignedURL)}`);
            return this.uploadService.uploadFileToS3(preSignedURL, this.originalFile, progress);
          })
        )
        .subscribe(
          event => {
            Log.debug(() => `startUpload() => event: ${JSON.stringify(event)}`);

            switch (event.type) {
              case HttpEventType.Response:
                this.fileUploaded && this.fileUploaded(file);
                this.service.confirm();
                break;
            }
          },
          error => {
            Log.error(() => `startUpload() => error: ${error}`);
            this.service.dismiss();
            this.toastService.show(Toast.danger('upload.toast.error'));
          },
          () => {
            this.uploading = false;
            this.uploadProgress = 0;
          }
        )
    );
  }

  createFileWithExternalLink(): void {
    const uuid = StringUtilService.uuid();

    const file: IFile = {
      uuid: uuid,
      active: true,
      url: this.url,
      name: this.url,
      sourceName: this.url,
      fileType: FileType.LINK,
      _edited: true
    } as IFile;

    this.service.confirm();
    this.fileUploaded && this.fileUploaded(file);
  }

  confirm(): void {
    this.fileType === FileType.FILE && this.startUpload();
    this.fileType === FileType.LINK && this.createFileWithExternalLink();
  }

  isEmpty(str) {
    return !str || str.length === 0;
  }

  urlIsInvalid(url: string) {
    return this.isEmpty(url) || url.length > this.MAX_SIZE_OF_URL;
  }

  // eslint-disable-next-line
  fileTypeChange($event) {
    !(this.fileType === FileType.LINK) && (this.url = '');
    !(this.fileType === FileType.FILE) && (this.originalFile = undefined);
  }

  ngOnDestroy(): void {
    unsubscribe(this.subscriptions);
  }
}
