import moment from 'moment';

export function convertDateFromServerUtil(obj: any) {
  if (!obj) return;

  Object.keys(obj).forEach(key => {
    if (Array.isArray(obj[key])) {
      obj[key].forEach(item => convertDateFromServerUtil(item));
    } else {
      const baseFormat = 'YYYY-MM-DDTHH:mm:ss';
      const formats = [];

      formats.push(`${baseFormat}Z`);
      for (let i = 1; i <= 8; i++) {
        formats.push(`${baseFormat}.${'S'.repeat(i)}Z`);
      }

      const keyIsAnValidMoment = formats
        .filter(format => (!!obj[key] && moment(obj[key], format, true).isValid()))
        .length > 0;

      if (keyIsAnValidMoment) {
        obj[key] = moment(obj[key]);
      }
    }
  });
}

export function convertDateFromClientUtil(obj: any): any {
  if (!obj) return undefined;

  const copy = Object.assign({}, obj);
  Object.keys(copy).forEach(key => {
    if (Array.isArray(copy[key])) {
      copy[key].forEach(item => convertDateFromClientUtil(item));
    } else {
      if (moment.isMoment(copy[key])) {
        copy[key] = copy[key].toJSON();
      }
    }
  });

  return copy;
}
