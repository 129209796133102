import {Injectable} from '@angular/core';
import {HttpClient, HttpResponse} from '@angular/common/http';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import moment from 'moment';

import {SERVER_API_URL} from 'app/app.constants';
import {IUserManual} from 'app/shared/model/admin/user-manual.model';

type EntityResponseType = HttpResponse<IUserManual>;
type EntityArrayResponseType = HttpResponse<IUserManual[]>;

@Injectable({providedIn: 'root'})
export class UserManualService {
  public resourceUrl = `${SERVER_API_URL}api/adm/users-manuals`;

  constructor(protected http: HttpClient) {}

  update(userManual: IUserManual): Observable<EntityResponseType> {
    const copy = this.convertDateFromClient(userManual);
    return this.http
      .put<IUserManual>(this.resourceUrl, copy, {observe: 'response'})
      .pipe(map((res: EntityResponseType) => this.convertDateFromServer(res)));
  }

  find(): Observable<EntityResponseType> {
    return this.http
      .get<IUserManual>(`${this.resourceUrl}`, {observe: 'response'})
      .pipe(map((res: EntityResponseType) => this.convertDateFromServer(res)));
  }

  protected convertDateFromClient(userManual: IUserManual): IUserManual {
    const copy: IUserManual = Object.assign({}, userManual, {
      createdDate: userManual.createdDate && userManual.createdDate.isValid() ? userManual.createdDate.toJSON() : undefined,
      lastModifiedDate:
        userManual.lastModifiedDate && userManual.lastModifiedDate.isValid() ? userManual.lastModifiedDate.toJSON() : undefined
    });
    return copy;
  }

  protected convertDateFromServer(res: EntityResponseType): EntityResponseType {
    if (res.body) {
      res.body.createdDate = res.body.createdDate ? moment(res.body.createdDate) : undefined;
      res.body.lastModifiedDate = res.body.lastModifiedDate ? moment(res.body.lastModifiedDate) : undefined;
    }
    return res;
  }

  protected convertDateArrayFromServer(res: EntityArrayResponseType): EntityArrayResponseType {
    if (res.body) {
      res.body.forEach((userManual: IUserManual) => {
        userManual.createdDate = userManual.createdDate ? moment(userManual.createdDate) : undefined;
        userManual.lastModifiedDate = userManual.lastModifiedDate ? moment(userManual.lastModifiedDate) : undefined;
      });
    }
    return res;
  }
}
