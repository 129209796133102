import {Component, EventEmitter, Input, Output} from '@angular/core';
import {QuillViewModalService} from 'app/shared/quill/quill-view-modal.service';
import {IconProp} from '@fortawesome/fontawesome-svg-core';

@Component({
  selector: 'app-quill-view-link',
  template: `
    <ng-container *ngIf="content && content.length">
      <a href="javascript:void(0);" (click)="open()" [ngClass]="{'btn btn-sm btn-primary': asButton}">
        <ng-container *ngIf="!noLabel">
          <span [jhiTranslate]="label ? (label | ellipsis: maxLength) : 'entity.action.view'"> </span>
          &nbsp;
        </ng-container>
        <fa-icon *ngIf="!icon" [fixedWidth]="true"icon="book-open"></fa-icon>
        <fa-icon *ngIf="icon" [fixedWidth]="true"[icon]="icon"></fa-icon>
      </a>
    </ng-container>
  `
})
export class QuillViewLinkComponent {
  @Input()
  content: string | undefined = undefined;

  @Input()
  title: string | undefined = undefined;

  @Input()
  label: string | undefined = undefined;

  @Input()
  maxLength: number = 150;

  @Input()
  icon: IconProp | undefined = undefined;

  @Input()
  noLabel: boolean = false;

  @Input()
  asButton: boolean = false;

  @Input()
  hasExportButton: boolean = false;

  @Output()
  onClick: EventEmitter<never> = new EventEmitter<never>();

  constructor(private quillViewService: QuillViewModalService) {}

  doClick(): void {
    this.onClick?.emit();
  }

  open(): void {
    this.content && this.quillViewService.show(this.content, this.title, this.hasExportButton);
    this.doClick();
  }
}
