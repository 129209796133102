import {BoolFunction, IButton, IconFunction, StringFunction} from 'app/shared/layout/window/button.model';

export declare type FilterFunction = (filter: any) => void;

export interface IPage {
  icon?: IconFunction;
  title?: StringFunction;
  subtitle?: StringFunction;
  titleOnClick?: Function;
  isDirty?: BoolFunction;
  buttons?: IButton[];
  onFilterSubmit?: FilterFunction;
}

export class Page implements IPage {
  private _clearSearchCallback: Function;

  constructor(
    public icon?: IconFunction,
    public title?: StringFunction,
    public subtitle?: StringFunction,
    public titleOnClick?: Function,
    public isDirty?: BoolFunction,
    public buttons?: IButton[],
    public onFilterSubmit?: FilterFunction
  ) {
    !subtitle && (this.subtitle = () => '');
    !titleOnClick && (this.titleOnClick = () => undefined);
    !isDirty && (this.isDirty = () => false);
    !buttons && (this.buttons = []);
  }

  clearSearch(): void {
    this._clearSearchCallback && this._clearSearchCallback();
  }

  setClearSearchCallback(callback: Function): void {
    this._clearSearchCallback = callback;
  }
}
