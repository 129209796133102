import {Component} from '@angular/core';
import {SessionStorageService} from 'ngx-webstorage';
import {AccountService} from 'app/core/auth/account.service';
import {APP_LANGUAGES, LanguageUtil} from 'app/shared/util/language-util';
import {VERSION} from 'app/app.constants';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.scss'],
})
export class FooterComponent {
  languages = APP_LANGUAGES;
  version = VERSION;

  constructor(private sessionStorage: SessionStorageService, private accountService: AccountService, private languageUtil: LanguageUtil) {}

  changeLanguage(code: string): void {
    this.languageUtil.changeLanguage(code);
  }

  getActiveLanguage(): string {
    return this.languageUtil.getActiveCode();
  }

  isUser = (): boolean => this.accountService.isUser();

  getCurrentYear(): string {
    return `${new Date().getFullYear()}`;
  }
}
