import {Component, Input} from '@angular/core';
import {MaskService} from 'app/shared/mask/mask.service';

@Component({
  selector: 'app-masked-value',
  template: `
    <ng-container *ngIf="value && pattern.length">{{ value | mask: getMask() }}</ng-container>
  `
})
export class MaskedValueComponent {
  @Input()
  value: any | null = null;

  @Input()
  pattern = '';

  constructor(private maskService: MaskService) {}

  getMask(): string {
    return (this.maskService[this.pattern] && this.maskService[this.pattern]()) || this.pattern;
  }
}
