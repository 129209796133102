<app-page-ribbon></app-page-ribbon>
<header *ngIf="isNavbarVisible()">
  <router-outlet name="navbar"></router-outlet>
</header>
<section>
  <div *ngIf="isSidebarVisible()" [@fadeInOnEnter] [@fadeOutOnLeave]>
    <router-outlet name="sidebar"></router-outlet>
  </div>
  <div class="main-content" [ngClass]="{
    'sidebar-padding': isSidebarVisible(),
    'with-navbar': isNavbarVisible()
    }">
    <div [hidden]="!isLoading.length" class="loading-indicator">
      <fa-icon icon="circle-notch" [spin]="true"></fa-icon>
    </div>

    <div [hidden]="isLoading.length" class="content">
      <app-toast></app-toast>
      <app-alert></app-alert>
      <app-alert-error *ngIf="isAuthenticated()"></app-alert-error>

      <div class="content-child" [ngClass]="{
        'window-content': isNavbarVisible(),
        'window-content-back': isNavbarVisible()
        }">
        <router-outlet></router-outlet>
        <app-footer></app-footer>
      </div>

    </div>
  </div>
</section>
