import {Injectable} from '@angular/core';
import {HttpClient, HttpResponse} from '@angular/common/http';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import moment from 'moment';

import {SERVER_API_URL} from 'app/app.constants';
import {createRequestOption} from 'app/shared/util/request-util';
import {IFile} from 'app/shared/model/admin/file.model';
import {MaskService} from 'app/shared/mask/mask.service';
import {ICompany} from 'app/shared/model/company/company.model';
import {FileType} from 'app/shared/model/enumerations/file-type.enum';
import {FileViewModalService} from 'app/shared/file/file-view-modal.service';

type EntityResponseType = HttpResponse<IFile>;
type EntityArrayResponseType = HttpResponse<IFile[]>;

@Injectable({providedIn: 'root'})
export class FileService {
  public resourceUrl = `${SERVER_API_URL}api/adm/files`;

  constructor(protected http: HttpClient, private maskService: MaskService, private fileViewModalService: FileViewModalService) {}

  create(file: IFile): Observable<EntityResponseType> {
    const copy = this.convertDateFromClient(file);
    return this.http
      .post<IFile>(this.resourceUrl, copy, {observe: 'response'})
      .pipe(map((res: EntityResponseType) => this.convertDateFromServer(res)));
  }

  update(file: IFile): Observable<EntityResponseType> {
    const copy = this.convertDateFromClient(file);
    return this.http
      .put<IFile>(this.resourceUrl, copy, {observe: 'response'})
      .pipe(map((res: EntityResponseType) => this.convertDateFromServer(res)));
  }

  find(id: number): Observable<EntityResponseType> {
    return this.http
      .get<IFile>(`${this.resourceUrl}/${id}`, {observe: 'response'})
      .pipe(map((res: EntityResponseType) => this.convertDateFromServer(res)));
  }

  query(req?: any): Observable<EntityArrayResponseType> {
    const options = createRequestOption(req);
    return this.http
      .get<IFile[]>(this.resourceUrl, {params: options, observe: 'response'})
      .pipe(map((res: EntityArrayResponseType) => this.convertDateArrayFromServer(res)));
  }

  delete(id: number): Observable<HttpResponse<{}>> {
    return this.http.delete(`${this.resourceUrl}/${id}`, {observe: 'response'});
  }

  download(file: IFile): void {
    file?.url &&
      this.http
        .get(file.url, {
          responseType: 'blob',
          observe: 'response'
        })
        .subscribe((response: HttpResponse<Blob>) => {
          const link = document.createElement('a');
          link.href = window.URL.createObjectURL(response.body);
          link.download = file.sourceName;
          link.target = '_blank';
          link.click();
        });
  }

  view(file: IFile): void {
    if (!file.url) {
      return;
    }
    const link = document.createElement('a');
    switch (file.fileType) {
      case FileType.FILE: {
        this.fileViewModalService.show(file, file.sourceName);
        break;
      }
      case FileType.LINK: {
        link.href = file.url;
        link.target = '_blank';
        link.click();
        break;
      }
    }
  }

  getCompanyLogo(company: ICompany): Observable<EntityResponseType> {
    return this.http
      .get<ICompany>(`${this.resourceUrl}/${company.id}/get-company-logo`, {observe: 'response'})
      .pipe(map((res: EntityResponseType) => this.convertDateFromServer(res)));
  }

  protected convertDateFromClient(file: IFile): IFile {
    const copy: IFile = Object.assign({}, file, {
      createdDate: file.createdDate && file.createdDate.isValid() ? file.createdDate.toJSON() : undefined,
      lastModifiedDate: file.lastModifiedDate && file.lastModifiedDate.isValid() ? file.lastModifiedDate.toJSON() : undefined,
      linkedEntityUuid: file.linkedEntityUuid ? this.maskService.apply(file.linkedEntityUuid, 'uuid') : undefined
    });
    return copy;
  }

  protected convertDateFromServer(res: EntityResponseType): EntityResponseType {
    if (res.body) {
      res.body.createdDate = res.body.createdDate ? moment(res.body.createdDate) : undefined;
      res.body.lastModifiedDate = res.body.lastModifiedDate ? moment(res.body.lastModifiedDate) : undefined;
    }
    return res;
  }

  protected convertDateArrayFromServer(res: EntityArrayResponseType): EntityArrayResponseType {
    if (res.body) {
      res.body.forEach((file: IFile) => {
        file.createdDate = file.createdDate ? moment(file.createdDate) : undefined;
        file.lastModifiedDate = file.lastModifiedDate ? moment(file.lastModifiedDate) : undefined;
      });
    }
    return res;
  }
}
