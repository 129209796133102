import {Routes} from '@angular/router';

export const notificationRoutes: Routes = [
  {
    path: 'ntf/events',
    loadChildren: () => import('./notification-event/notification-event.module').then(m => m.AppNotificationEventModule),
    data: {
      pageTitle: 'notificationEvent.home.title'
    }
  }
];
