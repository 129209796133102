import {Injectable} from '@angular/core';
import {NgbModal, NgbModalRef} from '@ng-bootstrap/ng-bootstrap';
import {IFormAnswer} from 'app/shared/model/form/form-answer.model';
import {FormAnswerDetailModalComponent} from 'app/entities/form/form/form-answer-detail-modal.component';

@Injectable({providedIn: 'root'})
export class FormAnswerDetailModalService {
  private isOpen = false;
  private instance?: NgbModalRef;

  constructor(private modalService: NgbModal) {}

  show(formAnswer: IFormAnswer, close?: Function): void {
    if (this.isOpen) return;

    this.isOpen = true;

    this.instance = this.modalService.open(FormAnswerDetailModalComponent, {size: 'lg', backdrop: 'static'});
    this.instance.componentInstance.formAnswer = formAnswer;
    this.instance.componentInstance.close = close;

    this.instance.result.finally(() => (this.isOpen = false));
  }

  close(): void {
    if (!this.isOpen || !this.instance) return;
    this.instance.dismiss();
  }
}
