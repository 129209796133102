import {Routes} from '@angular/router';

export const thirdPartyEntityRoutes: Routes = [
  {
    path: 'tpe/configurations',
    loadChildren: () => import('./configuration/configuration.module').then(m => m.AppConfigurationModule),
    data: {
      pageTitle: 'configuration.home.title'
    }
  }
];
