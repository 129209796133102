import {Injectable} from '@angular/core';
import {HttpClient, HttpResponse} from '@angular/common/http';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import moment from 'moment';
import {SERVER_API_URL} from 'app/app.constants';
import {createRequestOption} from 'app/shared/util/request-util';
import {IGeneralAlert} from 'app/shared/model/admin/general-alert.model';

type EntityResponseType = HttpResponse<IGeneralAlert>;
type EntityArrayResponseType = HttpResponse<IGeneralAlert[]>;

@Injectable({providedIn: 'root'})
export class GeneralAlertService {
  public resourceUrl = `${SERVER_API_URL}api/adm/generals-alerts`;

  constructor(protected http: HttpClient) {}

  create(generalAlert: IGeneralAlert): Observable<EntityResponseType> {
    const copy = this.convertDateFromClient(generalAlert);
    return this.http
      .post<IGeneralAlert>(this.resourceUrl, copy, {observe: 'response'})
      .pipe(map((res: EntityResponseType) => this.convertDateFromServer(res)));
  }

  update(generalAlert: IGeneralAlert): Observable<EntityResponseType> {
    const copy = this.convertDateFromClient(generalAlert);
    return this.http
      .put<IGeneralAlert>(this.resourceUrl, copy, {observe: 'response'})
      .pipe(map((res: EntityResponseType) => this.convertDateFromServer(res)));
  }

  find(id: number): Observable<EntityResponseType> {
    return this.http
      .get<IGeneralAlert>(`${this.resourceUrl}/${id}`, {observe: 'response'})
      .pipe(map((res: EntityResponseType) => this.convertDateFromServer(res)));
  }

  query(req?: any): Observable<EntityArrayResponseType> {
    const options = createRequestOption(req);
    return this.http
      .get<IGeneralAlert[]>(this.resourceUrl, {params: options, observe: 'response'})
      .pipe(map((res: EntityArrayResponseType) => this.convertDateArrayFromServer(res)));
  }

  delete(id: number): Observable<HttpResponse<{}>> {
    return this.http.delete(`${this.resourceUrl}/${id}`, {observe: 'response'});
  }

  getContentGeneralAlert(): Observable<HttpResponse<string[]>> {
    return this.http.get<string[]>('api/home/get-general-alerts', {observe: 'response'});
  }

  protected convertDateFromClient(generalAlert: IGeneralAlert): IGeneralAlert {
    const copy: IGeneralAlert = Object.assign({}, generalAlert, {
      createdDate: generalAlert.createdDate && generalAlert.createdDate.isValid() ? generalAlert.createdDate.toJSON() : undefined,
      lastModifiedDate:
        generalAlert.lastModifiedDate && generalAlert.lastModifiedDate.isValid() ? generalAlert.lastModifiedDate.toJSON() : undefined
    });
    return copy;
  }

  protected convertDateFromServer(res: EntityResponseType): EntityResponseType {
    if (res.body) {
      res.body.createdDate = res.body.createdDate ? moment(res.body.createdDate) : undefined;
      res.body.lastModifiedDate = res.body.lastModifiedDate ? moment(res.body.lastModifiedDate) : undefined;
    }
    return res;
  }

  protected convertDateArrayFromServer(res: EntityArrayResponseType): EntityArrayResponseType {
    if (res.body) {
      res.body.forEach((generalAlert: IGeneralAlert) => {
        generalAlert.createdDate = generalAlert.createdDate ? moment(generalAlert.createdDate) : undefined;
        generalAlert.lastModifiedDate = generalAlert.lastModifiedDate ? moment(generalAlert.lastModifiedDate) : undefined;
      });
    }
    return res;
  }
}
