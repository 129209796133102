import {Component, Input} from '@angular/core';

@Component({
  selector: 'app-bool-icon',
  template: `
    <fa-icon
      *ngIf="value !== undefined"
      [fixedWidth]="true"
      [icon]="!!value
          ? type === 'circle'
            ? 'check-circle'
            : type === 'square'
            ? 'check-square'
            : 'check'
          : type === 'circle'
          ? 'times-circle'
          : type === 'square'
          ? 'times-square'
          : 'times'"
      [ngClass]="{'text-success': !!value, 'text-danger': !!!value}"
    ></fa-icon>
  `
})
export class BoolIconComponent {
  @Input()
  value: any | undefined = undefined;

  @Input()
  type: 'flat' | 'circle' | 'square' = 'flat';
}
