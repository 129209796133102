import {NgModule} from '@angular/core';
import {RouterModule} from '@angular/router';
import {adminRoutes} from 'app/entities/admin/admin.route';
import {securityRoutes} from 'app/entities/security/security.route';
import {moduleRoutes} from 'app/entities/module/module.route';
import {companyRoutes} from 'app/entities/company/company.route';
import {locationRoutes} from 'app/entities/location/location.route';
import {userRoutes} from 'app/entities/user/user.route';
import {paymentRoutes} from 'app/entities/payment/payment.route';
import {emailRoutes} from 'app/entities/email/email.route';
import {notificationRoutes} from 'app/entities/notification/notification.route';
import {sessionRoutes} from 'app/entities/session/session.route';
import {contentRoutes} from 'app/entities/content/content.route';
import {productRoutes} from 'app/entities/product/product.route';
import {formRoutes} from 'app/entities/form/form.route';
import {warehouseRoutes} from 'app/entities/warehouse/warehouse.route';
import {scrumRoutes} from 'app/entities/scrum/scrum.route';
import {devopsRoutes} from 'app/entities/devops/devops.route';
import {thirdPartyAuthenticationRoutes} from 'app/entities/third/party/authentication/third-party-authentication.route';
import {thirdPartyEntityRoutes} from 'app/entities/third/party/entity/third-party-authentication.route';
import {erpRoutes} from 'app/entities/erp/erp.route';

@NgModule({
  imports: [
    RouterModule.forChild([
      ...adminRoutes,
      ...moduleRoutes,
      ...securityRoutes,
      ...companyRoutes,
      ...locationRoutes,
      ...userRoutes,
      ...contentRoutes,
      ...paymentRoutes,
      ...productRoutes,
      ...emailRoutes,
      ...formRoutes,
      ...notificationRoutes,
      ...scrumRoutes,
      ...devopsRoutes,
      ...sessionRoutes,
      ...warehouseRoutes,
      ...thirdPartyAuthenticationRoutes,
      ...thirdPartyEntityRoutes,
      ...erpRoutes
    ])
  ]
})
export class AppEntityModule {
}
