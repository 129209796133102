import {animate, group, query, style, transition, trigger} from '@angular/animations';

export const routeAnimations = trigger('routeAnimations', [
  transition('* <=> *', [
    query(':enter, :leave', [style({position: 'absolute'})]),
    query(':enter', [style({opacity: 0})]),
    group([
      query(':leave', [animate('0.3s ease-in-out', style({opacity: 0}))]),
      query(':enter', [animate('0.3s ease-in-out', style({opacity: 1}))])
    ])
  ])
]);
