import {NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';

import {NgSelectModule} from '@ng-select/ng-select';
import {FormsModule} from '@angular/forms';

import {AppSharedModule} from 'app/shared/shared.module';
import {AppCoreModule} from 'app/core/core.module';
import {AppAppRoutingModule} from './app-routing.module';
import {AppHomeModule} from './home/home.module';
import {AppEntityModule} from './entities/entity.module';
import {MainComponent} from './layouts/main/main.component';
import {NavbarComponent} from './layouts/navbar/navbar.component';
import {SidebarComponent} from './layouts/sidebar/sidebar.component';
import {FooterComponent} from './layouts/footer/footer.component';
import {PageRibbonComponent} from './layouts/profiles/page-ribbon.component';
import {ActiveMenuDirective} from './layouts/navbar/active-menu.directive';
import {ErrorComponent} from './layouts/error/error.component';
import {NgxMaskModule} from 'ngx-mask';
import {CurrencyMaskModule} from 'ng2-currency-mask';
import {CurrencyPipe} from '@angular/common';
import {TranslateModule} from '@ngx-translate/core';
import {SidebarService} from './layouts/sidebar/sidebar.service';
import {InactiveLogoutService} from 'app/inactive-logout.service';

@NgModule({
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    NgSelectModule,
    NgxMaskModule.forRoot(),
    CurrencyMaskModule,
    FormsModule,
    AppCoreModule,
    AppHomeModule,
    AppEntityModule,
    AppAppRoutingModule,
    AppSharedModule,
    TranslateModule
  ],
  declarations: [
    MainComponent,
    NavbarComponent,
    SidebarComponent,
    ErrorComponent,
    PageRibbonComponent,
    ActiveMenuDirective,
    FooterComponent
  ],
  providers: [CurrencyPipe, SidebarService, InactiveLogoutService],
  bootstrap: [MainComponent]
})
export class AppAppModule {
}
