import {Injectable} from '@angular/core';
import {NgbModal, NgbModalRef} from '@ng-bootstrap/ng-bootstrap';
import {FileViewModalComponent} from 'app/shared/file/file-view-modal.component';
import {IFile} from 'app/shared/model/admin/file.model';

@Injectable({providedIn: 'root'})
export class FileViewModalService {
  private isOpen = false;
  private instance?: NgbModalRef;

  constructor(private modalService: NgbModal) {}

  show(file: IFile, title?: string, close?: Function): void {
    if (this.isOpen) return;

    this.isOpen = true;

    this.instance = this.modalService.open(FileViewModalComponent, {size: 'lg', backdrop: 'static'});
    this.instance.componentInstance.file = file;
    this.instance.componentInstance.title = title;
    this.instance.componentInstance.close = close;

    this.instance.result.finally(() => (this.isOpen = false));
  }

  close(): void {
    if (!this.isOpen || !this.instance) return;
    this.instance.dismiss();
  }
}
