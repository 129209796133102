<div *ngIf="changeLog">
  <div *ngIf="false" class="modal-header">
    <h4 class="modal-title" jhiTranslate="entity.modals.item.detail.title"></h4>
    <button type="button" class="close" data-dismiss="modal" aria-hidden="true" (click)="onCloseClicked()">&times;
    </button>
  </div>

  <div class="modal-body">
    <div class="row justify-content-center">
      <ngb-accordion class="col-12 entity-details" activeIds="panelEntity">
        <ngb-panel id="panelEntity">
          <ng-template ngbPanelTitle>
            <fa-icon [fixedWidth]="true" icon="list-alt"></fa-icon>
            &nbsp;
            <span>{{'entity.panels.details' | translate}}</span>
          </ng-template>
          <ng-template ngbPanelContent>
            <div class="row justify-content-center">
              <dl class="col-12">
                <dt><span jhiTranslate="global.field.id"></span></dt>
                <dd>
                  <app-view-route-link [entity]="changeLog" route="adm/changes-logs"
                                       target="_blank"></app-view-route-link>
                </dd>

                <dt><span jhiTranslate="global.field.changeLog.revision"></span></dt>
                <dd>{{ changeLog.revision }}</dd>

                <dt><span jhiTranslate="global.field.changeLog.date"></span></dt>
                <dd>{{ changeLog.date | dateMoment:'dd/MM/yyyy' }}</dd>

                <dt><span jhiTranslate="global.field.user"></span></dt>
                <dd>
                  <app-view-route-link [entity]="changeLog.user" [param]="changeLog.user?.login" route="usr/users"
                                       target="_blank"></app-view-route-link>
                </dd>

                <dt><span jhiTranslate="global.field.changeLog.description"></span></dt>
                <dd>{{ changeLog.description }}</dd>
              </dl>
            </div>
          </ng-template>
        </ngb-panel>

        <ngb-panel *ngIf="(changeLog.newValue | jsonAsObject) as log" id="panelChanges">
          <ng-template ngbPanelTitle>
            <div class="d-flex justify-content-between">
              <div>
                <fa-icon [fixedWidth]="true" icon="history"></fa-icon>
                &nbsp;
                <span jhiTranslate="global.field.changes"></span>
              </div>
            </div>
          </ng-template>
          <ng-template ngbPanelContent>
            <div class="row justify-content-center">
              <dl *ngIf="log" class="col-12">

                <ng-container *dmHasAnyAuthority="'SYSTEM_ADMIN'">
                  <dt><span jhiTranslate="global.field.owner"></span></dt>
                  <dd>
                    <app-enum-label [value]="log.owner" [options]="owners" name="owner"></app-enum-label>
                  </dd>
                </ng-container>

                <dt><span jhiTranslate="global.field.company"></span></dt>
                <dd>
                  <app-view-route-link [entity]="log.company" route="com/companies"
                                       target="_blank"></app-view-route-link>
                </dd>

                <dt><span jhiTranslate="form.formModel"></span></dt>
                <dd>
                  <app-view-route-link [entity]="log.formModel" route="frm/forms-models"
                                       target="_blank"></app-view-route-link>
                </dd>

                <dt><span jhiTranslate="global.field.user"></span></dt>
                <dd>
                  <app-view-route-link [entity]="log.user" [param]="log.user?.login"
                                       route="usr/users" target="_blank"></app-view-route-link>
                </dd>

	              <dt><span jhiTranslate="global.field.name"></span></dt>
	              <dd>{{ log.name }}</dd>

                <dt><span jhiTranslate="global.field.status"></span></dt>
                <dd>
                  <app-status-label [status]="log.status"></app-status-label>
                </dd>

                <dt><span jhiTranslate="form.startedDate"></span></dt>
                <dd>{{ log.startedDate | dateMoment:'dd/MM/yyyy HH:mm' }}</dd>

                <dt><span jhiTranslate="form.finishedDate"></span></dt>
                <dd>{{ log.finishedDate | dateMoment:'dd/MM/yyyy HH:mm' }}</dd>

                <dt><span jhiTranslate="form.approvalDate"></span></dt>
                <dd>{{ log.approvalDate | dateMoment:'dd/MM/yyyy HH:mm' }}</dd>

                <dt><span jhiTranslate="form.score"></span></dt>
                <dd>{{ log.score | number:'1.2-2' }}</dd>

                <dt><span jhiTranslate="form.approvalUser"></span></dt>
                <dd>
                  <app-view-route-link [entity]="log.approvalUser" [param]="log.approvalUser?.login"
                                       route="usr/users" target="_blank"></app-view-route-link>
                </dd>

              </dl>
            </div>

            <ngb-accordion class="col-12 entity-details" activeIds="panelAnswers">

              <ngb-panel *ngIf="log.answers" id="panelAnswers">
                <ng-template ngbPanelTitle>
                  <div class="d-flex justify-content-between">
                    <div>
                      <fa-icon [fixedWidth]="true" icon="pen-square"></fa-icon>
                      &nbsp;
                      <span jhiTranslate="form.answers"></span>
                      &nbsp;
                      <span *ngIf="totalActiveFormAnswers(log)"
                            class="badge badge-secondary">{{totalActiveFormAnswers(log)}}</span>
                    </div>
                  </div>
                </ng-template>
                <ng-template ngbPanelContent>
                  <div *ngIf="!log.answers.length"
                       class="list-empty-alert alert alert-light text-center" role="alert">
                    <div class="font-weight-bold pb-2">
                      <fa-icon [fixedWidth]="true" icon="exclamation-circle"></fa-icon>
                      &nbsp;
                      <span jhiTranslate="global.empty.list.alt"></span>
                    </div>
                  </div>
                  <div *ngIf="log.answers.length" class="table-responsive">
                    <table class="table table-hover">
                      <thead class="thead-light">
                      <tr>
                        <th scope="col" class="icon"></th>
                        <th scope="col" class="text-center">
                          <span jhiTranslate="global.field.id"></span>
                        </th>
                        <th scope="col">
                          <span jhiTranslate="form.formAnswer.question"></span>
                        </th>
                        <th scope="col">
                          <span jhiTranslate="form.formAnswer.answer"></span>
                        </th>
                        <th scope="col">
                          <span jhiTranslate="form.score"></span>
                        </th>
                        <th scope="col">
                          <span jhiTranslate="form.startedDate"></span>
                        </th>
                        <th scope="col">
                          <span jhiTranslate="form.finishedDate"></span>
                        </th>
                        <th scope="col">
                          <span jhiTranslate="form.approvalDate"></span>
                        </th>
                        <th scope="col">
                          <span jhiTranslate="form.approvalUser"></span>
                        </th>
                        <th scope="col">
                          <span jhiTranslate="form.formAnswer.approvalObs"></span>
                        </th>
                        <th scope="col">
                          <span jhiTranslate="global.field.status"></span>
                        </th>
                      </tr>
                      </thead>
                      <tbody>
                      <tr *ngFor="let answer of log.answers; trackBy: trackIdentity"
                          [class]="{'notActive': !answer.active}">
                        <td class="text-center dirty-warning">
                          <fa-icon *ngIf="answer._edited" [fixedWidth]="true" icon="exclamation-triangle"
                                   title="{{'global.messages.dirty.warning' | translate}}"></fa-icon>
                        </td>
                        <td class="text-center">
                          <a *ngIf="answer.id" href="javascript:void(0);"
                             (click)="showFormAnswerDetails(answer)">{{ answer.id }}</a>
                        </td>
                        <td>{{ answer.question?.question }}</td>
                        <td>
                          <app-frm-form-answer-value [answer]="answer"></app-frm-form-answer-value>
                        </td>
                        <td>{{ answer.score | number:'1.2-2' }}</td>
                        <td>{{ answer.startedDate | dateMoment:'dd/MM/yyyy HH:mm' }}</td>
                        <td>{{ answer.finishedDate | dateMoment:'dd/MM/yyyy HH:mm' }}</td>
                        <td>{{ answer.approvalDate | dateMoment:'dd/MM/yyyy HH:mm' }}</td>
                        <td>
                          <app-view-route-link [entity]="answer.approvalUser" [param]="answer.approvalUser?.login"
                                               route="usr/users" target="_blank"></app-view-route-link>
                        </td>
                        <td>{{ answer.approvalObs }}</td>
                        <td>
                          <app-status-label [status]="answer.status"></app-status-label>
                        </td>
                      </tr>
                      </tbody>
                    </table>
                  </div>
                </ng-template>
              </ngb-panel>

            </ngb-accordion>

          </ng-template>
        </ngb-panel>

        <ngb-panel id="panelAudit">
          <ng-template ngbPanelTitle>
            <fa-icon [fixedWidth]="true" icon="exclamation-circle"></fa-icon>
            &nbsp;
            <span>{{'entity.panels.audit' | translate}}</span>
          </ng-template>
          <ng-template ngbPanelContent>
            <div class="row justify-content-center">
              <dl class="col-12">
                <dt><span jhiTranslate="global.field.uuid"></span></dt>
                <dd>{{ changeLog.uuid }}</dd>
                <dt><span jhiTranslate="global.field.active"></span></dt>
                <dd>
                  <app-bool-icon [value]="changeLog.active"></app-bool-icon>
                </dd>
                <dt><span jhiTranslate="global.field.createdBy"></span></dt>
                <dd>{{ changeLog.createdBy }}</dd>
                <dt><span jhiTranslate="global.field.createdDate"></span></dt>
                <dd>{{ changeLog.createdDate | dateMoment:'dd/MM/yyyy HH:mm' }}</dd>
                <dt><span jhiTranslate="global.field.lastModifiedBy"></span></dt>
                <dd>{{ changeLog.lastModifiedBy }}</dd>
                <dt><span jhiTranslate="global.field.lastModifiedDate"></span></dt>
                <dd>{{ changeLog.lastModifiedDate | dateMoment:'dd/MM/yyyy HH:mm' }}</dd>
              </dl>
            </div>
          </ng-template>
        </ngb-panel>
      </ngb-accordion>
    </div>
  </div>

  <div class="modal-footer">
    <div class="form-row">
      <div class="col-12">
        <div class="float-right">
          <button type="button" (click)="onCloseClicked()" class="btn btn-default">
            <fa-icon [fixedWidth]="true" icon="times"></fa-icon>
            &nbsp;
            <span jhiTranslate="entity.action.close"></span>
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
