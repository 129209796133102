import moment from 'moment';
import {Moment} from 'moment';
import {BaseEntity, IBaseEntity} from 'app/shared/model/root/base-entity.model';
import {Criteria} from 'app/shared/filter/criteria.model';
import {Status} from 'app/shared/model/enumerations/status.enum';
import {Stage} from 'app/shared/model/enumerations/stage.enum';
import {Company, ICompany} from 'app/shared/model/company/company.model';
import {allPaymentGatewayVendors, PaymentGatewayVendor} from 'app/shared/model/enumerations/payment-gateway-vendor.enum';

export interface IGateway extends IBaseEntity {
  company?: ICompany;
  title?: string;
  vendor?: PaymentGatewayVendor;
  status?: Status;
  stage?: Stage;
  statementDescriptor?: string;
  verifyCard?: boolean;
  publicKey?: string;
  secretKey?: string;
  merchantCode?: string;
  accountCode?: string;
  endpointUrl?: string;
  authorizationToken?: string;
  sellerAuthToken?: string;
}

export class Gateway extends BaseEntity implements IGateway {
  constructor(
    public id?: number,
    public uuid?: string,
    public createdBy?: string,
    public createdDate?: Moment,
    public lastModifiedBy?: string,
    public lastModifiedDate?: Moment,
    public active?: boolean,
    public company?: ICompany,
    public title?: string,
    public vendor?: PaymentGatewayVendor,
    public status?: Status,
    public stage?: Stage,
    public statementDescriptor?: string,
    public verifyCard?: boolean,
    public publicKey?: string,
    public secretKey?: string,
    public merchantCode?: string,
    public accountCode?: string,
    public endpointUrl?: string,
    public authorizationToken?: string,
    public sellerAuthToken?: string,
    public _label?: string,
    public _edited?: boolean
  ) {
    super(id, uuid, createdBy, createdDate, lastModifiedBy, lastModifiedDate, active, _label, _edited);
  }

  public static allowedVendors(): PaymentGatewayVendor[] {
    return allPaymentGatewayVendors();
  }

  public static allowedStatus(): Status[] {
    return [Status.DRAFT, Status.ACTIVE, Status.INACTIVE];
  }

  public static allowedStages(): Stage[] {
    return [Stage.DEVELOPMENT, Stage.PRODUCTION, Stage.TEST];
  }

  public static criterias(): Criteria<Gateway>[] {
    return Criteria.buildFrom(this.example()).map(criteria =>
      criteria.property.name === 'vendor'
        ? criteria.asEnum(() => this.allowedVendors(), 'paymentGatewayVendor')
        : criteria.property.name === 'stage'
        ? criteria.asEnum(() => this.allowedStages())
        : criteria.property.name === 'status'
        ? criteria.asEnum(() => this.allowedStatus())
        : criteria
    );
  }

  public static example(): Gateway {
    return new Gateway(
      0,
      '',
      '',
      moment(new Date()),
      '',
      moment(new Date()),
      false,
      new Company(),
      '',
      PaymentGatewayVendor.MUNDIPAGG,
      Status.ACTIVE,
      Stage.DEVELOPMENT,
      '',
      false,
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      false
    );
  }
}
