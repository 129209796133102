import {Routes} from '@angular/router';

export const sessionRoutes: Routes = [
  {
    path: 'sss/bookmarks',
    loadChildren: () => import('./bookmark/bookmark.module').then(m => m.AppBookmarkModule),
    data: {
      pageTitle: 'bookmark.home.title'
    }
  },
  {
    path: 'sss/devices',
    loadChildren: () => import('./device/device.module').then(m => m.AppDeviceModule),
    data: {
      pageTitle: 'device.home.title'
    }
  },
  {
    path: 'sss/trackings',
    loadChildren: () => import('./tracking/tracking.module').then(m => m.AppTrackingModule),
    data: {
      pageTitle: 'tracking.home.title'
    }
  }
];
