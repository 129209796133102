import {Injectable} from '@angular/core';
import {NgbModal, NgbModalRef} from '@ng-bootstrap/ng-bootstrap';
import {VideoModalComponent} from 'app/shared/video-modal/video-modal.component';
import {IVideoEntity} from 'app/shared/video-modal/video-entity.model';

@Injectable({providedIn: 'root'})
export class VideoModalService {
  private isOpen = false;
  private instance?: NgbModalRef;

  constructor(private modalService: NgbModal) {}

  show(entity: IVideoEntity, close?: Function): void {
    if (this.isOpen) return;

    this.isOpen = true;

    this.instance = this.modalService.open(VideoModalComponent, {size: 'lg', backdrop: 'static'});
    this.instance.componentInstance.entity = entity;
    this.instance.componentInstance.close = close;

    this.instance.result.finally(() => (this.isOpen = false));
  }

  confirm(): void {
    if (!this.isOpen || !this.instance) return;
    this.instance.close();
  }

  dismiss(): void {
    if (!this.isOpen || !this.instance) return;
    this.instance.dismiss();
  }
}
