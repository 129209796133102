export enum CardBrand {
  NOT_APPLICABLE = 'NOT_APPLICABLE',
  ELO = 'ELO',
  MASTERCARD = 'MASTERCARD',
  VISA = 'VISA',
  AMEX = 'AMEX',
  JCB = 'JCB',
  AURA = 'AURA',
  HIPERCARD = 'HIPERCARD',
  DINERS = 'DINERS',
  DISCOVER = 'DISCOVER',
  UNKNOWN = 'UNKNOWN'
}

export const allCardBrands = () => Object.values(CardBrand);
