import {IconProp} from '@fortawesome/fontawesome-svg-core';
import {ButtonColor} from 'app/shared/layout/window/button.model';

export class Toast {
  constructor(
    public color: ButtonColor,
    public body: string,
    public bodyParams?: any,
    public showHeader: boolean = false,
    public header?: string,
    public headerParams?: any,
    public bodyIcon?: IconProp,
    public headerIcon?: IconProp,
    public autoHide: boolean = true,
    public delay: number = 4000,
    public dismiss?: Function
  ) {}

  public static default(body: string): Toast {
    return new Toast('default', body);
  }

  public static primary(body: string): Toast {
    return new Toast('primary', body);
  }

  public static secondary(body: string): Toast {
    return new Toast('secondary', body);
  }

  public static info(body: string): Toast {
    return new Toast('info', body);
  }

  public static success(body: string): Toast {
    return new Toast('success', body);
  }

  public static warning(body: string): Toast {
    return new Toast('warning', body);
  }

  public static danger(body: string): Toast {
    return new Toast('danger', body);
  }

  getTextColor(): string {
    switch (this.color) {
      case 'primary':
      case 'secondary':
      case 'success':
      case 'danger':
      case 'info':
        return 'text-white';
      case 'warning':
      case 'default':
        return 'text-dark';
      default:
        return '';
    }
  }

  withBodyParams(bodyParams: any): Toast {
    this.bodyParams = bodyParams;
    return this;
  }

  withBodyIcon(bodyIcon: IconProp): Toast {
    this.bodyIcon = bodyIcon;
    return this;
  }

  withShowHeader(showHeader: boolean): Toast {
    this.showHeader = showHeader;
    return this;
  }

  withHeader(header: string): Toast {
    this.header = header;
    return this;
  }

  withHeaderParams(headerParams: any): Toast {
    this.headerParams = headerParams;
    return this;
  }

  withHeaderIcon(headerIcon: IconProp): Toast {
    this.headerIcon = headerIcon;
    return this;
  }

  withAutoHide(autoHide: boolean): Toast {
    this.autoHide = autoHide;
    return this;
  }

  withDelay(delay: number): Toast {
    this.delay = delay;
    return this;
  }

  withDismiss(dismiss: Function): Toast {
    this.dismiss = dismiss;
    return this;
  }
}
