import {Injectable} from '@angular/core';
import {NgbModal, NgbModalRef} from '@ng-bootstrap/ng-bootstrap';
import {SearchCnpjModalComponent} from 'app/shared/company/search-cnpj-modal.component';

@Injectable({providedIn: 'root'})
export class SearchCnpjModalService {
  private isOpen = false;
  private instance?: NgbModalRef;

  constructor(private modalService: NgbModal) {}

  show(confirm: Function, inputCnpj?: string, cancel?: Function): void {
    if (this.isOpen) return;

    this.isOpen = true;

    this.instance = this.modalService.open(SearchCnpjModalComponent, {size: 'lg', backdrop: 'static'});
    this.instance.componentInstance.inputCnpj = inputCnpj;
    this.instance.componentInstance.confirm = confirm;
    this.instance.componentInstance.cancel = cancel;

    this.instance.result.finally(() => (this.isOpen = false));
  }

  confirm(): void {
    if (!this.isOpen || !this.instance) return;
    this.instance.close();
  }

  dismiss(): void {
    if (!this.isOpen || !this.instance) return;
    this.instance.dismiss();
  }
}
