import {Component, OnDestroy, OnInit} from '@angular/core';
import {unsubscribe} from 'app/shared/util/react-util';
import {Subscription} from 'rxjs';
import {WidgetService} from 'app/shared/services/erp/widget.service';
import {finalize, flatMap, map} from 'rxjs/operators';
import {IWidgetGroup} from 'app/shared/model/erp/widget-group.model';
import {IWidget} from 'app/shared/model/erp/widget.model';
import {WidgetUserService} from 'app/shared/services/erp/widget-user.service';
import {convertCriteria} from 'app/shared/util/third-party-entity-util';
import {AccountService} from 'app/core/auth/account.service';
import {IWidgetUser} from 'app/shared/model/erp/widget-user.model';
import {WidgetFilter} from 'app/shared/model/enumerations/widget-filter.enum';

@Component({
  selector: 'app-widget-groups',
  templateUrl: './widgets-groups.component.html'
})
export class WidgetGroupsComponent implements OnInit, OnDestroy {

  private subscriptions: Subscription[] = [];

  isLoading: boolean = false;

  widgets: IWidget[] = [];
  widgetsByUser: IWidget[] = [];
  widgetGroups: IWidgetGroup[] = [];
  widgetGroupsByUser: IWidgetGroup[] = [];
  widgetUsers: IWidgetUser[] = [];

  filterWidgetBy: WidgetFilter = WidgetFilter.BY_USER;

  constructor(private widgetService: WidgetService, private widgetUserService: WidgetUserService, private accountService: AccountService) {
  }

  ngOnInit(): void {
    this.loadWidgets();
  }

  loadWidgets() {
    this.isLoading = true;
    this.subscriptions.push(
      this.accountService.identity()
        .pipe( //
          finalize(() => {
            this.isLoading = false;
          }), //
          map(account => account.email), //
          map(accountEmail => convertCriteria({'userEmail.equals': accountEmail})), //
          flatMap(criteria => this.widgetUserService.query(criteria)), //
          map(res => res?.body ?? []), //
          map(widgetUsers => {
            this.widgetUsers = widgetUsers;
          }), //
          flatMap(() => this.widgetService.query()))
        .subscribe(res => {
          this.widgets = res?.body ?? [];
          this.widgetsByUser = this.widgets //
            .filter(widget => !!this.widgetUsers.find(widgetUser => widgetUser.widget?.id === widget.id));
          this.separateWidgetsByGroups();
          this.separateWidgetsByUserByGroups();
        }));
  }

  reloadWidgets() {

    this.subscriptions.push(
      this.accountService.identity()
        .pipe( //
          finalize(() => {
            this.isLoading = false;
          }), //
          map(account => account.email), //
          map(accountEmail => convertCriteria({'userEmail.equals': accountEmail})), //
          flatMap(criteria => this.widgetUserService.query(criteria)), //
          map(res => res?.body ?? []), //
          map(widgetUsers => {
            this.widgetUsers = widgetUsers;
          }), //
          flatMap(() => this.widgetService.query()))
        .subscribe(res => {
          this.widgets = res?.body ?? [];
          this.widgetsByUser = this.widgets //
            .filter(widget => !!this.widgetUsers.find(widgetUser => widgetUser.widget?.id === widget.id));
          this.separateWidgetsByGroups();
          this.separateWidgetsByUserByGroups();
        }));
  }

  separateWidgetsByGroups() {
    this.widgetGroups = [];
    this.widgets.forEach(widget => {
      const widgetGroup = this.widgetGroups.find(widgetGroup => widgetGroup.name === widget.groupName);
      if (!widgetGroup) {
        this.widgetGroups.push({
          'name': widget.groupName,
          'widgets': [widget]
        } as IWidgetGroup);
      } else {
        widgetGroup.widgets.push(widget);
      }
    });
  }

  separateWidgetsByUserByGroups() {
    this.widgetGroupsByUser = [];
    this.widgetsByUser.forEach(widget => {
      const widgetGroup = this.widgetGroupsByUser.find(widgetGroup => widgetGroup.name === widget.groupName);
      if (!widgetGroup) {
        this.widgetGroupsByUser.push({
          'name': widget.groupName,
          'widgets': [widget]
        } as IWidgetGroup);
      } else {
        widgetGroup.widgets.push(widget);
      }
    });
  }

  selectedGroup(): IWidgetGroup[] {
    switch (this.filterWidgetBy) {
      case WidgetFilter.BY_USER:
        return this.widgetGroupsByUser;
      case WidgetFilter.ALL_WIDGETS:
        return this.widgetGroups;
    }
    return [];
  }

  applyAllWidgets() {
    this.filterWidgetBy = WidgetFilter.ALL_WIDGETS;
  }

  applyUserWidgets() {
    this.filterWidgetBy = WidgetFilter.BY_USER;
  }

  ngOnDestroy(): void {
    unsubscribe(this.subscriptions);
  }
}
