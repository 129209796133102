import {Injectable} from '@angular/core';
import {HttpClient, HttpResponse} from '@angular/common/http';
import {Observable} from 'rxjs';

import {SERVER_API_URL} from 'app/app.constants';

@Injectable({providedIn: 'root'})
export class PublicConfigurationService {
  public resourceUrl = `${SERVER_API_URL}api/public/login/configurations`;

  constructor(protected http: HttpClient) {
  }

  showAccount(): Observable<HttpResponse<boolean>> {
    return this.http
      .get<boolean>(`${this.resourceUrl}/show-account`, {observe: 'response'});
  }

}
