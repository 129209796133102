import {Injectable} from '@angular/core';
import {HttpClient, HttpResponse} from '@angular/common/http';
import {Observable} from 'rxjs';

import {SERVER_API_URL} from 'app/app.constants';

interface UserPageStatus {
  page: string;
  columnsMap: string;
}

type EntityResponseType = HttpResponse<UserPageStatus>;

@Injectable({providedIn: 'root'})
export class EntityMenuColumnService {
  public resourceUrl = `${SERVER_API_URL}api/usr/users-pages-status`;

  constructor(private http: HttpClient) {}

  saveCurrentStatus(page: string, columnMap: string): Observable<EntityResponseType> {
    return this.http.put<UserPageStatus>(`${this.resourceUrl}/${page}`, columnMap, {observe: 'response'});
  }

  findByCurrentUserAndPage(page: string): Observable<EntityResponseType> {
    return this.http.get<UserPageStatus>(`${this.resourceUrl}/${page}`, {observe: 'response'});
  }
}
