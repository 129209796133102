import {NgModule} from '@angular/core';
import {RouterModule} from '@angular/router';

import {AppSharedModule} from 'app/shared/shared.module';
import {HOME_ROUTES} from './home.route';
import {HomeComponent} from './home.component';
import {WidgetGroupsComponent} from 'app/home/widget-groups/widgets-groups.component';
import {WidgetComponent} from 'app/home/widget-groups/widget/widget.component';
import {WidgetsPreferencesMenuComponent} from 'app/home/widget-groups/user-widgets-preferences-menu/widgets-preferences-menu.component';

@NgModule({
  imports: [AppSharedModule, RouterModule.forChild(HOME_ROUTES)],
  declarations: [
    HomeComponent,
    WidgetComponent,
    WidgetGroupsComponent,
    WidgetsPreferencesMenuComponent
  ]
})
export class AppHomeModule {
}
