<div *ngFor="let content of generalAlertsContents">
  <div class="alert alert-warning text"
       role="alert">{{ content }}</div>
</div>


<div class="home container-fluid">
  <div class="row justify-content-center">
    <div class="col-12">
      <app-widget-groups></app-widget-groups>
    </div>
  </div>
</div>