import {Component, Input} from '@angular/core';
import {MaskService} from 'app/shared/mask/mask.service';

@Component({
  selector: 'app-call-link',
  template: `
    <a *ngIf="value" [href]="'tel:' + value">
      <span>
        {{ applyMask ? (value | mask: getMask()) : value }}
      </span>
      &nbsp;
      <fa-icon [fixedWidth]="true"icon="phone"></fa-icon>
    </a>
  `
})
export class CallLinkComponent {
  @Input()
  value: string | undefined = undefined;

  @Input()
  applyMask = true;

  constructor(private maskService: MaskService) {}

  getMask(): string {
    return this.maskService.phone();
  }
}
