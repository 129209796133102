import {Routes} from '@angular/router';

export const warehouseRoutes: Routes = [
  {
    path: 'whs/warehouses',
    loadChildren: () => import('./warehouse/warehouse.module').then(m => m.AppWarehouseModule),
    data: {
      pageTitle: 'warehouse.home.title'
    }
  },
  {
    path: 'whs/warehouses-launches',
    loadChildren: () => import('./warehouse-launch/warehouse-launch.module').then(m => m.AppWarehouseLaunchModule),
    data: {
      pageTitle: 'warehouseLaunch.home.title'
    }
  }
];
