import {Injectable} from '@angular/core';
import {NgbModal, NgbModalRef} from '@ng-bootstrap/ng-bootstrap';
import {UploadImageModalComponent} from 'app/shared/upload/upload-image-modal.component';
import {FileFunction, UploadImageOptions} from 'app/shared/upload/upload-options.model';

@Injectable({providedIn: 'root'})
export class UploadImageModalService {
  private isOpen = false;
  private instance?: NgbModalRef;

  constructor(private modalService: NgbModal) {}

  show(imageUploaded?: FileFunction, cancel?: Function, options?: UploadImageOptions): void {
    if (this.isOpen) return;

    this.isOpen = true;

    this.instance = this.modalService.open(UploadImageModalComponent, {
      size: 'lg',
      backdrop: 'static',
      container: options?.container ?? 'body'
    });
    this.instance.componentInstance.options =
      options ||
      ({
        fileExtension: '.jpg,.jpeg,.png',
        maintainAspectRatio: true,
        aspectRatio: 1,
        minWidth: 500,
        minHeight: 500,
        resizeToWidth: 500,
        resizeToHeight: 500
      } as UploadImageOptions);
    this.instance.componentInstance.imageUploaded = imageUploaded;
    this.instance.componentInstance.cancel = cancel;

    this.instance.result.finally(() => (this.isOpen = false));
  }

  confirm(): void {
    if (!this.isOpen || !this.instance) return;
    this.instance.close();
  }

  dismiss(): void {
    if (!this.isOpen || !this.instance) return;
    this.instance.dismiss();
  }
}
