import {Component, Input} from '@angular/core';
import {IChangeLog} from 'app/shared/model/admin/audit/change-log.model';
import {MaskService} from 'app/shared/mask/mask.service';
import {IBaseEntity} from 'app/shared/model/root/base-entity.model';
import {FormChangeLogDetailModalService} from 'app/entities/form/form/form-change-log-detail-modal.service';
import {FormActionsService} from 'app/entities/form/form/form-actions.service';
import {IFormAnswer} from 'app/shared/model/form/form-answer.model';
import {Form, IForm} from 'app/shared/model/form/form.model';

@Component({
  selector: 'app-frm-form-change-log-detail-modal',
  templateUrl: './form-change-log-detail-modal.component.html'
})
export class FormChangeLogDetailModalComponent {
  @Input()
  changeLog!: IChangeLog;

  owners = Form.allowedOwner();

  close?: Function;

  constructor(
    private service: FormChangeLogDetailModalService,
    private formActionsService: FormActionsService,
    public mask: MaskService
  ) {
  }

  trackIdentity(index: number, item: IBaseEntity): any {
    return item.id ?? item.uuid;
  }

  totalActiveFormAnswers(form: IForm): number {
    return this.formActionsService.totalActiveFormAnswers(form);
  }

  showFormAnswerDetails(answer: IFormAnswer): void {
    this.formActionsService.showFormAnswerDetails(answer);
  }

  totalActiveFormChangeLogs(form: IForm): number {
    return this.formActionsService.totalActiveFormChangeLogs(form);
  }

  onCloseClicked(): void {
    this.close && this.close();
    this.service.close();
  }
}
