import {Routes} from '@angular/router';

export const scrumRoutes: Routes = [
  {
    path: 'scr/activities',
    loadChildren: () => import('./activity/activity.module').then(m => m.AppActivityModule),
    data: {
      pageTitle: 'activity.home.title'
    }
  },
  {
    path: 'scr/projects',
    loadChildren: () => import('./project/project.module').then(m => m.AppProjectModule),
    data: {
      pageTitle: 'project.home.title'
    }
  },
  {
    path: 'scr/registers',
    loadChildren: () => import('./register/register.module').then(m => m.AppRegisterModule),
    data: {
      pageTitle: 'register.home.title'
    }
  }
];
