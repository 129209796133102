import {Component, Input} from '@angular/core';
import {ButtonColor} from 'app/shared/layout/window/button.model';
import {UnitType} from 'app/shared/model/enumerations/unit-type.enum';

@Component({
  selector: 'app-unit-type-label',
  template: `
    <span *ngIf="unitType" class="badge" [ngClass]="'badge-' + color()">{{ 'unitType.' + unitType | translate }}</span>
  `
})
export class UnitTypeLabelComponent {
  @Input()
  unitType: UnitType | null = null;

  color(): ButtonColor {
    if (!this.unitType) return 'default';

    switch (this.unitType) {
      case UnitType.UNIT:
        return 'primary';

      case UnitType.MONETARY:
        return 'success';

      default:
        return 'default';
    }
  }
}
