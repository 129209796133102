import {Component, Input} from '@angular/core';
import {ButtonColor} from 'app/shared/layout/window/button.model';
import {ScrumRole} from 'app/shared/model/enumerations/scrum-role.enum';

@Component({
  selector: 'app-scrum-role-label',
  template: `
    <span *ngIf="role" class="badge" [ngClass]="'badge-' + color()" [title]="'scrumRole.' + role | translate">
      <fa-icon *ngIf="getIcon() as icon" [fixedWidth]="true" [icon]="icon | asIcon"></fa-icon>
      &nbsp;
      {{ 'scrumRole.' + role | translate }}
    </span>
  `
})
export class ScrumRoleLabelComponent {
  @Input()
  role: ScrumRole | null = null;

  getIcon(): string {

    if (!this.role) {
      return null;
    }

    switch (this.role) {
      case ScrumRole.PRODUCT_OWNER:
        return 'user-crown';
      case ScrumRole.STAKEHOLDER:
        return 'user-tie';
      case ScrumRole.SCRUM_MASTER:
        return 'user-chart';
      case ScrumRole.REQUIREMENT:
        return 'user-edit';
      case ScrumRole.DESIGN:
        return 'chalkboard-teacher';
      case ScrumRole.DEVELOPER:
        return 'user-ninja';
      case ScrumRole.QA:
        return 'user-check';
      case ScrumRole.DEVOPS:
        return 'user-cog';
    }
  }

  color(): ButtonColor | string {
    if (!this.role) {
      return 'default';
    }

    switch (this.role) {
      case ScrumRole.PRODUCT_OWNER:
        return 'dark';
      case ScrumRole.STAKEHOLDER:
        return 'light';
      case ScrumRole.SCRUM_MASTER:
        return 'success';
      case ScrumRole.REQUIREMENT:
        return 'secondary';
      case ScrumRole.DESIGN:
        return 'info';
      case ScrumRole.DEVELOPER:
        return 'primary';
      case ScrumRole.QA:
        return 'danger';
      case ScrumRole.DEVOPS:
        return 'warning';
    }
  }
}
