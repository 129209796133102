import {GenericModalService} from 'app/shared/modal/generic-modal.service';
import {ButtonBuilder} from 'app/shared/layout/window/button-builder';
import {TranslateService} from '@ngx-translate/core';
import {Injectable, OnDestroy} from '@angular/core';
import {Subscription} from 'rxjs';
import {Router} from '@angular/router';
import {unsubscribe} from 'app/shared/util/react-util';
import {Authority} from 'app/shared/model/enumerations/authority.enum';
import {FeatureAuthority} from 'app/shared/model/enumerations/feature-authority.enum';
import {AccountService} from 'app/core/auth/account.service';
import {FormService} from 'app/shared/services/form/form.service';
import {IForm} from 'app/shared/model/form/form.model';
import {IFormAnswer} from 'app/shared/model/form/form-answer.model';
import {IFormAnswerOption} from 'app/shared/model/form/form-answer-option.model';
import {FormAnswerOptionDetailModalService} from 'app/entities/form/form/form-answer-option-detail-modal.service';
import {FormAnswerDetailModalService} from 'app/entities/form/form/form-answer-detail-modal.service';
import {FormQuestionType} from 'app/shared/model/enumerations/form-question-type.enum';
import {FormAnswerRejectModalService} from 'app/entities/form/form/form-answer-reject-modal.service';
import {IChangeLog} from 'app/shared/model/admin/audit/change-log.model';
import {FormChangeLogDetailModalService} from 'app/entities/form/form/form-change-log-detail-modal.service';

@Injectable({providedIn: 'root'})
export class FormActionsService implements OnDestroy {
  private subscriptions: Subscription[] = [];

  constructor(
    private formService: FormService,
    private router: Router,
    private genericModalService: GenericModalService,
    private translateService: TranslateService,
    private formAnswerDetailModalService: FormAnswerDetailModalService,
    private formAnswerOptionDetailModalService: FormAnswerOptionDetailModalService,
    private formAnswerRejectModalService: FormAnswerRejectModalService,
    private formChangeLogDetailModalService: FormChangeLogDetailModalService,
    private accountService: AccountService
  ) {
  }

  delete(form: IForm, successCallback?: Function): void {
    this.genericModalService.show('entity.delete.title', this.translateService.instant('form.modals.delete.question', {id: form._label}), [
      ButtonBuilder.cancel(() => this.genericModalService.dismiss()),
      ButtonBuilder.delete(() => {
        this.subscriptions.push(this.formService.delete(form.id!).subscribe(result => successCallback && successCallback(result)));
        this.genericModalService.confirm();
      })
    ]);
  }

  goToList(): void {
    this.router.navigate(['/frm/forms']);
  }

  goToView(form: IForm): void {
    this.router.navigate(['/frm/forms', form.id!, 'view']);
  }

  goToViewOrList(form?: IForm): void {
    form && form.id ? this.goToView(form) : this.goToList();
  }

  goToNew(): void {
    this.router.navigate(['/frm/forms/new']);
  }

  goToEdit(form: IForm): void {
    this.router.navigate(['/frm/forms', form.id!, 'edit']);
  }

  showFormAnswerDetails(formAnswer: IFormAnswer): void {
    this.formAnswerDetailModalService.show(formAnswer);
  }

  totalActiveFormAnswers(form: IForm): number {
    return form.answers?.filter(a => a.active).length || 0;
  }

  showFormAnswerOptionDetails(formAnswerOption: IFormAnswerOption): void {
    this.formAnswerOptionDetailModalService.show(formAnswerOption);
  }

  totalActiveFormAnswerOptions(formAnswer: IFormAnswer): number {
    return formAnswer.options?.filter(o => o.active).length || 0;
  }

  hasPrivilegeToView(): boolean {
    return this.accountService.hasAnyAuthority([Authority.SYSTEM_ADMIN, FeatureAuthority.FRM_FORM_VIEW]);
  }

  hasPrivilegeToCreate(): boolean {
    return this.accountService.hasAnyAuthority([Authority.SYSTEM_ADMIN, FeatureAuthority.FRM_FORM_CREATE]);
  }

  hasPrivilegeToEdit(): boolean {
    return this.accountService.hasAnyAuthority([Authority.SYSTEM_ADMIN, FeatureAuthority.FRM_FORM_EDIT]);
  }

  hasPrivilegeToDelete(): boolean {
    return this.accountService.hasAnyAuthority([Authority.SYSTEM_ADMIN, FeatureAuthority.FRM_FORM_DELETE]);
  }

  totalActiveFormChangeLogs(form: IForm): number {
    return form.changeLogs?.filter(l => l.active).length || 0;
  }

  showFormChangeLogDetails(changeLog: IChangeLog): void {
    this.formChangeLogDetailModalService.show(changeLog);
  }

  calcAnswerScore(answer: IFormAnswer): number {
    if (!answer || !answer.question) {
      return null;
    }

    switch (answer.question.type) {
      case FormQuestionType.YES_NO:
        return answer.answer || ['true', 'yes', '1'].includes(`${answer.answer}`.toLowerCase()) ? answer.question.weight : 0.0;

      case FormQuestionType.DATE:
        return answer.answer ? answer.question.weight : 0.0;

      case FormQuestionType.IMAGE_UPLOAD:
      case FormQuestionType.DOC_UPLOAD:
        return answer.answerFile ? answer.question.weight : 0.0;

      case FormQuestionType.SINGLE_CHOICE:
      case FormQuestionType.MULTIPLE_CHOICE: {
        const scores = (answer.options || []).filter(o => o.active).map(o => o.option?.value * answer.question.weight ?? 0.0);

        return scores.length ? scores.reduce((previous, current) => previous + current) : 0.0;
      }

      default:
        return `${answer.answer}`.trim().length ? answer.question.weight : 0.0;
    }
  }

  calcFormScore(form: IForm): number {
    if (!form) {
      return null;
    }
    const scores = form.answers.filter(a => a.active).map(a => a.score);
    return scores.length ? scores.reduce((previous, current) => previous + current) : 0.0;
  }

  rejectAnswer(answer: IFormAnswer, successCallback?: Function): void {
    this.formAnswerRejectModalService.show(answer, successCallback);
  }

  ngOnDestroy(): void {
    unsubscribe(this.subscriptions);
  }
}
