import moment, {Moment} from 'moment';
import {BaseEntity, IBaseEntity} from 'app/shared/model/root/base-entity.model';
import {Criteria} from 'app/shared/filter/criteria.model';
import {EntityType} from 'app/shared/model/enumerations/entity-type.enum';
import {Status} from 'app/shared/model/enumerations/status.enum';

export interface IMetadata extends IBaseEntity {
  entityType?: EntityType;
  entityUuid?: string;
  status?: Status;
  addToSitemap?: boolean;
  keywords?: string;
  metaDescription?: string;
  pageTitle?: string;
  pageUrl?: string;
  sortWeight?: number;
  lastIndexingAt?: Moment;
  indexContent?: string;
  tsvectorData?: string;
}

export class Metadata extends BaseEntity implements IMetadata {
  constructor(
    public id?: number,
    public uuid?: string,
    public createdBy?: string,
    public createdDate?: Moment,
    public lastModifiedBy?: string,
    public lastModifiedDate?: Moment,
    public active?: boolean,
    public entityType?: EntityType,
    public entityUuid?: string,
    public status?: Status,
    public addToSitemap?: boolean,
    public keywords?: string,
    public metaDescription?: string,
    public pageTitle?: string,
    public pageUrl?: string,
    public sortWeight?: number,
    public lastIndexingAt?: Moment,
    public indexContent?: string,
    public tsvectorData?: string,
    public _label?: string,
    public _edited?: boolean
  ) {
    super(id, uuid, createdBy, createdDate, lastModifiedBy, lastModifiedDate, active, _label, _edited);
  }

  public static allowedEntityTypes(): EntityType[] {
    return [
      EntityType.COM_COMPANY_PARAMETER,
      EntityType.CNT_POST,
      EntityType.PRO_BRAND,
      EntityType.PRO_CATEGORY,
      EntityType.PRO_PRODUCT
    ];
  }

  public static allowedStatus(): Status[] {
    return [Status.PENDING_INDEXING, Status.INDEXED];
  }

  public static criterias(): Criteria<Metadata>[] {
    return Criteria.buildFrom(this.example())
      .filter(criteria => !['tsvectorData'].includes(criteria.property.name))
      .map(criteria =>
        criteria.property.name === 'entityType'
          ? criteria.asEnum(() => this.allowedEntityTypes())
          : criteria.property.name === 'status'
            ? criteria.asEnum(() => this.allowedStatus())
            : criteria
      );
  }

  public static example(): Metadata {
    return new Metadata(
      0,
      '',
      '',
      moment(new Date()),
      '',
      moment(new Date()),
      false,
      EntityType.USR_USER,
      '',
      Status.NEW,
      false,
      '',
      '',
      '',
      '',
      0,
      moment(new Date()),
      '',
      '',
      '',
      false
    );
  }
}
