import {Injectable} from '@angular/core';
import {NgbDateParserFormatter, NgbDateStruct} from '@ng-bootstrap/ng-bootstrap';
import {StringUtilService} from 'app/shared/util/string-util.service';

/**
 * This Service handles how the date is rendered and parsed from keyboard i.e. in the bound input field.
 */
@Injectable()
export class NgbDatepickerFormatter extends NgbDateParserFormatter {
  readonly separator = '/';

  parse(value: string): NgbDateStruct | null {
    if (value) {
      const date = value.split(this.separator);
      return {
        day: parseInt(date[0], 10),
        month: parseInt(date[1], 10),
        year: parseInt(date[2], 10)
      };
    }
    return null;
  }

  format(date: NgbDateStruct | null): string {
    return date
      ? `${StringUtilService.leftZero(date.day)}${this.separator}${StringUtilService.leftZero(date.month)}${this.separator}${date.year}`
      : '';
  }
}
