import {Pipe, PipeTransform} from '@angular/core';
import {IFile} from 'app/shared/model/admin/file.model';
import {SERVERLESS_API_URL} from 'app/app.constants';

@Pipe({name: 'resizeImage'})
export class ResizeImagePipe implements PipeTransform {
  constructor() {}

  transform(file: IFile, width: number, height?: number) {
    return `${SERVERLESS_API_URL}/sls/api/resize/w${width}${height > 0 ? `h${height}` : ''}/${file.name}`;
  }
}
