import {Injectable, Injector} from '@angular/core';
import {interval, Subscription} from 'rxjs';
import {LocalStorageService} from 'ngx-webstorage';
import {Router} from '@angular/router';
import moment from 'moment';
import {unsubscribe} from 'app/shared/util/react-util';
import {LoginService} from 'app/core/login/login.service';
import {CompanyParameterService} from 'app/shared/services/company/company-parameter.service';

@Injectable()
export class InactiveLogoutService {
  private subscriptions: Subscription[] = [];

  constructor(
    private localStorageService: LocalStorageService,
    private companyParameterService: CompanyParameterService,
    private injector: Injector,
    private router: Router
  ) {}

  private loginHasExpired(timeToExpireLogin): boolean {
    const localLastEventMomentStorage = this.localStorageService.retrieve('lastEventMoment');
    if (!localLastEventMomentStorage) return false;
    const lastEventMoment = moment(localLastEventMomentStorage);
    const currentMoment = moment();
    lastEventMoment.add(timeToExpireLogin, 'm');
    return currentMoment.isAfter(lastEventMoment);
  }

  private logout(): void {
    this.injector.get(LoginService).logout();
    this.router.navigate(['/']);
  }

  private stopCheckLoginExpiredTimer(): void {
    this.localStorageService.clear('lastEventMoment');
    unsubscribe(this.subscriptions);
  }

  getTimeToLogoutForInactivity(): Promise<number> {
    return this.companyParameterService.getTimeToLogoutForInactivity().toPromise();
  }

  async startCheckLoginExpiredTimer() {
    const timeToLogoutForInactivity = await this.getTimeToLogoutForInactivity();
    timeToLogoutForInactivity &&
      this.subscriptions.push(
        interval(1000).subscribe(() => {
          if (this.loginHasExpired(timeToLogoutForInactivity)) {
            this.logout();
            this.stopCheckLoginExpiredTimer();
          }
        })
      );
  }
}
