import {Injectable} from '@angular/core';
import {HttpClient, HttpResponse} from '@angular/common/http';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import moment from 'moment';

import {SERVER_API_URL} from 'app/app.constants';
import {createRequestOption} from 'app/shared/util/request-util';
import {ICnpj} from 'app/shared/model/company/cnpj.model';
import {CnpjOrigin} from 'app/shared/model/enumerations/cnpj-origin.enum';

type EntityResponseType = HttpResponse<ICnpj>;
type EntityArrayResponseType = HttpResponse<ICnpj[]>;

@Injectable({providedIn: 'root'})
export class CnpjService {
  public resourceUrl = `${SERVER_API_URL}api/com/cnpjs`;

  constructor(protected http: HttpClient) {}

  create(cnpj: ICnpj): Observable<EntityResponseType> {
    const copy = this.convertDateFromClient(cnpj);
    return this.http
      .post<ICnpj>(this.resourceUrl, copy, {observe: 'response'})
      .pipe(map((res: EntityResponseType) => this.convertDateFromServer(res)));
  }

  update(cnpj: ICnpj): Observable<EntityResponseType> {
    const copy = this.convertDateFromClient(cnpj);
    return this.http
      .put<ICnpj>(this.resourceUrl, copy, {observe: 'response'})
      .pipe(map((res: EntityResponseType) => this.convertDateFromServer(res)));
  }

  searchCnpj(cnpjNumber: number, origin?: CnpjOrigin): Observable<EntityResponseType> {
    const options = createRequestOption({
      ...(origin ? {origin: origin} : {})
    });
    return this.http
      .get<ICnpj>(`${this.resourceUrl}/search/${cnpjNumber}`, {params: options, observe: 'response'})
      .pipe(map((res: EntityResponseType) => this.convertDateFromServer(res)));
  }

  find(id: number): Observable<EntityResponseType> {
    return this.http
      .get<ICnpj>(`${this.resourceUrl}/${id}`, {observe: 'response'})
      .pipe(map((res: EntityResponseType) => this.convertDateFromServer(res)));
  }

  getAllActive(): Observable<EntityArrayResponseType> {
    return this.http
      .get<ICnpj[]>(`${this.resourceUrl}/all-active`, {observe: 'response'})
      .pipe(map((res: EntityArrayResponseType) => this.convertDateArrayFromServer(res)));
  }

  query(req?: any): Observable<EntityArrayResponseType> {
    const options = createRequestOption(req);
    return this.http
      .get<ICnpj[]>(this.resourceUrl, {params: options, observe: 'response'})
      .pipe(map((res: EntityArrayResponseType) => this.convertDateArrayFromServer(res)));
  }

  delete(id: number): Observable<HttpResponse<{}>> {
    return this.http.delete(`${this.resourceUrl}/${id}`, {observe: 'response'});
  }

  protected convertDateFromClient(cnpj: ICnpj): ICnpj {
    const copy: ICnpj = Object.assign({}, cnpj, {
      createdDate: cnpj.createdDate && cnpj.createdDate.isValid() ? cnpj.createdDate.toJSON() : undefined,
      lastModifiedDate: cnpj.lastModifiedDate && cnpj.lastModifiedDate.isValid() ? cnpj.lastModifiedDate.toJSON() : undefined,
      cachedAt: cnpj.cachedAt && cnpj.cachedAt.isValid() ? cnpj.cachedAt.toJSON() : undefined
    });
    return copy;
  }

  protected convertDateFromServer(res: EntityResponseType): EntityResponseType {
    if (res.body) {
      res.body.createdDate = res.body.createdDate ? moment(res.body.createdDate) : undefined;
      res.body.lastModifiedDate = res.body.lastModifiedDate ? moment(res.body.lastModifiedDate) : undefined;
      res.body.cachedAt = res.body.cachedAt ? moment(res.body.cachedAt) : undefined;
    }
    return res;
  }

  protected convertDateArrayFromServer(res: EntityArrayResponseType): EntityArrayResponseType {
    if (res.body) {
      res.body.forEach((cnpj: ICnpj) => {
        cnpj.createdDate = cnpj.createdDate ? moment(cnpj.createdDate) : undefined;
        cnpj.lastModifiedDate = cnpj.lastModifiedDate ? moment(cnpj.lastModifiedDate) : undefined;
        cnpj.cachedAt = cnpj.cachedAt ? moment(cnpj.cachedAt) : undefined;
      });
    }
    return res;
  }
}
