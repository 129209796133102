import moment from 'moment';
import {Moment} from 'moment';
import {BaseEntity, IBaseEntity} from 'app/shared/model/root/base-entity.model';
import {Criteria} from 'app/shared/filter/criteria.model';
import {IState, State} from 'app/shared/model/location/state.model';

export interface ICity extends IBaseEntity {
  name?: string;
  code?: string;
  state?: IState;
}

export class City extends BaseEntity implements ICity {
  constructor(
    public id?: number,
    public uuid?: string,
    public createdBy?: string,
    public createdDate?: Moment,
    public lastModifiedBy?: string,
    public lastModifiedDate?: Moment,
    public active?: boolean,
    public name?: string,
    public code?: string,
    public state?: IState,
    public _label?: string,
    public _edited?: boolean
  ) {
    super(id, uuid, createdBy, createdDate, lastModifiedBy, lastModifiedDate, active, _label, _edited);
  }

  public static criterias(): Criteria<City>[] {
    return Criteria.buildFrom(this.example());
  }

  public static example(): City {
    return new City(0, '', '', moment(new Date()), '', moment(new Date()), false, '', '', new State(), '', false);
  }
}
