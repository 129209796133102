import {IconProp} from '@fortawesome/fontawesome-svg-core';

export declare type ButtonColor =
  | 'primary'
  | 'secondary'
  | 'success'
  | 'danger'
  | 'warning'
  | 'info'
  | 'default'
  | 'help'
  | 'light'
  | 'dark';
export declare type ButtonType = 'default' | 'submit' | 'dismiss';

export type StringFunction = () => string;
export type IconFunction = () => IconProp;
export type ButtonColorFunction = () => ButtonColor;
export type BoolFunction = () => boolean;

export interface IButton {
  icon?: IconFunction;
  label?: StringFunction;
  tooltip?: StringFunction;
  color?: ButtonColorFunction;
  action?: Function;
  enabled?: BoolFunction;
  visible?: BoolFunction;
  type?: ButtonType;
}

export class Button implements IButton {
  constructor(
    public icon?: IconFunction,
    public label?: StringFunction,
    public tooltip?: StringFunction,
    public color?: ButtonColorFunction,
    public action?: Function,
    public enabled?: BoolFunction,
    public visible?: BoolFunction,
    public type?: ButtonType | 'default'
  ) {
    !action && (this.action = () => (this.enabled = () => false));
    !enabled && (this.enabled = () => true);
    !visible && (this.visible = () => true);
  }
}
