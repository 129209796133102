import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {NgbDatepicker, NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {NgJhipsterModule} from 'ng-jhipster';
import {FontAwesomeModule} from '@fortawesome/angular-fontawesome';
import {TranslateModule} from '@ngx-translate/core';
import {NgSelectModule} from '@ng-select/ng-select';
import {NgxMaskModule} from 'ngx-mask';
import {InputMaskModule} from 'racoon-mask-raw';
import {CurrencyMaskModule} from 'ng2-currency-mask';
import {NgxJsonViewerModule} from 'ngx-json-viewer';
import {QuillModule} from 'ngx-quill';
import {ColorPickerModule} from 'ngx-color-picker';
import {ColorSketchModule} from 'ngx-color/sketch';
import {NgChartsModule} from 'ng2-charts';
import {NgxCaptchaModule} from 'ngx-captcha';
import {OrderModule} from 'ngx-order-pipe';
import {PdfViewerModule} from 'ng2-pdf-viewer';
import {LottieModule} from 'ngx-lottie';
import player from 'lottie-web';
import {TreeSelectModule} from 'primeng/treeselect';
import {QRCodeModule} from 'angularx-qrcode';
import {CodemirrorModule} from '@ctrl/ngx-codemirror';
import {DropzoneModule} from 'app/shared/dropzone/dropzone.module';

// Note we need a separate function as it's required
// by the AOT compiler.
export function playerFactory() {
  return player;
}

@NgModule({
  imports: [
    CommonModule,
    QuillModule.forRoot({
      modules: {
        history: {
          delay: 2000,
          maxStack: 500,
          userOnly: true
        }
      }
    }),
    // @ts-ignore
    LottieModule.forRoot({player: playerFactory})
  ],
  exports: [
    FormsModule,
    CommonModule,
    NgbModule,
    NgJhipsterModule,
    LottieModule,
    FontAwesomeModule,
    ReactiveFormsModule,
    TranslateModule,
    NgSelectModule,
    NgxMaskModule,
    InputMaskModule,
    QRCodeModule,
    CurrencyMaskModule,
    NgxJsonViewerModule,
    QuillModule,
    ColorPickerModule,
    ColorSketchModule,
    NgChartsModule,
    NgxCaptchaModule,
    OrderModule,
    PdfViewerModule,
    LottieModule,
    TreeSelectModule,
    CodemirrorModule,
    DropzoneModule
  ],
  entryComponents: [NgbDatepicker]
})
export class AppSharedLibsModule {
}
