import {HttpParams} from '@angular/common/http';

export interface Pagination {
  page?: number;
  size?: number;
  sort?: string[];
}

export interface Search {
  query: string;
}

export interface IPaginationQueryParams {
  page: number;
  predicate: string;
  ascending: boolean;
  sortType?: string;
  search?: string;
  itemsPerPage?: number;
}

export interface SearchWithPagination extends Search, Pagination {
}

export const createRequestOption = (req?: any): HttpParams => {
  let options: HttpParams = new HttpParams();

  if (req) {
    Object.keys(req).forEach(key => {
      if (key !== 'sort') {
        options = options.set(key, req[key]);
      }
    });

    if (req.sort) {
      req.sort.forEach((val: string) => {
        options = options.append('sort', val);
      });
    }
  }

  return options;
};

/**
 * Method to find whether the sort is defined
 */
export function parseAscending(sort: string): boolean {
  let sortArray = sort.split(',');
  sortArray = sortArray.length > 1 ? sortArray : sort.split('%2C');
  if (sortArray.length > 1) {
    return sortArray.slice(-1)[0] === 'asc';
  }
  // default to true if no sort is defined
  return true;
}

/**
 * Method to sort can be in the format `id,asc` or `id`
 */
export function parsePredicate(sort: string): string {
  return sort.split(',')[0].split('%2C')[0];
}
