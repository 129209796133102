export interface IS3PreSignedURL {
  url?: string;
  bucket?: string;
  fileName?: string;
  contentType?: string;
}

export class S3PreSignedURL implements IS3PreSignedURL {

  constructor(
    public url?: string,
    public bucket?: string,
    public fileName?: string,
    public contentType?: string
  ) {
  }

  public static from(data: object | string): S3PreSignedURL {
    return (typeof data === 'string' ? JSON.parse(data) : data) as S3PreSignedURL;
  }

  public toJSON(): string {
    return JSON.stringify(this);
  }

}