import moment from 'moment';
import {Moment} from 'moment';
import {BaseEntity, IBaseEntity} from 'app/shared/model/root/base-entity.model';
import {Criteria} from 'app/shared/filter/criteria.model';
import {IUser, User} from 'app/core/user/user.model';
import {Gateway, IGateway} from 'app/shared/model/payment/gateway.model';
import {Artifact, IArtifact} from 'app/shared/model/payment/artifact.model';
import {Company, ICompany} from 'app/shared/model/company/company.model';
import {ILocation, Location} from 'app/shared/model/location/location.model';

export interface ICustomer extends IBaseEntity {
  company?: ICompany;
  gateway?: IGateway;
  user?: IUser;
  type?: string;
  name?: string;
  email?: string;
  document?: string;
  birthDate?: Moment;
  delinquent?: boolean;
  location?: ILocation;
  artifact?: IArtifact;
  json?: string;
}

export class Customer extends BaseEntity implements ICustomer {
  constructor(
    public id?: number,
    public uuid?: string,
    public createdBy?: string,
    public createdDate?: Moment,
    public lastModifiedBy?: string,
    public lastModifiedDate?: Moment,
    public active?: boolean,
    public company?: ICompany,
    public gateway?: IGateway,
    public user?: IUser,
    public type?: string,
    public name?: string,
    public email?: string,
    public document?: string,
    public birthDate?: Moment,
    public delinquent?: boolean,
    public location?: ILocation,
    public artifact?: IArtifact,
    public json?: string,
    public _label?: string,
    public _edited?: boolean
  ) {
    super(id, uuid, createdBy, createdDate, lastModifiedBy, lastModifiedDate, active, _label, _edited);
  }

  public static criterias(): Criteria<Customer>[] {
    return Criteria.buildFrom(this.example()).filter(criteria => !['location'].includes(criteria.property.name));
  }

  public static example(): Customer {
    return new Customer(
      0,
      '',
      '',
      moment(new Date()),
      '',
      moment(new Date()),
      false,
      new Company(),
      new Gateway(),
      new User(),
      '',
      '',
      '',
      '',
      moment(new Date()),
      false,
      new Location(),
      new Artifact(),
      '',
      '',
      false
    );
  }
}
