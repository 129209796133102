import {Component, EventEmitter, forwardRef, Input, OnDestroy, OnInit, Output, Renderer2} from '@angular/core';
import {Subscription} from 'rxjs';
import {unsubscribe} from 'app/shared/util/react-util';
import {UntypedFormBuilder, NG_VALUE_ACCESSOR, Validators} from '@angular/forms';

export const CUSTOM_INPUT_CONTROL_VALUE_ACCESSOR: any = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => AccountDigitsValidatorComponent),
  multi: true
};

@Component({
  selector: 'app-account-digits-validator',
  providers: [CUSTOM_INPUT_CONTROL_VALUE_ACCESSOR],
  template: `
    <div [formGroup]="verifyCode" class="d-inline-block 1" style="width: 300px;">
      <div class="row ltr">
        <div class="col-2 px-2">
          <input
            type="text"
            class="form-control text-center ltr p-0 font-weight-bold"
            id="code1"
            [ngClass]="{'border-danger': hasError}"
            (keyup)="nextStep($event, 1)"
            (focus)="focused(1)"
            formControlName="code1"
            maxlength="1"
            (paste)="paste($event)"
            autofocus
          />
        </div>
        <div class="col-2 px-2">
          <input
            type="text"
            class="form-control text-center ltr p-0 font-weight-bold"
            id="code2"
            [ngClass]="{'border-danger': hasError}"
            (keyup)="nextStep($event, 2)"
            (focus)="focused(2)"
            formControlName="code2"
            maxlength="1"
          />
        </div>
        <div class="col-2 px-2">
          <input
            type="text"
            class="form-control text-center ltr p-0 font-weight-bold"
            id="code3"
            [ngClass]="{'border-danger': hasError}"
            (keyup)="nextStep($event, 3)"
            (focus)="focused(3)"
            formControlName="code3"
            maxlength="1"
          />
        </div>
        <div class="col-2 px-2">
          <input
            type="text"
            class="form-control text-center ltr p-0 font-weight-bold"
            id="code4"
            [ngClass]="{'border-danger': hasError}"
            (keyup)="nextStep($event, 4)"
            (focus)="focused(4)"
            formControlName="code4"
            maxlength="1"
          />
        </div>
        <div class="col-2 px-2">
          <input
            type="text"
            class="form-control text-center ltr p-0 font-weight-bold"
            id="code5"
            [ngClass]="{'border-danger': hasError}"
            (keyup)="nextStep($event, 5)"
            (focus)="focused(5)"
            formControlName="code5"
            maxlength="1"
          />
        </div>
        <div class="col-2 px-2">
          <input
            type="text"
            class="form-control text-center ltr p-0 font-weight-bold"
            id="code6"
            [ngClass]="{'border-danger': hasError}"
            (keyup)="nextStep($event, 6)"
            (focus)="focused(6)"
            formControlName="code6"
            maxlength="1"
          />
        </div>
      </div>
    </div>
  `
})
export class AccountDigitsValidatorComponent implements OnInit, OnDestroy {
  @Input() items: any[] | undefined = undefined;
  @Input() hasError: boolean = false;

  @Output() digitsCompletedEvent = new EventEmitter<string>();

  private subscriptions: Subscription[] = [];

  submitted: boolean = false;

  verifyCode = this.fb.group({
    code1: ['', Validators.required],
    code2: ['', Validators.required],
    code3: ['', Validators.required],
    code4: ['', Validators.required],
    code5: ['', Validators.required],
    code6: ['', Validators.required]
  });

  constructor(private renderer: Renderer2, private fb: UntypedFormBuilder) {}

  ngOnInit(): void {}

  lastTime = 0;

  nextStep(event, step: number): void {
    const now = new Date().getTime();
    if (now - this.lastTime < 100) {
      return;
    }
    this.lastTime = now;

    if (this.verifyCode.valid) {
      this.onSubmit();
    }
    const prevElement = document.getElementById('code' + (step - 1));
    const nextElement = document.getElementById('code' + (step + 1));
    if (event.code === 'Backspace' && event.target.value === '') {
      event.target.parentElement.parentElement.children[step - 2 > 0 ? step - 2 : 0].children[0].value = '';
      if (prevElement) {
        prevElement.focus();
        return;
      }
    } else {
      if (nextElement) {
        nextElement.focus();
        return;
      }
    }
  }

  paste(event) {
    const clipboardData = event.clipboardData;
    const pastedText = clipboardData.getData('text');
    this.verifyCode.setValue({
      code1: pastedText.charAt(0),
      code2: pastedText.charAt(1),
      code3: pastedText.charAt(2),
      code4: pastedText.charAt(3),
      code5: pastedText.charAt(4),
      code6: pastedText.charAt(5)
    });
    document.getElementById('code6').focus();
    this.onSubmit();
  }

  focused(step) {
    if (step === 2) {
      if (this.verifyCode.controls.code1.value === '') {
        document.getElementById('code1').focus();
      }
    }
    if (step === 3) {
      if (this.verifyCode.controls.code1.value === '' || this.verifyCode.controls.code2.value === '') {
        document.getElementById('code2').focus();
      }
    }

    if (step === 4) {
      if (
        this.verifyCode.controls.code1.value === '' ||
        this.verifyCode.controls.code2.value === '' ||
        this.verifyCode.controls.code3.value === ''
      ) {
        document.getElementById('code3').focus();
      }
    }
  }

  onSubmit(): void {
    this.submitted = true;

    const digits =
      this.verifyCode.get(['code1'])!.value +
      this.verifyCode.get(['code2'])!.value +
      this.verifyCode.get(['code3'])!.value +
      this.verifyCode.get(['code4'])!.value +
      this.verifyCode.get(['code5'])!.value +
      this.verifyCode.get(['code6'])!.value;

    this.digitsCompletedEvent.emit(digits);
  }

  ngOnDestroy(): void {
    unsubscribe(this.subscriptions);
  }
}
