<div class="modal-header">
  <h4 class="modal-title" [jhiTranslate]="options?.modalTitle || 'upload.modal.file.title'"></h4>
  <button type="button" class="close" data-dismiss="modal" aria-hidden="true" (click)="dismiss()">&times;</button>
</div>

<div class="modal-body">

  <div class="form-row">

    <!-- fileType -->
    <div *ngIf="fileTypes?.length > 1" class="form-group col-md-4 pb-1">
      <div class="input-group">
        <div class="input-group-prepend">
          <label class="input-group-text required" jhiTranslate="global.field.fileType"></label>
        </div>
        <select class="form-control" [(ngModel)]="fileType" (ngModelChange)="fileTypeChange($event)">
          <option *ngFor="let fileType of fileTypes" [ngValue]="fileType">
            {{'fileType.' + fileType | translate}}
          </option>
        </select>
      </div>
    </div>

    <!-- url -->
    <div *ngIf="fileType == 'LINK'" class="form-group col-md pb-1">
      <div class="input-group">
        <div class="input-group-prepend">
          <label class="input-group-text required" jhiTranslate="fileType.LINK"></label>
        </div>
        <input type="url" class="form-control" maxlength="{{MAX_SIZE_OF_URL}}"
               [(ngModel)]="url" [ngClass]="{'is-invalid': urlIsInvalid(url)}">
      </div>
      <div *ngIf="urlIsInvalid(url)">
        <small class="form-text text-danger"
               *ngIf="isEmpty(url)"
               jhiTranslate="entity.validation.required">
        </small>
        <small class="form-text text-danger"
               *ngIf="url?.length > MAX_SIZE_OF_URL"
               jhiTranslate="entity.validation.maxlength"
               [translateValues]="{ max: MAX_SIZE_OF_URL }">
        </small>
      </div>
    </div>

    <!-- file -->
    <div *ngIf="fileType == 'FILE'" class="form-group col-md pb-1">
      <input style="display: none" type="file" (change)="fileChangeEvent($event)" #hiddenFileInput
             [accept]="options.fileExtension">

      <button (click)="hiddenFileInput.click()" class="btn btn-secondary btn-block"
              [disabled]="uploading">
        <fa-icon [fixedWidth]="true" [icon]="options?.uploadButtonIcon || 'file'"></fa-icon>
        &nbsp;
        <span [jhiTranslate]="options?.uploadButtonTitle ?? 'upload.modal.file.select.btn'"></span>
      </button>
    </div>

  </div>

  <ng-container *ngIf="fileType == 'FILE'">
    <div *ngIf="originalFile" class="form-group py-4 text-center">
      <strong>{{originalFile.name}} - {{(originalFile.size / 1024 / 1024) | number}}mb</strong>
    </div>

    <div *ngIf="uploading" class="form-group py-2">
      <ngb-progressbar [showValue]="true" type="success" [value]="uploadProgress"></ngb-progressbar>
    </div>
  </ng-container>
</div>

<div class="modal-footer">
  <div class="float-right">
    <button type="button" (click)="dismiss()" class="btn btn-secondary">
      <fa-icon [fixedWidth]="true" icon="ban"></fa-icon>
      &nbsp;
      <span jhiTranslate="entity.action.cancel"></span>
    </button>
    &nbsp;
    <button [disabled]="!canConfirm()" type="submit" class="btn btn-primary" (click)="confirm()">
      <fa-icon [fixedWidth]="true" icon="check"></fa-icon>
      &nbsp;
      <span jhiTranslate="entity.action.confirm"></span>
    </button>
  </div>
</div>
