<div class="container-fluid">
  <div class="row justify-content-center">

    <div class="col-md-8">
      <div class="error-wrap">
        <div class="error-content">

          <div class="error-logo">
            <a routerLink="/home">
              <img src="/content/images/brand/icon.png" alt="ONCLICK">
            </a>
          </div>

          <div class="text-center">
            <h3 jhiTranslate="error.title"></h3>
          </div>

          <div *ngIf="errorMessage" class="py-3 text-center">
            {{ errorMessage }}
          </div>

          <a routerLink="/home" class="btn btn-block btn-outline-warning" jhiTranslate="error.start"></a>
        </div>
      </div>
    </div>
  </div>
</div>
