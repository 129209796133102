import {NgModule} from '@angular/core';
import {AppSharedLibsModule} from './shared-libs.module';
import {FindLanguageFromKeyPipe} from './language/find-language-from-key.pipe';
import {AlertComponent} from './alert/alert.component';
import {AlertErrorComponent} from './alert/alert-error.component';
import {HasAnyAuthorityDirective} from './auth/has-any-authority.directive';
import {GenericModalComponent} from './modal/generic-modal.component';
import {ToastComponent} from 'app/shared/toast/toast.component';
import {CriteriaFilterComponent} from 'app/shared/filter/criteria-filter.component';
import {CriteriaFilterModalComponent} from 'app/shared/filter/criteria-filter-modal.component';
import {CriteriaPropertyFilterPipe} from 'app/shared/filter/criteria-filter.pipe';
import {StatusLabelComponent} from 'app/shared/label/status-label-component';
import {EnumLabelComponent} from 'app/shared/label/enum-label-component';
import {MaskedValueComponent} from 'app/shared/mask/masked-value.component';
import {RoleLabelComponent} from 'app/shared/label/role-label-component';
import {BoolIconComponent} from 'app/shared/label/bool-icon-component';
import {MailLinkComponent} from 'app/shared/label/mail-link-component';
import {ViewRouteLinkComponent} from 'app/shared/label/view-route-link.component';
import {RouterModule} from '@angular/router';
import {LocationEditorComponent} from 'app/shared/location/location-editor.component';
import {LocationInputComponent} from 'app/shared/location/location-input.component';
import {LocationEditorModalComponent} from 'app/shared/location/location-editor-modal.component';
import {LocationOutputComponent} from 'app/shared/location/location-output.component';
import {UploadImageModalComponent} from 'app/shared/upload/upload-image-modal.component';
import {UploadFileModalComponent} from 'app/shared/upload/upload-file-modal.component';
import {FileInputComponent} from 'app/shared/upload/file-input.component';
import {FileDownloadLinkComponent} from 'app/shared/label/file-download-link-component';
import {ImageCropperModule} from 'ngx-image-cropper';
import {FormatJsonPipe} from 'app/shared/util/format-json.pipe';
import {JsonAsObjectPipe} from 'app/shared/util/json-as-object.pipe';
import {RemoveHtmlTagsPipe} from 'app/shared/util/remove-html-tags.pipe';
import {ResizeImagePipe} from 'app/shared/util/resize-image.pipe';
import {SafeHtmlPipe} from 'app/shared/util/safe-html.pipe';
import {ApprovalLabelComponent} from 'app/shared/label/approval-label-component';
import {ExternalLinkComponent} from 'app/shared/label/external-link.component';
import {QuillViewModalComponent} from 'app/shared/quill/quill-view-modal.component';
import {QuillViewLinkComponent} from 'app/shared/quill/quill-view-link-component';
import {CallLinkComponent} from 'app/shared/label/call-link-component';
import {LevelLabelComponent} from 'app/shared/label/level-label-component';
import {EllipsisPipe} from 'app/shared/util/ellipsis.pipe';
import {PercentLabelComponent} from 'app/shared/label/percent-label.component';
import {EffectiveLabelComponent} from 'app/shared/label/effective-label-component';
import {VideoModalComponent} from 'app/shared/video-modal/video-modal.component';
import {AppSelectorComponent} from 'app/shared/app-selector/app-selector.component';
import {PriorityLabelComponent} from 'app/shared/label/priority-label-component';
import {HelpModalComponent} from 'app/entities/module/help/help-modal/help-modal.component';
import {SearchCnpjModalComponent} from 'app/shared/company/search-cnpj-modal.component';
import {FileViewModalComponent} from 'app/shared/file/file-view-modal.component';
import {AccountDigitsValidatorComponent} from 'app/shared/account-digits-validator/account-digits-validator.component';
import {PasswordStrengthBarComponent} from 'app/shared/password-strength-bar/password-strength-bar.component';
import {CardBrandPipe} from 'app/shared/util/card-brand.pipe';
import {MaskedPipe} from 'app/shared/mask/masked.pipe';
import {AddressPipe} from 'app/shared/location/address.pipe';
import {MaskedCardNumberPipe} from 'app/shared/util/masked-card-number.pipe';
import {LanguageLabelComponent} from 'app/shared/label/language-label-component';
import {LanguageIconPipe} from 'app/shared/language/language-icon.pipe';
import {EntityMenuColumnComponent} from 'app/shared/entity-menu-column/entity-menu-column.component';
import {GrapejsMjmlEditorComponent} from 'app/shared/grapejs/grapejs-mjml-editor.component';
import {UnitTypeLabelComponent} from 'app/shared/label/unit-type-label-component';
import {DateMomentPipe} from 'app/shared/util/date-moment.pipe';
import {TreeSelectComponent} from 'app/shared/tree-select/tree-select.component';
import {FormResponseEditorModalComponent} from 'app/shared/form/form-response-editor-modal.component';
import {FormAnswerValueComponent} from 'app/shared/form/form-answer-value.component';
import {FormResponseEditorComponent} from 'app/shared/form/form-response-editor.component';
import {AsIconPipe} from 'app/shared/util/as-icon.pipe';
import {FormControlPipe} from 'app/shared/util/form-control.pipe';
import {GradientLoadingComponent} from 'app/shared/label/gradient-loading-component';
import {DeliveryTypeLabelComponent} from 'app/shared/label/delivery-type-label-component';
import {ScrumRoleLabelComponent} from 'app/shared/label/scrum-role-label-component';

@NgModule({
  imports: [AppSharedLibsModule, RouterModule, ImageCropperModule],
  declarations: [
    DateMomentPipe,
    FormControlPipe,
    PasswordStrengthBarComponent,
    FindLanguageFromKeyPipe,
    LanguageIconPipe,
    ToastComponent,
    AlertComponent,
    AlertErrorComponent,
    GenericModalComponent,
    HasAnyAuthorityDirective,
    AccountDigitsValidatorComponent,
    BoolIconComponent,
    MailLinkComponent,
    ViewRouteLinkComponent,
    LocationInputComponent,
    LocationOutputComponent,
    LocationEditorComponent,
    LocationEditorModalComponent,
    EnumLabelComponent,
    RoleLabelComponent,
    StatusLabelComponent,
    DeliveryTypeLabelComponent,
    EffectiveLabelComponent,
    LevelLabelComponent,
    LanguageLabelComponent,
    PercentLabelComponent,
    ScrumRoleLabelComponent,
    MaskedValueComponent,
    CriteriaFilterComponent,
    CriteriaFilterModalComponent,
    CriteriaPropertyFilterPipe,
    UploadImageModalComponent,
    UploadFileModalComponent,
    FileInputComponent,
    FileDownloadLinkComponent,
    GradientLoadingComponent,
    FormatJsonPipe,
    JsonAsObjectPipe,
    RemoveHtmlTagsPipe,
    ResizeImagePipe,
    SafeHtmlPipe,
    EllipsisPipe,
    CardBrandPipe,
    MaskedPipe,
    AddressPipe,
    MaskedCardNumberPipe,
    ApprovalLabelComponent,
    ExternalLinkComponent,
    CallLinkComponent,
    FormResponseEditorComponent,
    FormResponseEditorModalComponent,
    FormAnswerValueComponent,
    QuillViewModalComponent,
    QuillViewLinkComponent,
    VideoModalComponent,
    AppSelectorComponent,
    PriorityLabelComponent,
    HelpModalComponent,
    SearchCnpjModalComponent,
    UnitTypeLabelComponent,
    FileViewModalComponent,
    EntityMenuColumnComponent,
    GrapejsMjmlEditorComponent,
    TreeSelectComponent,
    AsIconPipe
  ],
  entryComponents: [
    GenericModalComponent,
    CriteriaFilterModalComponent,
    MaskedValueComponent,
    LocationInputComponent,
    LocationEditorComponent,
    FormResponseEditorComponent,
    FormResponseEditorModalComponent,
    FormAnswerValueComponent,
    QuillViewModalComponent,
    QuillViewLinkComponent,
    VideoModalComponent,
    FileViewModalComponent,
    PasswordStrengthBarComponent,
    EntityMenuColumnComponent
  ],
  exports: [
    AppSharedLibsModule,
    DateMomentPipe,
    FormControlPipe,
    PasswordStrengthBarComponent,
    FindLanguageFromKeyPipe,
    LanguageIconPipe,
    ToastComponent,
    AlertComponent,
    AlertErrorComponent,
    GenericModalComponent,
    HasAnyAuthorityDirective,
    AccountDigitsValidatorComponent,
    BoolIconComponent,
    MailLinkComponent,
    ViewRouteLinkComponent,
    EnumLabelComponent,
    LocationInputComponent,
    LocationOutputComponent,
    LocationEditorComponent,
    LocationEditorModalComponent,
    RoleLabelComponent,
    StatusLabelComponent,
    DeliveryTypeLabelComponent,
    EffectiveLabelComponent,
    LevelLabelComponent,
    LanguageLabelComponent,
    PercentLabelComponent,
    ScrumRoleLabelComponent,
    MaskedValueComponent,
    CriteriaFilterComponent,
    CriteriaFilterModalComponent,
    CriteriaPropertyFilterPipe,
    UploadImageModalComponent,
    UploadFileModalComponent,
    FileInputComponent,
    FileDownloadLinkComponent,
    GradientLoadingComponent,
    FormatJsonPipe,
    JsonAsObjectPipe,
    RemoveHtmlTagsPipe,
    ResizeImagePipe,
    SafeHtmlPipe,
    EllipsisPipe,
    CardBrandPipe,
    MaskedPipe,
    AddressPipe,
    MaskedCardNumberPipe,
    ApprovalLabelComponent,
    ExternalLinkComponent,
    CallLinkComponent,
    FormResponseEditorComponent,
    FormResponseEditorModalComponent,
    FormAnswerValueComponent,
    QuillViewModalComponent,
    QuillViewLinkComponent,
    VideoModalComponent,
    AppSelectorComponent,
    PriorityLabelComponent,
    SearchCnpjModalComponent,
    UnitTypeLabelComponent,
    FileViewModalComponent,
    EntityMenuColumnComponent,
    GrapejsMjmlEditorComponent,
    TreeSelectComponent,
    AsIconPipe
  ]
})
export class AppSharedModule {
}
