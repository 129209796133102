import {Component} from '@angular/core';

@Component({
  selector: 'app-gradient-loading',
  template: `
    <div class="gradient">
    </div>
  `,
  styles: [`

    .gradient {
      animation-duration: 1.8s;
      animation-fill-mode: forwards;
      animation-iteration-count: infinite;
      animation-name: placeHolderShimmer;
      animation-timing-function: linear;
      background: #f6f7f8;
      background: linear-gradient(to right, #e5e5e5 8%, #f4f4f4 38%, #e5e5e5 54%);
      background-size: 1000px 640px;
      position: relative;
      height: 21px;
      width: 80px;

    }

    @keyframes placeHolderShimmer {
      0% {
        background-position: -468px 0
      }
      100% {
        background-position: 468px 0
      }
    }

  `]
})
export class GradientLoadingComponent {


}
