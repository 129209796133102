import {Component, Input} from '@angular/core';
import {IBaseEntity} from 'app/shared/model/root/base-entity.model';
import {Router} from '@angular/router';
import {IconProp} from '@fortawesome/fontawesome-svg-core';

@Component({
  selector: 'app-view-route-link',
  template: `
    <ng-container *ngIf="entity && route && (param || entity.id)">
      <a
        *ngIf="!target"
        [routerLink]="['/' + route, param || entity.id, destination || 'view']"
        (click)="link()"
        [classList]="asButton ? 'btn btn-sm ' + (buttonClasses || 'btn-outline-primary') : ''"
        href="javascript:void(0);"
      >
        {{ label || entity._label }}
      </a>
      <a
        *ngIf="target"
        [routerLink]="['/' + route, param || entity.id, destination || 'view']"
        href="javascript:void(0);"
        [target]="target"
        [classList]="asButton ? 'btn btn-sm ' + (buttonClasses || 'btn-outline-primary') : ''"
      >
        <ng-container *ngIf="asButton">
          <fa-icon *ngIf="buttonIcon" [fixedWidth]="true"[icon]="buttonIcon"></fa-icon>
          <fa-icon *ngIf="!buttonIcon" [fixedWidth]="true"icon="link"></fa-icon>
          <ng-container *ngIf="!noLabel">
            &nbsp;
          </ng-container>
        </ng-container>
        <span *ngIf="!noLabel">
          {{ label || entity._label }}
        </span>
        <ng-container *ngIf="!asButton">
          &nbsp;
          <fa-icon [fixedWidth]="true"icon="external-link-alt"></fa-icon>
        </ng-container>
      </a>
    </ng-container>
  `
})
export class ViewRouteLinkComponent {
  @Input()
  entity: IBaseEntity | undefined = undefined;

  @Input()
  route: string | undefined = undefined;

  @Input()
  label: string | number | undefined = undefined;

  @Input()
  param: string | undefined = undefined;

  @Input()
  target: string | undefined = undefined;

  @Input()
  destination: string | undefined = undefined;

  @Input()
  asButton = false;

  @Input()
  buttonClasses: string | undefined = undefined;

  @Input()
  buttonIcon: IconProp | undefined = undefined;

  @Input()
  noLabel = false;

  constructor(private router: Router) {}

  link(): void {
    this.route && this.entity && this.router.navigate(['/' + this.route, this.param || this.entity.id, this.destination ?? 'view']);
  }
}
