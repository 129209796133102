import {BoolFunction, Button, IButton} from 'app/shared/layout/window/button.model';

export abstract class ButtonBuilder {
	public static save(action: Function, enabled?: BoolFunction, visible?: BoolFunction): IButton {
		return new Button(
			() => 'save',
			() => 'entity.action.save',
			() => 'entity.action.save',
			() => 'primary',
			action,
			enabled,
			visible,
			'submit'
		);
	}

	public static program(action: Function, enabled?: BoolFunction, visible?: BoolFunction): IButton {
		return new Button(
      () => 'clock',
      () => 'entity.action.scheduleExecution',
      () => 'entity.action.scheduleExecution',
      () => 'warning',
      action,
      enabled,
      visible,
      'submit'
    );
  }


  public static markAsShipped(action: Function, enabled?: BoolFunction, visible?: BoolFunction): IButton {
    return new Button(
      () => 'truck',
      () => 'entity.action.markAsShipped',
      () => 'entity.action.markAsShipped',
      () => 'warning',
      action,
      enabled,
      visible,
      'submit'
    );
  }

  public static markAsFinished(action: Function, enabled?: BoolFunction, visible?: BoolFunction): IButton {
    return new Button(
      () => 'check',
      () => 'entity.action.markAsFinished',
      () => 'entity.action.markAsFinished',
      () => 'success',
      action,
      enabled,
      visible,
      'submit'
    );
  }

  public static cancel(action: Function, enabled?: BoolFunction, visible?: BoolFunction): IButton {
    return new Button(
      () => 'ban',
      () => 'entity.action.cancel',
      () => 'entity.action.cancel',
      () => 'secondary',
      action,
      enabled,
      visible,
			'dismiss'
		);
	}

	public static confirm(action: Function, enabled?: BoolFunction, visible?: BoolFunction): IButton {
		return new Button(
			() => 'check',
			() => 'entity.action.confirm',
			() => 'entity.action.confirm',
			() => 'success',
			action,
			enabled,
			visible,
			'submit'
		);
	}

	public static finish(action: Function, enabled?: BoolFunction, visible?: BoolFunction): IButton {
		return new Button(
			() => 'check-square',
			() => 'entity.action.finish',
			() => 'entity.action.finish',
			() => 'success',
			action,
			enabled,
			visible,
			'submit'
		);
	}

	public static send(action: Function, enabled?: BoolFunction, visible?: BoolFunction): IButton {
		return new Button(
			() => 'paper-plane',
			() => 'entity.action.send',
			() => 'entity.action.send',
			() => 'success',
			action,
			enabled,
			visible,
			'submit'
		);
	}

	public static change(action: Function, enabled?: BoolFunction, visible?: BoolFunction): IButton {
		return new Button(
			() => 'exchange',
			() => 'entity.action.change',
			() => 'entity.action.change',
			() => 'warning',
			action,
			enabled,
			visible,
			'submit'
		);
	}

	public static activate(action: Function, enabled?: BoolFunction, visible?: BoolFunction): IButton {
		return new Button(
			() => 'check-circle',
			() => 'entity.action.activate',
			() => 'entity.action.activate',
			() => 'success',
			action,
			enabled,
			visible,
			'submit'
		);
	}

	public static deactivate(action: Function, enabled?: BoolFunction, visible?: BoolFunction): IButton {
		return new Button(
			() => 'minus-circle',
			() => 'entity.action.deactivate',
			() => 'entity.action.deactivate',
			() => 'danger',
			action,
			enabled,
			visible,
			'submit'
		);
	}

	public static expire(action: Function, enabled?: BoolFunction, visible?: BoolFunction): IButton {
		return new Button(
			() => 'ban',
			() => 'entity.action.expire',
			() => 'entity.action.expire',
			() => 'danger',
			action,
			enabled,
			visible,
			'submit'
		);
	}

	public static renew(action: Function, enabled?: BoolFunction, visible?: BoolFunction): IButton {
		return new Button(
			() => 'plus',
			() => 'entity.action.renew',
			() => 'entity.action.renew',
			() => 'success',
			action,
			enabled,
			visible,
			'submit'
		);
	}

	public static inactivate(action: Function, enabled?: BoolFunction, visible?: BoolFunction): IButton {
		return new Button(
			() => 'ban',
			() => 'entity.action.inactivate',
			() => 'entity.action.inactivate',
			() => 'danger',
			action,
			enabled,
			visible,
			'submit'
		);
	}

	public static delete(action: Function, enabled?: BoolFunction, visible?: BoolFunction): IButton {
		return new Button(
			() => 'times',
			() => 'entity.action.delete',
			() => 'entity.action.delete',
			() => 'danger',
			action,
			enabled,
			visible,
			'submit'
		);
	}

	public static new(action: Function, enabled?: BoolFunction, visible?: BoolFunction): IButton {
		return new Button(
			() => 'plus',
			() => 'entity.action.new',
			() => 'entity.action.new',
			() => 'primary',
			action,
			enabled,
			visible
		);
	}

	public static sendMail(action: Function, enabled?: BoolFunction, visible?: BoolFunction): IButton {
		return new Button(
			() => 'envelope',
			() => 'entity.action.sendMail',
			() => 'entity.action.sendMail',
			() => 'primary',
			action,
			enabled,
			visible
		);
	}

	public static searchCnpj(action: Function, enabled?: BoolFunction, visible?: BoolFunction): IButton {
		return new Button(
			() => 'search',
			() => 'entity.action.searchCnpj',
			() => 'entity.action.searchCnpj',
			() => 'info',
			action,
			enabled,
			visible
		);
	}

	public static newRevision(action: Function, enabled?: BoolFunction, visible?: BoolFunction): IButton {
		return new Button(
			() => 'copy',
			() => 'entity.action.newRevision',
			() => 'entity.action.newRevision',
			() => 'primary',
			action,
			enabled,
			visible
		);
	}

	static back(action: Function, enabled?: BoolFunction, visible?: BoolFunction): IButton {
		return new Button(
			() => 'arrow-left',
			() => 'entity.action.back',
			() => 'entity.action.back',
			() => 'default',
			action,
			enabled,
			visible
		);
	}


  static edit(action: Function, enabled?: BoolFunction, visible?: BoolFunction): IButton {
		return new Button(
			() => 'edit',
			() => 'entity.action.edit',
			() => 'entity.action.edit',
			() => 'primary',
			action,
			enabled,
			visible
		);
	}

	static open(action: Function, enabled?: BoolFunction, visible?: BoolFunction): IButton {
		return new Button(
			() => 'folder-open',
			() => 'entity.action.open',
			() => 'entity.action.open',
			() => 'secondary',
			action,
			enabled,
			visible
		);
	}

	static setup(action: Function, enabled?: BoolFunction, visible?: BoolFunction): IButton {
		return new Button(
			() => 'wrench',
			() => 'entity.action.setup',
			() => 'entity.action.setup',
			() => 'warning',
			action,
			enabled,
			visible
		);
	}

	static createOrOpenTicket(action: Function, enabled?: BoolFunction, visible?: BoolFunction): IButton {
		return new Button(
			() => 'envelope-open-text',
			() => 'entity.action.openTicket',
			() => 'entity.action.openTicket',
			() => 'info',
			action,
			enabled,
			visible
		);
	}

	static filter(action: Function, enabled?: BoolFunction, visible?: BoolFunction): IButton {
		return new Button(
			() => 'filter',
			() => 'entity.action.filter',
			() => 'entity.action.filter',
			() => 'default',
			action,
			enabled,
			visible
		);
	}

	static refresh(action: Function, enabled?: BoolFunction, visible?: BoolFunction): IButton {
		return new Button(
			() => 'sync-alt',
			() => 'entity.action.refresh',
			() => 'entity.action.refresh',
			() => 'primary',
			action,
			enabled,
			visible
		);
	}

	public static test(action: Function, enabled?: BoolFunction, visible?: BoolFunction): IButton {
		return new Button(
			() => 'tools',
			() => 'entity.action.test',
			() => 'entity.action.test',
			() => 'secondary',
			action,
			enabled,
			visible,
			'submit'
		);
	}

	static leave(action: Function, enabled?: BoolFunction, visible?: BoolFunction): IButton {
		return new Button(
			() => 'arrow-left',
			() => 'entity.action.leave',
			() => 'entity.action.leave',
			() => 'default',
			action,
			enabled,
			visible
		);
	}

	public static remove(action: Function, enabled?: BoolFunction, visible?: BoolFunction): IButton {
		return new Button(
			() => 'times',
			() => 'entity.action.remove',
			() => 'entity.action.remove',
			() => 'danger',
			action,
			enabled,
			visible,
			'submit'
		);
	}

	public static generate(action: Function, enabled?: BoolFunction, visible?: BoolFunction): IButton {
		return new Button(
			() => 'wand-magic',
			() => 'entity.action.generate',
			() => 'entity.action.generate',
			() => 'primary',
			action,
			enabled,
			visible,
			'submit'
		);
	}

	public static approve(action: Function, enabled?: BoolFunction, visible?: BoolFunction): IButton {
		return new Button(
			() => 'check-circle',
			() => 'entity.action.approve',
			() => 'entity.action.approve',
			() => 'success',
			action,
			enabled,
			visible,
			'submit'
		);
	}

	public static refuse(action: Function, enabled?: BoolFunction, visible?: BoolFunction): IButton {
		return new Button(
			() => 'times-circle',
			() => 'entity.action.refuse',
			() => 'entity.action.refuse',
			() => 'danger',
			action,
			enabled,
			visible,
			'submit'
		);
	}

	static report(action: Function, enabled?: BoolFunction, visible?: BoolFunction): IButton {
		return new Button(
			() => 'file-pdf',
			() => 'entity.action.report',
			() => 'entity.action.report',
			() => 'default',
			action,
			enabled,
			visible
		);
	}

	static print(action: Function, enabled?: BoolFunction, visible?: BoolFunction): IButton {
		return new Button(
			() => 'print',
			() => 'entity.action.print',
			() => 'entity.action.print',
			() => 'default',
			action,
			enabled,
			visible
		);
	}

	public static import(action: Function, enabled?: BoolFunction, visible?: BoolFunction): IButton {
		return new Button(
			() => 'file-import',
			() => 'entity.action.import',
			() => 'entity.action.import',
			() => 'default',
			action,
			enabled,
			visible,
			'submit'
		);
	}

	public static returnToCheckout(action: Function, enabled?: BoolFunction, visible?: BoolFunction): IButton {
		return new Button(
			() => 'shopping-cart',
			() => 'entity.action.returnToCheckout',
			() => 'entity.action.returnToCheckout',
			() => 'warning',
			action,
			enabled,
			visible,
			'submit'
		);
	}

	public static setAsPendingActivation(action: Function, enabled?: BoolFunction, visible?: BoolFunction): IButton {
		return new Button(
			() => 'hourglass-half',
			() => 'entity.action.forcePendingActivation',
			() => 'entity.action.forcePendingActivation',
			() => 'warning',
			action,
			enabled,
			visible,
			'submit'
		);
	}

	public static keep(action: Function, enabled?: BoolFunction, visible?: BoolFunction): IButton {
		return new Button(
			() => 'check',
			() => 'entity.action.keep',
			() => 'entity.action.keep',
			() => 'success',
			action,
			enabled,
			visible
		);
	}

	public static goToBottom(action: Function, enabled?: BoolFunction, visible?: BoolFunction): IButton {
		return new Button(
			() => 'arrow-down',
			() => 'entity.action.goToBottom',
			() => 'entity.action.goToBottom',
			() => 'primary',
			action,
			enabled,
			visible
		);
	}

	public static goToTop(action: Function, enabled?: BoolFunction, visible?: BoolFunction): IButton {
		return new Button(
			() => 'arrow-up',
			() => 'entity.action.goToTop',
			() => 'entity.action.goToTop',
			() => 'primary',
			action,
			enabled,
			visible
		);
	}

	public static copy(action: Function, enabled?: BoolFunction, visible?: BoolFunction): IButton {
		return new Button(
			() => 'copy',
			() => 'entity.action.copy',
			() => 'entity.action.copy',
			() => 'warning',
			action,
			enabled,
			visible
		);
	}

	public static help(action: Function, enabled?: BoolFunction, visible?: BoolFunction): IButton {
		return new Button(
			() => 'question',
			() => null,
			() => 'entity.action.help',
			() => 'help',
			action,
			enabled,
			visible
		);
	}

	static targetBlank(action: Function, enabled?: BoolFunction, visible?: BoolFunction): IButton {
		return new Button(
			() => 'arrow-up',
			() => 'entity.action.targetBlank',
			() => 'entity.action.targetBlank',
			() => 'primary',
			action,
			enabled,
			visible
		);
	}

	public static approvalDocuments(action: Function, enabled?: BoolFunction, visible?: BoolFunction): IButton {
		return new Button(
			() => 'arrow-alt-circle-down',
			() => 'entity.action.approveDocuments',
			() => 'entity.action.approveDocuments',
			() => 'primary',
			action,
			enabled,
			visible
		);
	}

	public static cancelOrder(action: Function, enabled?: BoolFunction, visible?: BoolFunction): IButton {
		return new Button(
			() => 'ban',
			() => 'entity.action.cancelOrder',
			() => 'entity.action.cancelOrder',
			() => 'danger',
			action,
			enabled,
			visible,
			'submit'
		);
	}

	public static cancelCharges(action: Function, enabled?: BoolFunction, visible?: BoolFunction): IButton {
		return new Button(
			() => 'ban',
			() => 'entity.action.cancelCharges',
			() => 'entity.action.cancelCharges',
			() => 'danger',
			action,
			enabled,
			visible,
			'submit'
		);
	}
}
