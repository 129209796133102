<div class="modal-header">
  <h4 class="modal-title" jhiTranslate="upload.modal.image.title"></h4>
  <button type="button" class="close" data-dismiss="modal" aria-hidden="true" (click)="dismiss()">&times;</button>
</div>

<div class="modal-body">

  <div class="form-group">
    <input style="display: none" type="file" (change)="fileChangeEvent($event)" #hiddenFileInput
           [accept]="options.fileExtension">

    <button (click)="hiddenFileInput.click()" class="btn btn-secondary btn-block"
            [disabled]="uploading">
      <fa-icon [fixedWidth]="true" icon="image"></fa-icon>
      &nbsp;
      <span jhiTranslate="upload.modal.image.select.btn"></span>
    </button>
  </div>

  <div *ngIf="imageChangedEvent" class="form-group py-4">
    <div class="cropper">
      <image-cropper [imageChangedEvent]="imageChangedEvent"
                     [maintainAspectRatio]="options.maintainAspectRatio"
                     [aspectRatio]="options.aspectRatio"
                     [cropperMinWidth]="options.minWidth"
                     [cropperMinHeight]="options.minHeight"
                     [resizeToWidth]="options.resizeToWidth"
                     [resizeToHeight]="options.resizeToHeight"
                     format="png" output="base64"
                     (imageCropped)="imageCropped($event)"
                     (imageLoaded)="imageLoaded()"
                     (cropperReady)="cropperReady()"
                     (loadImageFailed)="loadImageFailed()"></image-cropper>
    </div>
  </div>

  <div *ngIf="uploading" class="form-group py-2">
    <ngb-progressbar [showValue]="true" type="success" [value]="uploadProgress"></ngb-progressbar>
  </div>

</div>

<div class="modal-footer">
  <div class="float-right">
    <button type="button" (click)="dismiss()" class="btn btn-secondary">
      <fa-icon [fixedWidth]="true" icon="ban"></fa-icon>
      &nbsp;
      <span jhiTranslate="entity.action.cancel"></span>
    </button>
    &nbsp;
    <button [disabled]="!canConfirm()" type="submit" class="btn btn-primary" (click)="confirm()">
      <fa-icon [fixedWidth]="true" icon="check"></fa-icon>
      &nbsp;
      <span jhiTranslate="entity.action.confirm"></span>
    </button>
  </div>
</div>
