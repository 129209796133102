import {Routes} from '@angular/router';

export const contentRoutes: Routes = [
  {
    path: 'cnt/banners',
    loadChildren: () => import('./banner/banner.module').then(m => m.AppBannerModule),
    data: {
      pageTitle: 'banner.home.title'
    }
  },
  {
    path: 'cnt/metadatas',
    loadChildren: () => import('./metadata/metadata.module').then(m => m.AppMetadataModule),
    data: {
      pageTitle: 'metadata.home.title'
    }
  },
  {
    path: 'cnt/posts-categories',
    loadChildren: () => import('./post-category/post-category.module').then(m => m.AppPostCategoryModule),
    data: {
      pageTitle: 'postCategory.home.title'
    }
  },
  {
    path: 'cnt/posts',
    loadChildren: () => import('./post/post.module').then(m => m.AppPostModule),
    data: {
      pageTitle: 'post.home.title'
    }
  }
];
