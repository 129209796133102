import {Component, Input} from '@angular/core';
import {IconProp} from '@fortawesome/fontawesome-svg-core';
import {ButtonColor} from 'app/shared/layout/window/button.model';
import {DeliveryType} from 'app/shared/model/enumerations/delivery-type.enum';

@Component({
  selector: 'app-delivery-type-label',
  template: `
    <div *ngIf="deliveryType" class="badge" [ngClass]="'badge-' + color()">
      <fa-icon class="mr-1" *ngIf="showIcon && icon()" [icon]="icon()" [fixedWidth]="true"></fa-icon>
      <span>{{ 'deliveryType.' + deliveryType | translate }}</span>
    </div>
  `
})
export class DeliveryTypeLabelComponent {
  @Input()
  deliveryType: DeliveryType | null = null;

  @Input()
  showIcon: boolean = false;

  color(): ButtonColor {
    if (!this.deliveryType) return 'default';

    switch (this.deliveryType) {
      case DeliveryType.SHIPPING:
        return 'primary';

      case DeliveryType.PICKUP:
        return 'warning';

      default:
        return 'default';
    }
  }

  icon(): IconProp {
    if (!this.deliveryType) return null;

    switch (this.deliveryType) {
      case DeliveryType.SHIPPING:
        return 'truck';

      case DeliveryType.PICKUP:
        return 'box-alt';

      default:
        return null;
    }
  }
}
