import moment from 'moment';
import {Moment} from 'moment';
import {IState} from 'app/shared/model/location/state.model';
import {BaseEntity, IBaseEntity} from 'app/shared/model/root/base-entity.model';
import {Criteria} from 'app/shared/filter/criteria.model';
import {APP_LANGUAGES} from 'app/shared/util/language-util';

export interface ICountry extends IBaseEntity {
  title?: string;
  code?: string;
  defaultLangKey?: string;
  currencySign?: string;
  states?: IState[];
}

export class Country extends BaseEntity implements ICountry {
  constructor(
    public id?: number,
    public uuid?: string,
    public createdBy?: string,
    public createdDate?: Moment,
    public lastModifiedBy?: string,
    public lastModifiedDate?: Moment,
    public active?: boolean,
    public title?: string,
    public code?: string,
    public defaultLangKey?: string,
    public currencySign?: string,
    public states?: IState[],
    public _label?: string,
    public _edited?: boolean
  ) {
    super(id, uuid, createdBy, createdDate, lastModifiedBy, lastModifiedDate, active, _label, _edited);
  }

  public static criterias(): Criteria<Country>[] {
    return Criteria.buildFrom(this.example())
      .filter(criteria => criteria.property.name !== 'states')
      .map(criteria =>
        criteria.property.name === 'defaultLangKey' ? criteria.asEnum(() => APP_LANGUAGES.map(l => l.code), 'langKey') : criteria
      );
  }

  public static example(): Country {
    return new Country(0, '', '', moment(new Date()), '', moment(new Date()), false, '', '', '', '', [], '', false);
  }
}
