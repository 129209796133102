export class UploadRequest {
  constructor(public fileName?: string, public contentType?: string) {}

  public static from(data: object | string): UploadRequest {
    return (typeof data === 'string' ? JSON.parse(data) : data) as UploadRequest;
  }

  public toJSON(): string {
    return JSON.stringify(this);
  }
}
