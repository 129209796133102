<ng-container *ngIf="!accountService.isAuthenticated()">
  <div class="background"></div>
  <div class="container-fluid">
    <div class="row justify-content-center">
      <div class="col-12">
        <div class="login-wrap">
          <div class="login-content">
            <div
              [@bounceInOnEnter]
              class="alert alert-danger py-4"
              *ngIf="authenticationError"
              jhiTranslate="login.messages.error.authentication"
            ></div>

            <div class="login-logo">
              <a routerLink="/">
                <img src="/content/images/brand/logo.png" alt="ONCLICK"/>
              </a>
            </div>

            <div class="text-center welcome">
           <span jhiTranslate="login.welcome">
            </span>
              <br>
              <span jhiTranslate="login.fillTheFields">
            </span>
            </div>

            <div class="line">
            </div>

            <form class="form" role="form" (ngSubmit)="login()" [formGroup]="loginForm">

              <div [hidden]="!showAccount" class="form-group">
                <label for="account" jhiTranslate="global.form.username.account"></label>
                <input
                  type="text"
                  class="form-control"
                  name="account"
                  id="account"
                  formControlName="account"
                  #account
                />
              </div>

              <div class="form-group">
                <label class="username-label" for="username" jhiTranslate="global.form.username.label"></label>
                <input
                  type="text"
                  class="form-control"
                  name="username"
                  id="username"
                  placeholder="{{ 'global.form.username.placeholder' | translate }}"
                  formControlName="username"
                  #username
                />
              </div>

              <div class="form-group">
                <label for="password" jhiTranslate="login.form.password"></label>
                <input
                  type="password"
                  class="form-control"
                  name="password"
                  id="password"
                  placeholder="{{ 'login.form.password.placeholder' | translate }}"
                  formControlName="password"
                />
              </div>

              <button [disabled]="isLoading" type="submit" class="mt3 btn btn-primary btn-block">
              <span jhiTranslate="login.form.button">
              </span>
                <ng-container *ngIf="isLoading">
                  &nbsp;
                  <fa-icon icon="circle-notch" [spin]="true"></fa-icon>
                </ng-container>
              </button>
            </form>

            <div class="mt-3 text-center">
              <a class="forgot-password" routerLink="/account/reset/request" jhiTranslate="login.password.forgot"></a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-container>
