import {Injectable} from '@angular/core';
import {NgbModal, NgbModalRef} from '@ng-bootstrap/ng-bootstrap';
import {IFormModelCategory} from 'app/shared/model/form/form-model-category.model';
import {FormModelCategoryDetailModalComponent} from 'app/entities/form/form-model/form-model-category-detail-modal.component';

@Injectable({providedIn: 'root'})
export class FormModelCategoryDetailModalService {
  private isOpen = false;
  private instance?: NgbModalRef;

  constructor(private modalService: NgbModal) {}

  show(formModelCategory: IFormModelCategory, close?: Function): void {
    if (this.isOpen) return;

    this.isOpen = true;

    this.instance = this.modalService.open(FormModelCategoryDetailModalComponent, {size: 'lg', backdrop: 'static'});
    this.instance.componentInstance.formModelCategory = formModelCategory;
    this.instance.componentInstance.close = close;

    this.instance.result.finally(() => (this.isOpen = false));
  }

  close(): void {
    if (!this.isOpen || !this.instance) return;
    this.instance.dismiss();
  }
}
