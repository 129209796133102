import {AfterContentChecked, ChangeDetectorRef, Component, OnDestroy} from '@angular/core';
import {UploadImageModalService} from 'app/shared/upload/upload-image-modal.service';
import {base64ToFile, ImageCroppedEvent} from 'ngx-image-cropper';
import {UploadService} from 'app/shared/upload/upload.service';
import {StringUtilService} from 'app/shared/util/string-util.service';
import {UploadRequest} from '../../../../serverless/model/upload-request.model';
import {catchError, flatMap} from 'rxjs/operators';
import {Logger} from 'app/shared/util/logger';
import {S3PreSignedURL} from '../../../../serverless/model/s3-pre-signed-url.model';
import {Subject, Subscription, throwError} from 'rxjs';
import {unsubscribe} from 'app/shared/util/react-util';
import {HttpEventType} from '@angular/common/http';
import {IFile} from 'app/shared/model/admin/file.model';
import {UPLOAD_BUCKET_URL} from 'app/app.constants';
import {UploadImageOptions} from 'app/shared/upload/upload-options.model';
import {Toast} from 'app/shared/toast/toast.model';
import {ToastService} from 'app/shared/toast/toast.service';

const Log = new Logger('UploadImageModalComponent');

@Component({
  selector: 'app-upload-image-modal',
  templateUrl: './upload-image-modal.component.html',
  styleUrls: ['./upload-modal.component.scss']
})
export class UploadImageModalComponent implements OnDestroy, AfterContentChecked {
  imageChangedEvent: Event = null;
  croppedImage: any = null;
  uploading = false;
  uploadProgress = 0;
  options?: UploadImageOptions;
  imageUploaded?: Function;
  cancel?: Function;
  originalFile?: File;

  private subscriptions: Subscription[] = [];

  constructor(
    private service: UploadImageModalService,
    private uploadService: UploadService,
    private toastService: ToastService,
    private cdr: ChangeDetectorRef
  ) {}

  ngAfterContentChecked(): void {
    this.cdr.detectChanges();
  }

  fileChangeEvent(event: Event): void {
    this.imageChangedEvent = event;
    this.originalFile =
      this.imageChangedEvent.target instanceof HTMLInputElement ? (this.imageChangedEvent.target as HTMLInputElement).files[0] : null;
    setTimeout(() => this.cdr.detectChanges(), 500);
    this.cdr.detectChanges();
  }

  imageCropped(event: ImageCroppedEvent): void {
    this.croppedImage = base64ToFile(event.base64);
    this.cdr.detectChanges();
  }

  imageLoaded(): void {
    this.cdr.detectChanges();
  }

  cropperReady(): void {
    this.cdr.detectChanges();
  }

  loadImageFailed(): void {
    this.toastService.show(Toast.danger('upload.toast.load.error'));
    this.croppedImage = null;
  }

  dismiss(): void {
    this.cancel && this.cancel();
    this.service.dismiss();
  }

  canConfirm(): boolean {
    return !!this.croppedImage && !this.uploading;
  }

  startUpload(): void {
    Log.debug(() => `startUpload()`);

    this.uploading = true;
    this.uploadProgress = 0;

    const progress = new Subject<number>();

    const uuid = StringUtilService.uuid();
    const file: IFile = {
      uuid: uuid,
      active: true,
      name: `${uuid}.png`,
      sourceName: this.originalFile ? this.originalFile.name : `${uuid}.png`,
      contentType: 'image/png',
      url: `${UPLOAD_BUCKET_URL}${uuid}.png`,
      _edited: true
    } as IFile;

    this.subscriptions.push(
      progress.subscribe(value => (this.uploadProgress = value)),
      this.uploadService
        .getPreSignedURL(
          UploadRequest.from({
            fileName: file.name,
            contentType: 'image/png'
          })
        )
        .pipe(
          catchError(err => {
            Log.error(() => `startUpload() => Couldn't get pre-signed url => ${JSON.stringify(err)}`);
            return throwError('Something bad happened; please try again later.');
          }),
          flatMap((preSignedURL: S3PreSignedURL) => {
            Log.debug(() => `startUpload() => preSignedURL: ${JSON.stringify(preSignedURL)}`);
            return this.uploadService.uploadFileToS3(preSignedURL, this.croppedImage, progress);
          })
        )
        .subscribe(
          event => {
            Log.debug(() => `startUpload() => event: ${JSON.stringify(event)}`);

            switch (event.type) {
              case HttpEventType.Response:
                this.imageUploaded && this.imageUploaded(file);
                this.service.confirm();
                break;
            }
          },
          error => {
            Log.error(() => `startUpload() => error: ${error}`);
            this.service.dismiss();
            this.toastService.show(Toast.danger('upload.toast.error'));
          },
          () => {
            this.uploading = false;
            this.uploadProgress = 0;
          }
        )
    );
  }

  confirm(): void {
    this.startUpload();
  }

  ngOnDestroy(): void {
    unsubscribe(this.subscriptions);
  }
}
