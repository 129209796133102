import {Pipe, PipeTransform} from '@angular/core';
import {ICard} from 'app/shared/model/payment/card.model';
import {getCardBrandIcon} from 'app/shared/util/credit-card-validator';

@Pipe({
  name: 'cardBrand',
  pure: false
})
export class CardBrandPipe implements PipeTransform {
  transform(card?: ICard): any {
    return card?.brand?.length ? getCardBrandIcon(card.brand) : '';
  }
}
