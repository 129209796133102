import moment from 'moment';
import {Moment} from 'moment';
import {Country, ICountry} from 'app/shared/model/location/country.model';
import {BaseEntity, IBaseEntity} from 'app/shared/model/root/base-entity.model';
import {Criteria} from 'app/shared/filter/criteria.model';
import {ICity} from 'app/shared/model/location/city.model';

export interface IState extends IBaseEntity {
  name?: string;
  code?: string;
  country?: ICountry;
  cities?: ICity[];
}

export class State extends BaseEntity implements IState {
  constructor(
    public id?: number,
    public uuid?: string,
    public createdBy?: string,
    public createdDate?: Moment,
    public lastModifiedBy?: string,
    public lastModifiedDate?: Moment,
    public active?: boolean,
    public name?: string,
    public code?: string,
    public country?: ICountry,
    public cities?: ICity[],
    public _label?: string,
    public _edited?: boolean
  ) {
    super(id, uuid, createdBy, createdDate, lastModifiedBy, lastModifiedDate, active, _label, _edited);
  }

  public static criterias(): Criteria<State>[] {
    return Criteria.buildFrom(this.example()).filter(criteria => criteria.property.name !== 'cities');
  }

  public static example(): State {
    return new State(0, '', '', moment(new Date()), '', moment(new Date()), false, '', '', new Country(), [], '', false);
  }
}
