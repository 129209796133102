import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {flatMap} from 'rxjs/operators';

import {Account} from 'app/core/user/account.model';
import {AccountService} from 'app/core/auth/account.service';
import {AuthServerProvider} from 'app/core/auth/auth-jwt.service';
import {Login} from './login.model';

@Injectable({providedIn: 'root'})
export class LoginService {
  constructor(private accountService: AccountService, private authServerProvider: AuthServerProvider) {}

  login(credentials: Login): Observable<Account | null> {
    return this.authServerProvider.login(credentials).pipe(flatMap(() => this.accountService.identity(true)));
  }

  logout(): void {
    this.authServerProvider.logout().subscribe(null, null, () => this.accountService.authenticate(null));
  }
}
