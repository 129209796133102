import {Injectable, OnDestroy} from '@angular/core';
import {PdfService} from 'app/shared/pdf/pdf.service';
import {Subscription} from 'rxjs';
import {unsubscribe} from 'app/shared/util/react-util';
import {createPdf} from 'pdfmake/build/pdfmake';

@Injectable({providedIn: 'root'})
export class QuillContentReportService implements OnDestroy {
  private subscriptions: Subscription[] = [];

  constructor(private pdfService: PdfService) {}

  generate(content?: string): void {
    createPdf({
      pageSize: 'A4',
      pageMargins: [20, 20, 20, 20],
      content: [
        {
          image: content,
          width: 500,
          alignment: 'center'
        }
      ]
    }).open();
  }

  ngOnDestroy(): void {
    unsubscribe(this.subscriptions);
  }
}
