import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Toast} from 'app/shared/toast/toast.model';
import {ToastService} from 'app/shared/toast/toast.service';
import {Observable} from 'rxjs';
import {tap} from 'rxjs/operators';

@Injectable()
export class NotificationInterceptor implements HttpInterceptor {
  readonly appName = 'ONCLICK';

  constructor(private toastService: ToastService) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      tap((event: HttpEvent<any>) => {
        if (event instanceof HttpResponse) {
          let alert: string | null = null;
          let alertLevel: string | null = null;
          let alertParams: string | null = null;

          event.headers.keys().forEach(entry => {
            switch ((entry || '').toLowerCase()) {
              case 'x-app-alert':
                alert = event.headers.get(entry);
                break;
              case 'x-app-alert-level':
                alertLevel = event.headers.get(entry);
                break;
              case 'x-app-alert-params':
                alertParams = window.btoa(unescape(encodeURIComponent(event.headers.get(entry))));
                alertParams = decodeURIComponent(escape(window.atob(alertParams)));
                break;
            }
          });

          if (alert) {
            alertLevel = alertLevel || 'info';
            switch (alertLevel) {
              case 'success':
                this.toastService.show(
                  Toast.success(alert)
                    .withShowHeader(true)
                    .withHeader(this.appName)
                    .withHeaderIcon('check-circle')
                    .withBodyParams({param: alertParams})
                );
                break;
              case 'warning':
                this.toastService.show(
                  Toast.warning(alert)
                    .withShowHeader(true)
                    .withHeader(this.appName)
                    .withHeaderIcon('exclamation-circle')
                    .withBodyParams({param: alertParams})
                );
                break;
              case 'error':
                this.toastService.show(
                  Toast.danger(alert)
                    .withShowHeader(true)
                    .withHeader(this.appName)
                    .withHeaderIcon('times-circle')
                    .withBodyParams({param: alertParams})
                );
                break;
              case 'info':
              default:
                this.toastService.show(
                  Toast.primary(alert)
                    .withShowHeader(true)
                    .withHeader(this.appName)
                    .withHeaderIcon('info-circle')
                    .withBodyParams({param: alertParams})
                );
                break;
            }
          }
        }
      })
    );
  }
}
