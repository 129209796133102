<div *ngIf="changeLog">
  <div *ngIf="false" class="modal-header">
    <h4 class="modal-title" jhiTranslate="entity.modals.item.detail.title"></h4>
    <button type="button" class="close" data-dismiss="modal" aria-hidden="true" (click)="onCloseClicked()">&times;
    </button>
  </div>

  <div class="modal-body">
    <div class="row justify-content-center">
      <ngb-accordion class="col-12 entity-details" activeIds="panelEntity, panelCategories, panelQuestions">
        <ngb-panel id="panelEntity">
          <ng-template ngbPanelTitle>
            <fa-icon [fixedWidth]="true" icon="list-alt"></fa-icon>
            &nbsp;
            <span>{{'entity.panels.details' | translate}}</span>
          </ng-template>
          <ng-template ngbPanelContent>
            <div class="row justify-content-center">
              <dl class="col-12">
                <dt><span jhiTranslate="global.field.id"></span></dt>
                <dd>
                  <app-view-route-link [entity]="changeLog" route="adm/changes-logs"
                                       target="_blank"></app-view-route-link>
                </dd>

                <dt><span jhiTranslate="global.field.changeLog.revision"></span></dt>
                <dd>{{ changeLog.revision }}</dd>

                <dt><span jhiTranslate="global.field.changeLog.date"></span></dt>
                <dd>{{ changeLog.date | dateMoment:'dd/MM/yyyy' }}</dd>

                <dt><span jhiTranslate="global.field.user"></span></dt>
                <dd>
                  <app-view-route-link [entity]="changeLog.user" [param]="changeLog.user?.login" route="usr/users"
                                       target="_blank"></app-view-route-link>
                </dd>

                <dt><span jhiTranslate="global.field.changeLog.description"></span></dt>
                <dd>{{ changeLog.description }}</dd>
              </dl>
            </div>
          </ng-template>
        </ngb-panel>

        <ngb-panel *ngIf="(changeLog.newValue | jsonAsObject) as log" id="panelChanges">
          <ng-template ngbPanelTitle>
            <div class="d-flex justify-content-between">
              <div>
                <fa-icon [fixedWidth]="true" icon="history"></fa-icon>
                &nbsp;
                <span jhiTranslate="global.field.changes"></span>
              </div>
            </div>
          </ng-template>
          <ng-template ngbPanelContent>
            <div class="row justify-content-center">
              <dl *ngIf="log" class="col-12">

                <ng-container *dmHasAnyAuthority="'SYSTEM_ADMIN'">
                  <dt><span jhiTranslate="global.field.owner"></span></dt>
                  <dd>
                    <app-enum-label [value]="log.owner" [options]="owners" name="owner"></app-enum-label>
                  </dd>
                </ng-container>

                <dt><span jhiTranslate="global.field.name"></span></dt>
                <dd><span>{{ log.name }}</span></dd>

                <dt><span jhiTranslate="global.field.description"></span></dt>
                <dd><span>{{ log.description }}</span></dd>

                <dt><span jhiTranslate="global.field.type"></span></dt>
                <dd>
                  <app-enum-label [value]="log.type" [options]="types" name="formType"></app-enum-label>
                </dd>

                <dt><span jhiTranslate="global.field.company"></span></dt>
                <dd>
                  <app-view-route-link [entity]="log.company" route="com/companies"
                                       target="_blank"></app-view-route-link>
                </dd>

                <dt><span jhiTranslate="global.field.status"></span></dt>
                <dd>
                  <app-status-label [status]="log.status"></app-status-label>
                </dd>

              </dl>
            </div>

            <ngb-accordion class="col-12 entity-details" activeIds="panelCategories, panelQuestions">

              <ngb-panel *ngIf="log.categories" id="panelCategories">
                <ng-template ngbPanelTitle>
                  <div class="d-flex justify-content-between">
                    <div>
                      <fa-icon [fixedWidth]="true" icon="tags"></fa-icon>
                      &nbsp;
                      <span jhiTranslate="formModel.categories"></span>
                      &nbsp;
                      <span *ngIf="totalActiveFormModelCategories(log)"
                            class="badge badge-secondary">{{totalActiveFormModelCategories(log)}}</span>
                    </div>
                  </div>
                </ng-template>
                <ng-template ngbPanelContent>
                  <div *ngIf="!log.categories.length"
                       class="list-empty-alert alert alert-light text-center" role="alert">
                    <div class="font-weight-bold pb-2">
                      <fa-icon [fixedWidth]="true" icon="exclamation-circle"></fa-icon>
                      &nbsp;
                      <span jhiTranslate="global.empty.list.alt"></span>
                    </div>
                  </div>
                  <div *ngIf="log.categories.length" class="table-responsive">
                    <table class="table table-hover" aria-describedby="role-management-page-heading">
                      <thead class="thead-light">
                      <tr>
                        <th scope="col" class="text-center">
                          <span jhiTranslate="global.field.id"></span>
                        </th>
                        <th scope="col">
                          <span jhiTranslate="global.field.name"></span>
                        </th>
                        <th scope="col">
                          <span jhiTranslate="global.field.description"></span>
                        </th>
                        <th scope="col" class="w-20">
                          <span jhiTranslate="global.field.order"></span>
                        </th>
                        <th scope="col" class="w-20">
                          <span jhiTranslate="global.field.faIcon"></span>
                        </th>
                        <th scope="col" class="w-20">
                          <span jhiTranslate="formModel.alertColor"></span>
                        </th>
                      </tr>
                      </thead>
                      <tbody>
                      <tr *ngFor="let category of log.categories | orderBy:'order'; trackBy: trackIdentity"
                          [class]="{'notActive': !category.active}">
                        <td class="text-center">
                          <a *ngIf="category.id" href="javascript:void(0);"
                             (click)="showFormModelCategoryDetails(category)">{{ category.id }}</a>
                        </td>
                        <td>{{ category.name }}</td>
                        <td>{{ category.description }}</td>
                        <td>{{ category.order }}</td>
                        <td>
                          <ng-container *ngIf="category.faIcon">
                            <fa-icon [fixedWidth]="true" [icon]="category.faIcon"></fa-icon>
                            &nbsp;
                          </ng-container>
                          {{ category.faIcon }}
                        </td>
                        <td>
                          <app-enum-label [value]="category.alertColor"
                                          [options]="alertColors" name="alertColor"></app-enum-label>
                        </td>
                      </tr>
                      </tbody>
                    </table>
                  </div>
                </ng-template>
              </ngb-panel>

              <ngb-panel *ngIf="log.questions" id="panelQuestions">
                <ng-template ngbPanelTitle>
                  <div class="d-flex justify-content-between">
                    <div>
                      <fa-icon [fixedWidth]="true" icon="question-circle"></fa-icon>
                      &nbsp;
                      <span jhiTranslate="formModel.questions"></span>
                      &nbsp;
                      <span *ngIf="totalActiveFormModelQuestions(log)"
                            class="badge badge-secondary">{{totalActiveFormModelQuestions(log)}}</span>
                    </div>
                  </div>
                </ng-template>
                <ng-template ngbPanelContent>
                  <div *ngIf="!log.questions.length"
                       class="list-empty-alert alert alert-light text-center" role="alert">
                    <div class="font-weight-bold pb-2">
                      <fa-icon [fixedWidth]="true" icon="exclamation-circle"></fa-icon>
                      &nbsp;
                      <span jhiTranslate="global.empty.list.alt"></span>
                    </div>
                  </div>
                  <div *ngIf="log.questions.length" class="table-responsive">
                    <table class="table table-hover" aria-describedby="role-management-page-heading">
                      <thead class="thead-light">
                      <tr>
                        <th scope="col" class="text-center">
                          <span jhiTranslate="global.field.id"></span>
                        </th>
                        <th scope="col">
                          <span jhiTranslate="formModel.formModelQuestion.question"></span>
                        </th>
                        <th scope="col">
                          <span jhiTranslate="global.field.description"></span>
                        </th>
                        <th scope="col" class="w-20">
                          <span jhiTranslate="global.field.type"></span>
                        </th>
                        <th scope="col" class="w-20">
                          <span jhiTranslate="formModel.formModelQuestion.order"></span>
                        </th>
                        <th scope="col">
                          <span jhiTranslate="formModel.formModelQuestion.category"></span>
                        </th>
                        <th scope="col" class="w-20">
                          <span jhiTranslate="global.field.faIcon"></span>
                        </th>
                        <th scope="col" class="w-20">
                          <span jhiTranslate="formModel.alertColor"></span>
                        </th>
                        <th scope="col">
                          <span jhiTranslate="formModel.formModelQuestion.mandatory"></span>
                        </th>
                        <th scope="col">
                          <span jhiTranslate="formModel.formModelQuestion.weight"></span>
                        </th>
                        <th scope="col">
                          <span jhiTranslate="formModel.formModelQuestion.options"></span>
                        </th>
                      </tr>
                      </thead>
                      <tbody>
                      <ng-container
                        *ngFor="let question of log.questions | orderBy:'order'; trackBy: trackIdentity">
                        <tr [class]="{'notActive': !question.active}">
                          <td class="text-center">
                            <a *ngIf="question.id" href="javascript:void(0);"
                               (click)="showFormModelQuestionDetails(question)">{{ question.id }}</a>
                          </td>
                          <td>{{ question.question }}</td>
                          <td>{{ question.description }}</td>
                          <td>
                            <app-enum-label [value]="question.type" [options]="questionTypes"
                                            name="formQuestionType"></app-enum-label>
                          </td>
                          <td>{{ question.order }}</td>
                          <td>
                            <ng-container *ngIf="question.category">
                              <a *ngIf="question.category.id" href="javascript:void(0);"
                                 (click)="showFormModelCategoryDetails(question.category)">{{ question.category._label }}</a>
                              <span
                                *ngIf="!question.category.id">{{ question.category?.name }}</span>
                            </ng-container>
                          </td>
                          <td>
                            <ng-container *ngIf="question.faIcon">
                              <fa-icon [fixedWidth]="true" [icon]="question.faIcon"></fa-icon>
                              &nbsp;
                            </ng-container>
                            {{ question.faIcon }}
                          </td>
                          <td>
                            <app-enum-label [value]="question.alertColor"
                                            [options]="alertColors" name="alertColor"></app-enum-label>
                          </td>
                          <td>
                            <app-bool-icon [value]="question.mandatory"></app-bool-icon>
                          </td>
                          <td>{{ question.weight }}</td>
                          <td>
                            <button *ngIf="['SINGLE_CHOICE', 'MULTIPLE_CHOICE'].includes(question.type)"
                                    type="button" class="btn btn-sm btn-outline-default"
                                    (click)="toggleDisplayOptions(question)">
                              <span>{{ totalActiveOptions(question) }}</span>
                              &nbsp;
                              <fa-icon [fixedWidth]="true"
                                       [icon]="question._displayOptions ? 'angle-up' : 'angle-down'"></fa-icon>
                            </button>
                          </td>
                        </tr>
                        <tr *ngIf="question._displayOptions">
                          <td colspan="10000000">
                            <ngb-accordion class="entity-child-section" activeIds="panelEffectiveness">
                              <ngb-panel id="panelEffectiveness">
                                <ng-template ngbPanelTitle>
                                  <div class="d-flex justify-content-between">
                                    <div>
                                      <fa-icon [fixedWidth]="true" icon="bars"></fa-icon>
                                      &nbsp;
                                      <span jhiTranslate="formModel.formModelQuestion.options"></span>
                                      &nbsp;
                                      <span *ngIf="totalActiveOptions(question)"
                                            class="badge badge-secondary">{{totalActiveOptions(question)}}</span>
                                    </div>
                                  </div>
                                </ng-template>
                                <ng-template ngbPanelContent>
                                  <div *ngIf="!question.options.length"
                                       class="list-empty-alert alert alert-light text-center" role="alert">
                                    <div class="font-weight-bold pb-2">
                                      <fa-icon [fixedWidth]="true" icon="exclamation-circle"></fa-icon>
                                      &nbsp;
                                      <span jhiTranslate="global.empty.list.alt"></span>
                                    </div>
                                  </div>
                                  <div *ngIf="question.options.length" class="table-responsive">
                                    <table class="table table-hover">
                                      <thead class="thead-light">
                                      <tr jhiSort>
                                        <th scope="col" class="icon"></th>
                                        <th scope="col" class="text-center">
                                          <span jhiTranslate="global.field.id"></span>
                                        </th>
                                        <th scope="col">
                                          <span jhiTranslate="global.field.description"></span>
                                        </th>
                                        <th scope="col">
                                          <span jhiTranslate="global.field.value"></span>
                                        </th>
                                        <th scope="col">
                                          <span jhiTranslate="global.field.order"></span>
                                        </th>
                                      </tr>
                                      </thead>
                                      <tbody>
                                      <tr
                                        *ngFor="let option of question.options | orderBy: 'order'; trackBy: trackIdentity"
                                        [class]="{'notActive': !option.active}">
                                        <td class="text-center dirty-warning">
                                          <fa-icon *ngIf="option._edited" [fixedWidth]="true"
                                                   icon="exclamation-triangle"
                                                   title="{{'global.messages.dirty.warning' | translate}}"></fa-icon>
                                        </td>
                                        <td class="text-center">
                                          <a *ngIf="option.id" href="javascript:void(0);"
                                             (click)="showFormModelQuestionOptionDetails(option)">{{ option.id }}</a>
                                        </td>
                                        <td>{{ option.description }}</td>
                                        <td>{{ option.value }}</td>
                                        <td>{{ option.order }}</td>
                                      </tr>
                                      </tbody>
                                    </table>
                                  </div>
                                </ng-template>
                              </ngb-panel>
                            </ngb-accordion>
                          </td>
                        </tr>
                      </ng-container>
                      </tbody>
                    </table>
                  </div>
                </ng-template>
              </ngb-panel>

            </ngb-accordion>

          </ng-template>
        </ngb-panel>

        <ngb-panel id="panelAudit">
          <ng-template ngbPanelTitle>
            <fa-icon [fixedWidth]="true" icon="exclamation-circle"></fa-icon>
            &nbsp;
            <span>{{'entity.panels.audit' | translate}}</span>
          </ng-template>
          <ng-template ngbPanelContent>
            <div class="row justify-content-center">
              <dl class="col-12">
                <dt><span jhiTranslate="global.field.uuid"></span></dt>
                <dd>{{ changeLog.uuid }}</dd>
                <dt><span jhiTranslate="global.field.active"></span></dt>
                <dd>
                  <app-bool-icon [value]="changeLog.active"></app-bool-icon>
                </dd>
                <dt><span jhiTranslate="global.field.createdBy"></span></dt>
                <dd>{{ changeLog.createdBy }}</dd>
                <dt><span jhiTranslate="global.field.createdDate"></span></dt>
                <dd>{{ changeLog.createdDate | dateMoment:'dd/MM/yyyy HH:mm' }}</dd>
                <dt><span jhiTranslate="global.field.lastModifiedBy"></span></dt>
                <dd>{{ changeLog.lastModifiedBy }}</dd>
                <dt><span jhiTranslate="global.field.lastModifiedDate"></span></dt>
                <dd>{{ changeLog.lastModifiedDate | dateMoment:'dd/MM/yyyy HH:mm' }}</dd>
              </dl>
            </div>
          </ng-template>
        </ngb-panel>
      </ngb-accordion>
    </div>
  </div>

  <div class="modal-footer">
    <div class="form-row">
      <div class="col-12">
        <div class="float-right">
          <button type="button" (click)="onCloseClicked()" class="btn btn-default">
            <fa-icon [fixedWidth]="true" icon="times"></fa-icon>
            &nbsp;
            <span jhiTranslate="entity.action.close"></span>
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
