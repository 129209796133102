import moment from 'moment';
import {Moment} from 'moment';
import {BaseEntity, IBaseEntity} from 'app/shared/model/root/base-entity.model';
import {Criteria} from 'app/shared/filter/criteria.model';
import {Gateway, IGateway} from 'app/shared/model/payment/gateway.model';
import {EntityType} from 'app/shared/model/enumerations/entity-type.enum';

export interface IArtifact extends IBaseEntity {
  gateway?: IGateway;
  identifier?: string;
  status?: string;
  createdAt?: Moment;
  updatedAt?: Moment;
  deletedAt?: Moment;
  linkedEntityType?: EntityType;
  linkedEntityUuid?: string;
  json?: string;
}

export class Artifact extends BaseEntity implements IArtifact {
  constructor(
    public id?: number,
    public uuid?: string,
    public createdBy?: string,
    public createdDate?: Moment,
    public lastModifiedBy?: string,
    public lastModifiedDate?: Moment,
    public active?: boolean,
    public gateway?: IGateway,
    public identifier?: string,
    public status?: string,
    public createdAt?: Moment,
    public updatedAt?: Moment,
    public deletedAt?: Moment,
    public linkedEntityType?: EntityType,
    public linkedEntityUuid?: string,
    public json?: string,
    public _label?: string,
    public _edited?: boolean
  ) {
    super(id, uuid, createdBy, createdDate, lastModifiedBy, lastModifiedDate, active, _label, _edited);
  }

  public static allowedEntityTypes(): EntityType[] {
    return [
      EntityType.PAY_CARD,
      EntityType.PAY_CUSTOMER,
      EntityType.PAY_CARD,
      EntityType.PAY_ORDER,
      EntityType.PAY_ORDER_ITEM,
      EntityType.PAY_CHARGE,
      EntityType.PAY_TRANSACTION
    ];
  }

  public static criterias(): Criteria<Artifact>[] {
    return Criteria.buildFrom(this.example()).map(criteria =>
      criteria.property.name === 'linkedEntityType' ? criteria.asEnum(() => this.allowedEntityTypes(), 'entityType') : criteria
    );
  }

  public static example(): Artifact {
    return new Artifact(
      0,
      '',
      '',
      moment(new Date()),
      '',
      moment(new Date()),
      false,
      new Gateway(),
      '',
      '',
      moment(new Date()),
      moment(new Date()),
      moment(new Date()),
      EntityType.USR_USER,
      '',
      '',
      '',
      false
    );
  }
}
