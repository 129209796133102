const traitInputAttr = {placeholder: ''};

// copiado de grapesjs/src/i18n/locale/pt.js
export const ptBR = {
  assetManager: {
    addButton: 'Adicionar imagem',
    inputPlh: 'http://caminho/para/a/imagem.jpg',
    modalTitle: 'Adicionar imagem',
    uploadTitle: 'Arraste o arquivo ou clique aqui para fazer upload.'
  },
  // Here just as a reference, GrapesJS core doesn't contain any block,
  // so this should be omitted from other local files
  blockManager: {
    labels: {
      // 'block-id': 'Block Label',
    },
    categories: {
      // 'category-id': 'Category Label',
    }
  },
  domComponents: {
    names: {
      '': 'Box',
      wrapper: 'Corpo',
      text: 'Texto',
      comment: 'Comentário',
      image: 'Imagem',
      video: 'Vídeo',
      label: 'Label',
      link: 'Link',
      map: 'Mapa',
      tfoot: 'Rodapé da tabela',
      tbody: 'Corpo da tabela',
      thead: 'Cabeçalho da tabela',
      table: 'Tabela',
      row: 'Linha da tabela',
      cell: 'Célula da tabela',
      section: 'Seção',
      body: 'Corpo'
    }
  },
  deviceManager: {
    device: 'Dispositivo',
    devices: {
      desktop: 'Desktop',
      tablet: 'Tablet',
      mobileLandscape: 'Celular, modo panorâmico',
      mobilePortrait: 'Celular, modo retrato'
    }
  },
  panels: {
    buttons: {
      titles: {
        preview: 'Pré-visualização',
        fullscreen: 'Tela cheia',
        'sw-visibility': 'Exibir componentes',
        'export-template': 'Exibir código fonte',
        'open-sm': 'Personalizar',
        'open-tm': 'Atributos',
        'open-layers': 'Camadas',
        'open-blocks': 'Blocos'
      }
    }
  },
  selectorManager: {
    label: 'Classes',
    selected: 'Selecionado',
    emptyState: '- Estado -',
    states: {
      hover: 'Hover',
      active: 'Click',
      'nth-of-type(2n)': 'Par/Impar'
    }
  },
  styleManager: {
    empty: 'Selecione um componente para personalizá-lo',
    layer: 'Camada',
    fileButton: 'Imagens',
    sectors: {
      general: 'Geral',
      layout: 'Disposição',
      typography: 'Texto',
      decorations: 'Decorações',
      extra: 'Extra',
      flex: 'Flex',
      dimension: 'Dimensão'
    },
    // The core library generates the name by their `property` name
    properties: {
      float: 'Float',
      display: 'Exibição',
      position: 'Posição',
      top: 'Topo',
      right: 'Direita',
      left: 'Esquerda',
      bottom: 'Inferior',
      width: 'Largura',
      height: 'Altura',
      'max-width': 'Largura máxima',
      'max-height': 'Altura máxima',
      margin: 'Margem',
      'margin-top': 'Margem superior',
      'margin-top-sub': 'Superior',
      'margin-right': 'Margem direita',
      'margin-right-sub': 'Direita',
      'margin-left': 'Margem esquerda',
      'margin-left-sub': 'Esquerda',
      'margin-bottom': 'Margem inferior',
      'margin-bottom-sub': 'Inferior',
      padding: 'Preenchimento',
      'padding-top': 'Preenchimento superior',
      'padding-top-sub': 'Superior',
      'padding-left': 'Preenchimento a esquerda',
      'padding-left-sub': 'Esquerda',
      'padding-right': 'Preenchimento a direita',
      'padding-right-sub': 'Direita',
      'padding-bottom': 'Preenchimento inferior',
      'padding-bottom-sub': 'Inferior',
      'font-family': 'Fonte',
      'font-size': 'Tamanho',
      'font-weight': 'Espessura',
      'letter-spacing': 'Espaço entre letras',
      color: 'Cor',
      'line-height': 'Altura da linha',
      'text-align': 'Alinhamento',
      'text-decoration': 'Decoração',
      'font-style': 'Estilo',
      'text-shadow': 'Sombra',
      'text-shadow-h': 'Sombra horizontal',
      'text-shadow-v': 'Sombra vertical',
      'text-shadow-blur': 'Desfoque da sombra',
      'text-shadow-color': 'Cor da sombra',
      'border-top-left': 'Borda superior a esquerda',
      'border-top-right': 'Borda superior a direita',
      'border-bottom-left': 'Borda inferior a esquerda',
      'border-bottom-right': 'Borda inferior a direita',
      'border-radius': 'Arredondamento da borda',
      'border-top-left-radius': 'Superior esquerdo',
      'border-top-left-radius-sub': 'Superior esquerdo',
      'border-top-right-radius': 'Superior direito',
      'border-top-right-radius-sub': 'Superior direito',
      'border-bottom-left-radius': 'Inferior esquerdo',
      'border-bottom-left-radius-sub': 'Inferior esquerdo',
      'border-bottom-right-radius': 'Inferior direito',
      'border-bottom-right-radius-sub': 'Inferior direito',
      'border-radius-top-left': 'Raio da borda superior esquerda',
      'border-radius-top-right': 'Raio da borda superior direita',
      'border-radius-bottom-left': 'Raio da borda inferior esquerda',
      'border-radius-bottom-right': 'Raio da borda inferior direita',
      border: 'Borda',
      'border-width': 'Espessura da borda',
      'border-width-sub': 'Espessura',
      'border-style': 'Estilo da borda',
      'border-style-sub': 'Estilo',
      'border-color': 'Cor da borda',
      'border-color-sub': 'Cor',
      'box-shadow': 'Sombra da box',
      'box-shadow-h': 'Sombra da box: horizontal',
      'box-shadow-v': 'Sombra da box: vertical',
      'box-shadow-blur': 'Desfoque da sombra da box',
      'box-shadow-spread': 'Extensão da sombra da box',
      'box-shadow-color': 'Cor da sombra da box',
      'box-shadow-type': 'Tipo de sombra da box',
      background: 'Fundo',
      'container-background-color': 'Cor de fundo',
      'background-color': 'Cor de fundo',
      'background-url': 'URL da image de fundo',
      'background-image': 'Imagem de fundo',
      'background-repeat': 'Repetir fundo',
      'background-position': 'Posição do fundo',
      'background-attachment': 'Plugin de fundo',
      'background-size': 'Tamanho do fundo',
      transition: 'Transição',
      'transition-property': 'Tipo',
      'transition-duration': 'Tempo',
      'transition-timing-function': 'Função do tempo da transição',
      perspective: 'Perspectiva',
      transform: 'Transformação',
      'transform-rotate-x': 'Rotacionar horizontalmente',
      'transform-rotate-y': 'Rotacionar verticalmente',
      'transform-rotate-z': 'Rotacionar profundidade',
      'transform-scale-x': 'Escalar horizontalmente',
      'transform-scale-y': 'Escalar verticalmente',
      'transform-scale-z': 'Escalar profundidade',
      'flex-direction': 'Direção flex',
      'flex-wrap': 'Flex wrap',
      'justify-content': 'Ajustar conteúdo',
      'align-items': 'Alinhar elementos',
      'align-content': 'Alinhar conteúdo',
      order: 'Ordem',
      'flex-basis': 'Base flex',
      'flex-grow': 'Crescimento flex',
      'flex-shrink': 'Contração flex',
      'align-self': 'Alinhar-se',
      align: 'Alinhamento',
      'vertical-align': 'Alinhamento vertical'
    }
  },
  traitManager: {
    empty: 'Selecione um componente para editar seus atributos',
    label: 'Atributos',
    traits: {
      // The core library generates the name by their `name` property
      labels: {
        // id: 'Id',
        alt: 'Texto alternativo',
        title: 'Titulo',
        href: 'Link'
      },
      // In a simple trait, like text input, these are used on input attributes
      attributes: {
        id: traitInputAttr,
        alt: traitInputAttr,
        title: traitInputAttr,
        href: traitInputAttr
      },
      // In a trait like select, these are used to translate option names
      options: {
        target: {
          false: 'Esta janela',
          _blank: 'Nova janela'
        }
      }
    }
  }
};

// copiado de grapesjs/src/i18n/locale/en.js
export const en = {
  assetManager: {
    addButton: 'Add image',
    inputPlh: 'http://path/to/the/image.jpg',
    modalTitle: 'Select Image',
    uploadTitle: 'Drop files here or click to upload'
  },
  // Here just as a reference, GrapesJS core doesn't contain any block,
  // so this should be omitted from other local files
  blockManager: {
    labels: {
      // 'block-id': 'Block Label',
    },
    categories: {
      // 'category-id': 'Category Label',
    }
  },
  domComponents: {
    names: {
      '': 'Box',
      wrapper: 'Body',
      text: 'Text',
      comment: 'Comment',
      image: 'Image',
      video: 'Video',
      label: 'Label',
      link: 'Link',
      map: 'Map',
      tfoot: 'Table foot',
      tbody: 'Table body',
      thead: 'Table head',
      table: 'Table',
      row: 'Table row',
      cell: 'Table cell'
    }
  },
  deviceManager: {
    device: 'Device',
    devices: {
      desktop: 'Desktop',
      tablet: 'Tablet',
      mobileLandscape: 'Mobile Landscape',
      mobilePortrait: 'Mobile Portrait'
    }
  },
  panels: {
    buttons: {
      titles: {
        preview: 'Preview',
        fullscreen: 'Fullscreen',
        'sw-visibility': 'View components',
        'export-template': 'View code',
        'open-sm': 'Open Style Manager',
        'open-tm': 'Settings',
        'open-layers': 'Open Layer Manager',
        'open-blocks': 'Open Blocks'
      }
    }
  },
  selectorManager: {
    label: 'Classes',
    selected: 'Selected',
    emptyState: '- State -',
    states: {
      hover: 'Hover',
      active: 'Click',
      'nth-of-type(2n)': 'Even/Odd'
    }
  },
  styleManager: {
    empty: 'Select an element before using Style Manager',
    layer: 'Layer',
    fileButton: 'Images',
    sectors: {
      general: 'General',
      layout: 'Layout',
      typography: 'Typography',
      decorations: 'Decorations',
      extra: 'Extra',
      flex: 'Flex',
      dimension: 'Dimension'
    },
    // Default names for sub properties in Composite and Stack types.
    // Other labels are generated directly from their property names (eg. 'font-size' will be 'Font size').
    properties: {
      'text-shadow-h': 'X',
      'text-shadow-v': 'Y',
      'text-shadow-blur': 'Blur',
      'text-shadow-color': 'Color',
      'box-shadow-h': 'X',
      'box-shadow-v': 'Y',
      'box-shadow-blur': 'Blur',
      'box-shadow-spread': 'Spread',
      'box-shadow-color': 'Color',
      'box-shadow-type': 'Type',
      'margin-top-sub': 'Top',
      'margin-right-sub': 'Right',
      'margin-bottom-sub': 'Bottom',
      'margin-left-sub': 'Left',
      'padding-top-sub': 'Top',
      'padding-right-sub': 'Right',
      'padding-bottom-sub': 'Bottom',
      'padding-left-sub': 'Left',
      'border-width-sub': 'Width',
      'border-style-sub': 'Style',
      'border-color-sub': 'Color',
      'border-top-left-radius-sub': 'Top Left',
      'border-top-right-radius-sub': 'Top Right',
      'border-bottom-right-radius-sub': 'Bottom Right',
      'border-bottom-left-radius-sub': 'Bottom Left',
      'transform-rotate-x': 'Rotate X',
      'transform-rotate-y': 'Rotate Y',
      'transform-rotate-z': 'Rotate Z',
      'transform-scale-x': 'Scale X',
      'transform-scale-y': 'Scale Y',
      'transform-scale-z': 'Scale Z',
      'transition-property-sub': 'Property',
      'transition-duration-sub': 'Duration',
      'transition-timing-function-sub': 'Timing',
      'background-image-sub': 'Image',
      'background-repeat-sub': 'Repeat',
      'background-position-sub': 'Position',
      'background-attachment-sub': 'Attachment',
      'background-size-sub': 'Size'
    }
    // Translate options in style properties
    // options: {
    //   float: { // Id of the property
    //     ...
    //     left: 'Left', // {option id}: {Option label}
    //   }
    // }
  },
  traitManager: {
    empty: 'Select an element before using Trait Manager',
    label: 'Component settings',
    traits: {
      // The core library generates the name by their `name` property
      labels: {
        // id: 'Id',
        // alt: 'Alt',
        // title: 'Title',
        // href: 'Href',
      },
      // In a simple trait, like text input, these are used on input attributes
      attributes: {
        id: traitInputAttr,
        alt: traitInputAttr,
        title: traitInputAttr,
        href: {placeholder: 'eg. https://google.com'}
      },
      // In a trait like select, these are used to translate option names
      options: {
        target: {
          false: 'This window',
          _blank: 'New window'
        }
      }
    }
  },
  storageManager: {
    recover: 'Do you want to recover unsaved changes?'
  }
};

// copiado de grapesjs/src/i18n/locale/es.js
export const es = {
  assetManager: {
    addButton: 'Añadir imagen',
    inputPlh: 'http://camino/a/la/imagen.jpg',
    modalTitle: 'Seleccionar imagen',
    uploadTitle: 'Arrastre los archivos aquí o haga clic para cargar'
  },
  // Here just as a reference, GrapesJS core doesn't contain any block,
  // so this should be omitted from other local files
  blockManager: {
    labels: {
      // 'block-id': 'Block Label',
    },
    categories: {
      // 'category-id': 'Category Label',
    }
  },
  domComponents: {
    names: {
      '': 'Caja',
      wrapper: 'Cuerpo',
      text: 'Texto',
      comment: 'Comentario',
      image: 'Imagen',
      video: 'Video',
      label: 'Etiqueta',
      link: 'Vínculo',
      map: 'Mapa',
      tfoot: 'Pie de lista',
      tbody: 'Cuerpo de lista',
      thead: 'Encabezado de lista',
      table: 'Lista',
      row: 'Fila de lista',
      cell: 'Celda de lista'
    }
  },
  deviceManager: {
    device: 'Dispositivos',
    devices: {
      desktop: 'Escritorio',
      tablet: 'Tableta',
      mobileLandscape: 'Mobile Landscape',
      mobilePortrait: 'Mobile Portrait'
    }
  },
  panels: {
    buttons: {
      titles: {
        preview: 'Vista previa',
        fullscreen: 'Pantalla completa',
        'sw-visibility': 'Ver componentes',
        'export-template': 'Ver código',
        'open-sm': 'Abrir Administrador de estilos',
        'open-tm': 'Ajustes',
        'open-layers': 'Abrir Aministrador de capas',
        'open-blocks': 'Abrir Bloques'
      }
    }
  },
  selectorManager: {
    label: 'Clases',
    selected: 'Seleccionado',
    emptyState: '- Estado -',
    states: {
      hover: 'Hover',
      active: 'Click',
      'nth-of-type(2n)': 'Par/Impar'
    }
  },
  styleManager: {
    empty: 'Seleccione un elemento antes de usar el Administrador de estilos',
    layer: 'Capa',
    fileButton: 'Imágenes',
    sectors: {
      general: 'General',
      layout: 'Diseño',
      typography: 'Tipografía',
      decorations: 'Decoraciones',
      extra: 'Extras',
      flex: 'Flex',
      dimension: 'Dimensión'
    },
    // The core library generates the name by their `property` name
    properties: {
      float: 'Float',
      display: 'Vista',
      position: 'Posición',
      top: 'Superior',
      right: 'Derecho',
      left: 'Izquierdo',
      bottom: 'Inferior',
      width: 'Ancho',
      height: 'Altura',
      'max-width': 'Max. ancho',
      'max-height': 'Max. altura',
      margin: 'Margen',
      'margin-top': 'Margen Superior',
      'margin-right': 'Margen Derecho',
      'margin-left': 'Margen Izquierdo',
      'margin-bottom': 'Margen Inferior',
      padding: 'Padding',
      'padding-top': 'Padding Superior',
      'padding-left': 'Padding Sol',
      'padding-right': 'Padding Derecho',
      'padding-bottom': 'Padding Inferior',
      'font-family': 'Tipo de letra',
      'font-size': 'Tamaño de fuente',
      'font-weight': 'Espesor',
      'letter-spacing': 'Espacio de letras',
      color: 'Color',
      'line-height': 'Interlineado',
      'text-align': 'Alineación de texto',
      'text-shadow': 'Sombra de texto',
      'text-shadow-h': 'Sombra de texto: horizontal',
      'text-shadow-v': 'Sombra de texto: vertical',
      'text-shadow-blur': 'Desenfoque de sombra de texto',
      'text-shadow-color': 'Color de sombra de fuente',
      'border-top-left': 'Borde Superior Izquierdo',
      'border-top-right': 'Borde Superior Derecho',
      'border-bottom-left': 'Borde Inferior Izquierdo',
      'border-bottom-right': 'Borde Inferior Derecho',
      'border-radius-top-left': 'Borde Redondeado Superior Izquierdo',
      'border-radius-top-right': 'Borde Redondeado Superior Derecho',
      'border-radius-bottom-left': 'Borde Redondeado Inferior Izquierdo',
      'border-radius-bottom-right': 'Borde Redondeado Inferior Derecho',
      'border-radius': 'Borde Redondeado',
      border: 'Bordes',
      'border-width': 'Grosor del Borde',
      'border-style': 'Estilo del Borde',
      'border-color': 'Color de Borde',
      'box-shadow': 'Sombra de caja',
      'box-shadow-h': 'Sombra de caja: horizontal',
      'box-shadow-v': 'Sombra de caja: vertical',
      'box-shadow-blur': 'Desenfoque de sombra de caja',
      'box-shadow-spread': 'Extensión de sombra de caja',
      'box-shadow-color': 'Color de sombra de caja',
      'box-shadow-type': 'Tipo de sombra de caja',
      background: 'Fondo',
      'background-image': 'Imagen de Fondo',
      'background-repeat': 'Repetir fondo',
      'background-position': 'Posición de fondo',
      'background-attachment': 'Plugin de fondo',
      'background-size': 'Tamaño de fondo',
      transition: 'Transición',
      'transition-property': 'Tipo de transición',
      'transition-duration': 'Tiempo de transición',
      'transition-timing-function': 'Función de tiempo de la transición',
      perspective: 'Perspectiva',
      transform: 'Transformación',
      'transform-rotate-x': 'Rotar horizontalmente',
      'transform-rotate-y': 'Rotar verticalmente',
      'transform-rotate-z': 'Rotar profundidad',
      'transform-scale-x': 'Escalar horizontalmente',
      'transform-scale-y': 'Escalar verticalmente',
      'transform-scale-z': 'Escalar profundidad',
      'flex-direction': 'Dirección Flex',
      'flex-wrap': 'Flex wrap',
      'justify-content': 'Ajustar contenido',
      'align-items': 'Alinear elementos',
      'align-content': 'Alinear contenido',
      order: 'Orden',
      'flex-basis': 'Base Flex',
      'flex-grow': 'Crecimiento Flex',
      'flex-shrink': 'Contracción Flex',
      'align-self': 'Alinearse',
      'background-color': 'Color de fondo'
    }
  },
  traitManager: {
    empty: 'Seleccione un elemento antes de usar el Administrador de rasgos',
    label: 'Ajustes de componentes',
    traits: {
      // The core library generates the name by their `name` property
      labels: {
        id: 'Identificador',
        alt: 'Título alterno',
        title: 'Título',
        href: 'Vínculo'
      },
      // In a simple trait, like text input, these are used on input attributes
      attributes: {
        id: traitInputAttr,
        alt: traitInputAttr,
        title: traitInputAttr,
        href: {placeholder: 'ej. https://google.com'}
      },
      // In a trait like select, these are used to translate option names
      options: {
        target: {
          false: 'Esta ventana',
          _blank: 'Nueva ventana'
        }
      }
    }
  }
};

// copiado de grapesjs-mjml/locale/pt.js
export const mjmlPtBR = {
  'grapesjs-mjml': {
    category: '',
    panels: {
      buttons: {
        undo: 'Desfazer',
        redo: 'Refazer',
        desktop: 'Desktop',
        tablet: 'Tablet',
        mobile: 'Mobile',
        import: 'Importar MJML'
      },
      import: {
        title: 'Importar MJML',
        button: 'Importar',
        label: ''
      },
      export: {
        title: 'Exportar MJML'
      }
    },
    components: {
      names: {
        body: 'Corpo',
        button: 'Botão',
        column: 'Coluna',
        oneColumn: '1 Coluna',
        twoColumn: '2 Colunas',
        threeColumn: '3 Colunas',
        divider: 'Separador',
        group: 'Grupo',
        hero: 'Destaque',
        image: 'Imagem',
        navBar: 'Navegação',
        navLink: 'Link de navegação',
        section: 'Seção',
        socialGroup: 'Mídias sociais',
        socialElement: 'Mídia social',
        spacer: 'Espaçamento',
        text: 'Texto',
        wrapper: 'Embrulho',
        raw: 'HTML'
      }
    }
  }
};

// copiado de grapesjs-mjml/locale/en.js
export const mjmlEn = {
  'grapesjs-mjml': {
    category: '',
    panels: {
      buttons: {
        undo: 'Undo',
        redo: 'Redo',
        desktop: 'Desktop',
        tablet: 'Tablet',
        mobile: 'Mobile',
        import: 'Import MJML'
      },
      import: {
        title: 'Import MJML',
        button: 'Import',
        label: ''
      },
      export: {
        title: 'Export MJML'
      }
    },
    components: {
      names: {
        body: 'Body',
        button: 'Button',
        column: 'Column',
        oneColumn: '1 Column',
        twoColumn: '2 Columns',
        threeColumn: '3 Columns',
        divider: 'Divider',
        group: 'Group',
        hero: 'Hero',
        image: 'Image',
        navBar: 'Navbar',
        navLink: 'Navbar Link',
        section: 'Section',
        socialGroup: 'Group Social',
        socialElement: 'Social Element',
        spacer: 'Spacer',
        text: 'Text',
        wrapper: 'Wrapper',
        raw: 'Raw'
      }
    }
  }
};

// copiado de grapesjs-mjml/locale/es.js
export const mjmlEs = {
  'grapesjs-mjml': {
    category: '',
    panels: {
      buttons: {
        undo: 'Deshacer',
        redo: 'Rehacer',
        desktop: 'Escritorio',
        tablet: 'Tableta',
        mobile: 'Móvil',
        import: 'Importar MJML'
      },
      import: {
        title: 'Importar MJML',
        button: 'Importar',
        label: 'Etiqueta'
      },
      export: {
        title: 'Exportar MJML'
      }
    },
    components: {
      names: {
        body: 'Cuerpo',
        button: 'Botón',
        column: 'Columna',
        oneColumn: '1 Columna',
        twoColumn: '2 Columnas',
        threeColumn: '3 Columnas',
        divider: 'Divisor',
        group: 'Grupo',
        hero: 'Hero',
        image: 'Imagen',
        navBar: 'Barra navegación',
        navLink: 'Enlace barra navegación',
        section: 'Sección',
        socialGroup: 'Grupo Social',
        socialElement: 'Elemento Social',
        spacer: 'Separador',
        text: 'Texto',
        wrapper: 'Envoltura'
      }
    }
  }
};

export const fonts = [
  {
    name: 'Montserrat',
    value: 'Montserrat, sans-serif',
    url: 'https://fonts.googleapis.com/css?family=Montserrat:100,200,300,400,500,600,700,800,900'
  },
  {
    name: 'Source Sans Pro',
    value: 'Source Sans Pro, sans-serif',
    url: 'https://fonts.googleapis.com/css?family=Source+Sans+Pro:100,200,300,400,500,600,700,800,900'
  },
  {
    name: 'Open Sans',
    value: 'Open Sans, sans-serif',
    url: 'https://fonts.googleapis.com/css?family=Open+Sans:100,200,300,400,500,600,700,800,900'
  },
  {
    name: 'Roboto Slab',
    value: 'Roboto Slab, serif',
    url: 'https://fonts.googleapis.com/css?family=Roboto+Slab:100,200,300,400,500,600,700,800,900'
  }
];

export const defaultMjmlTemplate = `
<mjml owa="desktop" version="4.3.0">
  <mj-head>
    <mj-font href="https://fonts.googleapis.com/css?family=Montserrat" name="Montserrat">
    </mj-font>
    <mj-font href="https://fonts.googleapis.com/css?family=Raleway" name="Raleway">
    </mj-font>
    <mj-font href="https://fonts.googleapis.com/css?family=Open Sans" name="Open Sans">
    </mj-font>
    <mj-preview>
    </mj-preview>
  </mj-head>
  <mj-body background-color="#f8f8f8" color="#053254" font-family="Open Sans, Helvetica, Arial, sans-serif">
    <mj-section background-repeat="repeat" background-size="auto" padding-bottom="0px" padding-top="0px" padding="20px 0px 20px 0px" vertical-align="top">
      <mj-column>
        <mj-text color="#053254" font-family="Open Sans, Helvetica, Arial, sans-serif" font-size="11px" padding-bottom="0px" padding-top="0px" padding="0px 0px 0px 25px">
        </mj-text>
      </mj-column>
    </mj-section>
    <mj-section background-color="#ffffff" background-repeat="repeat" padding-bottom="0px" padding-top="0px" padding="20px 0" vertical-align="top">
      <mj-column>
        <mj-text font-size="12px" align="right">
          <a href="{{viewBrowserUrl}}" data-selectme="">{{viewBrowserName}}</a>
        </mj-text>
        <mj-divider border-color="#0d93fe" border-width="7px" padding-bottom="40px" padding-left="0px" padding-right="0px" padding-top="0px" padding="10px 25px">
      </mj-column>
    </mj-section>
    <mj-section background-color="#ffffff" background-repeat="repeat" background-size="auto" padding-bottom="0px" padding-top="0px" padding="20px 0" vertical-align="top">
    </mj-section>
    <mj-section background-color="#ffffff" background-repeat="repeat" background-size="auto" padding-bottom="70px" padding-top="30px" padding="20px 0px 20px 0px" vertical-align="top">
      <mj-column>
        <mj-text color="#053254" font-family="Open Sans, Helvetica, Arial, sans-serif" padding-bottom="0px" padding-left="50px" padding-right="50px" padding-top="0px" padding="0px 25px 0px 25px">
          <h1 id="iz7wi">{{email.title}}
          </h1>
        </mj-text>
        <mj-text color="#053254" font-family="Open Sans, Helvetica, Arial, sans-serif" padding-bottom="0px" padding-left="50px" padding-right="50px" padding-top="0px" padding="0px 25px 0px 25px">
          <p id="ivnkv">{{email.text}}
          </p>
        </mj-text>
        <mj-button background-color="#0d93fe" border-radius="100px" border="none" font-family="Open Sans, Helvetica, Arial, sans-serif" font-weight="normal" href="{{email.btn.url}}" inner-padding="15px 25px 15px 25px" padding-top="20px" padding="10px 25px" text-decoration="none" text-transform="none">
          <b>{{email.btn.name}}
          </b>
        </mj-button>
        <b id="iuz6g">
          <b id="i884s">Botão
          </b>
        </b>
      </mj-column>
    </mj-section>
  </mj-body>
</mjml>`;
