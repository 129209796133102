import {ErrorHandler, Injectable} from '@angular/core';
import {HttpErrorResponse} from '@angular/common/http';
import {Logger} from 'app/shared/util/logger';

const Log = new Logger('GlobalErrorHandler');

@Injectable()
export class GlobalErrorHandler implements ErrorHandler {

  constructor() {
  }

  handleError(error: any) {

    if (!(error instanceof HttpErrorResponse)) {
      error = error.rejection;
    }

    Log.error(() => `Not handled error: ${JSON.stringify(error)}`, error);
  }
}