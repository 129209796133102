<div *ngIf="formAnswerOption">

  <div class="modal-body">
    <div class="row justify-content-center">
      <ngb-accordion class="col-12 entity-details" activeIds="panelEntity">
        <ngb-panel id="panelEntity">
          <ng-template ngbPanelTitle>
            <fa-icon [fixedWidth]="true" icon="list-alt"></fa-icon>
            &nbsp;
            <span>{{'entity.panels.details' | translate}}</span>
          </ng-template>
          <ng-template ngbPanelContent>
            <div class="row justify-content-center">
              <dl class="col-12">
                <dt><span jhiTranslate="global.field.id"></span></dt>
                <dd>{{ formAnswerOption.id }}</dd>
                <dt><span jhiTranslate="form.formAnswerOption.option"></span></dt>
                <dd>
                  <a *ngIf="formAnswerOption.option" href="javascript:void(0);"
                     (click)="showFormModelQuestionOptionDetails(formAnswerOption.option)">{{formAnswerOption.option._label}}</a>
                </dd>
              </dl>
            </div>
          </ng-template>
        </ngb-panel>
        <ngb-panel id="panelAudit">
          <ng-template ngbPanelTitle>
            <fa-icon [fixedWidth]="true" icon="exclamation-circle"></fa-icon>
            &nbsp;
            <span>{{'entity.panels.audit' | translate}}</span>
          </ng-template>
          <ng-template ngbPanelContent>
            <div class="row justify-content-center">
              <dl class="col-12">
                <dt><span jhiTranslate="global.field.uuid"></span></dt>
                <dd>{{ formAnswerOption.uuid }}</dd>
                <dt><span jhiTranslate="global.field.active"></span></dt>
                <dd>
                  <app-bool-icon [value]="formAnswerOption.active"></app-bool-icon>
                </dd>
                <dt><span jhiTranslate="global.field.createdBy"></span></dt>
                <dd>{{ formAnswerOption.createdBy }}</dd>
                <dt><span jhiTranslate="global.field.createdDate"></span></dt>
                <dd>{{ formAnswerOption.createdDate | dateMoment:'dd/MM/yyyy HH:mm' }}</dd>
                <dt><span jhiTranslate="global.field.lastModifiedBy"></span></dt>
                <dd>{{ formAnswerOption.lastModifiedBy }}</dd>
                <dt><span jhiTranslate="global.field.lastModifiedDate"></span></dt>
                <dd>{{ formAnswerOption.lastModifiedDate | dateMoment:'dd/MM/yyyy HH:mm' }}</dd>
              </dl>
            </div>
          </ng-template>
        </ngb-panel>
      </ngb-accordion>
    </div>
  </div>

  <div class="modal-footer">
    <div class="form-row">
      <div class="col-12">
        <div class="float-right">
          <button type="button" (click)="onCloseClicked()" class="btn btn-default">
            <fa-icon [fixedWidth]="true" icon="times"></fa-icon>
            &nbsp;
            <span jhiTranslate="entity.action.close"></span>
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
