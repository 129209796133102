import {Injectable} from '@angular/core';
import {NgbModal, NgbModalRef} from '@ng-bootstrap/ng-bootstrap';
import {FormResponseEditorModalComponent} from 'app/shared/form/form-response-editor-modal.component';
import {IForm} from 'app/shared/model/form/form.model';
import {IFormResponseOptions} from 'app/shared/form/form-response-editor.component';

@Injectable({providedIn: 'root'})
export class FormResponseEditorModalService {
	private isOpen = false;
	private instance?: NgbModalRef;

	constructor(private modalService: NgbModal) {
	}

	show(
		form: IForm,
		submitSuccess?: Function,
		submitError?: Function,
		submitClicked?: Function,
		cancel?: Function,
		options: IFormResponseOptions = {
			mode: 'modal',
			showHeader: true,
			showFormDescription: true,
			showCancelButton: true,
			showSaveButton: true,
			autoSubmit: true,
			autoClose: true
		}
	): void {
		if (this.isOpen) {
			return;
		}

		this.isOpen = true;

		this.instance = this.modalService.open(FormResponseEditorModalComponent, {size: 'xl', backdrop: 'static'});
		this.instance.componentInstance.form = form;
		this.instance.componentInstance.options = options;
		this.instance.componentInstance.submitSuccess = submitSuccess;
		this.instance.componentInstance.submitError = submitError;
		this.instance.componentInstance.submitClicked = submitClicked;
		this.instance.componentInstance.cancel = cancel;

		this.instance.result.finally(() => (this.isOpen = false));
	}

	confirm(): void {
		if (!this.isOpen || !this.instance) {
			return;
		}
		this.instance.close();
	}

	dismiss(): void {
		if (!this.isOpen || !this.instance) {
			return;
		}
		this.instance.dismiss();
	}
}
