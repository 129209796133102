<form autocomplete="off" [formGroup]="form" (submit)="submit()">
  <div *ngIf="form" class="criteria-filter" [ngClass]="{'card': options.mode === 'card'}">

    <ng-container *ngIf="options.showHeader">
      <div *ngIf="options.mode === 'card'" class="card-header">
        <span jhiTranslate="criteria.title"></span>
      </div>
      <div *ngIf="options.mode === 'modal'" class="modal-header">
        <h4 class="modal-title" jhiTranslate="criteria.title"></h4>
        <button type="button" class="close" data-dismiss="modal" aria-hidden="true" (click)="cancel()">&times;</button>
      </div>
    </ng-container>

    <div class="{{options.mode}}-body">
      <div class="form-group">
        <div class="form-row">
          <div class="col-12"
               *ngFor="let criteria of criterias | criteriaPropertyFilterPipe:displayFields; trackBy: trackIdentity;">
            <div class="input-group pb-2">
              <div class="input-group-prepend">
                <label class="input-group-text" [translate]="getFieldTranslation(criteria.property.name)"
                       [title]="getFieldTranslation(criteria.property.name)"></label>
              </div>
              <select class="form-control criteria-condition-select" [id]="'condition-' + criteria.property.name"
                      [formControlName]="'condition-' + criteria.property.name">
                <option *ngFor="let condition of availableConditions(criteria)" [value]="condition"
                        [label]="('criteria.conditions.' + condition) | translate"></option>
              </select>

              <input *ngIf="!criteria.requiresValue()" class="form-control criteria-value" disabled>

              <input *ngIf="criteria.requiresValue() && criteria.isInput() && !isDateField(criteria)"
                     [type]="criteria.inputType()"
                     class="form-control criteria-value" [id]="criteria.property.name"
                     [formControlName]="criteria.property.name">

              <div *ngIf="criteria.requiresValue() && criteria.isInput() && isDateField(criteria)"
                   class="input-group criteria-value">
                <input type="text" [placeholder]="mask.datePlaceholder()"
                       minlength="10" maxlength="10"
                       class="form-control date-form-control"
                       [rInputMask]="mask.dateDDMMYYYY()"
                       [formControlName]="criteria.property.name"
                       [id]="criteria.property.name"
                       ngbDatepicker #d="ngbDatepicker" placement="bottom-right" [positionTarget]="btnCal">
                <div class="input-group-append">
                  <button #btnCal class="btn btn-outline-secondary" (click)="d.toggle()" type="button">
                    <fa-icon [fixedWidth]="true" icon="calendar-alt"></fa-icon>
                  </button>
                </div>
              </div>

              <select *ngIf="criteria.requiresValue() && criteria.isEnum()" class="form-control selector"
                      [id]="criteria.property.name" [formControlName]="criteria.property.name">
                <option *ngFor="let item of criteria.items()" [value]="item"
                        [label]="(criteria.translationPrefix + '.' + item) | translate"></option>
              </select>

              <div *ngIf="criteria.requiresValue() && criteria.isFaIcon()" class="form-control selector">
                <ng-select [items]="faIcons" [id]="criteria.property.name"
                           [formControlName]="criteria.property.name" bindLabel="_label"
                           [virtualScroll]="true">
                  <ng-template ng-label-tmp let-item="item">
										<span>
											<fa-icon [fixedWidth]="true" [icon]="item | asIcon"></fa-icon>
											&nbsp;
                      {{ item }}
										</span>
                  </ng-template>
                  <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
                    <div class="option-item" [title]="item.description">
                      <span>
                        <input id="item4-{{index}}" type="checkbox" [ngModel]="item$.selected"
                               [ngModelOptions]="{ standalone : true }"/>
                      </span>
                      &nbsp;
                      <fa-icon [fixedWidth]="true" [icon]="item | asIcon"></fa-icon>
                      &nbsp;
                      <label for="item4-{{index}}">{{item}}</label>
                    </div>
                  </ng-template>
                </ng-select>
              </div>

              <div *ngIf="criteria.requiresValue() && criteria.isObject()" class="form-control selector">
                <ng-select *ngIf="criteria.items() as items" [items]="items" [id]="criteria.property.name"
                           [formControlName]="criteria.property.name" bindLabel="_label"
                           [virtualScroll]="items?.length">
                  <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
                    <div class="option-item" [title]="item._label">
                    <span>
                      <input id="item-{{index}}" type="checkbox" [ngModel]="item$.selected"
                             [ngModelOptions]="{ standalone : true }"/>
                    </span>
                      <label for="item-{{index}}">{{item._label}}</label>
                    </div>
                  </ng-template>
                </ng-select>
              </div>
            </div>
          </div>
        </div>
        <div *ngIf="!thirdPartyEntity" class="form-row pt-2">
          <div class="form-check form-check-inline">
            <div class="form-check">
              <input type="checkbox" class="form-check-input" id="identifierFields"
                     [(ngModel)]="displayFields.identifier"
                     [ngModelOptions]="{standalone: true}">
              <label class=" form-check-label" for="identifierFields" jhiTranslate="criteria.identifierFields"></label>
            </div>
            <div class="form-check">
              <input type="checkbox" class="form-check-input" id="auditFields" [(ngModel)]="displayFields.audit"
                     [ngModelOptions]="{standalone: true}">
              <label class=" form-check-label" for="auditFields" jhiTranslate="criteria.auditFields"></label>
            </div>
            <div class="form-check" *dmHasAnyAuthority="'SYSTEM_ADMIN'">
              <input type="checkbox" class="form-check-input" id="adminFields" [(ngModel)]="displayFields.admin"
                     [ngModelOptions]="{standalone: true}">
              <label class=" form-check-label" for="adminFields" jhiTranslate="criteria.adminFields"></label>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="{{options.mode}}-footer">
      <div class="form-row">
        <div class="col-12">
          <div class="float-left">
            <button type="button" (click)="clear()" class="btn btn-default">
              <fa-icon [fixedWidth]="true" icon="eraser"></fa-icon>
              &nbsp;
              <span jhiTranslate="entity.action.clear"></span>
            </button>
          </div>
          <div class="float-right">
            <button *ngIf="options.mode === 'modal'" type="button" (click)="cancel()" class="btn btn-secondary">
              <fa-icon [fixedWidth]="true" icon="ban"></fa-icon>
              &nbsp;
              <span jhiTranslate="entity.action.cancel"></span>
            </button>
            &nbsp;
            <button type="submit" class="btn btn-primary">
              <fa-icon [fixedWidth]="true" icon="check"></fa-icon>
              &nbsp;
              <span jhiTranslate="entity.action.apply"></span>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</form>
