import {Pipe, PipeTransform} from '@angular/core';
import {StringUtilService} from 'app/shared/util/string-util.service';
import {ILocation} from 'app/shared/model/location/location.model';

@Pipe({
  name: 'address',
  pure: false
})
export class AddressPipe implements PipeTransform {
  constructor(private stringUtilService: StringUtilService) {}

  transform(location: ILocation): any {
    return location ? this.stringUtilService.formatLocation(location) : '';
  }
}
