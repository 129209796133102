import moment, {Moment} from 'moment';
import {Company, ICompany} from 'app/shared/model/company/company.model';
import {Criteria} from 'app/shared/filter/criteria.model';
import {BaseEntity, IBaseEntity} from 'app/shared/model/root/base-entity.model';
import {Status} from 'app/shared/model/enumerations/status.enum';
import {allFormTypes, FormType} from 'app/shared/model/enumerations/form-type.enum';
import {IFormModelQuestion} from 'app/shared/model/form/form-model-question.model';
import {Owner} from 'app/shared/model/enumerations/owner.enum';
import {IFormModelCategory} from 'app/shared/model/form/form-model-category.model';
import {IChangeLog} from 'app/shared/model/admin/audit/change-log.model';

export interface IFormModel extends IBaseEntity {
  owner?: Owner;
  company?: ICompany;
  name?: string;
  description?: string;
  type?: FormType;
  status?: Status;
  categories?: IFormModelCategory[];
  questions?: IFormModelQuestion[];
  changeLogs?: IChangeLog[];
}

export class FormModel extends BaseEntity implements IFormModel {
  constructor(
    public id?: number,
    public uuid?: string,
    public createdBy?: string,
    public createdDate?: Moment,
    public lastModifiedBy?: string,
    public lastModifiedDate?: Moment,
    public active?: boolean,
    public owner?: Owner,
    public company?: ICompany,
    public name?: string,
    public description?: string,
    public type?: FormType,
    public status?: Status,
    public categories?: IFormModelCategory[],
    public questions?: IFormModelQuestion[],
    public changeLogs?: IChangeLog[],
    public _label?: string,
    public _edited?: boolean
  ) {
    super(id, uuid, createdBy, createdDate, lastModifiedBy, lastModifiedDate, active, _label, _edited);
  }

  public static convertDatesFromClient(formModel: IFormModel): IFormModel {
    return Object.assign({}, formModel, {
      createdDate: formModel.createdDate && formModel.createdDate.isValid() ? formModel.createdDate.toJSON() : undefined,
      lastModifiedDate:
        formModel.lastModifiedDate && formModel.lastModifiedDate.isValid() ? formModel.lastModifiedDate.toJSON() : undefined,
      questions: (formModel.questions || []).map(q => ({
        ...q,
        minDate: q.minDate && moment(q.minDate).isValid() ? moment(q.minDate).toJSON() : undefined,
        maxDate: q.maxDate && moment(q.maxDate).isValid() ? moment(q.maxDate).toJSON() : undefined
      }))
    });
  }

  public static convertDatesFromServer(formModel: IFormModel): IFormModel {
    if (formModel) {
      formModel.createdDate = formModel.createdDate ? moment(formModel.createdDate) : undefined;
      formModel.lastModifiedDate = formModel.lastModifiedDate ? moment(formModel.lastModifiedDate) : undefined;
      formModel.questions &&
      formModel.questions.forEach((question: IFormModelQuestion) => {
        question.minDate = question.minDate ? moment(question.minDate) : undefined;
        question.maxDate = question.maxDate ? moment(question.maxDate) : undefined;
      });
    }
    return formModel;
  }

  public static criterias(): Criteria<FormModel>[] {
    return Criteria.buildFrom(this.example())
      .filter(criteria => !['categories', 'questions', 'changeLogs'].includes(criteria.property.name))
      .map(criteria =>
        criteria.property.name === 'status'
          ? criteria.asEnum(() => this.allowedStatus())
          : criteria.property.name === 'owner'
            ? criteria.asEnum(() => this.allowedOwners())
            : criteria.property.name === 'type'
              ? criteria.asEnum(() => this.allowedFormTypes(), 'formType')
              : criteria
      );
  }

  public static allowedOwners(): Owner[] {
    return [Owner.SYSTEM, Owner.USER];
  }

  public static allowedStatus(): Status[] {
    return [Status.ACTIVE, Status.INACTIVE];
  }

  public static allowedFormTypes(): FormType[] {
    return allFormTypes();
  }

  public static example(): FormModel {
    return new FormModel(
      0,
      '',
      '',
      moment(new Date()),
      '',
      moment(new Date()),
      false,
      Owner.SYSTEM,
      new Company(),
      '',
      '',
      FormType.PRODUCT,
      Status.ACTIVE,
      [],
      [],
      [],
      '',
      false
    );
  }
}
