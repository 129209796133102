<div *ngIf="formModelQuestion">

  <div class="modal-body">
    <div class="row justify-content-center">
      <ngb-accordion class="col-12 entity-details" activeIds="panelEntity">
        <ngb-panel id="panelEntity">
          <ng-template ngbPanelTitle>
            <fa-icon [fixedWidth]="true" icon="list-alt"></fa-icon>
            &nbsp;
            <span>{{'entity.panels.details' | translate}}</span>
          </ng-template>
          <ng-template ngbPanelContent>
            <div class="row justify-content-center">
              <dl class="col-12">
                <dt><span jhiTranslate="global.field.id"></span></dt>
                <dd>{{ formModelQuestion.id }}</dd>

                <dt><span jhiTranslate="formModel.formModelQuestion.question"></span></dt>
                <dd>{{ formModelQuestion.question }}</dd>

                <dt><span jhiTranslate="global.field.description"></span></dt>
                <dd>{{ formModelQuestion.description }}</dd>

                <dt><span jhiTranslate="global.field.type"></span></dt>
                <dd>
                  <app-enum-label [value]="formModelQuestion.type" [options]="types"
                                  name="formQuestionType"></app-enum-label>
                </dd>

                <dt><span jhiTranslate="formModel.formModelQuestion.order"></span></dt>
                <dd>{{ formModelQuestion.order }}</dd>

                <dt><span jhiTranslate="formModel.formModelQuestion.category"></span></dt>
                <dd>
                  <a *ngIf="formModelQuestion.category" href="javascript:void(0);"
                     (click)="showFormModelCategoryDetails(formModelQuestion.category)">{{ formModelQuestion.category._label }}</a>
                </dd>

                <dt><span jhiTranslate="global.field.faIcon"></span></dt>
                <dd>
                  <ng-container *ngIf="formModelQuestion.faIcon">
                    <fa-icon [fixedWidth]="true" [icon]="formModelQuestion.faIcon | asIcon"></fa-icon>
                    &nbsp;
                  </ng-container>
                  {{ formModelQuestion.faIcon }}
                </dd>

                <dt><span jhiTranslate="formModel.alertColor"></span></dt>
                <dd>
                  <app-enum-label [value]="formModelQuestion.alertColor"
                                  [options]="alertColors" name="alertColor"></app-enum-label>
                </dd>

                <dt><span jhiTranslate="formModel.formModelQuestion.mandatory"></span></dt>
                <dd>
                  <app-bool-icon [value]="formModelQuestion.mandatory"></app-bool-icon>
                </dd>

                <dt><span jhiTranslate="formModel.formModelQuestion.weight"></span></dt>
                <dd>{{ formModelQuestion.weight | number:'1.2-2' }}</dd>

                <ng-container *ngIf="formModelQuestion.type === 'CURRENCY'">
                  <dt><span jhiTranslate="global.field.currency"></span></dt>
                  <dd>
                    <app-enum-label [value]="formModelQuestion.currency"
                                    [options]="currencies" name="currency"></app-enum-label>
                  </dd>
                </ng-container>

                <ng-container *ngIf="showTextMinAndMaxLength()">
                  <dt><span jhiTranslate="formModel.formModelQuestion.minLength"></span></dt>
                  <dd>{{ formModelQuestion.min }}</dd>

                  <dt><span jhiTranslate="formModel.formModelQuestion.maxLength"></span></dt>
                  <dd>{{ formModelQuestion.max }}</dd>
                </ng-container>

                <ng-container *ngIf="showMinAndMax()">
                  <dt><span jhiTranslate="formModel.formModelQuestion.min"></span></dt>
                  <dd>{{ formModelQuestion.min }}</dd>

                  <dt><span jhiTranslate="formModel.formModelQuestion.max"></span></dt>
                  <dd>{{ formModelQuestion.max }}</dd>
                </ng-container>

                <ng-container *ngIf="formModelQuestion.type === 'DATE'">
                  <dt><span jhiTranslate="formModel.formModelQuestion.minDate"></span></dt>
                  <dd>{{ formModelQuestion.minDate | dateMoment:'dd/MM/yyyy' }}</dd>

                  <dt><span jhiTranslate="formModel.formModelQuestion.maxDate"></span></dt>
                  <dd>{{ formModelQuestion.maxDate | dateMoment:'dd/MM/yyyy' }}</dd>
                </ng-container>
              </dl>
            </div>
          </ng-template>
        </ngb-panel>

        <ngb-panel
          *ngIf="['SINGLE_CHOICE', 'MULTIPLE_CHOICE'].includes(formModelQuestion.type) && formModelQuestion.options"
          id="panelOptions">
          <ng-template ngbPanelTitle>
            <div class="d-flex justify-content-between">
              <div>
                <fa-icon [fixedWidth]="true" icon="bars"></fa-icon>
                &nbsp;
                <span jhiTranslate="formModel.formModelQuestion.options"></span>
                &nbsp;
                <span *ngIf="totalActiveOptions()" class="badge badge-secondary">{{totalActiveOptions()}}</span>
              </div>
            </div>
          </ng-template>

          <ng-template ngbPanelContent>
            <div *ngIf="!formModelQuestion.options.length"
                 class="list-empty-alert alert alert-light text-center" role="alert">
              <div class="font-weight-bold pb-2">
                <fa-icon [fixedWidth]="true" icon="exclamation-circle"></fa-icon>
                &nbsp;
                <span jhiTranslate="global.empty.list.alt"></span>
              </div>
            </div>
            <div *ngIf="formModelQuestion.options.length" class="table-responsive">
              <table class="table table-hover">
                <thead class="thead-light">
                <tr>
                  <th scope="col" class="icon"></th>
                  <th scope="col" class="text-center">
                    <span jhiTranslate="global.field.id"></span>
                  </th>
                  <th scope="col">
                    <span jhiTranslate="global.field.description"></span>
                  </th>
                  <th scope="col" class="w-20">
                    <span jhiTranslate="global.field.value"></span>
                  </th>
                  <th scope="col" class="w-20">
                    <span jhiTranslate="global.field.order"></span>
                  </th>
                </tr>
                </thead>
                <tbody>
                <tr *ngFor="let option of formModelQuestion.options | orderBy: 'order'; trackBy: trackIdentity"
                    [class]="{'notActive': !option.active}">
                  <td class="text-center dirty-warning">
                    <fa-icon *ngIf="option._edited" [fixedWidth]="true" icon="exclamation-triangle"
                             title="{{'global.messages.dirty.warning' | translate}}"></fa-icon>
                  </td>
                  <td class="text-center">
                    <a *ngIf="option.id" href="javascript:void(0);"
                       (click)="showFormModelQuestionOptionDetails(option)">{{ option.id }}</a>
                  </td>
                  <td>{{ option.description }}</td>
                  <td>{{ option.value | number:'1.2-2' }}</td>
                  <td>{{ option.order }}</td>
                </tr>
                </tbody>
              </table>
            </div>
          </ng-template>
        </ngb-panel>

        <ngb-panel id="panelAudit">
          <ng-template ngbPanelTitle>
            <fa-icon [fixedWidth]="true" icon="exclamation-circle"></fa-icon>
            &nbsp;
            <span>{{'entity.panels.audit' | translate}}</span>
          </ng-template>
          <ng-template ngbPanelContent>
            <div class="row justify-content-center">
              <dl class="col-12">
                <dt><span jhiTranslate="global.field.uuid"></span></dt>
                <dd>{{ formModelQuestion.uuid }}</dd>
                <dt><span jhiTranslate="global.field.active"></span></dt>
                <dd>
                  <app-bool-icon [value]="formModelQuestion.active"></app-bool-icon>
                </dd>
                <dt><span jhiTranslate="global.field.createdBy"></span></dt>
                <dd>{{ formModelQuestion.createdBy }}</dd>
                <dt><span jhiTranslate="global.field.createdDate"></span></dt>
                <dd>{{ formModelQuestion.createdDate | dateMoment:'dd/MM/yyyy HH:mm' }}</dd>
                <dt><span jhiTranslate="global.field.lastModifiedBy"></span></dt>
                <dd>{{ formModelQuestion.lastModifiedBy }}</dd>
                <dt><span jhiTranslate="global.field.lastModifiedDate"></span></dt>
                <dd>{{ formModelQuestion.lastModifiedDate | dateMoment:'dd/MM/yyyy HH:mm' }}</dd>
              </dl>
            </div>
          </ng-template>
        </ngb-panel>
      </ngb-accordion>
    </div>
  </div>

  <div class="modal-footer">
    <div class="form-row">
      <div class="col-12">
        <div class="float-right">
          <button type="button" (click)="onCloseClicked()" class="btn btn-default">
            <fa-icon [fixedWidth]="true" icon="times"></fa-icon>
            &nbsp;
            <span jhiTranslate="entity.action.close"></span>
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
