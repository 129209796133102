import {Component, ElementRef, forwardRef, Input, OnDestroy, OnInit, Renderer2, ViewChild} from '@angular/core';
import {of, Subject, Subscription} from 'rxjs';
import {debounceTime, delay, distinctUntilChanged, tap} from 'rxjs/operators';
import {unsubscribe} from 'app/shared/util/react-util';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';

export const CUSTOM_INPUT_CONTROL_VALUE_ACCESSOR: any = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => AppSelectorComponent),
  multi: true
};

@Component({
  selector: 'app-selector',
  providers: [CUSTOM_INPUT_CONTROL_VALUE_ACCESSOR],
  template: `
    <div class="form-group">
      <div class="input-group">
        <div class="input-group-prepend">
          <label *ngIf="title" class="input-group-text" [ngClass]="{required: isRequired}" [jhiTranslate]="title"></label>
        </div>
        <div class="form-control selector" [class]="{'is-invalid': isInvalid}">
          <ng-select
            #selector
            [items]="items"
            [ngModel]="selectedItem"
            (ngModelChange)="selectedItemModelChanged($event)"
            [ngModelOptions]="{standalone: true}"
            [bindLabel]="bindLabel"
            [searchable]="false"
            [virtualScroll]="true"
            [loading]="isLoading"
            [multiple]="multiple"
            (open)="focusOnFilter()"
            (focusout)="propagateTouch()"
          >
            <ng-template ng-header-tmp>
              <div class="d-flex">
                <input
                  #filterInput
                  class="w-100"
                  [ngModel]="filter"
                  (ngModelChange)="filterModelChanged($event)"
                  [ngModelOptions]="{standalone: true}"
                  type="text"
                  placeholder="{{ 'global.ngSelect.typeToSearchText' | translate }}"
                />
              </div>
            </ng-template>
            <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
              <div class="option-item" [title]="item._label">
                <span>
                  <input id="item1-{{ index }}" type="checkbox" [ngModel]="item$.selected" [ngModelOptions]="{standalone: true}" />
                </span>
                <label for="item1-{{ index }}">{{ item._label }}</label>
              </div>
            </ng-template>
          </ng-select>
        </div>
      </div>
      <div *ngIf="isInvalid">
        <small class="form-text text-danger" *ngIf="isRequired" jhiTranslate="entity.validation.required"> </small>
      </div>
    </div>
  `
})
export class AppSelectorComponent implements ControlValueAccessor, OnInit, OnDestroy {
  @Input() items: any[] | undefined = undefined;
  @Input() searchFunction: Function;
  @Input() isLoading: boolean = false;
  @Input() bindLabel: string = '_label';
  @Input() title: string | undefined = undefined;
  @Input() isInvalid: boolean = false;
  @Input() isRequired: boolean = false;
  @Input() multiple: boolean = false;

  @ViewChild('filterInput') filterInput: ElementRef;
  @ViewChild('selector') selector: ElementRef;

  public filter: string;
  public selectedItem: any;

  private itemChanged: Subject<any> = new Subject<any>();
  private filterChanged: Subject<string> = new Subject<string>();
  private subscriptions: Subscription[] = [];

  constructor(private renderer: Renderer2) {}

  ngOnInit(): void {
    this.filterChanged.pipe(debounceTime(500), distinctUntilChanged()).subscribe(filter => {
      this.filter = filter;
      this.searchFunction(this.filter);
    });

    this.itemChanged.pipe().subscribe(item => {
      this.selectedItem = item;
      this.propagateChange(this.selectedItem);
    });
  }

  filterModelChanged(text: string) {
    this.filterChanged.next(text);
  }

  selectedItemModelChanged(item: any) {
    this.itemChanged.next(item);
  }

  focusOnFilter() {
    this.searchFunction(this.filter);

    of(null)
      .pipe(
        delay(100),
        tap(() => {
          this.filterInput.nativeElement.focus();
          this.filterInput.nativeElement.select();
        })
      )
      .subscribe();
  }

  // get accessor
  get value(): any {
    return this.selectedItem;
  }

  // set accessor including call the onchange callback
  set value(v: any) {
    if (v !== this.selectedItem) {
      this.selectedItem = v;
    }
  }

  // propagate changes into the custom form control
  // não remover suppressWarning, necessário argumento para o component funcionar devidamente
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  propagateChange = (_: any) => {};

  // propagate touches into the custom form control
  propagateTouch = () => {};

  // From ControlValueAccessor interface
  writeValue(value: any) {
    this.selectedItem = value;
  }

  // From ControlValueAccessor interface
  registerOnChange(fn: any) {
    this.propagateChange = fn;
  }

  // From ControlValueAccessor interface
  registerOnTouched(fn: () => void) {
    this.propagateTouch = fn;
  }

  // From ControlValueAccessor interface
  setDisabledState(isDisabled: boolean) {
    (<any>this.selector).setDisabledState(isDisabled);
  }

  ngOnDestroy(): void {
    unsubscribe(this.subscriptions);
  }
}
