import {Injectable} from '@angular/core';
import {HttpClient, HttpResponse} from '@angular/common/http';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import moment from 'moment';

import {SERVER_API_URL} from 'app/app.constants';
import {createRequestOption} from 'app/shared/util/request-util';
import {ILocation} from 'app/shared/model/location/location.model';
import {MaskService} from 'app/shared/mask/mask.service';

type EntityResponseType = HttpResponse<ILocation>;
type EntityArrayResponseType = HttpResponse<ILocation[]>;

@Injectable({providedIn: 'root'})
export class LocationService {
  public resourceUrl = `${SERVER_API_URL}api/loc/locations`;

  constructor(protected http: HttpClient, private maskService: MaskService) {
  }

  create(location: ILocation): Observable<EntityResponseType> {
    const copy = this.convertDateFromClient(location);
    return this.http
      .post<ILocation>(this.resourceUrl, copy, {observe: 'response'})
      .pipe(map((res: EntityResponseType) => this.convertDateFromServer(res)));
  }

  update(location: ILocation): Observable<EntityResponseType> {
    const copy = this.convertDateFromClient(location);
    return this.http
      .put<ILocation>(this.resourceUrl, copy, {observe: 'response'})
      .pipe(map((res: EntityResponseType) => this.convertDateFromServer(res)));
  }

  searchZipCode(zipCode: number): Observable<EntityResponseType> {
    return this.http
      .get<ILocation>(`${this.resourceUrl}/search-zip-code-by-web-services/${zipCode}`, {observe: 'response'})
      .pipe(map((res: EntityResponseType) => this.convertDateFromServer(res)));
  }

  currentlyActive(): Observable<EntityResponseType> {
    return this.http
      .get<ILocation>(`${this.resourceUrl}/currently-active`, {observe: 'response'})
      .pipe(map((res: EntityResponseType) => this.convertDateFromServer(res)));
  }

  find(id: number): Observable<EntityResponseType> {
    return this.http
      .get<ILocation>(`${this.resourceUrl}/${id}`, {observe: 'response'})
      .pipe(map((res: EntityResponseType) => this.convertDateFromServer(res)));
  }

  query(req?: any): Observable<EntityArrayResponseType> {
    const options = createRequestOption(req);
    return this.http
      .get<ILocation[]>(this.resourceUrl, {params: options, observe: 'response'})
      .pipe(map((res: EntityArrayResponseType) => this.convertDateArrayFromServer(res)));
  }

  delete(id: number): Observable<HttpResponse<{}>> {
    return this.http.delete(`${this.resourceUrl}/${id}`, {observe: 'response'});
  }

  concatLocation(location: ILocation): string {
    return `${location.address}, ${location.number}, ${location.neighborhood}, ${location.zipCode}, ${location.city.name}-${location.city.state.code}`;
  }

  protected convertDateFromClient(location: ILocation): ILocation {
    const copy: ILocation = Object.assign({}, location, {
      createdDate: location.createdDate && location.createdDate.isValid() ? location.createdDate.toJSON() : undefined,
      lastModifiedDate: location.lastModifiedDate && location.lastModifiedDate.isValid() ? location.lastModifiedDate.toJSON() : undefined,
      linkedEntityUuid: location.linkedEntityUuid ? this.maskService.apply(location.linkedEntityUuid, 'uuid') : undefined
    });
    return copy;
  }

  protected convertDateFromServer(res: EntityResponseType): EntityResponseType {
    if (res.body) {
      res.body.createdDate = res.body.createdDate ? moment(res.body.createdDate) : undefined;
      res.body.lastModifiedDate = res.body.lastModifiedDate ? moment(res.body.lastModifiedDate) : undefined;
    }
    return res;
  }

  protected convertDateArrayFromServer(res: EntityArrayResponseType): EntityArrayResponseType {
    if (res.body) {
      res.body.forEach((location: ILocation) => {
        location.createdDate = location.createdDate ? moment(location.createdDate) : undefined;
        location.lastModifiedDate = location.lastModifiedDate ? moment(location.lastModifiedDate) : undefined;
      });
    }
    return res;
  }
}
