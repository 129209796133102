import {Injectable} from '@angular/core';
import {
  HttpClient,
  HttpEventType,
  HttpHeaderResponse,
  HttpHeaders,
  HttpProgressEvent,
  HttpRequest,
  HttpResponse,
  HttpSentEvent,
  HttpUserEvent
} from '@angular/common/http';
import {Observable, Subject} from 'rxjs';
import {map} from 'rxjs/operators';
import {S3PreSignedURL} from '../../../../serverless/model/s3-pre-signed-url.model';
import {Logger} from 'app/shared/util/logger';
import {UploadRequest} from '../../../../serverless/model/upload-request.model';
import {SERVERLESS_API_KEY, SERVERLESS_API_URL} from 'app/app.constants';
import {APIResult} from '../../../../serverless/util/api-utils';

const Log = new Logger('UploadService');

@Injectable({providedIn: 'root'})
export class UploadService {
  constructor(private http: HttpClient) {
  }

  public getPreSignedURL(request: UploadRequest): Observable<S3PreSignedURL> {
    Log.debug(() => `getPreSignedURL(request: ${JSON.stringify(request)})`);
    return this.http
      .post<APIResult>(`${SERVERLESS_API_URL}/sls/api/file/send`, request, {
        headers: new HttpHeaders({
          // 'Content-Type': 'application/json',
          'x-api-key': SERVERLESS_API_KEY
        })
      })
      .pipe(map((result: APIResult) => {
        Log.debug(() => `getPreSignedURL() => result: ${JSON.stringify(result)}`);
        return S3PreSignedURL.from(result['body'] ?? result);
      }));
  }

  public uploadFileToS3(
    preSignedURL: S3PreSignedURL,
    data: any,
    progressObserver?: Subject<number>
  ): Observable<HttpResponse<unknown> | HttpProgressEvent | HttpSentEvent | HttpHeaderResponse | HttpUserEvent<unknown>> {
    Log.debug(() => `uploadFileToS3(request: ${JSON.stringify(preSignedURL)})`);

    const headers = new HttpHeaders({'Content-Type': preSignedURL.contentType});
    const req = new HttpRequest('PUT', preSignedURL.url, data, {
      headers: headers,
      reportProgress: true
    });

    return this.http.request(req).pipe(
      map(event => {
        switch (event.type) {
          case HttpEventType.Response:
            progressObserver && progressObserver.complete();
            break;
          case HttpEventType.UploadProgress:
            progressObserver && progressObserver.next(Math.round((100 * event.loaded) / event.total));
            break;
        }
        return event;
      })
    );
  }
}
