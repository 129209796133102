import {ILocation} from 'app/shared/model/location/location.model';
import {Injectable} from '@angular/core';
import {MaskService} from 'app/shared/mask/mask.service';
import {v4 as uuidV4} from 'uuid';
import moment from 'moment';
import {CurrencyPipe} from '@angular/common';

@Injectable({providedIn: 'root'})
export class StringUtilService {
  constructor(private mask: MaskService, private currencyPipe: CurrencyPipe) {}

  public static leftZero(value: string | number): string {
    return `0${value}`.slice(-2);
  }

  public static uuid(): string {
    return uuidV4();
  }

  /* https://stackoverflow.com/a/6134070/770000 */
  public static formatNumber(num: number, decimals: number): string {
    return (Math.round(num * 100) / 100).toFixed(decimals);
  }

  public formatLocation(location: ILocation): string {
    return [
      location.address,
      location.number,
      location.complement,
      location.neighborhood,
      location.zipCode ? this.mask.apply(location.zipCode, 'cep') : location.zipCode,
      location.city && location.city.name,
      location.city?.state && location.city.state.code,
      location.city?.state && location.city.state.country && location.city.state.country.title
    ]
      .filter(s => s && s.length)
      .map(s => ('' + s).trim())
      .join(', ')
      .trim()
      .replace(/ {2}/g, ' ');
  }

  public googleMapsLink(location: ILocation): string {
    // https://developers.google.com/maps/documentation/urls/guide#map-action
    return location
      ? `https://www.google.com/maps/search/?api=1&query=${this.formatLocation(location)
          .replace(/ /g, '+')
          .replace(/,/g, '%2C')}`
      : '';
  }

  public formatAsCurrency(value: number, currencyCode: string): string {
    return this.currencyPipe.transform(value, currencyCode);
  }

  public static extractFileExtension(fileName: string): string {
    return (fileName || '').split('.').pop();
  }

  public static dateToString(date: Date, pattern: string): string {
    return moment(date).format(pattern);
  }

  public static removeDiacritics(value: string): string {
    // https://stackoverflow.com/a/37511463/770000
    return (value || '').normalize('NFD').replace(/[\u0300-\u036f]/g, '');
  }

  public static removeNumbers(value: string): string {
    // https://stackoverflow.com/a/4993780/770000
    return (value || '').replace(/[0-9]/g, '');
  }

  public static removeChars(value: string): string {
    // https://stackoverflow.com/a/4993780/770000
    return (value || '').replace(/[^0-9]/g, '');
  }

  public static hasText(value: string): boolean {
    return !!(value ?? '').trim().length;
  }

  public static sanitizeForUrl(value: string): string {
    return StringUtilService.removeDiacritics(value)
      .replace(/\s+/g, '-')
      .replace(/[^A-Za-z0-9-]+/g, '')
      .trim();
  }
}
