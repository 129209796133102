import './polyfills';
import '@angular/compiler';
import {platformBrowserDynamic} from '@angular/platform-browser-dynamic';
import {ProdConfig} from './app/blocks/config/prod.config';
import {AppAppModule} from './app/app.module';
import {Logger} from './app/shared/util/logger';
import {load} from 'webfontloader';
import 'codemirror/mode/javascript/javascript';
import 'codemirror/mode/markdown/markdown';

ProdConfig();

if (module['hot']) {
  module['hot'].accept();
}

const log: Logger = new Logger('App');

load({
  google: {
    families: ['Inter:200,300,400,500,600,700,800,900', 'Poppins:200,300,400,500,600,700,800,900']
  },
  fontloading(familyName: string): void {
    log.debug(() => `Loading font => ${familyName}`);
  },
  fontactive(familyName: string): void {
    log.debug(() => `Font is active => ${familyName}`);
  },
  active(): void {
    log.debug(() => 'All fonts were loaded... Starting app...');

    platformBrowserDynamic()
      .bootstrapModule(AppAppModule, {preserveWhitespaces: true})
      .then(() => log.info(() => 'App started!'))
      .catch(err => log.error(() => 'Bootstrapping error.', err));
  }
});
