import {Pipe, PipeTransform} from '@angular/core';
import {MaskService, MaskType} from 'app/shared/mask/mask.service';

@Pipe({
  name: 'masked',
  pure: false
})
export class MaskedPipe implements PipeTransform {
  constructor(private maskService: MaskService) {}

  transform(value: string, type: MaskType): any {
    return this.maskService.apply(value, type);
  }
}
