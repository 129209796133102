<div *ngIf="outerEntity">
  <form autocomplete="off" #form="ngForm" name="editForm" role="form" novalidate (ngSubmit)="onConfirmClicked()"
        [formGroup]="editForm">

    <div class="modal-header">
      <h4 class="modal-title" jhiTranslate="location.modals.editor.title"></h4>
      <button type="button" class="close" data-dismiss="modal" aria-hidden="true" (click)="onCancelClicked()">&times;
      </button>
    </div>

    <div class="modal-body">
      <div *ngIf="entity" class="row justify-content-center">
        <div class="col-12">

          <div class="form-row">
            <!-- zipCode -->
            <div class="form-group col-md-4 pb-1">
              <div class="input-group">
                <div class="input-group-prepend">
                  <label class="input-group-text" jhiTranslate="location.zipCode"></label>
                </div>
                <input type="text" class="form-control" name="zipCode" formControlName="zipCode" [mask]="mask.cep()"
                       [showMaskTyped]="true" [ngClass]="{'is-invalid': isInvalidAndTouched('zipCode')}">
              </div>
              <div *ngIf="isInvalidAndTouched('zipCode')">
                <small class="form-text text-danger"
                       *ngIf="editForm.get('zipCode')?.errors?.maxlength"
                       jhiTranslate="entity.validation.maxlength"
                       [translateValues]="{ max: 9 }">
                </small>
              </div>
              <div *ngIf="zipCodeNotFound">
                <small class="form-text text-warning"
                       jhiTranslate="location.zipCodeNotFound">
                </small>
              </div>
            </div>

            <!-- address -->
            <div class="form-group col-md-8 pb-1">
              <div class="input-group">
                <div class="input-group-prepend">
                  <label class="input-group-text required" jhiTranslate="location.address"></label>
                </div>
                <input type="text" class="form-control" name="address" formControlName="address" minlength="1"
                       maxlength="255" required [ngClass]="{'is-invalid': isInvalidAndTouched('address')}">
              </div>
              <div *ngIf="isInvalidAndTouched('address')">
                <small class="form-text text-danger"
                       *ngIf="editForm.get('address')?.errors?.required"
                       jhiTranslate="entity.validation.required">
                </small>

                <small class="form-text text-danger"
                       *ngIf="editForm.get('address')?.errors?.minlength" jhiTranslate="entity.validation.minlength"
                       [translateValues]="{ min: 1 }">
                </small>

                <small class="form-text text-danger"
                       *ngIf="editForm.get('address')?.errors?.maxlength"
                       jhiTranslate="entity.validation.maxlength"
                       [translateValues]="{ max: 255 }">
                </small>
              </div>
            </div>
          </div>

          <div class="form-row">
            <!-- number -->
            <div class="form-group col-md-6 pb-1">
              <div class="input-group">
                <div class="input-group-prepend">
                  <label class="input-group-text" jhiTranslate="location.number"></label>
                </div>
                <input type="text" class="form-control" name="number" formControlName="number" maxlength="10"
                       [ngClass]="{'is-invalid': isInvalidAndTouched('number')}">
              </div>
              <div *ngIf="isInvalidAndTouched('number')">
                <small class="form-text text-danger"
                       *ngIf="editForm.get('number')?.errors?.maxlength"
                       jhiTranslate="entity.validation.maxlength"
                       [translateValues]="{ max: 10 }">
                </small>
              </div>
            </div>

            <!-- neighborhood -->
            <div class="form-group col-md-6 pb-1">
              <div class="input-group">
                <div class="input-group-prepend">
                  <label class="input-group-text" jhiTranslate="location.neighborhood"></label>
                </div>
                <input type="text" class="form-control" name="neighborhood" formControlName="neighborhood"
                       maxlength="255" [ngClass]="{'is-invalid': isInvalidAndTouched('neighborhood')}">
              </div>
              <div *ngIf="isInvalidAndTouched('neighborhood')">
                <small class="form-text text-danger"
                       *ngIf="editForm.get('neighborhood')?.errors?.maxlength"
                       jhiTranslate="entity.validation.maxlength"
                       [translateValues]="{ max: 255 }">
                </small>
              </div>
            </div>
          </div>

          <div class="form-row">
            <!-- complement -->
            <div class="form-group col-md-6 pb-1">
              <div class="input-group">
                <div class="input-group-prepend">
                  <label class="input-group-text" jhiTranslate="location.complement"></label>
                </div>
                <input type="text" class="form-control" name="complement" formControlName="complement"
                       maxlength="255" [ngClass]="{'is-invalid': isInvalidAndTouched('complement')}">
              </div>
              <div *ngIf="isInvalidAndTouched('complement')">
                <small class="form-text text-danger"
                       *ngIf="editForm.get('complement')?.errors?.maxlength"
                       jhiTranslate="entity.validation.maxlength"
                       [translateValues]="{ max: 255 }">
                </small>
              </div>
            </div>

            <!--  state -->
            <div class="form-group col-md-6 pb-1">
              <div class="input-group">
                <div class="input-group-prepend">
                  <label class="input-group-text required" jhiTranslate="location.state"></label>
                </div>
                <div [ngClass]="{'is-invalid': isInvalidAndTouched('state')}" class="form-control selector">
                  <ng-select [items]="states" bindLabel="_label" class="custom" formControlName="state">
                    <ng-template let-index="index" let-item="item" let-item$="item$" ng-option-tmp>
                      <div [title]="item._label" class="option-item">
                      <span>
                        <input [ngModelOptions]="{ standalone : true }" [ngModel]="item$.selected"
                               id="item-{{index}}" type="checkbox"/>
                      </span>
                        <label for="item-{{index}}">{{item._label}}</label>
                      </div>
                    </ng-template>
                  </ng-select>
                </div>
              </div>
            </div>

            <!-- city -->
            <div class="form-group col-md-12 pb-1">
              <div class="input-group">
                <div class="input-group-prepend">
                  <label class="input-group-text required" jhiTranslate="location.city"></label>
                </div>
                <div class="form-control selector" [ngClass]="{'is-invalid': isInvalidAndTouched('city')}">
                  <ng-select [items]="cities" formControlName="city" bindLabel="_label">
                    <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
                      <div class="option-item" [title]="item._label">
                          <span>
                            <input id="item1-{{index}}" type="checkbox" [ngModel]="item$.selected"
                                   [ngModelOptions]="{ standalone : true }"/>
                          </span>
                        <label for="item1-{{index}}">{{item._label}}</label>
                      </div>
                    </ng-template>
                  </ng-select>
                </div>
              </div>

              <div *ngIf="isInvalidAndTouched('city')">
                <small class="form-text text-danger"
                       *ngIf="editForm.get('city')?.errors?.required"
                       jhiTranslate="entity.validation.required">
                </small>
              </div>
            </div>
          </div>

        </div>
      </div>

    </div>

    <div class="modal-footer">
      <div class="form-row">
        <div class="col-12">
          <div class="float-right">
            <button type="button" (click)="onCancelClicked()" class="btn btn-secondary">
              <fa-icon [fixedWidth]="true" icon="ban"></fa-icon>
              &nbsp;
              <span jhiTranslate="entity.action.cancel"></span>
            </button>
            &nbsp;
            <button type="submit" class="btn btn-primary" [disabled]="!canSubmit()">
              <fa-icon [fixedWidth]="true" icon="check"></fa-icon>
              &nbsp;
              <span jhiTranslate="entity.action.apply"></span>
            </button>
          </div>
        </div>
      </div>
    </div>
  </form>
</div>
