<div>
  <form autocomplete="off" #form="ngForm" name="editForm" role="form" novalidate (ngSubmit)="onConfirmClicked()"
        [formGroup]="editForm">

    <div class="modal-header">
      <h4 class="modal-title" jhiTranslate="cnpj.modals.search.title"></h4>
      <button type="button" class="close" data-dismiss="modal" aria-hidden="true" (click)="onCancelClicked()">&times;
      </button>
    </div>

    <div class="modal-body">
      <div class="row justify-content-center">
        <div class="col-12">

          <div class="form-row">

            <!-- cnpj -->
            <div class="form-group col-md-8 pb-1">
              <div class="input-group">
                <div class="input-group-prepend">
                  <label class="input-group-text required" jhiTranslate="global.field.cnpj"></label>
                </div>
                <input type="text" class="form-control" name="cnpj" formControlName="cnpj" [mask]="mask.cnpj()"
                       required [showMaskTyped]="true" [ngClass]="{'is-invalid': isInvalidAndTouched('cnpj')}">
              </div>
              <div *ngIf="isInvalidAndTouched('cnpj')">
                <small class="form-text text-danger"
                       *ngIf="editForm.get('cnpj')?.errors?.required"
                       jhiTranslate="entity.validation.required">
                </small>

                <small class="form-text text-danger"
                       *ngIf="editForm.get('cnpj')?.errors?.maxlength"
                       jhiTranslate="entity.validation.maxlength"
                       [translateValues]="{ max: 18 }">
                </small>

                <small *ngIf="editForm.get('cnpj')?.errors?.mask || editForm.get('cnpj')?.errors?.invalid"
                       class="form-text text-danger" jhiTranslate="entity.validation.cnpj">
                </small>
              </div>
            </div>

            <!-- origin -->
            <div class="form-group col-md-4 pb-1">
              <div class="input-group">
                <div class="input-group-prepend">
                  <label class="input-group-text" jhiTranslate="cnpj.origin"></label>
                </div>
                <select class="form-control" name="origin" formControlName="origin"
                        [ngClass]="{'is-invalid': isInvalidAndTouched('origin')}">
                  <option [ngValue]="null"></option>
                  <option *ngFor="let origin of origins" [ngValue]="origin">
                    {{'cnpjOrigin.' + origin | translate}}
                  </option>
                </select>
              </div>
            </div>

          </div>

          <div *ngIf="cnpjNotFound" class="alert alert-danger text-center" role="alert">
            <span jhiTranslate="cnpj.modals.search.cnpjNotFound"></span>
          </div>

        </div>
      </div>

    </div>

    <div class="modal-footer">
      <div class="form-row">
        <div class="col-12">
          <div class="float-right">
            <button type="button" (click)="onCancelClicked()" class="btn btn-secondary">
              <fa-icon [fixedWidth]="true" icon="ban"></fa-icon>
              &nbsp;
              <span jhiTranslate="entity.action.cancel"></span>
            </button>
            &nbsp;
            <button type="submit" class="btn btn-primary" [disabled]="!canSubmit()">
              <fa-icon [fixedWidth]="true" icon="search"></fa-icon>
              &nbsp;
              <span jhiTranslate="entity.action.searchCnpj"></span>
            </button>
          </div>
        </div>
      </div>
    </div>
  </form>
</div>
