<ng-container *ngIf="!isLoading && widgetDetail">
  <div class="card">
    <div class="semaphore" [ngClass]="getSemaphoreClass()"></div>
    <div class="widget-value">
      {{widgetDetail.value | number: '1.0'}}
    </div>
    <div class="widget-label">
      {{widgetDetail.label}}
    </div>
  </div>
</ng-container>
