<ng-container *ngIf="!isLoading">

  <ng-container>
    <div class="row">
      <div class="btn-group btn-group-toggle col-md" data-toggle="buttons">
        <label (click)="applyUserWidgets()"
               [ngClass]="{'active': filterWidgetBy == 'BY_USER'}"
               class="btn btn-light">
          <input [checked]="filterWidgetBy == 'BY_USER'" autocomplete="off" name="options" type="radio">
          <span jhiTranslate="myActivities.filter.userWidgets"></span>
        </label>
        <label (click)="applyAllWidgets()"
               [ngClass]="{'active': filterWidgetBy == 'ALL_WIDGETS'}"
               class="btn btn-light">
          <input [checked]="filterWidgetBy == 'ALL_WIDGETS'" autocomplete="off" name="options" type="radio">
          <span jhiTranslate="myActivities.filter.allWidgets"></span>
        </label>
      </div>
      <ng-container>
        <app-widgets-preferences-menu [userWidgets]="widgetUsers" [widgets]="widgets"
                                      (saved)="reloadWidgets()"></app-widgets-preferences-menu>
      </ng-container>
    </div>
  </ng-container>

  <ng-container *ngIf="isLoading">
    <fa-icon icon="circle-notch" [spin]="true"></fa-icon>
  </ng-container>

  <ng-container *ngFor="let widgetGroup of selectedGroup();">

    <ngb-accordion class="col-12 entity-details"
                   activeIds="panelWidgetGroup">

      <ngb-panel id="panelWidgetGroup">

        <ng-template ngbPanelTitle>
          <fa-icon [fixedWidth]="true"></fa-icon>
          &nbsp;
          <span>{{widgetGroup.name ?? ''}}</span>
        </ng-template>

        <ng-template ngbPanelContent>
          <div class="row justify-content-center">
            <dl class="col-12">
              <div class="row">

                <ng-container *ngFor="let widget of widgetGroup.widgets;">
                  <div class="col-lg-3 pb-4">
                    <app-widget [widget]="widget"></app-widget>
                  </div>
                </ng-container>

              </div>
            </dl>
          </div>
        </ng-template>
      </ngb-panel>

    </ngb-accordion>

  </ng-container>
</ng-container>