import {Routes} from '@angular/router';

export const formRoutes: Routes = [
  {
    path: 'frm/forms',
    loadChildren: () => import('./form/form.module').then(m => m.AppFormModule),
    data: {
      pageTitle: 'form.home.title'
    }
  },
  {
    path: 'frm/forms-models',
    loadChildren: () => import('./form-model/form-model.module').then(m => m.AppFormModelModule),
    data: {
      pageTitle: 'formModel.home.title'
    }
  }
];
