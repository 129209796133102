<div *ngIf="title" class="modal-header">
  <h4 class="modal-title" [jhiTranslate]="title"></h4>
  <button type="button" class="close" data-dismiss="modal" aria-hidden="true" (click)="onCloseClicked()">&times;
  </button>
</div>

<div class="modal-body">
  <div class="row justify-content-center">
    <div class="col-12">
      <iframe *ngIf="isAGoogleViewerSupportedExtension()"
              [src]="cleanURL(googleViewerUrl)"
              class="w-100 googleViewer"></iframe>
      <img *ngIf="isAImage()" [src]="file.url" class="img-fluid" [alt]="file.sourceName">
    </div>
  </div>
</div>

<div class="modal-footer">
  <div class="form-row">
    <div class="col-12">
      <div class="float-right">
        <button type="button" (click)="onCloseClicked()" class="btn btn-default">
          <fa-icon [fixedWidth]="true" icon="times"></fa-icon>
          &nbsp;
          <span jhiTranslate="entity.action.close"></span>
        </button>
      </div>
    </div>
  </div>
</div>
