import {Injectable} from '@angular/core';
import {HttpClient, HttpResponse} from '@angular/common/http';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import moment from 'moment';

import {SERVER_API_URL} from 'app/app.constants';
import {createRequestOption} from 'app/shared/util/request-util';
import {IState} from 'app/shared/model/location/state.model';

type EntityResponseType = HttpResponse<IState>;
type EntityArrayResponseType = HttpResponse<IState[]>;

@Injectable({providedIn: 'root'})
export class StateService {
  public resourceUrl = `${SERVER_API_URL}api/loc/states`;

  constructor(protected http: HttpClient) {}

  create(state: IState): Observable<EntityResponseType> {
    const copy = this.convertDateFromClient(state);
    return this.http
      .post<IState>(this.resourceUrl, copy, {observe: 'response'})
      .pipe(map((res: EntityResponseType) => this.convertDateFromServer(res)));
  }

  update(state: IState): Observable<EntityResponseType> {
    const copy = this.convertDateFromClient(state);
    return this.http
      .put<IState>(this.resourceUrl, copy, {observe: 'response'})
      .pipe(map((res: EntityResponseType) => this.convertDateFromServer(res)));
  }

  find(id: number): Observable<EntityResponseType> {
    return this.http
      .get<IState>(`${this.resourceUrl}/${id}`, {observe: 'response'})
      .pipe(map((res: EntityResponseType) => this.convertDateFromServer(res)));
  }

  query(req?: any): Observable<EntityArrayResponseType> {
    const options = createRequestOption(req);
    return this.http
      .get<IState[]>(this.resourceUrl, {params: options, observe: 'response'})
      .pipe(map((res: EntityArrayResponseType) => this.convertDateArrayFromServer(res)));
  }

  delete(id: number): Observable<HttpResponse<{}>> {
    return this.http.delete(`${this.resourceUrl}/${id}`, {observe: 'response'});
  }

  protected convertDateFromClient(state: IState): IState {
    const copy: IState = Object.assign({}, state, {
      createdDate: state.createdDate && state.createdDate.isValid() ? state.createdDate.toJSON() : undefined,
      lastModifiedDate: state.lastModifiedDate && state.lastModifiedDate.isValid() ? state.lastModifiedDate.toJSON() : undefined
    });
    return copy;
  }

  protected convertDateFromServer(res: EntityResponseType): EntityResponseType {
    if (res.body) {
      res.body.createdDate = res.body.createdDate ? moment(res.body.createdDate) : undefined;
      res.body.lastModifiedDate = res.body.lastModifiedDate ? moment(res.body.lastModifiedDate) : undefined;
    }
    return res;
  }

  protected convertDateArrayFromServer(res: EntityArrayResponseType): EntityArrayResponseType {
    if (res.body) {
      res.body.forEach((state: IState) => {
        state.createdDate = state.createdDate ? moment(state.createdDate) : undefined;
        state.lastModifiedDate = state.lastModifiedDate ? moment(state.lastModifiedDate) : undefined;
      });
    }
    return res;
  }

  importCities(id: number): Observable<HttpResponse<{}>> {
    return this.http.post(`${this.resourceUrl}/${id}/import-cities`, null, {observe: 'response'});
  }

  importAllCities(): Observable<HttpResponse<{}>> {
    return this.http.post(`${this.resourceUrl}/import-all-cities`, null, {observe: 'response'});
  }
}
