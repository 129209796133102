import {Component, ElementRef, Input, ViewChild} from '@angular/core';
import {QuillViewModalService} from 'app/shared/quill/quill-view-modal.service';
import * as html2canvas from 'html2canvas';
import {QuillContentReportService} from 'app/shared/quill/reports/quill-content-report.service';

@Component({
  selector: 'app-quill-view-modal',
  templateUrl: './quill-view-modal.component.html'
})
export class QuillViewModalComponent {
  @Input()
  content!: string;

  @Input()
  title: string | undefined = undefined;

  @Input()
  hasExportButton: boolean = false;

  close?: Function;

  @ViewChild('screen')
  screen: ElementRef;

  constructor(private service: QuillViewModalService, private quillContentReportService: QuillContentReportService) {}

  private export(): Promise<any> {
    return new Promise<any>(resolve => {
      // @ts-ignore
      html2canvas(this.screen.nativeElement, {
        logging: true,
        letterRendering: 1,
        allowTaint: true,
        useCORS: true
      }).then(canvas => resolve(canvas.toDataURL('image/png')));
    });
  }

  async exportToPdf() {
    this.quillContentReportService.generate(await this.export());
  }

  onCloseClicked(): void {
    this.close && this.close();
    this.service.close();
  }
}
