import {Component, Input} from '@angular/core';

@Component({
  selector: 'app-mail-link',
  template: `
    <a *ngIf="value" [href]="'mailto:' + value">
      <span>
        {{ value }}
      </span>
      &nbsp;
      <fa-icon [fixedWidth]="true"icon="envelope"></fa-icon>
    </a>
  `
})
export class MailLinkComponent {
  @Input()
  value: string | undefined = undefined;
}
