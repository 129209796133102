import {ErrorHandler, LOCALE_ID, NgModule} from '@angular/core';
import {DatePipe, registerLocaleData} from '@angular/common';
import {HTTP_INTERCEPTORS, HttpClient, HttpClientModule} from '@angular/common/http';
import {Title} from '@angular/platform-browser';
import {FaIconLibrary} from '@fortawesome/angular-fontawesome';
import {CookieModule} from 'ngx-cookie';
import {MissingTranslationHandler, TranslateLoader, TranslateModule} from '@ngx-translate/core';
import {NgxWebstorageModule} from 'ngx-webstorage';
import {JhiLanguageService, NgJhipsterModule, translatePartialLoader} from 'ng-jhipster';
import locale from '@angular/common/locales/pt';

import moment from 'moment';
import {NgbDateAdapter, NgbDateParserFormatter, NgbDatepickerConfig} from '@ng-bootstrap/ng-bootstrap';
import {NgbDateMomentAdapter} from 'app/shared/util/datepicker-adapter';

import {AuthInterceptor} from 'app/blocks/interceptor/auth.interceptor';
import {AuthExpiredInterceptor} from 'app/blocks/interceptor/auth-expired.interceptor';
import {ErrorHandlerInterceptor} from 'app/blocks/interceptor/errorhandler.interceptor';
import {NotificationInterceptor} from 'app/blocks/interceptor/notification.interceptor';
import {TranslationNotFoundHandler} from 'app/shared/language/translation-not-found.handler';
import {NgbDatepickerFormatter} from 'app/shared/util/datepicker-formatter';
import {fas} from '@fortawesome/pro-solid-svg-icons';
import {far} from '@fortawesome/pro-regular-svg-icons';
import {fad} from '@fortawesome/pro-duotone-svg-icons';
import {fal} from '@fortawesome/pro-light-svg-icons';
import {fab} from '@fortawesome/free-brands-svg-icons';
import {LanguageUtil} from 'app/shared/util/language-util';
import {GlobalErrorHandler} from 'app/blocks/interceptor/global-error-handler.interceptor';

@NgModule({
  imports: [
    HttpClientModule,
    CookieModule.forRoot(),
    NgxWebstorageModule.forRoot({prefix: 'app', separator: '-'}),
    NgJhipsterModule.forRoot({
      // set below to true to make alerts look like toast
      alertAsToast: true,
      alertTimeout: 5000,
      i18nEnabled: true,
      defaultI18nLang: (() => {
        const lang = sessionStorage?.getItem('app-locale')?.replace(/"/g, '');
        return LanguageUtil.isValid(lang) ? lang : LanguageUtil.getDefault().code;
      })()
    }),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: translatePartialLoader,
        deps: [HttpClient]
      },
      missingTranslationHandler: {
        provide: MissingTranslationHandler,
        useClass: TranslationNotFoundHandler
      }
    })
  ],
  providers: [
    Title,
    {
      provide: LOCALE_ID,
      useValue: 'pt'
    },
    {
      provide: NgbDateAdapter,
      useClass: NgbDateMomentAdapter
    },
    {
      provide: NgbDateParserFormatter,
      useClass: NgbDatepickerFormatter
    },
    DatePipe,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthExpiredInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ErrorHandlerInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: NotificationInterceptor,
      multi: true
    },
    {
      provide: ErrorHandler,
      useClass: GlobalErrorHandler

    }
  ]
})
export class AppCoreModule {
  constructor(iconLibrary: FaIconLibrary, dpConfig: NgbDatepickerConfig, languageService: JhiLanguageService) {
    registerLocaleData(locale);
    // @ts-ignore
    iconLibrary.addIconPacks(fas, far, fad, fal, fab);
    dpConfig.minDate = {year: moment().year() - 100, month: 1, day: 1};
    languageService.init();
  }
}
