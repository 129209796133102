import {ConditionType} from 'app/shared/model/enumerations/condition-type.enum';

export function convertCriteria(criteria: any): any {
  if (!criteria) {
    return {};
  }
  const newCriteria = {};

  Object.entries(criteria).forEach(([key, value]) => {

    const data = key.split('.');
    const field = data[0];
    const condition = data[1];
    let conditionType: ConditionType;
    switch (condition) {
      case 'equals':
        conditionType = ConditionType.EQUALS;
        break;
      case 'notEquals':
        conditionType = ConditionType.NOT_EQUALS;
        break;
      case 'greaterThan':
        conditionType = ConditionType.GREATER_THAN;
        break;
      case 'lessThan':
        conditionType = ConditionType.LESS_THAN;
        break;
      case 'greaterOrEqualThan':
        conditionType = ConditionType.GREATER_THAN_OR_EQUAL;
        break;
      case 'lessOrEqualThan':
        conditionType = ConditionType.LESS_THAN_OR_EQUAL;
        break;
    }

    if (!newCriteria[field]) {
      newCriteria[field] = {conditions: []};
    }
    newCriteria[field].conditions.push({
      value: value,
      condition: conditionType
    });
  });

  return {base64Entity: btoa(JSON.stringify(newCriteria))};
}
