import {Injectable} from '@angular/core';
import {HttpClient, HttpResponse} from '@angular/common/http';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';

import {SERVER_API_URL} from 'app/app.constants';
import {createRequestOption} from 'app/shared/util/request-util';
import {ICompany} from 'app/shared/model/company/company.model';
import {FormModel, IFormModel} from 'app/shared/model/form/form-model.model';
import {FormType} from 'app/shared/model/enumerations/form-type.enum';

type EntityResponseType = HttpResponse<IFormModel>;
type EntityArrayResponseType = HttpResponse<IFormModel[]>;

@Injectable({providedIn: 'root'})
export class FormModelService {
	public resourceUrl = `${SERVER_API_URL}api/frm/forms-models`;

	constructor(protected http: HttpClient) {
	}

	create(formModel: IFormModel): Observable<EntityResponseType> {
		const copy = FormModel.convertDatesFromClient(formModel);
		return this.http
			.post<IFormModel>(this.resourceUrl, copy, {observe: 'response'})
			.pipe(map((res: EntityResponseType) => this.convertDateFromServer(res)));
	}

	update(formModel: IFormModel): Observable<EntityResponseType> {
		const copy = FormModel.convertDatesFromClient(formModel);
		return this.http
			.put<IFormModel>(this.resourceUrl, copy, {observe: 'response'})
			.pipe(map((res: EntityResponseType) => this.convertDateFromServer(res)));
	}

	find(id: number): Observable<EntityResponseType> {
		return this.http
			.get<IFormModel>(`${this.resourceUrl}/${id}`, {observe: 'response'})
			.pipe(map((res: EntityResponseType) => this.convertDateFromServer(res)));
	}

	query(req?: any): Observable<EntityArrayResponseType> {
		const options = createRequestOption(req);
		return this.http
			.get<IFormModel[]>(this.resourceUrl, {params: options, observe: 'response'})
			.pipe(map((res: EntityArrayResponseType) => this.convertDateArrayFromServer(res)));
	}

	getActiveByCompany(company: ICompany): Observable<EntityArrayResponseType> {
		return this.http
			.get<IFormModel[]>(`${this.resourceUrl}/active-by-company`, {
				params: {
					companyId: `${company.id}`
				},
				observe: 'response'
			})
			.pipe(map((res: EntityArrayResponseType) => this.convertDateArrayFromServer(res)));
	}

	getAvailableByCompany(company?: ICompany): Observable<EntityArrayResponseType> {
		return this.http
			.get<IFormModel[]>(`${this.resourceUrl}/available-by-company`, {
				...(company ? {
					params: {
						companyId: `${company.id}`
					}
				} : {}),
				observe: 'response'
			})
			.pipe(map((res: EntityArrayResponseType) => this.convertDateArrayFromServer(res)));
	}

	getAllSystemActiveByType(type: FormType): Observable<EntityArrayResponseType> {
		return this.http
			.get<IFormModel[]>(`${this.resourceUrl}/system-active-by-type`, {
				params: {
					type: `${type}`
				},
				observe: 'response'
			})
			.pipe(map((res: EntityArrayResponseType) => this.convertDateArrayFromServer(res)));
	}

	getAllActiveForProductByCategories(categoryIds: number[]): Observable<EntityArrayResponseType> {
		return this.http
			.get<IFormModel[]>(`${this.resourceUrl}/all-active-for-product-by-categories`, {
				params: {
					categoryIds: categoryIds
				},
				observe: 'response'
			})
			.pipe(map((res: EntityArrayResponseType) => this.convertDateArrayFromServer(res)));
	}

	getSystemOwnerActiveById(id: number): Observable<EntityResponseType> {
		return this.http
			.get<IFormModel>(`${this.resourceUrl}/system-owner-active/${id}`, {observe: 'response'})
			.pipe(map((res: EntityResponseType) => this.convertDateFromServer(res)));
	}

	delete(id: number): Observable<HttpResponse<{}>> {
		return this.http.delete(`${this.resourceUrl}/${id}`, {observe: 'response'});
	}

	protected convertDateFromServer(res: EntityResponseType): EntityResponseType {
		res.body && FormModel.convertDatesFromServer(res.body);
		return res;
	}

	protected convertDateArrayFromServer(res: EntityArrayResponseType): EntityArrayResponseType {
		res.body && res.body.forEach((formModel: IFormModel) => FormModel.convertDatesFromServer(formModel));
		return res;
	}
}
