import {AbstractControl, ValidationErrors, Validator} from '@angular/forms';

export class BrValidator implements Validator {
  static cpfLength = 11;
  static cnpjLength = 14;

  /**
   * Calcula o dígito verificador do CPF ou CNPJ.
   */
  static buildDigit(arr: number[]): number {
    const isCpf = arr.length < BrValidator.cpfLength;
    const digit =
      arr.map((val, idx) => val * ((!isCpf ? idx % 8 : idx) + 2)).reduce((total, current) => total + current) % BrValidator.cpfLength;

    if (digit < 2 && isCpf) {
      return 0;
    } else if (digit < 2) {
      return 0;
    }

    return BrValidator.cpfLength - digit;
  }

  /**
   * Valida um CPF ou CNPJ de acordo com seu dígito verificador.
   */
  static validate(c: AbstractControl, testLength = false): ValidationErrors | null {
    const cpfCnpj = (c.value || '').replace(/\D/g, '');

    // Verifica o tamanho da string.
    if ([BrValidator.cpfLength, BrValidator.cnpjLength].indexOf(cpfCnpj.length) < 0) {
      return testLength ? {length: true} : null;
    }

    // Verifica se todos os dígitos são iguais.
    if (/^([0-9])\1*$/.test(cpfCnpj)) {
      return {invalid: true};
    }

    // A seguir é realizado o cálculo verificador.
    const cpfCnpjArr: number[] = cpfCnpj
      .split('')
      .reverse()
      .slice(2);

    cpfCnpjArr.unshift(BrValidator.buildDigit(cpfCnpjArr));
    cpfCnpjArr.unshift(BrValidator.buildDigit(cpfCnpjArr));

    if (cpfCnpj !== cpfCnpjArr.reverse().join('')) {
      // Dígito verificador não é válido, resultando em falha.
      return {invalid: true};
    }

    return null;
  }

  /**
   * Implementa a interface de um validator.
   */
  validate(c: AbstractControl): ValidationErrors | null {
    return BrValidator.validate(c);
  }
}
