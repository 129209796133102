import {Injectable} from '@angular/core';
import {HttpClient, HttpResponse} from '@angular/common/http';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';

import {SERVER_API_URL} from 'app/app.constants';
import {createRequestOption} from 'app/shared/util/request-util';
import {Form, IForm} from 'app/shared/model/form/form.model';
import {ICompany} from 'app/shared/model/company/company.model';

type EntityResponseType = HttpResponse<IForm>;
type EntityArrayResponseType = HttpResponse<IForm[]>;

@Injectable({providedIn: 'root'})
export class FormService {
  public resourceUrl = `${SERVER_API_URL}api/frm/forms`;

  constructor(protected http: HttpClient) {}

  create(form: IForm): Observable<EntityResponseType> {
    const copy = this.convertDateFromClient(form);
    return this.http
      .post<IForm>(this.resourceUrl, copy, {observe: 'response'})
      .pipe(map((res: EntityResponseType) => this.convertDateFromServer(res)));
  }

  batchInsert(formLaunchs: IForm[], company: ICompany): Observable<HttpResponse<{}>> {
    const copy = formLaunchs.map(ct => this.convertDateFromClient(ct));
    return this.http.post<IForm[]>(`${this.resourceUrl}/batch-insert`, copy, {
      params: {
        companyId: `${company.id}`
      },
      observe: 'response'
    });
  }

  update(form: IForm): Observable<EntityResponseType> {
    const copy = this.convertDateFromClient(form);
    return this.http
      .put<IForm>(this.resourceUrl, copy, {observe: 'response'})
      .pipe(map((res: EntityResponseType) => this.convertDateFromServer(res)));
  }

  find(id: number): Observable<EntityResponseType> {
    return this.http
      .get<IForm>(`${this.resourceUrl}/${id}`, {observe: 'response'})
      .pipe(map((res: EntityResponseType) => this.convertDateFromServer(res)));
  }

  query(req?: any): Observable<EntityArrayResponseType> {
    const options = createRequestOption(req);
    return this.http
      .get<IForm[]>(this.resourceUrl, {params: options, observe: 'response'})
      .pipe(map((res: EntityArrayResponseType) => this.convertDateArrayFromServer(res)));
  }

  delete(id: number): Observable<HttpResponse<{}>> {
    return this.http.delete(`${this.resourceUrl}/${id}`, {observe: 'response'});
  }

  updateForCurrentUser(form: IForm): Observable<EntityResponseType> {
    const copy = this.convertDateFromClient(form);
    return this.http
      .post<IForm>(`${this.resourceUrl}/update-for-current-user`, copy, {observe: 'response'})
      .pipe(map((res: EntityResponseType) => this.convertDateFromServer(res)));
  }

  updateForEvaluator(form: IForm): Observable<EntityResponseType> {
    const copy = this.convertDateFromClient(form);
    return this.http
      .post<IForm>(`${this.resourceUrl}/update-for-evaluator`, copy, {observe: 'response'})
      .pipe(map((res: EntityResponseType) => this.convertDateFromServer(res)));
  }

  protected convertDateFromClient(form: IForm): IForm {
    return Form.convertDatesFromClient(form);
  }

  protected convertDateFromServer(res: EntityResponseType): EntityResponseType {
    res.body && Form.convertDatesFromServer(res.body);
    return res;
  }

  protected convertDateArrayFromServer(res: EntityArrayResponseType): EntityArrayResponseType {
    res.body && res.body.forEach((form: IForm) => Form.convertDatesFromServer(form));
    return res;
  }
}
