<div class="modal-header">
  <h4 class="modal-title" jhiTranslate="help.home.title"></h4>
  <button type="button" class="close" data-dismiss="modal" aria-hidden="true" (click)="dismiss()">&times;</button>
</div>

<div class="modal-body">
  <h1>{{title}}</h1>
  <pdf-viewer [src]="file?.url"
              [render-text]="true"
              [original-size]="false"
              [fit-to-page]="true"></pdf-viewer>
</div>

<div class="modal-footer" *ngIf="buttons && buttons.length">
  <span *ngFor="let btn of buttons">
    <button *ngIf="btn.visible()" type="button" [disabled]="!btn.enabled()" class="btn btn-{{btn.color()}}"
            (click)="btn.action()" title="{{(btn.tooltip() || '') | translate}}">
      <fa-icon *ngIf="btn.icon() as icon" [fixedWidth]="true" [icon]="icon"></fa-icon>
      &nbsp;
      <span *ngIf="btn.label() as label" jhiTranslate="{{label}}"></span>
    </button>
  </span>
</div>
