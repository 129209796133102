import {Routes} from '@angular/router';

export const moduleRoutes: Routes = [
  {
    path: 'mod/features',
    loadChildren: () => import('./feature/feature.module').then(m => m.AppFeatureModule),
    data: {
      pageTitle: 'feature.home.title'
    }
  },
  {
    path: 'mod/helps',
    loadChildren: () => import('./help/help.module').then(m => m.AppHelpModule),
    data: {
      pageTitle: 'help.home.title'
    }
  },
  {
    path: 'mod/helps-categories',
    loadChildren: () => import('./help-category/help-category.module').then(m => m.AppHelpCategoryModule),
    data: {
      pageTitle: 'helpCategory.home.title'
    }
  },
  {
    path: 'mod/modules',
    loadChildren: () => import('./module/module.module').then(m => m.AppModuleModule),
    data: {
      pageTitle: 'module.home.title'
    }
  }
];
