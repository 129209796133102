import {Pipe, PipeTransform} from '@angular/core';
import {Criteria} from 'app/shared/filter/criteria.model';
import {IBaseEntity} from 'app/shared/model/root/base-entity.model';

export class CriteriaPropertyFilter {
  constructor(public admin: boolean, public audit: boolean, public identifier: boolean) {}
}

@Pipe({
  name: 'criteriaPropertyFilterPipe',
  pure: false
})
export class CriteriaPropertyFilterPipe<T extends IBaseEntity> implements PipeTransform {
  transform(criterias: Criteria<T>[], filter: CriteriaPropertyFilter): any {
    if (!criterias || !filter) {
      return criterias;
    }
    return criterias.filter(criteria =>
      criteria.property.admin
        ? filter.admin
        : criteria.property.audit
        ? filter.audit
        : criteria.property.identifier
        ? filter.identifier
        : true
    );
  }
}
