import {Component, EventEmitter, Input, Output} from '@angular/core';
import {ButtonBuilder} from 'app/shared/layout/window/button-builder';
import {GenericModalService} from 'app/shared/modal/generic-modal.service';
import {TranslateService} from '@ngx-translate/core';
import {File, IFile} from 'app/shared/model/admin/file.model';
import {UploadFileModalService} from 'app/shared/upload/upload-file-modal.service';
import {FileService} from 'app/shared/services/admin/file.service';
import {FileType} from 'app/shared/model/enumerations/file-type.enum';

@Component({
  selector: 'app-file-input',
  styles: [
    `
      :host {
        display: flex;
        flex: auto;
      }

      .btn {
        border-bottom-left-radius: 0;
        border-top-left-radius: 0;
      }
    `
  ],
  template: `
    <input (click)="upload()" type="text" class="form-control" readonly [value]="print()" />
    <div *ngIf="downloadBtnVisible || uploadBtnVisible || removeBtnVisible" class="input-group-append">
      <button
        *ngIf="file && file.fileType === 'FILE' && downloadBtnVisible"
        class="btn btn-outline-primary"
        (click)="download()"
        type="button"
        [disabled]="downloadBtnDisabled"
        [title]="'entity.action.download' | translate"
      >
        <fa-icon [fixedWidth]="true" icon="download"></fa-icon>
      </button>
      <a
        *ngIf="file && file.fileType === 'LINK' && downloadBtnVisible"
        class="btn btn-outline-primary d-flex"
        [class.disabled]="downloadBtnDisabled"
        [href]="file.url"
        target="_blank"
        [title]="'entity.action.open' | translate"
      >
        <fa-icon [fixedWidth]="true" icon="external-link-alt" class="m-auto"></fa-icon>
      </a>
      <button
        *ngIf="file && removeBtnVisible"
        class="btn btn-outline-danger"
        (click)="remove()"
        type="button"
        [disabled]="removeBtnDisabled"
        [title]="'entity.action.remove' | translate"
      >
        <fa-icon [fixedWidth]="true" icon="times"></fa-icon>
      </button>
      <button
        *ngIf="uploadBtnVisible"
        class="btn btn-outline-info"
        (click)="upload()"
        type="button"
        [disabled]="editBtnDisabled"
        [title]="'entity.action.upload' | translate"
      >
        <fa-icon [fixedWidth]="true" icon="upload"></fa-icon>
      </button>
    </div>
  `
})
export class FileInputComponent {
  @Input()
  onlyByUpload: boolean = false;

  @Input()
  file: File | null = null;

  @Input()
  uploadBtnVisible = true;

  @Input()
  editBtnDisabled = false;

  @Input()
  downloadBtnVisible = false;

  @Input()
  downloadBtnDisabled = false;

  @Input()
  removeBtnVisible = true;

  @Input()
  removeBtnDisabled = false;

  @Input()
  fileExtensions: string;

  @Output()
  onUploaded: EventEmitter<IFile> = new EventEmitter<IFile>();

  @Output()
  onUploadedCanceled: EventEmitter<unknown> = new EventEmitter<unknown>();

  @Output()
  onRemoved: EventEmitter<IFile | null> = new EventEmitter<IFile | null>();

  constructor(
    private uploadFileModalService: UploadFileModalService,
    private genericModalService: GenericModalService,
    private translateService: TranslateService,
    private fileService: FileService
  ) {
  }

  print(): string {
    return this.file ? this.file.sourceName : '';
  }

  upload(): void {
    !this.editBtnDisabled &&
    this.uploadFileModalService.show({
        fileExtension: this.fileExtensions || '*.*',
        ...(this.onlyByUpload ? {fileTypes: [FileType.FILE]} : {})
      },
      file => this.onUploaded.emit(file),
      () => this.onUploadedCanceled.emit()
    );
  }

  download(): void {
    !this.downloadBtnDisabled && this.fileService.download(this.file);
  }

  remove(): void {
    !this.removeBtnDisabled &&
    this.genericModalService.show(
      'entity.modals.confirm.title',
      this.translateService.instant('upload.modal.remove.question', {file: this.file.name}),
      [
        ButtonBuilder.cancel(() => this.genericModalService.dismiss()),
        ButtonBuilder.remove(() => {
          this.onRemoved.emit(this.file);
          this.genericModalService.confirm();
        })
      ]
    );
  }
}
