import {Injectable} from '@angular/core';
import {NgbModal, NgbModalOptions, NgbModalRef} from '@ng-bootstrap/ng-bootstrap';
import {Button} from 'app/shared/layout/window/button.model';
import {HelpModalComponent} from 'app/entities/module/help/help-modal/help-modal.component';
import {IFile} from 'app/shared/model/admin/file.model';

@Injectable({providedIn: 'root'})
export class HelpModalService {
  private isOpen = false;
  private instance?: NgbModalRef;

  constructor(private modalService: NgbModal) {}

  show(title: string, file: IFile, buttons: Button[], options?: NgbModalOptions): void {
    if (this.isOpen) return;

    this.isOpen = true;

    this.instance = this.modalService.open(HelpModalComponent, options || {size: 'lg', backdrop: 'static'});
    this.instance.componentInstance.title = title;
    this.instance.componentInstance.file = file;
    this.instance.componentInstance.buttons = buttons;

    this.instance.result.finally(() => (this.isOpen = false));
  }

  confirm(): void {
    if (!this.isOpen || !this.instance) return;
    this.instance.close();
  }

  dismiss(): void {
    if (!this.isOpen || !this.instance) return;
    this.instance.dismiss();
  }
}
