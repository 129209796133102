export type LoggerMessageFunction = () => string;

const sign = {
  debug: '🟪',
  info: '🟦',
  warn: '🟧',
  error: '🟥'
};

export class Logger {
  protected _clazz: string;

  constructor(clazz: string) {
    this._clazz = clazz;
  }

  protected output(level: string, msg: LoggerMessageFunction, e?: Error): void {
    let message: string;
    try {
      message = msg();
    } catch (err) {
      message = `Error during logger message evaluation => ${err.toString()}`;
    }
    // eslint-disable-next-line no-console
    console[level](
      `${sign[level]}[${level.toUpperCase()}|${new Date().toISOString()}|${this._clazz}] => [${message}]${
        e ? ` [Exception: ${e.toString()} | StackTrace: ${e.stack}]` : ''
      }`
    );
  }

  public debug(msg: LoggerMessageFunction): void {
    this.output('debug', msg);
  }

  public info(msg: LoggerMessageFunction): void {
    this.output('info', msg);
  }

  public warn(msg: LoggerMessageFunction): void {
    this.output('warn', msg);
  }

  public error(msg: LoggerMessageFunction, e?: Error | any): void {
    this.output('error', msg, e);
  }
}
