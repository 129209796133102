import {Routes} from '@angular/router';

export const devopsRoutes: Routes = [
  {
    path: 'dev/vaults',
    loadChildren: () => import('./vault/vault.module').then(m => m.AppVaultModule),
    data: {
      pageTitle: 'vault.home.title'
    }
  }
];
