import {Injectable} from '@angular/core';
import {NgbModal, NgbModalRef} from '@ng-bootstrap/ng-bootstrap';
import {UploadFileModalComponent} from 'app/shared/upload/upload-file-modal.component';
import {FileFunction, UploadOptions} from 'app/shared/upload/upload-options.model';

@Injectable({providedIn: 'root'})
export class UploadFileModalService {
  private isOpen = false;
  private instance?: NgbModalRef;

  constructor(private modalService: NgbModal) {}

  show(options: UploadOptions, fileUploaded?: FileFunction, cancel?: Function): void {
    if (this.isOpen) return;

    this.isOpen = true;

    this.instance = this.modalService.open(UploadFileModalComponent, {size: 'lg', backdrop: 'static'});
    this.instance.componentInstance.options = options;
    this.instance.componentInstance.fileUploaded = fileUploaded;
    this.instance.componentInstance.cancel = cancel;

    this.instance.result.finally(() => (this.isOpen = false));
  }

  confirm(): void {
    if (!this.isOpen || !this.instance) return;
    this.instance.close();
  }

  dismiss(): void {
    if (!this.isOpen || !this.instance) return;
    this.instance.dismiss();
  }
}
