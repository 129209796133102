import {AfterViewInit, Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild} from '@angular/core';

import grapesjs from 'grapesjs';
import grapesJSMJML from 'grapesjs-mjml';
import {defaultMjmlTemplate, en, es, fonts, mjmlEn, mjmlEs, mjmlPtBR, ptBR} from 'app/shared/grapejs/grapejs-util';
import {UploadImageModalService} from 'app/shared/upload/upload-image-modal.service';
import {IFile} from 'app/shared/model/admin/file.model';
import {LangChangeEvent, TranslateService} from '@ngx-translate/core';
import {Subscription} from 'rxjs';
import {unsubscribe} from 'app/shared/util/react-util';
import {LanguageUtil} from 'app/shared/util/language-util';

@Component({
  selector: 'app-grapejs-mjml-editor',
  template: `
    <div class="app-mjml-editor" [ngClass]="theme === 'light' ? 'app-gjs-light' : ''" #gjs></div>
  `,
  styleUrls: ['./grapejs-mjml-editor.component.scss']
})
export class GrapejsMjmlEditorComponent implements OnInit, AfterViewInit, OnDestroy {
  /*
    https://esketchers.com/integration-of-grapesjs-with-angular/
    https://stackblitz.com/edit/angular-f1pviw?file=src%2Fapp%2Fapp.component.ts
  */

  @Input()
  mjml?: string;

  @Input()
  theme: 'light' | 'dark' = 'dark';

  @Output()
  onUpdate: EventEmitter<null> = new EventEmitter<null>();

  @ViewChild('gjs')
  private gjs: ElementRef;

  private editor: grapesjs.Editor;

  private subscriptions: Subscription[] = [];

  constructor(
    private uploadImageModalService: UploadImageModalService,
    private translateService: TranslateService,
    private languageUtil: LanguageUtil
  ) {}

  ngOnInit(): void {
    this.subscriptions.push(
      this.translateService.onLangChange.subscribe((langChangeEvent: LangChangeEvent) => {
        if (this.editor) {
          const mjml = this.getMjml();
          this.destroyEditor();
          this.createGrapes(mjml, langChangeEvent.lang);
        }
      })
    );
  }

  ngAfterViewInit(): void {
    this.createGrapes(this.mjml ?? defaultMjmlTemplate, this.languageUtil.getActiveCode());
  }

  private createGrapes(mjml: string, lang: string): void {
    this.gjs.nativeElement.innerHTML = mjml;

    this.editor = grapesjs.init({
      container: this.gjs.nativeElement,
      fromElement: true,
      autorender: true,
      forceClass: false,
      width: 'auto',
      storageManager: false,
      // @ts-ignore
      log: false,
      // @ts-ignore
      colorPicker: {
        appendTo: 'parent',
        offset: {top: 26, left: -166}
      },
      i18n: {
        locale: lang?.startsWith('pt') ? 'pt' : lang ?? 'en',
        detectLocale: false,
        messages: {
          pt: ptBR,
          en: en,
          es: es
        }
      },
      assetManager: {
        assets: [],
        showUrlInput: false,
        custom: true
      },
      plugins: [grapesJSMJML],
      pluginsOpts: {
        // @ts-ignore
        [grapesJSMJML]: {
          codeViewerTheme: this.theme === 'light' ? 'default' : 'hopscotch',
          blocks: [
            'mj-1-column',
            'mj-2-columns',
            'mj-3-columns',
            'mj-text',
            'mj-button',
            'mj-image',
            'mj-divider',
            'mj-social-group',
            'mj-social-element',
            'mj-spacer',
            'mj-navbar',
            'mj-navbar-link',
            'mj-hero',
            'mj-wrapper'
            // 'mj-raw'
          ],
          i18n: {
            pt: mjmlPtBR,
            en: mjmlEn,
            es: mjmlEs
          },
          fonts: fonts.map(f => {
            const font = {};
            font[f.name] = f.url;

            return font;
          })
        }
      },
      canvas: {
        styles: fonts.map(f => f.url)
      }
    });

    this.editor.on('load', () => this.loadFonts());
    this.editor.on('update', () => this.onUpdate.emit());

    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const thisComponent = this;
    this.editor.Commands.add('open-assets', {
      run(editor, sender, opts) {
        const assetTarget = opts.target;
        thisComponent.openImageModal(newFile => assetTarget.set('src', newFile.url));
      }
    });
  }

  private loadFonts(): void {
    const styleManager = this.editor.StyleManager;
    const fontProperty = styleManager.getProperty('typography', 'font-family');

    fontProperty.setOptions(
      fonts.map(f => ({
        name: f.name,
        value: f.value
      }))
    );
    fontProperty.set('defaults', `Montserrat, Open Sans, sans-serif`);

    styleManager.render();
  }

  openImageModal(imageUploaded: (newFile: IFile) => void): void {
    this.uploadImageModalService.show(
      file => {
        file._edited = true;
        imageUploaded && imageUploaded(file);
      },
      () => undefined,
      {
        fileExtension: '.jpg,.jpeg,.png',
        container: this.gjs.nativeElement
      }
    );
  }

  public getJson(): string {
    const json = this.editor?.getComponents()['toJSON']();
    return JSON.stringify(json);
  }

  public getMjml(): string {
    return this.editor?.runCommand('mjml-code');
  }

  public getHtml(): string {
    return this.editor?.runCommand('mjml-code-to-html')?.html;
  }

  private destroyEditor(): void {
    this.editor?.destroy();
  }

  ngOnDestroy(): void {
    unsubscribe(this.subscriptions);
    this.destroyEditor();
  }
}
