import {Moment} from 'moment';

export interface IBaseEntity {
  id?: number;
  uuid?: string;
  createdBy?: string;
  createdDate?: Moment;
  lastModifiedBy?: string;
  lastModifiedDate?: Moment;
  active?: boolean;
  _label?: string;
  _edited?: boolean;

  isNew(): boolean;
}

export abstract class BaseEntity implements IBaseEntity {
  protected constructor(
    public id?: number,
    public uuid?: string,
    public createdBy?: string,
    public createdDate?: Moment,
    public lastModifiedBy?: string,
    public lastModifiedDate?: Moment,
    public active?: boolean,
    public _label?: string,
    public _edited?: boolean
  ) {
    this.active = this.active || false;
  }

  isNew(): boolean {
    return this.id === undefined;
  }
}
