<div *ngIf="formAnswer">
  <form autocomplete="off" #form="ngForm" name="editForm" role="form" novalidate (ngSubmit)="onConfirmClicked()"
        [formGroup]="editForm">

    <div class="modal-header">
      <h4 class="modal-title" jhiTranslate="entity.modals.confirm.title"></h4>
      <button type="button" class="close" data-dismiss="modal" aria-hidden="true" (click)="onCancelClicked()">&times;
      </button>
    </div>

    <div class="modal-body">

      <div class="row justify-content-center">
        <p class="pb-3" jhiTranslate="form.modals.rejectAnswer.question"
           [translateValues]="{'id': formAnswer._label}"></p>
      </div>

      <div class="row justify-content-center">
        <div class="col-12">
          <div class="form-row">

            <!-- description -->
            <div class="form-group col-12 pb-1">
              <div class="input-group">
                <div class="input-group-prepend">
                  <label class="input-group-text required" jhiTranslate="form.formAnswer.approvalObs"></label>
                </div>
                <textarea class="form-control" name="description" formControlName="description" rows="6"
                          maxlength="2000"
                          [ngClass]="{'is-invalid': isInvalidAndTouched('description')}"></textarea>
              </div>

              <div *ngIf="isInvalidAndTouched('description')">
                <small class="form-text text-danger" *ngIf="editForm.get('description')?.errors?.required"
                       jhiTranslate="entity.validation.required">
                </small>

                <small class="form-text text-danger" *ngIf="editForm.get('description')?.errors?.maxlength"
                       jhiTranslate="entity.validation.maxlength" [translateValues]="{ max: 2000 }">
                </small>
              </div>
            </div>

          </div>
        </div>
      </div>
    </div>

    <div class="modal-footer">
      <div class="form-row">
        <div class="col-12">
          <div class="float-right">
            <button type="button" (click)="onCancelClicked()" class="btn btn-secondary">
              <fa-icon [fixedWidth]="true" icon="ban"></fa-icon>
              &nbsp;
              <span jhiTranslate="entity.action.cancel"></span>
            </button>
            &nbsp;
            <button type="submit" class="btn btn-danger" [disabled]="!canSubmit()">
              <fa-icon [fixedWidth]="true" icon="times-circle"></fa-icon>
              &nbsp;
              <span jhiTranslate="entity.action.refuse"></span>
            </button>
          </div>
        </div>
      </div>
    </div>
  </form>
</div>
