import {Routes} from '@angular/router';

export const companyRoutes: Routes = [
  {
    path: 'com/companies',
    loadChildren: () => import('./company/company.module').then(m => m.AppCompanyModule),
    data: {
      pageTitle: 'company.home.title'
    }
  },
  {
    path: 'com/companies-parameters',
    loadChildren: () => import('./company-parameter/company-parameter.module').then(m => m.AppCompanyParameterModule),
    data: {
      pageTitle: 'companyParameter.home.title'
    }
  },
  {
    path: 'com/cnpjs',
    loadChildren: () => import('./cnpj/cnpj.module').then(m => m.AppCnpjModule),
    data: {
      pageTitle: 'cnpj.home.title'
    }
  }
];
