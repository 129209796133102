import {Injectable} from '@angular/core';
import {HttpClient, HttpResponse} from '@angular/common/http';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';

import {SERVER_API_URL} from 'app/app.constants';
import {createRequestOption} from 'app/shared/util/request-util';
import {convertDateFromClientUtil, convertDateFromServerUtil} from 'app/shared/util/date-util';
import {IWidgetDetail} from 'app/shared/model/erp/widget-detail.model';

type EntityResponseType = HttpResponse<IWidgetDetail>;
type EntityArrayResponseType = HttpResponse<IWidgetDetail[]>;

@Injectable({providedIn: 'root'})
export class WidgetDetailService {
  public resourceUrl = `${SERVER_API_URL}api/erp/widgets-details`;

  constructor(protected http: HttpClient) {
  }

  find(id: number): Observable<EntityResponseType> {
    return this.http
      .get<IWidgetDetail>(`${this.resourceUrl}/${id}`, {observe: 'response'})
      .pipe(map((res: EntityResponseType) => this.convertDateFromServer(res)));
  }

  query(req?: any): Observable<EntityArrayResponseType> {
    const options = createRequestOption(req);
    return this.http
      .get<IWidgetDetail[]>(this.resourceUrl, {params: options, observe: 'response'})
      .pipe(map((res: EntityArrayResponseType) => this.convertDateArrayFromServer(res)));
  }

  count(req?: any): Observable<HttpResponse<number>> {
    const options = createRequestOption(req);
    return this.http
      .get<number>(`${this.resourceUrl}/count`, {params: options, observe: 'response'});
  }

  delete(id: number): Observable<HttpResponse<{}>> {
    return this.http.delete(`${this.resourceUrl}/${id}`, {observe: 'response'});
  }

  protected convertDateFromClient(widget: IWidgetDetail): IWidgetDetail {
    return convertDateFromClientUtil(widget);
  }

  protected convertDateFromServer(res: EntityResponseType): EntityResponseType {
    if (res.body) {
      convertDateFromServerUtil(res.body);
    }
    return res;
  }

  protected convertDateArrayFromServer(res: EntityArrayResponseType): EntityArrayResponseType {
    if (res.body) {
      res.body.forEach((widget: IWidgetDetail) => convertDateFromServerUtil(widget));
    }
    return res;
  }
}
