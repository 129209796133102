import {Injectable} from '@angular/core';
import {HttpClient, HttpResponse} from '@angular/common/http';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import moment from 'moment';

import {SERVER_API_URL} from 'app/app.constants';
import {createRequestOption} from 'app/shared/util/request-util';
import {ICity} from 'app/shared/model/location/city.model';
import {IState} from 'app/shared/model/location/state.model';

type EntityResponseType = HttpResponse<ICity>;
type EntityArrayResponseType = HttpResponse<ICity[]>;

@Injectable({providedIn: 'root'})
export class CityService {
  public resourceUrl = `${SERVER_API_URL}api/loc/cities`;

  constructor(protected http: HttpClient) {}

  create(city: ICity): Observable<EntityResponseType> {
    const copy = this.convertDateFromClient(city);
    return this.http
      .post<ICity>(this.resourceUrl, copy, {observe: 'response'})
      .pipe(map((res: EntityResponseType) => this.convertDateFromServer(res)));
  }

  update(city: ICity): Observable<EntityResponseType> {
    const copy = this.convertDateFromClient(city);
    return this.http
      .put<ICity>(this.resourceUrl, copy, {observe: 'response'})
      .pipe(map((res: EntityResponseType) => this.convertDateFromServer(res)));
  }

  find(id: number): Observable<EntityResponseType> {
    return this.http
      .get<ICity>(`${this.resourceUrl}/${id}`, {observe: 'response'})
      .pipe(map((res: EntityResponseType) => this.convertDateFromServer(res)));
  }

  query(req?: any): Observable<EntityArrayResponseType> {
    const options = createRequestOption(req);
    return this.http
      .get<ICity[]>(this.resourceUrl, {params: options, observe: 'response'})
      .pipe(map((res: EntityArrayResponseType) => this.convertDateArrayFromServer(res)));
  }

  getByState(state?: IState): Observable<EntityArrayResponseType> {
    const options = createRequestOption({
      stateId: `${state.id}`
    });
    return this.http
      .get<ICity[]>(`${this.resourceUrl}/cities-by-state`, {params: options, observe: 'response'})
      .pipe(map((res: EntityArrayResponseType) => this.convertDateArrayFromServer(res)));
  }

  delete(id: number): Observable<HttpResponse<{}>> {
    return this.http.delete(`${this.resourceUrl}/${id}`, {observe: 'response'});
  }

  protected convertDateFromClient(city: ICity): ICity {
    const copy: ICity = Object.assign({}, city, {
      createdDate: city.createdDate && city.createdDate.isValid() ? city.createdDate.toJSON() : undefined,
      lastModifiedDate: city.lastModifiedDate && city.lastModifiedDate.isValid() ? city.lastModifiedDate.toJSON() : undefined
    });
    return copy;
  }

  protected convertDateFromServer(res: EntityResponseType): EntityResponseType {
    if (res.body) {
      res.body.createdDate = res.body.createdDate ? moment(res.body.createdDate) : undefined;
      res.body.lastModifiedDate = res.body.lastModifiedDate ? moment(res.body.lastModifiedDate) : undefined;
    }
    return res;
  }

  protected convertDateArrayFromServer(res: EntityArrayResponseType): EntityArrayResponseType {
    if (res.body) {
      res.body.forEach((city: ICity) => {
        city.createdDate = city.createdDate ? moment(city.createdDate) : undefined;
        city.lastModifiedDate = city.lastModifiedDate ? moment(city.lastModifiedDate) : undefined;
      });
    }
    return res;
  }
}
