import {Routes} from '@angular/router';

export const emailRoutes: Routes = [
  {
    path: 'eml/templates',
    loadChildren: () => import('./email-template/email-template.module').then(m => m.AppEmailTemplateModule),
    data: {
      pageTitle: 'emailTemplate.home.title'
    }
  },
  {
    path: 'eml/addresses',
    loadChildren: () => import('./email-address/email-address.module').then(m => m.AppEmailAddressModule),
    data: {
      pageTitle: 'emailAddress.home.title'
    }
  },
  {
    path: 'eml/notifications',
    loadChildren: () => import('./email-notification/email-notification.module').then(m => m.AppEmailNotificationModule),
    data: {
      pageTitle: 'emailNotification.home.title'
    }
  }
];
