import {Routes} from '@angular/router';

export const thirdPartyAuthenticationRoutes: Routes = [
  {
    path: 'tpa/configurations',
    loadChildren: () => import('./configuration/configuration.module').then(m => m.AppConfigurationModule),
    data: {
      pageTitle: 'configuration.home.title'
    }
  }
];
