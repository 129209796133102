import {Component, Input} from '@angular/core';
import {ButtonColor} from 'app/shared/layout/window/button.model';
import {Priority} from 'app/shared/model/enumerations/priority.enum';

@Component({
  selector: 'app-priority-label',
  template: `
    <span *ngIf="priority" class="badge" [ngClass]="'badge-' + (overpassColor || color())">{{ 'priority.' + priority | translate }}</span>
  `
})
export class PriorityLabelComponent {
  @Input()
  priority: Priority | null = null;

  @Input()
  overpassColor: ButtonColor | null = null;

  color(): ButtonColor {
    if (!this.priority) return 'default';

    switch (this.priority) {
      case Priority.LOW:
        return 'success';

      case Priority.AVERAGE:
        return 'info';

      case Priority.HIGH:
        return 'warning';

      case Priority.URGENT:
        return 'danger';

      default:
        return 'default';
    }
  }
}
