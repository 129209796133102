import {Injectable} from '@angular/core';
import {NgbModal, NgbModalRef} from '@ng-bootstrap/ng-bootstrap';
import {IChangeLog} from 'app/shared/model/admin/audit/change-log.model';
import {FormChangeLogDetailModalComponent} from 'app/entities/form/form/form-change-log-detail-modal.component';

@Injectable({providedIn: 'root'})
export class FormChangeLogDetailModalService {
  private isOpen = false;
  private instance?: NgbModalRef;

  constructor(private modalService: NgbModal) {
  }

  show(changeLog: IChangeLog, close?: Function): void {
    if (this.isOpen) return;

    this.isOpen = true;

    this.instance = this.modalService.open(FormChangeLogDetailModalComponent, {size: 'lg', backdrop: 'static'});
    this.instance.componentInstance.changeLog = changeLog;
    this.instance.componentInstance.close = close;

    this.instance.result.finally(() => (this.isOpen = false));
  }

  close(): void {
    if (!this.isOpen || !this.instance) return;
    this.instance.dismiss();
  }
}
