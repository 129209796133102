import {Routes} from '@angular/router';

export const locationRoutes: Routes = [
  {
    path: 'loc/countries',
    loadChildren: () => import('./country/country.module').then(m => m.AppCountryModule),
    data: {
      pageTitle: 'country.home.title'
    }
  },
  {
    path: 'loc/states',
    loadChildren: () => import('./state/state.module').then(m => m.AppStateModule),
    data: {
      pageTitle: 'state.home.title'
    }
  },
  {
    path: 'loc/locations',
    loadChildren: () => import('./location/location.module').then(m => m.AppLocationModule),
    data: {
      pageTitle: 'location.home.title'
    }
  },
  {
    path: 'loc/cities',
    loadChildren: () => import('./city/city.module').then(m => m.AppCityModule),
    data: {
      pageTitle: 'city.home.title'
    }
  }
];
